import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../base.component';
import { PopupService } from 'src/app/services/shared/popup.service';
import { PopupSettings } from 'src/app/models/popup-settings';
import { NewCallOffComponent } from '../../components/new-call-off/new-call-off.component';
import { AweFormComponent } from '../../components/awe-form/awe-form.component';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { ConfirmDialogPopupSettings } from 'src/app/models/confirm-dialog-popup-settings';
import { takeWhile } from 'rxjs/operators';
import { CallOffUpdateProperty } from 'src/app/store/call-off/actions';
import { ApplicationState } from 'src/app/store/model';
import { RoleService } from 'src/app/services/shared/role.service';
import { Constants } from 'src/app/constants';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent extends BaseComponent implements OnInit {
    updatedProperties$: Observable<string[]> = this.store.select((state) => state.callOffState.updatedProperties);
    updatedProperties: string[] = [];
    showNewAwe = false;
    showMyPage = false;
    showNewCallOff = false;
    showAWERegister = false;
    showCallOffRegister = false;

    constructor(
        private popupService: PopupService,
        private store: Store<ApplicationState>,
        private roleService: RoleService
    ) {
        super();
        this.updatedProperties$.pipe(takeWhile(() => this.isAlive === true)).subscribe((updatedProperties: []) => {
            this.updatedProperties = updatedProperties;
        });
    }

    ngOnInit() {
        this.showNewAwe = this.roleService.isInAnyRole([
            Constants.applicableGroups.Admin,
            Constants.applicableGroups.Contracts,
        ]);
        this.showMyPage = this.roleService.isInAnyRole([
            Constants.applicableGroups.Admin,
            Constants.applicableGroups.CallOffUser,
            Constants.applicableGroups.TCOApprover,
            Constants.applicableGroups.ContractorApprover,
            Constants.applicableGroups.Contracts,
        ]);
        this.showNewCallOff = this.roleService.isInAnyRole([
            Constants.applicableGroups.Admin,
            Constants.applicableGroups.CallOffUser,
        ]);
        this.showAWERegister = this.roleService.isInAnyRole([
            Constants.applicableGroups.Admin,
            Constants.applicableGroups.Contracts,
            Constants.applicableGroups.CallOffUser,
            Constants.applicableGroups.TCOApprover,
        ]);
        this.showCallOffRegister = this.roleService.isInAnyRole([
            Constants.applicableGroups.Admin,
            Constants.applicableGroups.Contracts,
            Constants.applicableGroups.CallOffUser,
            Constants.applicableGroups.TCOApprover,
            Constants.applicableGroups.ContractorApprover,
            Constants.applicableGroups.ReadOnly,
        ]);
    }

    openAWEFormPopup() {
        this.popupService.openPopup(new PopupSettings(AweFormComponent, null, null, null, 582, 510, 582, 750, true));
    }

    openCallOffPopup() {
        if (this.updatedProperties.length > 0) {
            this.popupService
                .openPopup(
                    new ConfirmDialogPopupSettings({
                        title: 'Changes are not saved!',
                        text: "You haven't save your changes. Do you want to discard them?",
                        isDiscardChanges: true,
                    })
                )
                .afterClosed()
                .pipe(takeWhile(() => this.isAlive === true))
                .subscribe((answer) => {
                    if (answer === true) {
                        this.store.dispatch(
                            new CallOffUpdateProperty({ key: null, value: null, updatedProperties: [] })
                        );
                        this.popupService.openPopup(new PopupSettings(NewCallOffComponent, 447, 660));
                    }
                });
        } else {
            this.popupService.openPopup(new PopupSettings(NewCallOffComponent, 447, 660));
        }
    }
}
