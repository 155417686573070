import { AweRegisterState, AweRegisterFilter, AweRegisterDataPagination } from './model';
import { AweRegisterActionTypes, AweRegisterActions } from './actions';

const initialState: AweRegisterState = {
    dataPagination: new AweRegisterDataPagination(),
    isLoading: false,
    filter: new AweRegisterFilter(),
};

export function reducer(state: AweRegisterState = initialState, action: AweRegisterActions) {
    switch (action.type) {
        case AweRegisterActionTypes.AweRegisterFilterRequest: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case AweRegisterActionTypes.AweRegisterFilterSuccess: {
            return {
                ...state,
                dataPagination: action.payload,
                isLoading: false,
            };
        }
        case AweRegisterActionTypes.AweRegisterFilterError: {
            return {
                ...state,
                isLoading: false,
            };
        }

        case AweRegisterActionTypes.AweDeleteRequest: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case AweRegisterActionTypes.AweDeleteSuccess: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case AweRegisterActionTypes.AweDeleteError: {
            return {
                ...state,
                isLoading: false,
            };
        }

        case AweRegisterActionTypes.AweRegisterFilterPropertyUpdate: {
            if (action.payload.key === 'sortBy') {
                return {
                    ...state,
                    filter: {
                        ...state.filter,
                        sortBy: action.payload.value.active,
                        direction: action.payload.value.direction,
                    },
                };
            } else {
                return {
                    ...state,
                    filter: {
                        ...state.filter,
                        [action.payload.key]: Array.isArray(state.filter[action.payload.key])
                            ? [...action.payload.value]
                            : action.payload.value,
                    },
                };
            }
        }
        case AweRegisterActionTypes.AweRegisterFilterReset: {
            const newFilter = new AweRegisterFilter();
            return {
                ...state,
                filter: { ...newFilter },
            };
        }
        case AweRegisterActionTypes.AweRegisterExportToExcelRequest: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case AweRegisterActionTypes.AweRegisterExportToExcelSuccess: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case AweRegisterActionTypes.AweRegisterExportToExcelError: {
            return {
                ...state,
                isLoading: false,
            };
        }
        default: {
            return state;
        }
    }
}
