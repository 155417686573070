import { NgModule } from '@angular/core';
import { StoreModule as RxjsStoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { CallOffEffects } from './call-off/effects';
import { CallOffRegisterEffects } from './call-off-register/effects';
import { AweRegisterEffects } from './awe-register/effects';
import { ActionUsersEffects } from './action-users/effects';
import { CallOffMyPageEffects } from './my-page/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import * as callOff from './call-off/reducer';
import * as jodit from './jodit-description/reducer';
import * as aweRegisterReducer from './awe-register/reducer';
import * as callOffRegister from './call-off-register/reducer';
import * as actionUsers from './action-users/reducer';
import * as callOffMyPageState from './my-page/reducers';
import * as lookupState from './lookup/reducer';
import * as constructionApproverMatrixState from './construction-approver-matrix/reducer';
import { ConstructionApproverMatrixEffects } from './construction-approver-matrix/effects';


@NgModule({
    declarations: [],
    imports: [
        RxjsStoreModule.forRoot({
            callOffState: callOff.reducer,
            joditDescriptionState: jodit.reducer,
            aweRegisterState: aweRegisterReducer.reducer,
            actionUsersState: actionUsers.reducer,
            callOffRegisterState: callOffRegister.reducer,
            callOffMyPageState: callOffMyPageState.reducer,
            lookupState: lookupState.reducer,
            constructionApproverMatrixState: constructionApproverMatrixState.reducer,
        }),
        EffectsModule.forRoot([
            CallOffEffects,
            AweRegisterEffects,
            CallOffRegisterEffects,
            ActionUsersEffects,
            CallOffMyPageEffects,
            ConstructionApproverMatrixEffects,
        ]),
        StoreDevtoolsModule.instrument({ maxAge: 0 }),
    ],
})
export class StoreModule {}
