import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Store } from '@ngrx/store';
import { FormGroup } from '@angular/forms';
import { takeWhile, startWith, map, take, filter, distinctUntilChanged } from 'rxjs/operators';
import { BaseComponent } from 'src/app/components/base.component';
import { CallOffScopeOfWorkDescriptionRequest } from 'src/app/store/call-off/actions';
import { Observable } from 'rxjs';
import { ApplicationState } from 'src/app/store/model';
import { ChangeDocParentType } from 'src/app/models/enums';
import { CallOffLookupService } from 'src/app/services/call-off-lookup.service';
import {
    CompanyRepresentativesRequest,
    ContractorRepresentativesRequest,
    ContractsTeamReviewersRequest,
} from 'src/app/store/action-users/actions';
import { JoditDescriptionType } from 'src/app/store/jodit-description/model';

@Component({
    selector: 'app-cof-scope-of-work',
    templateUrl: './cof-scope-of-work.component.html',
    styleUrls: ['./cof-scope-of-work.component.scss'],
})
export class CofScopeOfWorkComponent extends BaseComponent implements OnInit {
    @Input() callOffForm: FormGroup;
    @Output() openJodit = new EventEmitter<{
        description: string;
        type: JoditDescriptionType;
    }>();
    @Output() scopeOfWorkDescriptionSet = new EventEmitter<{ controlName: string; value: string }>();

    osdDisplayName = 'OS&D';
    isScopeOfWorkLoading = false;
    docRefEnabled = false;
    isScopeOfWorkLoading$ = this.store.select((state) => state.callOffState.isScopeOfWorkLoading);
    changeDocParentTypeValues = [];
    changeDocRefValues = [];
    filteredParentsNos: Observable<string[]>;
    callOffId: number = null;
    isLocked$ = this.store.select((state) => state.callOffState.isLocked);
    mainForm$ = this.store.select((state) => state.callOffState.form);
    changeDocParentType$ = this.store.select((state) => state.callOffState.form.changeDocParentType);
    changeDocRef$ = this.store.select((state) => state.callOffState.form.changeDocRef);
    isMainFormLoaded$ = this.store.select((state) => state.callOffState.isLoading);
    scopeOfWorkDescription$ = this.store.select((state) => state.callOffState.form.scopeOfWork);
    reasonValues = [];
    isLocked = false;

    constructor(private store: Store<ApplicationState>, private callOffLookupService: CallOffLookupService) {
        super();
    }

    ngOnInit() {
        this.changeDocParentTypeValues = Object.entries(ChangeDocParentType)
            .filter((e) => !isNaN(e[0] as any))
            .map((e) => ({ value: e[1], id: +e[0] }));
        this.changeDocParentTypeValues.find((x) => x.value === 'OSD').value = this.osdDisplayName;

        this.isScopeOfWorkLoading$.pipe(takeWhile(() => this.isAlive)).subscribe((isLoading) => {
            this.isScopeOfWorkLoading = isLoading;
        });

        this.mainForm$
            .pipe(
                takeWhile(() => this.isAlive),
                filter((form) => !!form.id),
                distinctUntilChanged((prev, curr) => prev.id === curr.id)
            )
            .subscribe((form) => {
                if (form.id !== null) {
                    this.callOffId = form.id;
                    this.store.dispatch(new CallOffScopeOfWorkDescriptionRequest(form.id));
                    this.configureParentNoControl(form.changeDocParentType.toString(), true);
                    this.scopeOfWorkDescription$
                        .pipe(
                            filter((description) => description !== null),
                            take(1)
                        )
                        .subscribe((description) =>
                            this.scopeOfWorkDescriptionSet.emit({ controlName: 'scopeOfWork', value: description })
                        );
                }
            });
        this.isLocked$.pipe(takeWhile(() => this.isAlive)).subscribe((isLocked) => {
            this.isLocked = isLocked;
        });

        this.filteredParentsNos = this.callOffForm.controls['changeDocRef'].valueChanges.pipe(
            takeWhile(() => this.isAlive),
            startWith(''),
            map((value) => this.filterParentNos(value))
        );
        this.callOffForm.controls['changeDocParentType'].valueChanges
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((changedValue) => this.configureParentNoControl(changedValue, false));
        this.callOffLookupService
            .getReasons()
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((reasons) => (this.reasonValues = reasons));
    }
    openJoditPopup(description: string) {
        this.openJodit.emit({ description, type: JoditDescriptionType.ScopeOfWork });
    }

    onFocus() {
        this.callOffForm.controls['changeDocRef'].setValue(this.callOffForm.controls['changeDocRef'].value);
    }

    private filterParentNos(value: string): string[] {
        const filterValue = value.toLowerCase();

        return this.changeDocRefValues.filter((option) => option.toLowerCase().includes(filterValue)).slice(0, 20);
    }

    configureParentNoControl(changeDocParentTypeValue: string, isInitialRequest: boolean) {
        const changeDocParentType = +ChangeDocParentType[ChangeDocParentType[changeDocParentTypeValue]];

        if (changeDocParentType === ChangeDocParentType.RFI || changeDocParentType === ChangeDocParentType.DCN) {
            this.resetChangeDocRefField(true, isInitialRequest);
            this.callOffLookupService
                .getChangeDocRefValues(changeDocParentType)
                .pipe(takeWhile(() => this.isAlive))
                .subscribe((changeDocRefValues) => (this.changeDocRefValues = changeDocRefValues));
        } else if (changeDocParentType === ChangeDocParentType.Nil) {
            this.resetChangeDocRefField(false, isInitialRequest);
        } else {
            this.resetChangeDocRefField(true, isInitialRequest);
        }
    }

    resetChangeDocRefField(enabled: boolean, isInitialRequest: boolean) {
        this.changeDocRefValues = [];

        if (!isInitialRequest) {
            this.callOffForm.controls['changeDocRef'].setValue('');
        }

        this.docRefEnabled = enabled;
    }

    reasonSelected(reasonId: number) {
        this.store.dispatch(
            new CompanyRepresentativesRequest({
                contractNo: this.callOffForm.controls['awe'].value.contractNo,
                reasonId: reasonId,
            })
        );
        this.store.dispatch(
            new ContractorRepresentativesRequest({
                contractNo: this.callOffForm.controls['awe'].value.contractNo,
                reasonId: reasonId,
            })
        );
        this.store.dispatch(new ContractsTeamReviewersRequest(this.callOffForm.controls['awe'].value.contractNo));
    }
}
