<main class="main">
    <form [formGroup]="aweFormGroup">
        <span class="close" (click)="close()">&times;</span>
        <p mat-dialog-title class="title">
            <span class="font-gotham-narrow-bold">{{ titleText }}</span>
        </p>
        <div [hidden]="expansionPanel.expanded">
            <mat-form-field>
                <mat-label>Contractor</mat-label>
                <mat-select formControlName="contractor" required panelClass="contractor-mat-select">
                    <mat-option disabled class="toprow">
                        <div>Contract No</div>
                        <div>Contractor</div>
                    </mat-option>
                    <mat-select-trigger>
                        {{ aweFormGroup.controls['contractor'].value }}
                    </mat-select-trigger>
                    <mat-option
                        *ngFor="let contractor of contractors"
                        [value]="contractor.contractNo"
                        class="optionrow"
                    >
                        <div>{{ contractor.contractNo }}</div>
                        <div>{{ contractor.contract }}</div>
                    </mat-option>
                </mat-select>
                <mat-error
                    *ngIf="
                        aweFormGroup.controls['contractor'].invalid &&
                        aweFormGroup.controls['contractor'].errors.required
                    "
                >
                    Contractor is required.
                </mat-error>
            </mat-form-field>
            <mat-form-field class="mat-form-field-awe-number">
                <mat-label>AWE number</mat-label>
                <input matInput formControlName="aweNumber" placeholder="AWE number" required />
                <mat-error
                    *ngIf="
                        aweFormGroup.controls['aweNumber'].invalid && aweFormGroup.controls['aweNumber'].errors.required
                    "
                >
                    Number is required.
                </mat-error>
                <mat-error *ngIf="aweFormGroup.controls.aweNumber.errors?.uniqueAWENumber">
                    AWE Number already taken.
                </mat-error>
                <mat-error *ngIf="aweFormGroup.controls.aweNumber.errors?.aweNumberChars">
                    AWE Number contains not allowed characters.
                </mat-error>
            </mat-form-field>
            <div class="awetitle-row">
                <mat-form-field class="margin-right15">
                    <mat-label>AWE title</mat-label>
                    <input matInput formControlName="aweTitle" placeholder="AWE title" required />
                    <mat-error
                        *ngIf="
                            aweFormGroup.controls['aweTitle'].invalid &&
                            aweFormGroup.controls['aweTitle'].errors.required
                        "
                    >
                        AWE title is required.
                    </mat-error>
                </mat-form-field>
                <mat-form-field class="mat-form-field-revision">
                    <mat-label>Revision</mat-label>
                    <mat-select formControlName="revision" required>
                        <mat-option *ngFor="let revision of revisions" [value]="revision">{{ revision }}</mat-option>
                    </mat-select>
                    <mat-error
                        *ngIf="aweFormGroup.controls.revision.invalid && aweFormGroup.controls.revision.errors.required"
                    >
                        Revision is required.
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="currencies-container">
                <mat-form-field class="usd" *ngIf="contractorCurrencies['USD']">
                    <mat-label>USD</mat-label>
                    <input
                        matInput
                        formControlName="usd"
                        currencyMask
                        placeholder="USD"
                        [options]="{ prefix: '$ ' }"
                        required
                    />
                </mat-form-field>
                <mat-form-field class="kzt" *ngIf="contractorCurrencies['KZT']">
                    <mat-label>KZT</mat-label>
                    <input
                        matInput
                        formControlName="kzt"
                        currencyMask
                        placeholder="KZT"
                        [options]="{ prefix: 'KZT ' }"
                        required
                    />
                </mat-form-field>
                <mat-form-field class="rub" *ngIf="contractorCurrencies['RUB']">
                    <mat-label>RUB</mat-label>
                    <input
                        matInput
                        formControlName="rub"
                        currencyMask
                        placeholder="RUB"
                        [options]="{ prefix: 'RUB ' }"
                        required
                    />
                </mat-form-field>
                <mat-form-field class="eur" *ngIf="contractorCurrencies['EUR']">
                    <mat-label>EUR</mat-label>
                    <input
                        matInput
                        formControlName="eur"
                        currencyMask
                        placeholder="EUR"
                        [options]="{ prefix: '€ ' }"
                        required
                    />
                </mat-form-field>
            </div>
            <div class="usd-equiv" *ngIf="anyContractorCurrency">
                <mat-form-field>
                    <mat-label>NTE USD Equiv.</mat-label>
                    <input
                        matInput
                        currencyMask
                        formControlName="aweNte"
                        placeholder="NTE USD Equiv."
                        [options]="{ prefix: '$ ' }"
                    />
                </mat-form-field>
            </div>
            <div class="attachments-container">
                <div class="attachments-header">AWE attachments</div>
                <div appDragAndDrop (filesAdded)="fileChangeEvent($event)" class="drag-and-drop-container">
                    <div class="DandD-img"></div>
                    <div class="drag-and-drop">
                        Drop files here or click to
                        <button class="upload-file-button" type="button" (click)="attachmentFiles.click($event)">
                            select files to upload
                        </button>
                        <div
                            class="tooltip-icon"
                            matTooltip="Maximum file upload limit is 100 MB at a time"
                            matTooltipClass="cofftooltip"
                        ></div>
                        <input
                            multiple
                            #attachmentFiles
                            type="file"
                            (click)="attachmentFiles.value = null"
                            (change)="fileChangeEvent($event.target.files)"
                        />
                    </div>
                </div>
                <div
                    class="uploaded-attachments-container"
                    *ngIf="aweFormGroup.controls.attachments.value && aweFormGroup.controls.attachments.value.length"
                >
                    <div
                        class="uploaded-attachment"
                        *ngFor="let uploadedAttachment of aweFormGroup.controls.attachments.value"
                    >
                        <label>{{ uploadedAttachment.name }}</label>
                        <div class="uploaded-attachment-buttons">
                            <button
                                type="button"
                                class="download-uploaded-attachment"
                                (click)="downloadUploadedAttachment(uploadedAttachment)"
                                *ngIf="uploadedAttachment.link"
                            ></button>
                            <button
                                type="button"
                                class="delete-uploaded-attachment"
                                (click)="deleteUploadedAttachment(uploadedAttachment)"
                            ></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <mat-accordion>
            <mat-expansion-panel #expansionPanel="matExpansionPanel">
                <mat-expansion-panel-header collapsedHeight="auto">
                    <div *ngIf="!expansionPanel.expanded">
                        Default Reviewers & Approvers
                    </div>
                </mat-expansion-panel-header>
                <mat-form-field class="container300 margin-right20">
                    <mat-label>Default Contracts Team Reviewer</mat-label>
                    <mat-select [compareWith]="compareUsers" formControlName="defaultReviewer">
                        <mat-option> </mat-option>
                        <mat-option
                            *ngFor="let contractsTeamReviewer of contractsTeamReviewers"
                            [value]="contractsTeamReviewer"
                        >
                            {{ contractsTeamReviewer !== null ? contractsTeamReviewer.name : '' }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <div *ngFor="let reason of reasons">
                    <mat-form-field>
                        <mat-label>Default Company Approver for {{ reason.name }}</mat-label>
                        <mat-select
                            [compareWith]="compareDefaultUsers"
                            (selectionChange)="approverSelected($event)"
                            [value]="getDefaultApprover(reason, appRoles.TCOApprover)"
                        >
                            <mat-option [value]="getEmptyApprover(reason, appRoles.TCOApprover)"> </mat-option>
                            <mat-option
                                *ngFor="let companyApprover of approvers | reasonFilter: reason:appRoles.TCOApprover"
                                [value]="companyApprover"
                            >
                                {{ companyApprover !== null ? companyApprover.approver.name : '' }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label>Default Contractor Approver for {{ reason.name }}</mat-label>
                        <mat-select
                            [compareWith]="compareDefaultUsers"
                            (selectionChange)="approverSelected($event)"
                            [value]="getDefaultApprover(reason, appRoles.ContractorApprover)"
                        >
                            <mat-option [value]="getEmptyApprover(reason, appRoles.ContractorApprover)"> </mat-option>
                            <mat-option
                                *ngFor="
                                    let contractorApprover of approvers
                                        | reasonFilter: reason:appRoles.ContractorApprover
                                "
                                [value]="contractorApprover"
                            >
                                {{ contractorApprover !== null ? contractorApprover.approver.name : '' }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
        <div class="buttons-container flex center">
            <button type="button" (click)="close()" class="cof-button cof-button-white">cancel</button>
            <button
                type="button"
                (click)="submit()"
                [disabled]="!aweFormGroup.valid"
                class="cof-button cof-button-light ok-button"
            >
                submit
            </button>
        </div>
        <app-loading-indicator *ngIf="isLoading || currenciesCheckIsLoading"></app-loading-indicator>
    </form>
</main>
