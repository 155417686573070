import { Injectable } from '@angular/core';
import { UserDetail } from '../store/common.model';
import { HttpParams, HttpClient } from '@angular/common/http';
import { ActionUsers } from '../store/action-users/model';
import { appConfig } from '../app.config';
import { DefaultApprover } from '../store/awe-form/model';
@Injectable({
    providedIn: 'root',
})
export class ActionUsersService {
    private readonly rootUrl: string = appConfig.apiEndpoint;
    private readonly actionUserUrl: string = '/calloff/actionusers';

    constructor(private http: HttpClient) {}

    getContractorRepresentatives(contractNo: string, reasonId: number) {
        let params = new HttpParams();
        params = params.append('contractNo', contractNo);
        params = params.append('reasonId', reasonId.toString());
        return this.http.get<UserDetail[]>(`${this.rootUrl}${this.actionUserUrl}/contractor-representatives`, {
            params: params,
        });
    }

    getCompanyRepresentatives(contractNo: string, reasonId: number) {
        let params = new HttpParams();
        params = params.append('contractNo', contractNo);
        params = params.append('reasonId', reasonId.toString());
        return this.http.get<UserDetail[]>(`${this.rootUrl}${this.actionUserUrl}/company-representatives`, {
            params: params,
        });
    }

    getRepresentatives(contractNo: string) {
        let params = new HttpParams();
        params = params.append('contractNo', contractNo);
        return this.http.get<DefaultApprover[]>(`${this.rootUrl}${this.actionUserUrl}/representatives`, {
            params: params,
        });
    }

    getActionUsers(contractNo: string, reasonId: number) {
        let params = new HttpParams();
        params = params.append('contractNo', contractNo);
        params = params.append('reasonId', reasonId.toString());

        return this.http.get<ActionUsers>(`${this.rootUrl}${this.actionUserUrl}`, { params: params });
    }
}
