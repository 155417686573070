import { CallOffState, CallOff } from './model';
import { CallOffActions, CallOffActionTypes } from './actions';
import { CallOffStatusType } from '../../models/enums';
import { Attachment, CallOffAttachmentType } from '../call-off-attachments/model';

const initialState: CallOffState = {
    form: new CallOff(),
    isLoading: false,
    isCreated: false,
    updatedProperties: [],
    isScopeOfWorkLoading: true,
    isEstimateDetailsLoading: true,
    isHistoryLoading: false,
    isLocked: false,
    isAutosaveInProgress: false,
};

const attachmentTypes = CallOffAttachmentType;

export function reducer(state = initialState, action: CallOffActions) {
    switch (action.type) {
        case CallOffActionTypes.GetCallOffRequest: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case CallOffActionTypes.GetCallOffRequestSuccess: {
            return {
                ...state,
                form: {
                    ...state.form,
                    ...action.payload,
                },

                isLoading: false,
            };
        }
        case CallOffActionTypes.GetCallOffRequestError: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case CallOffActionTypes.AddCallOffRequest: {
            return {
                ...state,
                isCreated: false,
            };
        }
        case CallOffActionTypes.AddCallOffRequestSuccess: {
            return {
                ...state,
                isCreated: true,
            };
        }
        case CallOffActionTypes.AddCallOffRequestError: {
            return {
                ...state,
                isCreated: false,
            };
        }
        case CallOffActionTypes.CallOffUpdateRequest: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case CallOffActionTypes.CallOffUpdateRequestSuccess: {
            return {
                ...state,
                form: {
                    ...state.form,
                    ...action.payload,
                },
                isLoading: false,
                updatedProperties: [],
            };
        }
        case CallOffActionTypes.CallOffUpdateRequestError: {
            return {
                ...state,
                isLoading: false,
                isLocked: action.payload.unlockForm ? false : state.isLocked,
            };
        }

        case CallOffActionTypes.CallOffUpdateProperty: {
            return {
                ...state,
                updatedProperties: action.payload.updatedProperties,
                form: {
                    ...state.form,
                    [action.payload.key]: Array.isArray(action.payload.value)
                        ? [...action.payload.value]
                        : action.payload.value,
                },
            };
        }
        case CallOffActionTypes.CallOffClear: {
            const newCallOff = new CallOff();
            return {
                ...state,
                isLocked: false,
                form: {
                    ...newCallOff,
                },
                updatedProperties: [],
            };
        }
        case CallOffActionTypes.CallOffEstimateDetailsDescriptionRequest: {
            return {
                ...state,
                isEstimateDetailsLoading: true,
            };
        }
        case CallOffActionTypes.CallOffEstimateDetailsDescriptionRequestSuccess: {
            return {
                ...state,
                form: {
                    ...state.form,
                    estimateDetails: action.payload,
                },
                isEstimateDetailsLoading: false,
            };
        }
        case CallOffActionTypes.CallOffEstimateDetailsDescriptionRequestError: {
            return {
                ...state,
                isEstimateDetailsLoading: false,
            };
        }

        case CallOffActionTypes.CallOffScopeOfWorkDescriptionRequest: {
            return {
                ...state,
                isScopeOfWorkLoading: true,
            };
        }
        case CallOffActionTypes.CallOffScopeOfWorkDescriptionRequestSuccess: {
            return {
                ...state,
                form: {
                    ...state.form,
                    scopeOfWork: action.payload,
                },
                isScopeOfWorkLoading: false,
            };
        }
        case CallOffActionTypes.CallOffScopeOfWorkDescriptionRequestError: {
            return {
                ...state,
                isScopeOfWorkLoading: false,
            };
        }
        case CallOffActionTypes.CallOffActionUsersRequestSuccess: {
            return {
                ...state,
                isLoading: false,
                form: {
                    ...state.form,
                    contractorRepresentative: action.payload.contractorRepresentative,
                    companyRepresentative: action.payload.companyRepresentative,
                },
            };
        }
        case CallOffActionTypes.CallOffActionUsersRequest: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case CallOffActionTypes.CallOffActionUsersRequestError: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case CallOffActionTypes.CallOffUpdateStatusRequest: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case CallOffActionTypes.CallOffUpdateStatusRequestSuccess: {
            return {
                ...state,
                form: {
                    ...state.form,
                    ...action.payload,
                    SupportingDocumentAttachments: state.form.SupportingDocumentAttachments,
                    EstimateAttachments: state.form.EstimateAttachments,
                    COFCompletedAttachments: state.form.COFCompletedAttachments,
                    COFCompanyCompletedAttachments: state.form.COFCompanyCompletedAttachments,
                    OldSupportingDocumentAttachments: state.form.SupportingDocumentAttachments,
                    OldEstimateAttachments: state.form.EstimateAttachments,
                    OldCOFCompletedAttachments: state.form.COFCompletedAttachments,
                    OldCOFCompanyCompletedAttachments: state.form.COFCompanyCompletedAttachments,
                },
                isLoading: false,
                updatedProperties: [],
            };
        }
        case CallOffActionTypes.CallOffUpdateStatusRequestError: {
            return {
                ...state,
                isLoading: false,
                isLocked: action.payload.unlockForm ? false : state.isLocked,
            };
        }
        case CallOffActionTypes.CallOffSaveAndUpdateStatusRequest: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case CallOffActionTypes.CallOffRemoveCommentRequest: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case CallOffActionTypes.CallOffRemoveCommentSuccess: {
            return {
                ...state,
                form: {
                    ...state.form,
                    comments: [...state.form.comments.filter((c) => c.id !== action.payload.comment.id)],
                },
                isLoading: false,
            };
        }
        case CallOffActionTypes.CallOffRemoveCommentError: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case CallOffActionTypes.CallOffAddCommentRequest: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case CallOffActionTypes.CallOffAddCommentSuccess: {
            const comment = Object.assign({}, action.payload.comment, { isLoading: false });
            return {
                ...state,
                isLoading: false,
                form: {
                    ...state.form,
                    comments: [comment, ...state.form.comments],
                },
            };
        }
        case CallOffActionTypes.CallOffAddCommentError: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case CallOffActionTypes.CallOffHistoryRequest: {
            return {
                ...state,
                isHistoryLoading: true,
            };
        }
        case CallOffActionTypes.CallOffHistoryRequestSuccess: {
            return {
                ...state,
                form: {
                    ...state.form,
                    history: [...action.payload],
                },
                isHistoryLoading: false,
            };
        }
        case CallOffActionTypes.CallOffHistoryRequestError: {
            return {
                ...state,
                isHistoryLoading: false,
            };
        }

        case CallOffActionTypes.CallOffAutosaveRequest: {
            return {
                ...state,
                isAutosaveInProgress: true,
            };
        }
        case CallOffActionTypes.CallOffAutosaveSuccess: {
            return {
                ...state,
                isAutosaveInProgress: false,
                form: action.payload,
                updatedProperties: [],
            };
        }
        case CallOffActionTypes.CallOffAutosaveError: {
            return {
                ...state,
                isAutosaveInProgress: false,
            };
        }

        case CallOffActionTypes.LockCallOffFormIfNoChanges: {
            let isLocked = false;
            if (state.updatedProperties.length === 0 && state.form.status > CallOffStatusType.Draft) {
                isLocked = true;
            }

            return {
                ...state,
                isLocked,
            };
        }

        case CallOffActionTypes.LockCallOffForm: {
            return {
                ...state,
                isLocked: true,
            };
        }

        case CallOffActionTypes.UnLockCallOffForm: {
            return {
                ...state,
                isLocked: false,
            };
        }

        case CallOffActionTypes.CallOffNewRevisionRequest: {
            return {
                ...state,
                isLoading: true,
            };
        }

        case CallOffActionTypes.CallOffNewRevisionRequestSuccess: {
            return {
                ...state,
                isLoading: false,
            };
        }

        case CallOffActionTypes.CallOffNewRevisionRequestError: {
            return {
                ...state,
                isLoading: false,
            };
        }

        case CallOffActionTypes.CallOffAttachmentsSuccess: {
            return {
                ...state,
                form: {
                    ...state.form,
                    [`${attachmentTypes[action.payload.type]}Attachments`]: action.payload.attachments.attachments,
                    [`Old${attachmentTypes[action.payload.type]}Attachments`]: action.payload.attachments.attachments,
                },
            };
        }
        case CallOffActionTypes.CallOffDownloadAttachmentRequest: {
            return {
                ...state,
                form: {
                    ...state.form,
                    [`${attachmentTypes[action.payload.type]}Attachments`]: [
                        ...state.form[`${attachmentTypes[action.payload.type]}Attachments`].map(
                            (attachment: Attachment) => {
                                return {
                                    ...attachment,
                                    isBeingProcessed:
                                        attachment.name === action.payload.attachment.name
                                            ? true
                                            : attachment.isBeingProcessed,
                                };
                            }
                        ),
                    ],
                },
            };
        }
        case CallOffActionTypes.CallOffDownloadAttachmentSuccess: {
            return {
                ...state,
                form: {
                    ...state.form,
                    [`${attachmentTypes[action.payload.type]}Attachments`]: [
                        ...state.form[`${attachmentTypes[action.payload.type]}Attachments`].map(
                            (attachment: Attachment) => {
                                return {
                                    ...attachment,
                                    isBeingProcessed:
                                        attachment.name === action.payload.fileName
                                            ? false
                                            : attachment.isBeingProcessed,
                                };
                            }
                        ),
                    ],
                },
            };
        }
        case CallOffActionTypes.CallOffDownloadAttachmentError: {
            return {
                ...state,
                form: {
                    ...state.form,
                    [`${attachmentTypes[action.payload.type]}Attachments`]: [
                        ...state.form[`${attachmentTypes[action.payload.type]}Attachments`].map(
                            (attachment: Attachment) => {
                                return {
                                    ...attachment,
                                    isBeingProcessed:
                                        attachment.name === action.payload.attachment.name
                                            ? false
                                            : attachment.isBeingProcessed,
                                };
                            }
                        ),
                    ],
                },
            };
        }
        default: {
            return state;
        }
    }
}
