import { Component, OnInit } from '@angular/core';
import { OrderDirection, Contractor } from 'src/app/store/common.model';
import { ContractorLimit } from 'src/app/models/contractor-limit';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { BaseComponent } from 'src/app/components/base.component';
import { takeWhile, take, tap } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { ReplaySubject, forkJoin } from 'rxjs';
import { LookupService } from 'src/app/services/lookup.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { ToastService } from 'src/app/services/shared/toast.service';

@Component({
    selector: 'app-limits',
    templateUrl: './limits.component.html',
    styleUrls: ['./limits.component.scss'],
})
export class LimitsComponent extends BaseComponent implements OnInit {
    direction: OrderDirection.Desc;
    displayedColumns = ['key', 'value', 'edit'];
    limits: ContractorLimit[] = [];
    contractors: Contractor[] = [];
    filteredContractors: ReplaySubject<Contractor[]> = new ReplaySubject<Contractor[]>();
    isLoading = false;
    sortBy: string = 'key';
    filteredContractorCtrl: FormControl = new FormControl();
    selectCtrl: FormControl = new FormControl();

    constructor(
        private iconRegistry: MatIconRegistry,
        private sanitizer: DomSanitizer,
        private configurationService: ConfigurationService,
        private lookupService: LookupService,
        private toastService: ToastService
    ) {
        super();

        this.iconRegistry.addSvgIcon(
            'edit',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/edit.svg')
        );
        this.iconRegistry.addSvgIcon(
            'delete',
            sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/delete.svg')
        );
    }

    ngOnInit(): void {
        this.isLoading = true;
        forkJoin([this.configurationService.getContractorsLimits(), this.lookupService.searchContractors('')])
            .pipe(take(1))
            .subscribe(
                ([contractorLimits, contractors]) => {
                    this.isLoading = false;
                    this.limits = contractorLimits;
                    this.contractors = contractors;

                    this.filteredContractors.next(
                        this.contractors
                            .filter(
                                (x) =>
                                    !this.limits
                                        .filter((y) => !y.isInEditMode)
                                        .map((y) => y.contractNo)
                                        .includes(x.contractNo)
                            )
                            .slice()
                    );
                },
                () => {
                    this.isLoading = false;
                    this.toastService.Error(
                        'An error occured while getting limits and contractors. Please contract to program administrator'
                    );
                }
            );

        this.filteredContractorCtrl.valueChanges
            .pipe(takeWhile(() => this.isAlive))
            .subscribe(() => this.filterContractors());
    }

    filterContractors(): void {
        let search = this.filteredContractorCtrl.value;
        if (!search) {
            this.filteredContractors.next(
                this.contractors
                    .filter(
                        (x) =>
                            !this.limits
                                .filter((y) => !y.isInEditMode)
                                .map((y) => y.contractNo)
                                .includes(x.contractNo)
                    )
                    .slice()
            );
            return;
        } else {
            search = search.toLowerCase();
        }

        const contractors = this.contractors
            .filter(
                (x) =>
                    !this.limits
                        .filter((y) => !y.isInEditMode)
                        .map((y) => y.contractNo)
                        .includes(x.contractNo)
            )
            .slice();
        this.filteredContractors.next(contractors.filter((item) => item.contractNo.toLowerCase().indexOf(search) > -1));
    }

    enterEditMode(element: ContractorLimit) {
        element.isInEditMode = !element.isInEditMode;
        element.prevContractNo = element.contractNo;
        element.prevLimit = element.limit;

        this.filterContractors();
    }

    save(element: ContractorLimit) {
        element.isInEditMode = !element.isInEditMode;
        this.filterContractors();
        this.updateLimit(element);
    }

    updateLimit(element: ContractorLimit) {
        this.isLoading = true;
        this.configurationService
            .updateLimits(element)
            .pipe(
                takeWhile(() => this.isAlive),
                tap(() => (this.isLoading = true))
            )
            .subscribe(
                () => {
                    this.isLoading = false;
                    this.filteredContractors.next(
                        this.contractors
                            .filter(
                                (x) =>
                                    !this.limits
                                        .filter((y) => !y.isInEditMode)
                                        .map((y) => y.contractNo)
                                        .includes(x.contractNo)
                            )
                            .slice()
                    );
                    this.toastService.Success('Limit has been updated.');
                },
                () => {
                    this.isLoading = false;
                    this.toastService.Error('An error occured while updating limit. Please contact Program Administrator.');
                }
            );
    }

    deleteLimit(element: ContractorLimit) {
        this.isLoading = true;
        this.configurationService
            .deleteLimit(element)
            .pipe(
                takeWhile(() => this.isAlive),
                tap(() => (this.isLoading = true))
            )
            .subscribe(
                () => {
                    this.isLoading = false;
                    this.filteredContractors.next(
                        this.contractors
                            .filter(
                                (x) =>
                                    !this.limits
                                        .filter((y) => !y.isInEditMode)
                                        .map((y) => y.contractNo)
                                        .includes(x.contractNo)
                            )
                            .slice()
                    );
                    this.toastService.Success('Limit has been deleted.');
                },
                () => {
                    this.isLoading = false;
                    this.toastService.Error('An error occured while deleting limit. Please contact Program Administrator.');
                }
            );
    }

    anyItemInEditMode(): boolean {
        return this.limits.filter((x) => x.isInEditMode === true).length > 0;
    }

    cancelEdit(element: ContractorLimit) {
        if (!element.prevContractNo && !element.prevLimit) {
            this.limits.pop();
            this.limits = [...this.limits];
        }

        element.isInEditMode = !element.isInEditMode;
        element.limit = element.prevLimit;
        element.contractNo = element.prevContractNo;
        this.filterContractors();
    }

    remove(element: ContractorLimit) {
        this.limits = [...this.limits.filter((x) => x.contractNo !== element.contractNo)];
        this.deleteLimit(element);
    }

    anyRowInEditMode(skipIndex: number = null) {
        return this.limits.filter((r, i) => i !== skipIndex).some((r) => r.isInEditMode);
    }

    isAcceptDisabled(element: ContractorLimit) {
        return element.contractNo === null || element.contractNo === '' || element.limit === null || element.limit < 0;
    }

    addLimit() {
        this.limits = [
            ...this.limits,
            Object.assign({}, new ContractorLimit(), { contractNo: '', limit: 0, isInEditMode: true }),
        ];
    }
}
