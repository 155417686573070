import { Component, OnInit, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { PopupService } from 'src/app/services/shared/popup.service';
import { RoleService } from 'src/app/services/shared/role.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { PopupSettings } from 'src/app/models/popup-settings';
import { Constants } from 'src/app/constants';
import { DeleteConfirmationPopupComponent } from '../../delete-confirmation-popup/delete-confirmation-popup.component';
import { CommentPopupComponent } from '../../comment-popup/comment-popup.component';
import { CallOffComment } from 'src/app/store/call-off/model';
import { CallOffRemoveCommentRequest, CallOffAddCommentRequest } from 'src/app/store/call-off/actions';
import moment from 'moment';
import { ApplicationState } from 'src/app/store/model';
import { MsalService } from '@azure/msal-angular';
import { AccountInfo } from '@azure/msal-common';

@Component({
    selector: 'app-cof-comments-history',
    templateUrl: './cof-comments-history.component.html',
    styleUrls: ['./cof-comments-history.component.scss'],
})
export class CofCommentsHistoryComponent implements OnInit {
    @Input() comments: CallOffComment[] = [];
    @Input() callOffId: number = 0;
    @Input() callOffNumber: string = '';
    @Input() printMode = false;
    displayedColumns: string[] = ['comment', 'user', 'date', 'delete'];
    status$ = this.store.select((state) => state.callOffState.form.status);
    isLatestRevision$ = this.store.select((state) => state.callOffState.form.isLatestRevision);
    user: AccountInfo;
    isAdmin = false;
    isReadOnly = false;

    constructor(
        private store: Store<ApplicationState>,
        private popupSvc: PopupService,
        private roleService: RoleService,
        private authService: MsalService,
        iconRegistry: MatIconRegistry,
        sanitizer: DomSanitizer
    ) {
        iconRegistry.addSvgIcon(
            'del-icon',
            sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/del-icon.svg')
        );
    }

    ngOnInit() {
        this.user = this.roleService.getAccount();
        this.isAdmin = this.roleService.isInRole('Admin');
        this.isReadOnly = this.roleService.isReadOnly();
    }

    removeComment(element: CallOffComment) {
        this.popupSvc.openPopup(
            new PopupSettings(DeleteConfirmationPopupComponent, 400, 130, {
                action: (value: boolean) => {
                    if (value) {
                        this.store.dispatch(new CallOffRemoveCommentRequest({ comment: Object.assign({}, element, { isLoading: true }) }));
                    }
                },
            })
        );
    }

    openCommentPopup(event: any) {
        this.popupSvc.openPopup(
            new PopupSettings(CommentPopupComponent, Constants.popupWidth, Constants.popupHeight, {
                action: (value: string) => {
                    let comment = new CallOffComment();
                    comment.callOffId = this.callOffId;
                    comment.date = moment().toDate();
                    comment.description = value;
                    this.store.dispatch(
                        new CallOffAddCommentRequest({
                            comment: comment,
                        })
                    );
                },
            })
        );

        event.stopPropagation();
    }
}
