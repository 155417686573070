import { Component, OnInit, ViewChild, ChangeDetectorRef, EventEmitter, NgZone } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormService } from 'src/app/services/shared/form.service';
import { Store, select } from '@ngrx/store';
import { takeWhile, map, take } from 'rxjs/operators';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import {
    CallOffRegisterFilterRequest,
    CallOffRegisterFilterPropertyUpdate,
    CallOffRegisterFilterReset,
    CallOffRegisterExportToExcelRequest,
    CallOffRegisterToggleColumnShowSetting,
} from 'src/app/store/call-off-register/actions';
import { Router } from '@angular/router';
import { CallOffRegisterFilter, CallOffRegisterDTO, ShowColumnSettings } from 'src/app/store/call-off-register/model';
import { LookupService } from '../../services/lookup.service';
import { SetInputEventArgs } from 'src/app/models/set-input';
import { ToastService } from 'src/app/services/shared/toast.service';
import { BaseComponent } from 'src/app/components/base.component';
import { OrderDirection, Contractor, CheckListColumn, RangeColumn, CalendarColumn } from 'src/app/store/common.model';
import { CallOffStatusType } from '../../models/enums';
import { forkJoin } from 'rxjs';
import { RoleService } from 'src/app/services/shared/role.service';
import { ApplicationState } from 'src/app/store/model';
import { CallOffLookupService } from 'src/app/services/call-off-lookup.service';
import { HeaderCheckListFilter, HeaderDateFilter, HeaderRangeFilter } from 'src/app/models/filter-settings';
import { HeaderChecklistFilterComponent } from '../filter/header-checklist-filter/header-checklist-filter.component';
import { PopupSettings } from 'src/app/models/popup-settings';
import { PopupService } from 'src/app/services/shared/popup.service';
import { HeaderRangeFilterComponent } from '../filter/header-range-filter/header-range-filter.component';
import { HeaderDateFilterComponent } from '../filter/header-date-filter/header-date-filter.component';

@Component({
    selector: 'app-call-off-register',
    templateUrl: './call-off-register.component.html',
    styleUrls: ['./call-off-register.component.scss'],
})
export class CallOffRegisterComponent extends BaseComponent implements OnInit {
    filterForm: FormGroup;
    isLoading = false;
    resultsLength = 0;

    todayDate = new Date();
    data: CallOffRegisterDTO[] = [];
    registerGridData$ = this.store.select((state) => state.callOffRegisterState.dataPagination);
    registerGridLoader$ = this.store.select((state) => state.callOffRegisterState.isLoading);
    registerFilter$ = this.store.select((state) => state.callOffRegisterState.filter);
    setOriginatorInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setContractorInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setSubsystemInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    sortBy = 'number';
    pageSize;
    direction: OrderDirection = OrderDirection.Desc;
    disciplines: string[] = [];
    readonly isIE: boolean = /msie\s|trident\//i.test(window.navigator.userAgent);

    showColumnSettings$ = this.store.select((state) => state.callOffRegisterState.showColumnSettings);
    showColumnSettings: ShowColumnSettings;

    displayedColumns: string[] = [
        'number',
        'revision',
        'discipline',
        'subject',
        'status',
        'nteUSD',
        'subsystems',
        'awe',
        'originator',
        'raisedDate',
        'companyApproveDate',
        'contractorApproveDate',
        'cwp',
        'contractor',
        'manualFormNo',
        'parentNo',
    ];
    statuses = CallOffStatusType;
    isReadOnly = false;
    autocompleteDisplayedColumns = ['name', 'description'];
    contractorDisplayedColumns = ['contractno', 'contractor'];
    columnAWE$ = this.store.select((state) => state.callOffRegisterState.filter.columnAWE);
    columnAWE: CheckListColumn = null;
    columnNumbers$ = this.store.select((state) => state.callOffRegisterState.filter.columnNumbers);
    columnNumbers: CheckListColumn = null;
    columnContracts$ = this.store.select((state) => state.callOffRegisterState.filter.columnContracts);
    columnContracts: CheckListColumn = null;
    columnRevisions$ = this.store.select((state) => state.callOffRegisterState.filter.columnRevisions);
    columnRevisions: CheckListColumn = null;
    columnDisciplines$ = this.store.select((state) => state.callOffRegisterState.filter.columnDisciplines);
    columnDisciplines: CheckListColumn = null;
    columnSubjects$ = this.store.select((state) => state.callOffRegisterState.filter.columnSubjects);
    columnSubjects: CheckListColumn = null;
    columnSubsystems$ = this.store.select((state) => state.callOffRegisterState.filter.columnSubsystems);
    columnSubsystems: CheckListColumn = null;
    columnCWPs$ = this.store.select((state) => state.callOffRegisterState.filter.columnCWPs);
    columnCWPs: CheckListColumn = null;
    columnOriginators$ = this.store.select((state) => state.callOffRegisterState.filter.columnOriginators);
    columnOriginators: CheckListColumn = null;
    columnStatuses$ = this.store.select((state) => state.callOffRegisterState.filter.columnStatuses);
    columnStatuses: CheckListColumn = null;
    columnManualFormNos$ = this.store.select((state) => state.callOffRegisterState.filter.columnManualFormNos);
    columnManualFormNos: CheckListColumn = null;
    columnParentNos$ = this.store.select((state) => state.callOffRegisterState.filter.columnParentNos);
    columnParentNos: CheckListColumn = null;
    columnNTE$ = this.store.select((state) => state.callOffRegisterState.filter.columnNTE);
    columnNTE: RangeColumn = null;
    columnRaisedDate$ = this.store.select((state) => state.callOffRegisterState.filter.columnRaisedDate);
    columnRaisedDate: CalendarColumn = null;
    columnCompanyApproveDate$ = this.store.select(
        (state) => state.callOffRegisterState.filter.columnCompanyApproveDate
    );
    columnCompanyApproveDate: CalendarColumn = null;
    columnContractorApproveDate$ = this.store.select(
        (state) => state.callOffRegisterState.filter.columnContractorApproveDate
    );
    columnContractorApproveDate: CalendarColumn = null;

    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;

    constructor(
        private formSvc: FormService,
        private store: Store<ApplicationState>,
        private router: Router,
        private changeDetectorRef: ChangeDetectorRef,
        private lookupService: LookupService,
        private toastService: ToastService,
        private ngZone: NgZone,
        private roleService: RoleService,
        private callOffLookupService: CallOffLookupService,
        private popupService: PopupService
    ) {
        super();

        this.filterForm = this.formSvc.createSimpleForm(new CallOffRegisterFilter());
    }

    watchFormChanges() {
        for (const key of Object.keys(this.filterForm.controls)) {
            this.filterForm.controls[key].valueChanges.pipe(takeWhile(() => this.isAlive)).subscribe((value) => {
                this.store.dispatch(new CallOffRegisterFilterPropertyUpdate({ key, value }));
                this.paginator.pageIndex = 0;
            });
        }
    }

    ngOnInit() {
        this.paginator.pageIndex = 0;
        this.isReadOnly = this.roleService.isReadOnly();
        this.registerFilter$.pipe(takeWhile(() => this.isAlive)).subscribe((filter) => {
            this.filterForm.patchValue(filter, { emitEvent: false });
            this.sortBy = filter.sortBy;
            this.direction = filter.direction;
            this.pageSize = filter.take;
            this.paginator.pageIndex = filter.page;
        });

        forkJoin(this.lookupService.getDisciplines())
            .pipe(takeWhile(() => this.isAlive))
            .subscribe(
                ([disciplines]) => {
                    this.disciplines = disciplines;
                },
                () => {
                    this.toastService.Error(
                        'Error occurred while fetching disciplines/contractors. Please contact Program Administrator.'
                    );
                }
            );

        this.registerGridData$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => {
            this.data = data.items;
            this.resultsLength = data.totalCount;
        });

        this.registerGridLoader$.pipe(takeWhile(() => this.isAlive)).subscribe((isLoading) => {
            this.isLoading = isLoading;
            // hack for the Angular bug:
            // https://stackoverflow.com/questions/39741293/why-is-ngonchanges-not-firing-when-a-boolean-value-changed-in-angularjs-2
            this.changeDetectorRef.detectChanges();
        });

        this.sort.sortChange.pipe(takeWhile(() => this.isAlive)).subscribe((sortChange: Sort) => {
            this.store.dispatch(
                new CallOffRegisterFilterPropertyUpdate({
                    key: 'sortBy',
                    value: sortChange,
                })
            );
            this.paginator.pageIndex = 0;
            this.store.dispatch(new CallOffRegisterFilterRequest());
        });
        this.columnAWE$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnAWE = data));
        this.columnSubjects$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnSubjects = data));
        this.columnStatuses$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnStatuses = data));
        this.columnDisciplines$
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((data) => (this.columnDisciplines = data));
        this.columnNumbers$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnNumbers = data));
        this.columnContracts$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnContracts = data));
        this.columnSubsystems$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnSubsystems = data));
        this.columnCWPs$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnCWPs = data));
        this.columnManualFormNos$
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((data) => (this.columnManualFormNos = data));
        this.columnOriginators$
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((data) => (this.columnOriginators = data));
        this.columnRevisions$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnRevisions = data));
        this.columnNTE$.pipe(takeWhile(() => this.isAlive)).subscribe((nte) => (this.columnNTE = nte));
        this.columnRaisedDate$
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((raiseDate) => (this.columnRaisedDate = raiseDate));
        this.columnCompanyApproveDate$
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((companyApproveDate) => (this.columnCompanyApproveDate = companyApproveDate));
        this.columnContractorApproveDate$
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((contractorApproveDate) => (this.columnContractorApproveDate = contractorApproveDate));
        this.showColumnSettings$
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((showColumnSettings) => (this.showColumnSettings = showColumnSettings));

        this.store.dispatch(new CallOffRegisterFilterRequest());
        this.watchFormChanges();
    }

    openDetailsPage(id: number): void {
        this.ngZone.run(() => this.router.navigate([`details/${id}`]));
    }

    onPaginatorChange(pageEvent: PageEvent) {
        if (this.pageSize !== pageEvent.pageSize) {
            this.store.dispatch(
                new CallOffRegisterFilterPropertyUpdate({
                    key: 'take',
                    value: pageEvent.pageSize,
                })
            );
            this.filterForm.controls.take.setValue(pageEvent.pageSize);
            this.pageSize = pageEvent.pageSize;
        } else {
            this.store.dispatch(
                new CallOffRegisterFilterPropertyUpdate({
                    key: 'page',
                    value: pageEvent.pageIndex,
                })
            );
        }
        this.store.dispatch(new CallOffRegisterFilterRequest());
    }

    getOriginators = (search = '', take = 10, page = 0) => {
        return this.lookupService.searchUsers(search, page, take);
    };

    resetFilters() {
        this.store.dispatch(new CallOffRegisterFilterReset());
        this.setOriginatorInput.emit(new SetInputEventArgs(false, ''));
        this.setContractorInput.emit(new SetInputEventArgs(false, ''));
        this.setSubsystemInput.emit(new SetInputEventArgs(false, ''));
        this.paginator.pageIndex = 0;
        this.paginator.pageSize = 10;
    }

    exportToExcel() {
        this.store.dispatch(new CallOffRegisterExportToExcelRequest());
    }

    getSubsystems = (search = '', take = 10, page = 0) => {
        return this.lookupService.searchSubsystems(search, take, page);
    };

    getCWPs = (search = '', take = 10, page = 0) => {
        return this.lookupService.searchCWPs(search, take, page).pipe(
            takeWhile(() => this.isAlive),
            map((cwps: string[]) =>
                cwps.map((s) => {
                    return { id: s };
                })
            )
        );
    };

    search() {
        this.paginator.pageIndex = 0;
        this.store.dispatch(
            new CallOffRegisterFilterPropertyUpdate({
                key: 'page',
                value: 0,
            })
        );
        this.store.dispatch(new CallOffRegisterFilterRequest());
    }

    onPageChange(newPage: number) {
        if (newPage < 1) {
            newPage = 1;
        } else if (newPage > this.paginator.getNumberOfPages()) {
            newPage = this.paginator.getNumberOfPages();
        }
        var pageEvt = new PageEvent();
        pageEvt.pageIndex = newPage - 1;
        pageEvt.pageSize = this.pageSize;
        this.paginator.pageIndex = pageEvt.pageIndex;
        this.onPaginatorChange(pageEvt);
    }

    onContractorsClosed() {
        this.setContractorInput.emit(new SetInputEventArgs(true));
    }

    getContractors = (search = '', take = 10, page = 0) => {
        return this.lookupService
            .searchContractors(search, take, page, [])
            .pipe(map((contractors: Contractor[]) => contractors));
    };

    private getLatestFilterData(): CallOffRegisterFilter {
        let filter: CallOffRegisterFilter;
        this.store.pipe(select((x) => x.callOffRegisterState, take(1))).subscribe((data) => (filter = data.filter));
        return filter;
    }

    getAWE = (search = '', take = 10, page = 0) =>
        this.callOffLookupService.getAweNumbers(search, take, page, this.getLatestFilterData());

    getNumbers = (search = '', take = 10, page = 0) =>
        this.callOffLookupService.getNumbers(search, take, page, this.getLatestFilterData());

    getRevisions = (search = '', take = 10, page = 0) =>
        this.callOffLookupService.getRevisions(search, take, page, this.getLatestFilterData());

    getDisciplines = (search = '', take = 10, page = 0) =>
        this.callOffLookupService.getDisciplines(search, take, page, this.getLatestFilterData());

    getSubjects = (search = '', take = 10, page = 0) =>
        this.callOffLookupService.getSubjects(search, take, page, this.getLatestFilterData());

    getStatuses = (search = '', take = 10, page = 0) =>
        this.callOffLookupService.getStatuses(search, take, page, this.getLatestFilterData());

    searchOriginators = (search = '', take = 10, page = 0) =>
        this.callOffLookupService.getOriginators(search, take, page, this.getLatestFilterData());

    searchContractors = (search = '', take = 10, page = 0) =>
        this.callOffLookupService.getContractors(search, take, page, this.getLatestFilterData());

    searchCWPs = (search = '', take = 10, page = 0) =>
        this.callOffLookupService.getCWPs(search, take, page, this.getLatestFilterData());

    searchSubsystems = (search = '', take = 10, page = 0) =>
        this.callOffLookupService.getSubsystems(search, take, page, this.getLatestFilterData());

    searchManualFormNos = (search = '', take = 10, page = 0) =>
        this.callOffLookupService.getManualFormNos(search, take, page, this.getLatestFilterData());

    searchParentNos = (search = '', take = 10, page = 0) =>
        this.callOffLookupService.getParentNos(search, take, page, this.getLatestFilterData());

    openHeaderCheckListFilter(
        columnName: string,
        searchFunc: any,
        propertyName: string,
        placeholder: string,
        selected: CheckListColumn,
        showCounts: boolean = false,
        showInputSearch: boolean = true,
        isSortingOff: boolean = false
    ) {
        this.popupService.openPopup(
            new PopupSettings<HeaderCheckListFilter>(HeaderChecklistFilterComponent, 400, 590, {
                isAscendingSort: this.sortBy === columnName && this.direction === OrderDirection.Asc,
                isDescendingSort: this.sortBy === columnName && this.direction === OrderDirection.Desc,
                placeHolder: placeholder,
                searchFunc: searchFunc,
                selectedItems: selected ? [...selected.items] : [],
                isSortingOff: isSortingOff,
                showCounts,
                showInputSearch: showInputSearch,
                action: (data) => {
                    let value = new CheckListColumn();
                    value.items = data.selectedItems.length > 0 ? data.selectedItems : [];
                    this.filterForm.controls[propertyName].setValue(value);

                    this.sortUpdate(data.isAscendingSort, data.isDescendingSort, columnName);

                    this.search();
                },
                resetColumnAction: () => {
                    this.store.dispatch(
                        new CallOffRegisterFilterPropertyUpdate({
                            key: propertyName,
                            value: null,
                        })
                    );
                },
            })
        );
    }

    openHeaderRangeFilter(columnName: string, propertyName: string, placeholder: string, rangeColumn: RangeColumn) {
        const ranges = rangeColumn === null ? [] : rangeColumn.ranges;
        this.popupService.openPopup(
            new PopupSettings<HeaderRangeFilter>(HeaderRangeFilterComponent, 425, 280, {
                isAscendingSort: this.sortBy === columnName && this.direction === OrderDirection.Asc,
                isDescendingSort: this.sortBy === columnName && this.direction === OrderDirection.Desc,
                rangeColumn: { ranges },
                placeHolder: placeholder,
                action: (data) => {
                    this.filterForm.controls[propertyName].setValue(data.rangeColumn);
                    this.sortUpdate(data.isAscendingSort, data.isDescendingSort, columnName);
                    this.search();
                },
            })
        );
    }

    openHeaderDateFilter(
        columnName: string,
        propertyName: string,
        placeholder: string,
        calendarColumn: CalendarColumn
    ) {
        const excludeBlanks = calendarColumn === null ? false : calendarColumn.excludeBlanks;
        const onlyBlanks = calendarColumn === null ? false : calendarColumn.onlyBlanks;
        const rangeDates = calendarColumn === null ? [] : calendarColumn.rangeDates;
        this.popupService.openPopup(
            new PopupSettings<HeaderDateFilter>(HeaderDateFilterComponent, 345, 625, {
                isAscendingSort: this.sortBy === columnName && this.direction === OrderDirection.Asc,
                isDescendingSort: this.sortBy === columnName && this.direction === OrderDirection.Desc,
                calendarColumn: { rangeDates, excludeBlanks, onlyBlanks },
                placeHolder: placeholder,
                action: (data) => {
                    this.filterForm.controls[propertyName].setValue(data.calendarColumn);
                    this.sortUpdate(data.isAscendingSort, data.isDescendingSort, columnName);
                    this.search();
                },
            })
        );
    }

    private sortUpdate(isAscendingSort: boolean, isDescendingSort: boolean, columnName: string) {
        if (isAscendingSort || isDescendingSort) {
            const direction: OrderDirection = isAscendingSort ? OrderDirection.Asc : OrderDirection.Desc;
            this.store.dispatch(
                new CallOffRegisterFilterPropertyUpdate({
                    key: 'sortBy',
                    value: { active: columnName, direction: direction },
                })
            );
        } else {
            this.store.dispatch(
                new CallOffRegisterFilterPropertyUpdate({
                    key: 'sortBy',
                    value: { active: 'number', direction: OrderDirection.Desc },
                })
            );
        }
    }

    public toggleShowSetting(columnName: string) {
        this.store.dispatch(new CallOffRegisterToggleColumnShowSetting(columnName));
    }
}
