<div class="scope-of-work-container">
    <div [formGroup]="callOffForm">
        <div class="position">
            <div class="title">call-off scope of work</div>
            <mat-form-field class="container620 margin-right20">
                <mat-label>Reason for instruction</mat-label>

                <mat-select matNativeControl formControlName="reasonForInstructionId">
                    <mat-option
                        *ngFor="let reason of reasonValues"
                        [value]="reason.id"
                        (click)="reasonSelected(reason.id)"
                    >
                        {{ reason.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <div class="scope-of-work-editor-container">
                <div class="doc-title">
                    <div
                        [ngClass]="{
                            'mat-error':
                                callOffForm.controls['scopeOfWork'].invalid &&
                                callOffForm.controls['scopeOfWork'].errors.required
                        }"
                    >
                        Scope of work
                    </div>
                </div>
                <div class="separator-10px">
                    <div
                        class="jodit-container"
                        [ngClass]="{
                            'description-error':
                                callOffForm.controls['scopeOfWork'].invalid &&
                                callOffForm.controls['scopeOfWork'].errors.required,
                            'description-disabled': isLocked
                        }"
                        (click)="openJoditPopup(callOffForm.controls['scopeOfWork']?.value)"
                    >
                        <div
                            *ngIf="!isScopeOfWorkLoading"
                            [innerHTML]="callOffForm.controls['scopeOfWork']?.value | keepHtml"
                        ></div>
                        <div *ngIf="isScopeOfWorkLoading" class="jodit-loader">
                            <div class="lds-hourglass"></div>
                        </div>
                    </div>
                </div>
                <mat-error
                    class="error-description"
                    *ngIf="
                        callOffForm.controls['scopeOfWork'].invalid &&
                        callOffForm.controls['scopeOfWork'].errors.required
                    "
                >
                    Scope of work is required.
                </mat-error>
                <div class="container-radiobuttons">
                    <mat-label class="title-radiobuttons margin-right15">Priority</mat-label>
                    <mat-radio-group formControlName="priority">
                        <mat-radio-button [value]="1" class="margin-right40">1</mat-radio-button>
                        <mat-radio-button [value]="2" class="margin-right40">2</mat-radio-button>
                    </mat-radio-group>
                </div>
                <mat-form-field class="margin-right15">
                    <mat-label>Parent</mat-label>

                    <mat-select matNativeControl formControlName="changeDocParentType">
                        <mat-option *ngFor="let cdpt of changeDocParentTypeValues" [value]="cdpt.id">
                            {{ cdpt.value }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field class="parentNoContainer">
                    <mat-label>Parent No</mat-label>
                    <input
                        type="text"
                        placeholder="Parent No"
                        matInput
                        formControlName="changeDocRef"
                        [matAutocomplete]="auto"
                        [readonly]="!docRefEnabled"
                        (focus)="onFocus()"
                    />
                    <mat-autocomplete #auto="matAutocomplete">
                        <mat-option *ngFor="let fpnValue of filteredParentsNos | async" [value]="fpnValue">
                            {{ fpnValue }}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
        </div>
    </div>
</div>
