import { CalendarColumn, CheckListColumn, OrderDirection } from 'src/app/store/common.model';
import { CallOffStatusType } from '../../models/enums';
import { Moment } from 'moment';

export interface CallOffMyPageState {
    readonly counters: MyPageCounters;
    readonly isCountersSectionLoading: boolean;

    readonly myPendingActionsPagination: MyPageCollection<MyPageCallOffRow>;
    readonly isMyPendingActionsSectonLoading: boolean;
    readonly myPendingActionsFilter: MyPageFilter;

    readonly summaryPagination: MyPageCollection<MyPageCallOffRow>;
    readonly summaryFilter: MyPageFilter;
    readonly isSummarySectionLoading: boolean;
    readonly selectedSummarySection: CallOffMyPageSections;

    readonly allDraftsPagination: MyPageCollection<MyPageCallOffRow>;
    readonly isAllDraftsSectionLoading: boolean;
    readonly allDraftsFilter: MyPageFilter;

    readonly delegationsPagination: MyPageCollection<DelegationsRow>;
    readonly isDelegationsSectionLoading: boolean;
    readonly delegationsFilter: DelegationFilter;
    readonly selectedItems: DelegationsRow[];
}

export enum DelegationType {
    Originator = 1,
    TCOApprover = 2,
    ContractorApprover = 3,
    ContractsReviewer = 4,
}

export class DelegationsRow {
    type: DelegationType;
    id: number;
    callOffNumber: string;
    revision: string;
    subject: string;
    originator: string;
    companyRepresentative: string;
    contractorRepresentative: string;
    contractsTeamReviewer: string;
    status: CallOffStatusType;
    discipline: string;
    raisedDate: Moment;
    contractNo: string;
    reasonForInstruction: string;
}

export class MyPageCallOffRow {
    id: number;
    callOffNumber: string;
    revision: string;
    subject: string;
    originator: string;
    status: CallOffStatusType;
    discipline: string;
    raisedDate: Moment;
    routedDate: Moment;
}

export class MyPageCollection<T> {
    totalCount = 0;
    items: T[] = [];
}

export class MyPageFilter {
    sortBy: string;
    direction: OrderDirection = OrderDirection.Desc;
    page = 0;
    take = 10;
    columnNumber: CheckListColumn = null;
    columnRevision: CheckListColumn = null;
    columnDiscipline: CheckListColumn = null;
    columnSubject: CheckListColumn = null;
    columnStatus: CheckListColumn = null;
    columnOriginator: CheckListColumn = null;
    columnContractorRepresentative: CheckListColumn = null;
    columnCompanyRepresentative: CheckListColumn = null;
    columnContractsTeamReviewer: CheckListColumn = null;
    columnRaisedDate: CalendarColumn = null;
    columnCompanyApproveDate: CalendarColumn = null;
    columnContractorApproveDate: CalendarColumn = null;
    columnContractNo: CheckListColumn = null;
    columnReasonForInstruction: CheckListColumn = null;

    constructor(sortBy: string) {
        this.sortBy = sortBy;
    }
}

export class DelegationFilter extends MyPageFilter {
    type: DelegationType = DelegationType.Originator;
}

export class MyPageCounters {
    totalCount = 0;
    pendingActionCount = 0;
    draftCount = 0;
    submittedCount = 0;
    approvedCount = 0;
    reviewedCount = 0;
}

export enum CallOffMyPageSections {
    Total = 0,
    Pending = 1,
    Draft = 2,
    Submitted = 3,
    Reviewed = 4,
    Approved = 5,
    MyDraft = 6,
}
