<div class="popup">
    <p mat-dialog-title class="title"><span class="font-gotham-narrow-bold">add user contracts</span></p>
    <div>
        <form [formGroup]="addUserToContractsForm">
            <mat-form-field>
                <mat-label>User Role</mat-label>
                <mat-select
                    [compareWith]="compareRoles"
                    formControlName="userRole"
                    (openedChange)="onRoleClosed($event)"
                >
                    <mat-option *ngFor="let c of types" [value]="c.value">
                        {{ c.key }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <div class="user-selector">
                <app-multiple-items-selector
                    formControlName="user"
                    [filterForm]="addUserToContractsForm"
                    [formSetter]="'user'"
                    [isAsync]="true"
                    [searchFunc]="getUsers"
                    propertyToShow="name"
                    placeholder="User"
                    [setInput]="setUserInput"
                    [ngStyle]="{ display: isIE === true ? 'inline' : null }"
                    [oneItemMode]="true"
                    (autocompleteClosed)="onUsersClosed()"
                    (itemRemoved)="onUsersClosed()"
                    [isTableAutoComplete]="true"
                    [displayedColumns]="autocompleteDisplayedColumns"
                >
                </app-multiple-items-selector>
            </div>
            <mat-form-field>
                <mat-label>Contracts</mat-label>
                <mat-select formControlName="contracts" multiple>
                    <mat-option *ngFor="let c of contractors" [value]="c.contractNo">{{ c.contractNo }}</mat-option>
                </mat-select>
            </mat-form-field>
            <div class="row">
                <button mat-button type="button" class="cof-button cof-button-white" (click)="onCancel()">
                    cancel
                </button>
                <button
                    mat-button
                    type="button"
                    [disabled]="!addUserToContractsForm.valid"
                    class="cof-button cof-button-light"
                    (click)="onConfirm()"
                >
                    add
                </button>
            </div>
            <app-loading-indicator *ngIf="loading"> </app-loading-indicator>
        </form>
    </div>
</div>
