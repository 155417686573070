import { UserDetail } from '../common.model';
import { Action } from '@ngrx/store';

export enum ActionUsersActionTypes {
    ContractorRepresentativesRequest = '[Call-Off Action Users] ContractorRepresentatives Request',
    ContractorRepresentativesRequestSuccess = '[Call-Off Action Users] ContractorRepresentatives Success',
    ContractorRepresentativesRequestError = '[Call-Off Action Users] ContractorRepresentatives Error',

    CompanyRepresentativesRequest = '[Call-Off Action Users] CompanyRepresentatives Request',
    CompanyRepresentativesRequestSuccess = '[Call-Off Action Users] CompanyRepresentatives Success',
    CompanyRepresentativesRequestError = '[Call-Off Action Users] CompanyRepresentatives Error',

    ContractsTeamReviewersRequest = '[Call-Off Action Users] Contracts Team Reviewer Request',
    ContractsTeamReviewersRequestSuccess = '[Call-Off Action Users] Contracts Team Reviewer Success',
    ContractsTeamReviewersRequestError = '[Call-Off Action Users] Contracts Team Reviewer Error',

    AddPowerUserToCompanyRepresentatives = '[Call-Off Action Users] Add PowerUser to CompanyRepresentatives',
    AddPowerUserToContractorRepresentatives = '[Call-Off Action Users] Add PowerUser to ContractorRepresentatives',
}

export class ContractorRepresentativesRequest implements Action {
    readonly type = ActionUsersActionTypes.ContractorRepresentativesRequest;

    constructor(public payload: { contractNo: string; reasonId: number }) {}
}

export class ContractorRepresentativesRequestSuccess implements Action {
    readonly type = ActionUsersActionTypes.ContractorRepresentativesRequestSuccess;

    constructor(public payload: UserDetail[]) {}
}

export class ContractorRepresentativesRequestError implements Action {
    readonly type = ActionUsersActionTypes.ContractorRepresentativesRequestError;

    constructor(public payload: string) {}
}

export class CompanyRepresentativesRequest implements Action {
    readonly type = ActionUsersActionTypes.CompanyRepresentativesRequest;

    constructor(public payload: { contractNo: string; reasonId: number }) {}
}

export class CompanyRepresentativesRequestSuccess implements Action {
    readonly type = ActionUsersActionTypes.CompanyRepresentativesRequestSuccess;

    constructor(public payload: UserDetail[]) {}
}

export class CompanyRepresentativesRequestError implements Action {
    readonly type = ActionUsersActionTypes.CompanyRepresentativesRequestError;

    constructor(public payload: string) {}
}

export class ContractsTeamReviewersRequest implements Action {
    readonly type = ActionUsersActionTypes.ContractsTeamReviewersRequest;

    constructor(public payload: string) {}
}

export class ContractsTeamReviewersRequestSuccess implements Action {
    readonly type = ActionUsersActionTypes.ContractsTeamReviewersRequestSuccess;

    constructor(public payload: UserDetail[]) {}
}

export class ContractsTeamReviewersRequestError implements Action {
    readonly type = ActionUsersActionTypes.ContractsTeamReviewersRequestError;

    constructor(public payload: string) {}
}

export class AddPowerUserToCompanyRepresentatives implements Action {
    readonly type = ActionUsersActionTypes.AddPowerUserToCompanyRepresentatives;

    constructor(public payload: UserDetail) {}
}

export class AddPowerUserToContractorRepresentatives implements Action {
    readonly type = ActionUsersActionTypes.AddPowerUserToContractorRepresentatives;

    constructor(public payload: UserDetail) {}
}

export type ActionUsersAction =
    | ContractorRepresentativesRequest
    | ContractorRepresentativesRequestSuccess
    | ContractorRepresentativesRequestError
    | CompanyRepresentativesRequest
    | CompanyRepresentativesRequestSuccess
    | CompanyRepresentativesRequestError
    | ContractsTeamReviewersRequest
    | ContractsTeamReviewersRequestSuccess
    | ContractsTeamReviewersRequestError
    | AddPowerUserToCompanyRepresentatives
    | AddPowerUserToContractorRepresentatives;
