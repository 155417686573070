<div class="popup">
    <div class="icon-remove" (click)="onCancel()"></div>
    <p mat-dialog-title class="title"><span class="font-gotham-narrow-bold">Call-Off - previous revisions</span></p>
    <div class="table-container">
        <table
            mat-table
            matSort
            [dataSource]="dataSource"
            [matSortActive]="sortBy"
            matSortDisableClear
            [matSortDirection]="direction"
        >
            <ng-container matColumnDef="revision"
                ><th mat-header-cell mat-sort-header disableClear *matHeaderCellDef>Revision</th>
                <td class="rev" mat-cell *matCellDef="let element">
                    <a (click)="openRevision(element.id)">{{ element.revision }}</a>
                </td></ng-container
            >
            <ng-container matColumnDef="raiseDate"
                ><th mat-header-cell mat-sort-header disableClear *matHeaderCellDef>Raise Date</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.raiseDate | date: 'd/MMM/y' }}
                </td></ng-container
            >
            <ng-container matColumnDef="approveDate"
                ><th mat-header-cell mat-sort-header disableClear *matHeaderCellDef>Approve Date</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.approveDate | date: 'd/MMM/y' }}
                </td></ng-container
            >

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
        <mat-paginator [length]="resultsLength" [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 15]"></mat-paginator>

        <div *ngIf="isLoading" class="loader"><div class="lds-hourglass"></div></div>
    </div>
</div>
