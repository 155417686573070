import { Injectable } from '@angular/core';
import { ReasonForInstruction, Area } from '../store/common/model';
import { AWE, DefaultApprover } from '../store/awe-form/model';
import { ChangeDocParentType } from '../models/enums';
import { HttpClient, HttpParams } from '@angular/common/http';
import { CallOffRegisterFilter } from '../store/call-off-register/model';
import { ContractorLimit } from '../models/contractor-limit';
import { appConfig } from '../app.config';

@Injectable({
    providedIn: 'root',
})
export class CallOffLookupService {
    private readonly rootUrl: string = appConfig.apiEndpoint;
    private readonly callOffLookupEndpoint: string = '/lookup';

    constructor(private http: HttpClient) {}

    getReasons() {
        return this.http.get<ReasonForInstruction[]>(
            `${this.rootUrl}${this.callOffLookupEndpoint}/reason-for-instructions`
        );
    }

    getAreas() {
        return this.http.get<Area[]>(`${this.rootUrl}${this.callOffLookupEndpoint}/areas`);
    }

    getAwes() {
        return this.http.get<AWE[]>(`${this.rootUrl}${this.callOffLookupEndpoint}/awes`);
    }

    getAweNumbers(search = '', take = -1, page = -1, filter: CallOffRegisterFilter) {
        let params = this.getParams(search, take, page);
        return this.http.post<string[]>(
            `${this.rootUrl}${this.callOffLookupEndpoint}/awenumbers/calloffregister`,
            filter,
            { params }
        );
    }

    getNumbers(search = '', take = -1, page = -1, filter: CallOffRegisterFilter) {
        let params = this.getParams(search, take, page);
        return this.http.post<string[]>(
            `${this.rootUrl}${this.callOffLookupEndpoint}/numbers/calloffregister`,
            filter,
            { params }
        );
    }

    getRevisions(search = '', take = -1, page = -1, filter: CallOffRegisterFilter) {
        let params = this.getParams(search, take, page);
        return this.http.post<string[]>(
            `${this.rootUrl}${this.callOffLookupEndpoint}/revisions/calloffregister`,
            filter,
            { params }
        );
    }

    getSubjects(search = '', take = -1, page = -1, filter: CallOffRegisterFilter) {
        let params = this.getParams(search, take, page);
        return this.http.post<string[]>(
            `${this.rootUrl}${this.callOffLookupEndpoint}/subjects/calloffregister`,
            filter,
            { params }
        );
    }

    getStatuses(search = '', take = -1, page = -1, filter: CallOffRegisterFilter) {
        let params = this.getParams(search, take, page);
        return this.http.post<string[]>(
            `${this.rootUrl}${this.callOffLookupEndpoint}/statuses/calloffregister`,
            filter,
            { params }
        );
    }

    getSubsystems(search = '', take = -1, page = -1, filter: CallOffRegisterFilter) {
        let params = this.getParams(search, take, page);
        return this.http.post<string[]>(
            `${this.rootUrl}${this.callOffLookupEndpoint}/subsystems/calloffregister`,
            filter,
            { params }
        );
    }

    getManualFormNos(search = '', take = -1, page = -1, filter: CallOffRegisterFilter) {
        let params = this.getParams(search, take, page);
        return this.http.post<string[]>(
            `${this.rootUrl}${this.callOffLookupEndpoint}/manualformno/calloffregister`,
            filter,
            { params }
        );
    }

    getParentNos(search = '', take = -1, page = -1, filter: CallOffRegisterFilter) {
        let params = this.getParams(search, take, page);
        return this.http.post<string[]>(
            `${this.rootUrl}${this.callOffLookupEndpoint}/parentno/calloffregister`,
            filter,
            { params }
        );
    }

    getCWPs(search = '', take = -1, page = -1, filter: CallOffRegisterFilter) {
        let params = this.getParams(search, take, page);
        return this.http.post<string[]>(`${this.rootUrl}${this.callOffLookupEndpoint}/cwps/calloffregister`, filter, {
            params,
        });
    }

    getDisciplines(search = '', take = -1, page = -1, filter: CallOffRegisterFilter) {
        let params = this.getParams(search, take, page);
        return this.http.post<string[]>(
            `${this.rootUrl}${this.callOffLookupEndpoint}/disciplines/calloffregister`,
            filter,
            { params }
        );
    }

    getOriginators(search = '', take = -1, page = -1, filter: CallOffRegisterFilter) {
        let params = this.getParams(search, take, page);
        return this.http.post<string[]>(
            `${this.rootUrl}${this.callOffLookupEndpoint}/originators/calloffregister`,
            filter,
            { params }
        );
    }

    getContractors(search = '', take = -1, page = -1, filter: CallOffRegisterFilter) {
        let params = this.getParams(search, take, page);
        return this.http.post<string[]>(
            `${this.rootUrl}${this.callOffLookupEndpoint}/contractors/calloffregister`,
            filter,
            { params }
        );
    }

    getAweTitles() {
        return this.http.get<string[]>(`${this.rootUrl}${this.callOffLookupEndpoint}/awetitles`);
    }

    getContractNumbers() {
        return this.http.get<string[]>(`${this.rootUrl}${this.callOffLookupEndpoint}/awecontractnumbers`);
    }

    getChangeDocRefValues(changeDocParentType: ChangeDocParentType) {
        return this.http.get<string[]>(
            `${this.rootUrl}${this.callOffLookupEndpoint}/changedocrefvalues?changeDocParentType=${changeDocParentType}`
        );
    }

    getNteRemaining(aweId: number, cofId: number) {
        return this.http.get<number>(
            `${this.rootUrl}${this.callOffLookupEndpoint}/nteRemaining?aweId=${aweId}&cofId=${cofId}`
        );
    }

    getContractorLimitByContractNo(contractNo: string) {
        return this.http.get<ContractorLimit>(
            `${this.rootUrl}${this.callOffLookupEndpoint}/contractorlimit?contractNo=${contractNo}`
        );
    }

    private getParams(search: string, take: number, page: number) {
        let params = new HttpParams();
        params = params.append('page', page.toString());
        params = params.append('take', take.toString());
        params = params.append('search', search);
        return params;
    }
}
