<main class="main">
    <form [formGroup]="filterForm">
        <div class="title-container">
            <div class="title">
                Call-Off Register
            </div>
        </div>
        <div class="filter-container">
            <mat-form-field class="container620 margin-right20">
                <mat-label>Call-Off Number</mat-label>
                <input matInput formControlName="number" />
            </mat-form-field>
            <mat-form-field class="container620">
                <mat-label>Subject</mat-label>
                <input matInput formControlName="subject" />
            </mat-form-field>
            <div class="mixedapp-container">
                <div class="container450 margin-right20">
                    <app-multiple-items-selector
                        [setInput]="setContractorInput"
                        formControlName="contractors"
                        [searchFunc]="getContractors"
                        [isAsync]="true"
                        [isTableAutoComplete]="true"
                        [displayedColumns]="contractorDisplayedColumns"
                        (autocompleteClosed)="onContractorsClosed()"
                        (itemRemoved)="onContractorsClosed()"
                        [filterForm]="filterForm"
                        [formSetter]="'contractors'"
                        [propertyToShow]="'contractNo'"
                        [placeholder]="'Contractor'"
                        [contractorClass]="'nobreak'"
                        [ngStyle]="{ display: isIE === true ? 'inline' : null }"
                    >
                    </app-multiple-items-selector>
                </div>
                <mat-form-field class="container150 margin-right20">
                    <mat-label>Revision</mat-label>
                    <input matInput type="number" formControlName="revision" />
                </mat-form-field>
                <mat-form-field class="container300 margin-right20">
                    <mat-label>Discipline</mat-label>
                    <mat-select formControlName="disciplines" multiple>
                        <mat-option *ngFor="let disc of disciplines" [value]="disc">{{ disc }} </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field class="container300">
                    <mat-label>Status</mat-label>
                    <mat-select [compareWith]="compareStatuses" formControlName="statuses" multiple>
                        <mat-option *ngFor="let s of statuses | enumKeyValuelist" [value]="s.key">
                            {{ s.value }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="row-multiselector-container" [ngStyle]="{ 'margin-top': isIE === true ? '10px' : null }">
                <div class="container300-mh55 margin-right20">
                    <app-multiple-items-selector
                        class="container300"
                        formControlName="cwps"
                        [searchFunc]="getCWPs"
                        [isAsync]="true"
                        [filterForm]="filterForm"
                        [formSetter]="'cwps'"
                        placeholder="CWP"
                        [ngStyle]="{ display: isIE === true ? 'inline' : null }"
                    >
                    </app-multiple-items-selector>
                </div>
                <div class="container300-mh55 margin-right20">
                    <app-multiple-items-selector
                        class="container300"
                        formControlName="originators"
                        [filterForm]="filterForm"
                        [formSetter]="'originators'"
                        [searchFunc]="getOriginators"
                        [isAsync]="true"
                        propertyToShow="name"
                        placeholder="Originator"
                        [setInput]="setOriginatorInput"
                        [ngStyle]="{ display: isIE === true ? 'inline' : null }"
                    >
                    </app-multiple-items-selector>
                </div>
                <div class="container300-mh55">
                    <app-multiple-items-selector
                        class="container300"
                        formControlName="subsystems"
                        [searchFunc]="getSubsystems"
                        [isAsync]="true"
                        [filterForm]="filterForm"
                        [formSetter]="'subsystems'"
                        [setInput]="setSubsystemInput"
                        placeholder="Subsystem"
                        [ngStyle]="{ display: isIE === true ? 'inline' : null }"
                    >
                    </app-multiple-items-selector>
                </div>
            </div>
        </div>
        <div class="buttons-container flex">
            <button
                *ngIf="!isReadOnly"
                type="button"
                class="cof-button cof-button-light margin-right20"
                (click)="exportToExcel()"
            >
                export to excel
            </button>
            <button type="button" (click)="resetFilters()" class="cof-button cof-button-light margin-right20">
                reset filters
            </button>
            <button type="button" (click)="search()" class="cof-button cof-button-light">search</button>
        </div>
        <div class="results-container">
            <div class="results-title">
                <div class="title-date">{{ todayDate | date: 'MMM d yyyy' }}</div>
                <div class="table-checkboxes">
                    <mat-checkbox [checked]="showColumnSettings?.discipline" (change)="toggleShowSetting('discipline')"
                        >Discipline</mat-checkbox
                    >
                    <mat-checkbox [checked]="showColumnSettings?.originator" (change)="toggleShowSetting('originator')"
                        >Originator</mat-checkbox
                    >
                    <mat-checkbox [checked]="showColumnSettings?.raiseDate" (change)="toggleShowSetting('raiseDate')"
                        >Raise Date</mat-checkbox
                    >
                    <mat-checkbox
                        [checked]="showColumnSettings?.companyApprDate"
                        (change)="toggleShowSetting('companyApprDate')"
                        >Company Approve Date</mat-checkbox
                    >
                    <mat-checkbox
                        [checked]="showColumnSettings?.contractorApprDate"
                        (change)="toggleShowSetting('contractorApprDate')"
                        >Contractor Approve Date</mat-checkbox
                    >
                    <mat-checkbox [checked]="showColumnSettings?.AWE" (change)="toggleShowSetting('AWE')"
                        >AWE</mat-checkbox
                    >
                    <mat-checkbox [checked]="showColumnSettings?.CWP" (change)="toggleShowSetting('CWP')"
                        >CWP</mat-checkbox
                    >
                    <mat-checkbox [checked]="showColumnSettings?.contractor" (change)="toggleShowSetting('contractor')"
                        >Contractor</mat-checkbox
                    >
                    <mat-checkbox
                        [checked]="showColumnSettings?.manualFormNo"
                        (change)="toggleShowSetting('manualFormNo')"
                        >Manual Form No</mat-checkbox
                    >
                    <mat-checkbox [checked]="showColumnSettings?.parentNo" (change)="toggleShowSetting('parentNo')"
                        >Parent No</mat-checkbox
                    >
                </div>
            </div>
            <div class="scroll">
                <table
                    mat-table
                    matSort
                    [dataSource]="data"
                    [matSortActive]="sortBy"
                    matSortDisableClear
                    [matSortDirection]="direction"
                >
                    <ng-container matColumnDef="number" sticky>
                        <th app-col-resize mat-header-cell disableClear *matHeaderCellDef class="sort-header">
                            <div class="sort-container center">
                                <span
                                    class="hover-pointer"
                                    [ngClass]="{
                                        'header-underline': showUnderlineForCheckListColumn(columnNumbers)
                                    }"
                                    (click)="
                                        openHeaderCheckListFilter(
                                            'number',
                                            getNumbers,
                                            'columnNumbers',
                                            'Number',
                                            columnNumbers
                                        )
                                    "
                                    >Number</span
                                >
                                <div class="sort-icon" *ngIf="sortBy === 'number'">
                                    <mat-icon *ngIf="direction === 'asc'">keyboard_arrow_up</mat-icon>
                                    <mat-icon *ngIf="direction === 'desc'">keyboard_arrow_down</mat-icon>
                                </div>
                            </div>
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <a (click)="openDetailsPage(element.id)">{{ element.number }}</a>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="revision">
                        <th mat-header-cell disableClear *matHeaderCellDef class="center">
                            <div class="sort-container center">
                                <span
                                    class="hover-pointer"
                                    [ngClass]="{
                                        'header-underline': showUnderlineForCheckListColumn(columnRevisions)
                                    }"
                                    (click)="
                                        openHeaderCheckListFilter(
                                            'revision',
                                            getRevisions,
                                            'columnRevisions',
                                            'Revision',
                                            columnRevisions
                                        )
                                    "
                                    >Rev</span
                                >
                                <div class="sort-icon" *ngIf="sortBy === 'revision'">
                                    <mat-icon *ngIf="direction === 'asc'">keyboard_arrow_up</mat-icon>
                                    <mat-icon *ngIf="direction === 'desc'">keyboard_arrow_down</mat-icon>
                                </div>
                            </div>
                        </th>
                        <td mat-cell *matCellDef="let element" class="center">
                            {{ element.revision }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="discipline">
                        <th
                            mat-header-cell
                            disableClear
                            *matHeaderCellDef
                            class="center"
                            [ngClass]="{
                                hidecells: !showColumnSettings?.discipline
                            }"
                        >
                            <div class="sort-container center">
                                <span
                                    class="hover-pointer"
                                    [ngClass]="{
                                        'header-underline': showUnderlineForCheckListColumn(columnDisciplines)
                                    }"
                                    (click)="
                                        openHeaderCheckListFilter(
                                            'discipline',
                                            getDisciplines,
                                            'columnDisciplines',
                                            'Discipline',
                                            columnDisciplines,
                                            true
                                        )
                                    "
                                    >Discipline</span
                                >
                                <div class="sort-icon" *ngIf="sortBy === 'discipline'">
                                    <mat-icon *ngIf="direction === 'asc'">keyboard_arrow_up</mat-icon>
                                    <mat-icon *ngIf="direction === 'desc'">keyboard_arrow_down</mat-icon>
                                </div>
                            </div>
                        </th>
                        <td
                            mat-cell
                            *matCellDef="let element"
                            class="center"
                            [ngClass]="{
                                hidecells: !showColumnSettings?.discipline
                            }"
                        >
                            {{ element.discipline }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="subject">
                        <th app-col-resize mat-header-cell disableClear *matHeaderCellDef class="sort-header">
                            <div class="sort-container center">
                                <span
                                    class="hover-pointer"
                                    [ngClass]="{
                                        'header-underline': showUnderlineForCheckListColumn(columnSubjects)
                                    }"
                                    (click)="
                                        openHeaderCheckListFilter(
                                            'subject',
                                            getSubjects,
                                            'columnSubjects',
                                            'Subject',
                                            columnSubjects
                                        )
                                    "
                                    >Subject</span
                                >
                                <div class="sort-icon" *ngIf="sortBy === 'subject'">
                                    <mat-icon *ngIf="direction === 'asc'">keyboard_arrow_up</mat-icon>
                                    <mat-icon *ngIf="direction === 'desc'">keyboard_arrow_down</mat-icon>
                                </div>
                            </div>
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.subject }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="originator">
                        <th
                            mat-header-cell
                            disableClear
                            *matHeaderCellDef
                            class="center"
                            [ngClass]="{
                                hidecells: !showColumnSettings?.originator
                            }"
                        >
                            <div class="sort-container center">
                                <span
                                    class="hover-pointer"
                                    [ngClass]="{
                                        'header-underline': showUnderlineForCheckListColumn(columnOriginators)
                                    }"
                                    (click)="
                                        openHeaderCheckListFilter(
                                            'originator',
                                            searchOriginators,
                                            'columnOriginators',
                                            'Originator',
                                            columnOriginators,
                                            true
                                        )
                                    "
                                    >Originator</span
                                >
                                <div class="sort-icon" *ngIf="sortBy === 'originator'">
                                    <mat-icon *ngIf="direction === 'asc'">keyboard_arrow_up</mat-icon>
                                    <mat-icon *ngIf="direction === 'desc'">keyboard_arrow_down</mat-icon>
                                </div>
                            </div>
                        </th>
                        <td
                            mat-cell
                            *matCellDef="let element"
                            class="center"
                            [ngClass]="{
                                hidecells: !showColumnSettings?.originator
                            }"
                        >
                            {{ element.originator }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="status">
                        <th mat-header-cell disableClear *matHeaderCellDef class="center">
                            <div class="sort-container center">
                                <span
                                    class="hover-pointer"
                                    [ngClass]="{
                                        'header-underline': showUnderlineForCheckListColumn(columnStatuses)
                                    }"
                                    (click)="
                                        openHeaderCheckListFilter(
                                            'status',
                                            getStatuses,
                                            'columnStatuses',
                                            'Status',
                                            columnStatuses,
                                            true,
                                            false
                                        )
                                    "
                                    >Status</span
                                >
                                <div class="sort-icon" *ngIf="sortBy === 'status'">
                                    <mat-icon *ngIf="direction === 'asc'">keyboard_arrow_up</mat-icon>
                                    <mat-icon *ngIf="direction === 'desc'">keyboard_arrow_down</mat-icon>
                                </div>
                            </div>
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <div class="cof-status" [ngClass]="getClassName(element.status)">
                                {{ getStatusName(element.status) }}
                            </div>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="nteUSD">
                        <th
                            mat-header-cell
                            disableClear
                            *matHeaderCellDef
                            class="hover-pointer"
                            (click)="openHeaderRangeFilter('nteUSD', 'columnNTE', 'Value (USDE)', columnNTE)"
                        >
                            <div class="sort-container center">
                                <span [ngClass]="{ 'header-underline': showUnderlineForRangeColumn(columnNTE) }"
                                    >Value (USDE)</span
                                >
                                <div class="sort-icon" *ngIf="sortBy === 'nteUSD'">
                                    <mat-icon *ngIf="direction === 'asc'">keyboard_arrow_up</mat-icon>
                                    <mat-icon *ngIf="direction === 'desc'">keyboard_arrow_down</mat-icon>
                                </div>
                            </div>
                        </th>
                        <td mat-cell *matCellDef="let element" class="center">
                            {{ element.nteUSD | currency: 'USD':'symbol':'1.0-0' }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="subsystems">
                        <th app-col-resize mat-header-cell disableClear *matHeaderCellDef class="center">
                            <div class="sort-container center">
                                <span
                                    class="hover-pointer"
                                    [ngClass]="{
                                        'header-underline': showUnderlineForCheckListColumn(columnSubsystems)
                                    }"
                                    (click)="
                                        openHeaderCheckListFilter(
                                            'subsystems',
                                            searchSubsystems,
                                            'columnSubsystems',
                                            'Subsystems',
                                            columnSubsystems,
                                            false,
                                            true,
                                            true
                                        )
                                    "
                                    >Subsystems</span
                                >
                                <div class="sort-icon" *ngIf="sortBy === 'subsystems'">
                                    <mat-icon *ngIf="direction === 'asc'">keyboard_arrow_up</mat-icon>
                                    <mat-icon *ngIf="direction === 'desc'">keyboard_arrow_down</mat-icon>
                                </div>
                            </div>
                        </th>
                        <td mat-cell *matCellDef="let element" class="center">
                            {{ element.subsystems.join(', ') }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="awe">
                        <th
                            app-col-resize
                            mat-header-cell
                            disableClear
                            *matHeaderCellDef
                            class="center"
                            [ngClass]="{
                                hidecells: !showColumnSettings?.AWE
                            }"
                        >
                            <div class="sort-container center">
                                <span
                                    class="hover-pointer"
                                    [ngClass]="{
                                        'header-underline': showUnderlineForCheckListColumn(columnAWE)
                                    }"
                                    (click)="
                                        openHeaderCheckListFilter(
                                            'awe',
                                            getAWE,
                                            'columnAWE',
                                            'AWE',
                                            columnAWE,
                                            true,
                                            true
                                        )
                                    "
                                    >AWE</span
                                >
                                <div class="sort-icon" *ngIf="sortBy === 'awe'">
                                    <mat-icon *ngIf="direction === 'asc'">keyboard_arrow_up</mat-icon>
                                    <mat-icon *ngIf="direction === 'desc'">keyboard_arrow_down</mat-icon>
                                </div>
                            </div>
                        </th>
                        <td
                            mat-cell
                            *matCellDef="let element"
                            class="center"
                            [ngClass]="{
                                hidecells: !showColumnSettings?.AWE
                            }"
                        >
                            {{ element.awe }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="raisedDate">
                        <th
                            mat-header-cell
                            disableClear
                            *matHeaderCellDef
                            class="hover-pointer"
                            [ngClass]="{
                                hidecells: !showColumnSettings?.raiseDate
                            }"
                            (click)="
                                openHeaderDateFilter('raisedDate', 'columnRaisedDate', 'Raise Date', columnRaisedDate)
                            "
                        >
                            <div class="sort-container center">
                                <span
                                    [ngClass]="{
                                        'header-underline': showUnderlineForCalendarColumn(columnRaisedDate)
                                    }"
                                    >Raise Date</span
                                >
                                <div class="sort-icon" *ngIf="sortBy === 'raisedDate'">
                                    <mat-icon *ngIf="direction === 'asc'">keyboard_arrow_up</mat-icon>
                                    <mat-icon *ngIf="direction === 'desc'">keyboard_arrow_down</mat-icon>
                                </div>
                            </div>
                        </th>
                        <td
                            mat-cell
                            *matCellDef="let element"
                            class="center"
                            [ngClass]="{
                                hidecells: !showColumnSettings?.raiseDate
                            }"
                        >
                            {{ element.raisedDate | date: 'd/MMM/y' }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="companyApproveDate">
                        <th
                            mat-header-cell
                            disableClear
                            *matHeaderCellDef
                            class="hover-pointer"
                            [ngClass]="{
                                hidecells: !showColumnSettings?.companyApprDate
                            }"
                            (click)="
                                openHeaderDateFilter(
                                    'companyApproveDate',
                                    'columnCompanyApproveDate',
                                    'Company Approve Date',
                                    columnCompanyApproveDate
                                )
                            "
                        >
                            <div class="sort-container center">
                                <span
                                    [ngClass]="{
                                        'header-underline': showUnderlineForCalendarColumn(columnCompanyApproveDate)
                                    }"
                                    >Company Approve Date</span
                                >
                                <div class="sort-icon" *ngIf="sortBy === 'companyApproveDate'">
                                    <mat-icon *ngIf="direction === 'asc'">keyboard_arrow_up</mat-icon>
                                    <mat-icon *ngIf="direction === 'desc'">keyboard_arrow_down</mat-icon>
                                </div>
                            </div>
                        </th>
                        <td
                            mat-cell
                            *matCellDef="let element"
                            class="center"
                            [ngClass]="{
                                hidecells: !showColumnSettings?.companyApprDate
                            }"
                        >
                            {{ element.companyApproveDate | date: 'd/MMM/y' }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="contractorApproveDate">
                        <th
                            mat-header-cell
                            disableClear
                            *matHeaderCellDef
                            class="hover-pointer"
                            [ngClass]="{
                                hidecells: !showColumnSettings?.contractorApprDate
                            }"
                            (click)="
                                openHeaderDateFilter(
                                    'contractorApproveDate',
                                    'columnContractorApproveDate',
                                    'Contractor Approve Date',
                                    columnContractorApproveDate
                                )
                            "
                        >
                            <div class="sort-container center">
                                <span
                                    [ngClass]="{
                                        'header-underline': showUnderlineForCalendarColumn(columnContractorApproveDate)
                                    }"
                                    >Contractor Approve Date</span
                                >
                                <div class="sort-icon" *ngIf="sortBy === 'contractorApproveDate'">
                                    <mat-icon *ngIf="direction === 'asc'">keyboard_arrow_up</mat-icon>
                                    <mat-icon *ngIf="direction === 'desc'">keyboard_arrow_down</mat-icon>
                                </div>
                            </div>
                        </th>
                        <td
                            mat-cell
                            *matCellDef="let element"
                            class="center"
                            [ngClass]="{
                                hidecells: !showColumnSettings?.contractorApprDate
                            }"
                        >
                            {{ element.contractorApproveDate | date: 'd/MMM/y' }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="cwp">
                        <th
                            mat-header-cell
                            disableClear
                            *matHeaderCellDef
                            class="center"
                            [ngClass]="{
                                hidecells: !showColumnSettings?.CWP
                            }"
                        >
                            <div class="sort-container center">
                                <span
                                    class="hover-pointer"
                                    [ngClass]="{
                                        'header-underline': showUnderlineForCheckListColumn(columnCWPs)
                                    }"
                                    (click)="
                                        openHeaderCheckListFilter(
                                            'cwp',
                                            searchCWPs,
                                            'columnCWPs',
                                            'CWPs',
                                            columnCWPs,
                                            false,
                                            true,
                                            true
                                        )
                                    "
                                    >CWPs</span
                                >
                                <div class="sort-icon" *ngIf="sortBy === 'cwp'">
                                    <mat-icon *ngIf="direction === 'asc'">keyboard_arrow_up</mat-icon>
                                    <mat-icon *ngIf="direction === 'desc'">keyboard_arrow_down</mat-icon>
                                </div>
                            </div>
                        </th>
                        <td
                            mat-cell
                            *matCellDef="let element"
                            class="center"
                            [ngClass]="{
                                hidecells: !showColumnSettings?.CWP
                            }"
                        >
                            {{ element.cwp }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="manualFormNo">
                        <th
                            mat-header-cell
                            disableClear
                            *matHeaderCellDef
                            class="center"
                            [ngClass]="{
                                hidecells: !showColumnSettings?.manualFormNo
                            }"
                        >
                            <div class="sort-container center">
                                <span
                                    class="hover-pointer"
                                    [ngClass]="{
                                        'header-underline': showUnderlineForCheckListColumn(columnManualFormNos)
                                    }"
                                    (click)="
                                        openHeaderCheckListFilter(
                                            'manualFormNo',
                                            searchManualFormNos,
                                            'columnManualFormNos',
                                            'Manual Form No',
                                            columnManualFormNos
                                        )
                                    "
                                    >Manual Form No</span
                                >
                                <div class="sort-icon" *ngIf="sortBy === 'manualFormNo'">
                                    <mat-icon *ngIf="direction === 'asc'">keyboard_arrow_up</mat-icon>
                                    <mat-icon *ngIf="direction === 'desc'">keyboard_arrow_down</mat-icon>
                                </div>
                            </div>
                        </th>
                        <td
                            mat-cell
                            *matCellDef="let element"
                            class="center"
                            [ngClass]="{
                                hidecells: !showColumnSettings?.manualFormNo
                            }"
                        >
                            {{ element.manualFormNo }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="parentNo">
                        <th
                            mat-header-cell
                            disableClear
                            *matHeaderCellDef
                            class="center"
                            [ngClass]="{
                                hidecells: !showColumnSettings?.parentNo
                            }"
                        >
                            <div class="sort-container center">
                                <span
                                    class="hover-pointer"
                                    [ngClass]="{
                                        'header-underline': showUnderlineForCheckListColumn(columnParentNos)
                                    }"
                                    (click)="
                                        openHeaderCheckListFilter(
                                            'parentNo',
                                            searchParentNos,
                                            'columnParentNos',
                                            'Parent No',
                                            columnParentNos
                                        )
                                    "
                                    >Parent No</span
                                >
                                <div class="sort-icon" *ngIf="sortBy === 'parentNo'">
                                    <mat-icon *ngIf="direction === 'asc'">keyboard_arrow_up</mat-icon>
                                    <mat-icon *ngIf="direction === 'desc'">keyboard_arrow_down</mat-icon>
                                </div>
                            </div>
                        </th>
                        <td
                            mat-cell
                            *matCellDef="let element"
                            class="center"
                            [ngClass]="{
                                hidecells: !showColumnSettings?.parentNo
                            }"
                        >
                            {{ element.parentNo }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="contractor">
                        <th
                            mat-header-cell
                            disableClear
                            *matHeaderCellDef
                            [ngClass]="{
                                hidecells: !showColumnSettings?.contractor
                            }"
                        >
                            <div class="sort-container center">
                                <span
                                    class="hover-pointer"
                                    [ngClass]="{
                                        'header-underline': showUnderlineForCheckListColumn(columnContracts)
                                    }"
                                    (click)="
                                        openHeaderCheckListFilter(
                                            'contractor',
                                            searchContractors,
                                            'columnContracts',
                                            'Contractor',
                                            columnContracts,
                                            true
                                        )
                                    "
                                    >Contractor</span
                                >
                                <div class="sort-icon" *ngIf="sortBy === 'contractor'">
                                    <mat-icon *ngIf="direction === 'asc'">keyboard_arrow_up</mat-icon>
                                    <mat-icon *ngIf="direction === 'desc'">keyboard_arrow_down</mat-icon>
                                </div>
                            </div>
                        </th>
                        <td
                            mat-cell
                            *matCellDef="let element"
                            [ngClass]="{
                                hidecells: !showColumnSettings?.contractor
                            }"
                        >
                            {{ element.contractor }}
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                </table>
            </div>
            <div class="paginator page-counter">
                <mat-paginator
                    [length]="resultsLength"
                    [pageSize]="pageSize"
                    [pageSizeOptions]="[10, 15, 20]"
                    (page)="onPaginatorChange($event)"
                    showFirstLastButtons
                >
                </mat-paginator>
                <input
                    type="number"
                    min="1"
                    [value]="this.paginator.pageIndex + 1"
                    (change)="onPageChange($event.target.value)"
                />
            </div>
        </div>
        <app-loading-indicator *ngIf="isLoading"></app-loading-indicator>
    </form>
</main>
