import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NTENotificationDTO } from '../models/nte-notification-dto';
import { appConfig } from '../app.config';

@Injectable({
    providedIn: 'root',
})
export class NTENotificationService {
    private readonly rootUrl: string = appConfig.apiEndpoint;
    private readonly endpoint: string = '/nteNotification';

    constructor(private http: HttpClient) {}

    get() {
        return this.http.get<NTENotificationDTO[]>(`${this.rootUrl}${this.endpoint}`);
    }

    add(notification: NTENotificationDTO) {
        return this.http.post(`${this.rootUrl}${this.endpoint}`, notification);
    }

    remove(contract: string) {
        return this.http.delete<boolean>(`${this.rootUrl}${this.endpoint}?contract=${contract}`);
    }

    update(notification: NTENotificationDTO) {
        return this.http.patch(`${this.rootUrl}${this.endpoint}`, notification);
    }
}
