import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Constants } from './models/constants.model';

@Component({
    selector: 'app-cof-attachments',
    templateUrl: './cof-attachments.component.html',
    styleUrls: ['./cof-attachments.component.scss'],
})
export class CofAttachmentsComponent {
    @Input() callOffForm: FormGroup;
    @Input() printMode = false;
    supportingDocsHeader = Constants.SupportingDocumentsHeader;
}
