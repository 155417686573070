import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { appConfig } from '../app.config';
import {
    ConstructionApproverMatrixDTO,
    ConstructionApproverMatrixFilter,
    ConstructionApproverMatrixResultData,
} from '../store/construction-approver-matrix/models';

@Injectable({
    providedIn: 'root',
})
export class ConstructionApproverMatrixService {
    private readonly rootUrl: string = appConfig.apiEndpoint;
    private readonly endpoint: string = '/constructionapprovermatrix';

    constructor(private http: HttpClient) {}

    private static getParams(search: string, take: number, page: number) {
        return new HttpParams()
            .append('page', page.toString())
            .append('take', take.toString())
            .append('search', search);
    }

    getAllData(filter: ConstructionApproverMatrixFilter) {
        return this.http.post<ConstructionApproverMatrixResultData>(`${this.rootUrl}${this.endpoint}/register`, filter);
    }

    add(item: ConstructionApproverMatrixDTO) {
        return this.http.post<ConstructionApproverMatrixDTO>(`${this.rootUrl}${this.endpoint}`, item);
    }

    delete(id: number) {
        return this.http.delete(`${this.rootUrl}${this.endpoint}/${id}`);
    }

    update(item: ConstructionApproverMatrixDTO) {
        return this.http.put<ConstructionApproverMatrixDTO>(`${this.rootUrl}${this.endpoint}`, item);
    }

    getApproversForFilter(search: string, take: number, page: number, filter: ConstructionApproverMatrixFilter){
        var params = ConstructionApproverMatrixService.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.endpoint}/getapproversforfilter`, filter, {params}); 
    }
}
