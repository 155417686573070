import { Component, Inject, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { CallOffStatusType } from 'src/app/models/enums';
import { CallOffAttachmentType } from 'src/app/store/call-off-attachments/model';
import { LockCallOffForm, UnLockCallOffForm } from 'src/app/store/call-off/actions';
import { ApplicationState } from 'src/app/store/model';

@Component({
    selector: 'app-cof-completed-popup',
    templateUrl: './cof-completed-popup.component.html',
    styleUrls: ['./cof-completed-popup.component.scss'],
})
export class COFCompletedPopupComponent implements OnInit {
    attachmentTypes = CallOffAttachmentType;
    cofStatuses = CallOffStatusType;
    isFormInitiallyEnabled = false;
    isCOFCompleted = false;

    constructor(
        public dialogRef: MatDialogRef<COFCompletedPopupComponent>,
        @Inject(MAT_DIALOG_DATA) public data,
        private store: Store<ApplicationState>
    ) {
        this.isFormInitiallyEnabled = data.callOffForm.enabled;
        this.isCOFCompleted = data.callOffForm.value.status === this.cofStatuses.Completed;
    }

    ngOnInit() {
        setTimeout(() => {
            this.store.dispatch(new UnLockCallOffForm());
        });
        this.data.callOffForm.updateValueAndValidity();
        this.dialogRef
            .afterClosed()
            .subscribe(() => !this.isFormInitiallyEnabled && this.store.dispatch(new LockCallOffForm()));
    }

    signAndClose() {
        this.dialogRef.close(true);
    }

    cancel() {
        this.dialogRef.close(false);
    }
}
