import { FormControl, Validators, AbstractControl } from '@angular/forms';

export function commaSepEmail(control: AbstractControl): { [key: string]: any } | null {
    const forbidden = isCommaSepEmail(control.value)
    return forbidden ? { emails: { value: control.value } } : null;
}

export function isCommaSepEmail(value: string): boolean {
    const emails = value.split(',').map((e) => e.trim());
    const forbidden = emails.some((email) => Validators.email(new FormControl(email)));
    return forbidden;
}
