import { Component, OnInit, Input, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BaseComponent } from 'src/app/components/base.component';
import { LookupService } from '../../../services/lookup.service';
import { map, take, takeWhile } from 'rxjs/operators';
import { SetInputEventArgs } from 'src/app/models/set-input';
import { Area } from 'src/app/store/common/model';
import { CallOffLookupService } from 'src/app/services/call-off-lookup.service';
import { Store } from '@ngrx/store';
import { ApplicationState } from 'src/app/store/model';
@Component({
    selector: 'app-cof-header',
    templateUrl: './cof-header.component.html',
    styleUrls: ['./cof-header.component.scss'],
})
export class CallOfFormHeaderComponent extends BaseComponent implements OnInit {
    @Input() callOffForm: FormGroup;
    disciplines: string[] = [];
    areas: Area[];
    searchCurrentProjectSubsystems = false;
    tags: string[] = [];
    autocompleteDisplayedColumns = ['tagNo', 'tagType'];
    setSubsystemInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setTagInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setCWPInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    isSubsystemsLoading = false;
    isLocked = false;
    isLocked$ = this.store.select((state) => state.callOffState.isLocked);

    constructor(
        private lookupService: LookupService,
        private callOffLookupService: CallOffLookupService,
        private store: Store<ApplicationState>
    ) {
        super();
    }

    ngOnInit() {
        this.isLocked$.pipe(takeWhile(() => this.isAlive)).subscribe((isLocked) => {
            this.isLocked = isLocked;
        });

        this.lookupService
            .getDisciplines()
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((disciplines) => {
                this.disciplines = disciplines;
            });
        this.callOffLookupService
            .getAreas()
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((areas) => {
                this.areas = areas;
            });

        this.store
            .select((state) => state.callOffState.isCreated)
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((isCreated) => {
                if (isCreated) {
                    this.setSubsystemInput.emit(new SetInputEventArgs(false));
                    this.setCWPInput.emit(new SetInputEventArgs(false));
                    this.setTagInput.emit(new SetInputEventArgs(false));
                }
            });
    }

    ngAfterViewInit() {
        this.setSubsystemInput.emit(new SetInputEventArgs(true));
        this.setCWPInput.emit(new SetInputEventArgs(true));
        this.setTagInput.emit(new SetInputEventArgs(true));
    }

    getSubsystems = (search = '', take = 10, page = 0) => {
        return this.lookupService.searchSubsystems(
            search,
            take,
            page,
            this.searchCurrentProjectSubsystems ? [this.callOffForm.value.awe.projectTeamName] : []
        );
    };

    getTags = (search = '', take = 10, page = 0) => {
        return this.lookupService.searchTags(search, take, page);
    };

    onTagsClosed() {
        this.isSubsystemsLoading = true;
        this.lookupService
            .getSubsystemsByTags(this.callOffForm.value.tags || [])
            .pipe(take(1))
            .subscribe(
                (subs: string[]) => {
                    this.isSubsystemsLoading = false;
                    this.callOffForm.controls['subsystems'].setValue(
                        subs.map((s) => {
                            return { id: s };
                        })
                    );
                },
                () => {
                    this.isSubsystemsLoading = false;
                }
            );
    }

    getCWPs = (search = '', take = 10, page = 0) => {
        return this.lookupService.searchCWPs(search, take, page).pipe(
            takeWhile(() => this.isAlive),
            map((cwps: string[]) =>
                cwps.map((s) => {
                    return { id: s };
                })
            )
        );
    };

    displayMultipleSelected(values: any[], propertyName: string) {
        return values.map((x) => x[propertyName]).join(', ');
    }

    clearProperty(propertyName: string) {
        if (Array.isArray(this.callOffForm.controls[propertyName].value)) {
            this.callOffForm.controls[propertyName].setValue([]);
        } else {
            this.callOffForm.controls[propertyName].setValue('');
        }
    }

    toggleSearchSubsystemProjectTeam() {
        this.searchCurrentProjectSubsystems = !this.searchCurrentProjectSubsystems;
        this.setSubsystemInput.emit(new SetInputEventArgs(true));
    }

    addR(value: number): string {
        return value.toString() + 'R';
    }
}
