import { CallOffRegisterState, CallOffRegisterDataPagination, CallOffRegisterFilter, ShowColumnSettings } from './model';
import { CallOffRegisterActions, CallOffRegisterActionTypes } from './actions';

const initialState: CallOffRegisterState = {
    dataPagination: new CallOffRegisterDataPagination(),
    filter: new CallOffRegisterFilter(),
    isLoading: false,
    showColumnSettings: new ShowColumnSettings()
};

export function reducer(state = initialState, action: CallOffRegisterActions) {
    switch (action.type) {
        case CallOffRegisterActionTypes.CallOffRegisterFilterRequest: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case CallOffRegisterActionTypes.CallOffRegisterFilterSuccess: {
            return {
                ...state,
                isLoading: false,
                dataPagination: action.payload,
            };
        }
        case CallOffRegisterActionTypes.CallOffRegisterFilterError: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case CallOffRegisterActionTypes.CallOffRegisterFilterPropertyUpdate: {
            if (action.payload.key === 'sortBy') {
                return {
                    ...state,
                    filter: {
                        ...state.filter,
                        sortBy: action.payload.value.active,
                        direction: action.payload.value.direction,
                    },
                };
            } else {
                return {
                    ...state,
                    filter: {
                        ...state.filter,
                        [action.payload.key]: Array.isArray(state.filter[action.payload.key])
                            ? [...action.payload.value]
                            : action.payload.value,
                    },
                };
            }
        }
        case CallOffRegisterActionTypes.CallOffRegisterFilterReset: {
            const newFilter = new CallOffRegisterFilter();
            return {
                ...state,
                filter: { ...newFilter },
            };
        }
        case CallOffRegisterActionTypes.CallOffRegisterExportToExcelRequest: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case CallOffRegisterActionTypes.CallOffRegisterExportToExcelSuccess: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case CallOffRegisterActionTypes.CallOffRegisterExportToExcelError: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case CallOffRegisterActionTypes.CallOffRegisterToggleColumnShowSetting: {
            return {
                ...state,
                showColumnSettings:
                {
                    ...state.showColumnSettings,
                    [action.columnName]: !state.showColumnSettings[action.columnName]
                }
            }
        }
        default:
            return state;
    }
}
