<div class="buttons-container">
    <button class="cof-button cof-button-white" *ngIf="isCancelButtonVisible" (click)="cancel()">
        {{ cancelText }}
    </button>
    <button
        class="cof-button cof-button-white"
        *ngIf="isDownloadDataButtonVisible"
        (click)="downloadData()"
        [disabled]="isDownloadDataButtonDisabled || (callOffForm.enabled && !callOffForm.valid)"
    >
        download data
    </button>

    <button
        class="cof-button cof-button-light"
        *ngIf="status === 4 && !isReadOnly"
        [disabled]="isNewRevisionButtonDisabled"
        (click)="createNewRevision()"
    >
        new revision
    </button>
    <button
        class="cof-button cof-button-light"
        [ngClass]="{
            'cof-button-red': isCompanyRepresentative || isContractorRepresentative || isContractsTeamReviewer
        }"
        *ngIf="isRevertButtonVisible"
        (click)="revertToDraft()"
    >
        {{
            isCompanyRepresentative || isContractorRepresentative || isContractsTeamReviewer
                ? 'reject'
                : 'revert to draft'
        }}
    </button>
    <button
        class="cof-button cof-button-light"
        *ngIf="isSubmitButtonVisible"
        (click)="submit()"
        [disabled]="isSubmitButtonDisabled || !callOffForm.valid || isAutosaveInProgress"
    >
        submit
    </button>
    <button
        class="cof-button cof-button-green"
        *ngIf="isContractsTeamReviewedButtonVisible"
        (click)="contractsTeamReviewed()"
    >
        reviewed (contracts)
    </button>
    <button
        class="cof-button cof-button-green"
        *ngIf="isContractorRepresentativeApprovedButtonVisible"
        (click)="contractorRepresentativeApproved()"
    >
        approved (contractor)
    </button>
    <button
        class="cof-button cof-button-green"
        *ngIf="isCompanyRepresentativeApprovedButtonVisible"
        [disabled]="isCompanyRepresentativeApprovedButtonDisabled"
        (click)="companyRepresentativeApproved()"
    >
        approved (company)
    </button>
    <button class="cof-button cof-button-light" *ngIf="isCOFCompletedButtonVisible" (click)="cofCompleted()">
        COF completed
    </button>
    <button class="cof-button cof-button-light cof-button-company-complete" *ngIf="isCOFCompanyCompletedButtonVisible" (click)="cofCompanyCompleted()">
        COF Company completed
    </button>
    <button class="cof-button cof-button-light" *ngIf="isEditButtonVisible" (click)="edit()">
        edit
    </button>
    <button
        class="cof-button cof-button-light"
        *ngIf="isSaveButtonVisible"
        (click)="save()"
        [disabled]="isSaveButtonDisabled || isAutosaveInProgress"
    >
        save
    </button>
    <button
        class="cof-button cof-button-light"
        *ngIf="!isEditButtonVisible && isSaveButtonVisible && status !== 1"
        (click)="cancelEdit()"
    >
        cancel edit
    </button>
</div>
<app-loading-indicator *ngIf="!loaded"></app-loading-indicator>
