<div class="popup">
    <p mat-dialog-title class="title"><span class="font-gotham-narrow-bold">add construction approver matrix</span></p>
    <div>
        <form [formGroup]="addApprovalMatrixForm">
            <div class="user-selector">
                <app-multiple-items-selector
                    formControlName="approver"
                    [filterForm]="addApprovalMatrixForm"
                    [formSetter]="'approver'"
                    [isAsync]="true"
                    [searchFunc]="getUsers"
                    propertyToShow="name"
                    placeholder="Approver"
                    [setInput]="setUserInput"
                    [ngStyle]="{ display: isIE === true ? 'inline' : null }"
                    [oneItemMode]="true"
                    (autocompleteClosed)="onUsersClosed()"
                    (itemRemoved)="onUsersClosed()"
                    [isTableAutoComplete]="true"
                    [displayedColumns]="autocompleteDisplayedColumns"
                >
                </app-multiple-items-selector>
            </div>
            <mat-form-field>
                <mat-label>Emails</mat-label>
                <input matInput formControlName="emails" placeholder="email@chevron.com, email@chevron.com" required />
                <mat-error
                    *ngIf="
                        addApprovalMatrixForm.controls['emails'].invalid &&
                        addApprovalMatrixForm.controls['emails'].errors.required
                    "
                >
                    Email is required.
                </mat-error>
            </mat-form-field>
            <div class="row">
                <button mat-button type="button" class="cof-button cof-button-white" (click)="onCancel()">
                    cancel
                </button>
                <button
                    mat-button
                    type="button"
                    [disabled]="!addApprovalMatrixForm.valid"
                    class="cof-button cof-button-light"
                    (click)="onConfirm()"
                >
                    add
                </button>
            </div>
            <app-loading-indicator *ngIf="loading"> </app-loading-indicator>
        </form>
    </div>
</div>
