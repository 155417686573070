import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { FormService } from 'src/app/services/shared/form.service';
import { FormControl, FormGroup } from '@angular/forms';
import { CallOffPopupValidators } from '../../validators/call-off-popup-validators';
import { takeWhile, take } from 'rxjs/operators';
import { MatDialogRef } from '@angular/material/dialog';
import { ToastService } from 'src/app/services/shared/toast.service';
import { CallOffLookupService } from '../../services/call-off-lookup.service';
import { forkJoin, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { ReasonForInstruction, Area, ReasonStatus } from '../../store/common/model';
import { CallOffInitialFields } from '../../store/call-off/model';
import { AddCallOffRequest } from '../../store/call-off/actions';
import { AWE } from '../../store/awe-form/model';
import { BaseComponent } from 'src/app/components/base.component';
import { LookupService } from '../../services/lookup.service';
import { ApplicationState } from 'src/app/store/model';
import _ from 'lodash';
import { PopupService } from 'src/app/services/shared/popup.service';
import { PopupSettings } from 'src/app/models/popup-settings';
import { AWESearchComponent } from './awe-search/awe-search.component';
import { MatSelect } from '@angular/material/select';

@Component({
    selector: 'app-new-call-off',
    templateUrl: './new-call-off.component.html',
    styleUrls: ['./new-call-off.component.scss'],
})
export class NewCallOffComponent extends BaseComponent implements OnInit, OnDestroy {
    callOffForm: FormGroup;
    loading: boolean;
    areas: Area[];
    disciplines: string[];
    awes: AWE[] = [];
    reasons: ReasonForInstruction[];
    contractNo = '';
    filteredAWEs = new Subject<AWE[]>();
    filteredAWEsControl = new FormControl();

    constructor(
        private formSvc: FormService,
        private dialogRef: MatDialogRef<NewCallOffComponent>,
        private toastService: ToastService,
        private callOffPopupValidators: CallOffPopupValidators,
        private callOffLookupService: CallOffLookupService,
        private lookupService: LookupService,
        private store: Store<ApplicationState>,
        private popupService: PopupService
    ) {
        super();

        this.callOffForm = this.formSvc.createForm(new CallOffInitialFields(), this.callOffPopupValidators);
        this.callOffForm.addControl('filteredAWEsControl', this.filteredAWEsControl);
    }

    ngOnDestroy(): void {
        this.isAlive = false;
    }

    ngOnInit() {
        this.loading = true;
        forkJoin([
            this.callOffLookupService.getAreas(),
            this.lookupService.getDisciplines(),
            this.callOffLookupService.getReasons(),
            this.callOffLookupService.getAwes(),
        ])
            .pipe(takeWhile(() => this.isAlive))
            .subscribe(
                ([areas, disciplines, reasons, awes]) => {
                    this.areas = _.orderBy(areas, ['name']);
                    this.disciplines = disciplines;
                    this.reasons = reasons.filter((r) => r.status === ReasonStatus.Active);
                    this.awes = awes;
                    this.filteredAWEs.next(this.awes.slice());
                    this.loading = false;
                },
                () => {
                    this.toastService.Error(
                        'Error occurred while fetching areas/disciplines/reasons. Please contact Program Administrator.'
                    );
                }
            );

        this.filteredAWEsControl.valueChanges.pipe(takeWhile(() => this.isAlive)).subscribe(() => this.filterAWEs());
    }

    filterAWEs() {
        let search = this.filteredAWEsControl.value;
        if (!search) {
            this.filteredAWEs.next(this.awes.slice());
            return;
        } else {
            search = search.toLowerCase();
        }
        this.filteredAWEs.next(this.awes.filter((awe) => awe.number.toLowerCase().indexOf(search) > -1));
    }

    openAWESearchPopup($event: MouseEvent) {
        this.popupService
            .openPopup(new PopupSettings(AWESearchComponent, 1350, 800))
            .afterClosed()
            .pipe(take(1))
            .subscribe((selectedAWE: AWE) => {
                if (selectedAWE) {
                    this.callOffForm.controls.aweNumber.setValue(selectedAWE.number);
                }
            });
        $event.stopPropagation();
    }
    public onCancel(): void {
        this.dialogRef.close();
    }

    public onConfirm(): void {
        this.loading = true;
        this.store.dispatch(
            new AddCallOffRequest({
                callOff: this.callOffForm.value,
            })
        );
        this.store
            .select((state) => state.callOffState.isCreated)
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((isCreated) => {
                if (isCreated) {
                    this.loading = false;
                    this.dialogRef.close();
                }
            });
    }
}
