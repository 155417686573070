import { Component, OnInit, ViewChild, NgZone } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { FormService } from 'src/app/services/shared/form.service';
import { select, Store } from '@ngrx/store';
import { ApplicationState } from 'src/app/store/model';
import { BaseComponent } from 'src/app/components/base.component';
import { CallOffMyPageSections, MyPageCallOffRow, MyPageFilter } from 'src/app/store/my-page/models';
import { AllDraftsRequest, AllDraftsUpdateFilterProperty } from 'src/app/store/my-page/actions';
import { take, takeWhile } from 'rxjs/operators';
import { Router } from '@angular/router';
import { CalendarColumn, CheckListColumn, OrderDirection } from 'src/app/store/common.model';
import { HeaderCheckListFilter, HeaderDateFilter } from 'src/app/models/filter-settings';
import { HeaderChecklistFilterComponent } from '../../filter/header-checklist-filter/header-checklist-filter.component';
import { PopupService } from 'src/app/services/shared/popup.service';
import { MyPageService } from 'src/app/services/my-page.service';
import { PopupSettings } from 'src/app/models/popup-settings';
import { HeaderDateFilterComponent } from '../../filter/header-date-filter/header-date-filter.component';

@Component({
    selector: 'app-cof-all-drafts',
    templateUrl: './cof-all-drafts.component.html',
    styleUrls: ['./cof-all-drafts.component.scss'],
})
export class CofAllDraftsComponent extends BaseComponent implements OnInit {
    allDraftsForm: FormGroup;

    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;

    pageSize = 10;
    sortBy = 'callOffNumber';
    direction: OrderDirection = OrderDirection.Desc;
    displayedColumns: string[] = [
        'callOffNumber',
        'revision',
        'discipline',
        'subject',
        'status',
        'originator',
        'raisedDate',
        'awe.contractNo',
    ];

    columnNumber$ = this.store.select((state) => state.callOffMyPageState.allDraftsFilter.columnNumber);
    columnNumber: CheckListColumn = null;
    columnRevision$ = this.store.select((state) => state.callOffMyPageState.allDraftsFilter.columnRevision);
    columnRevision: CheckListColumn = null;
    columnDiscipline$ = this.store.select((state) => state.callOffMyPageState.allDraftsFilter.columnDiscipline);
    columnDiscipline: CheckListColumn = null;
    columnSubject$ = this.store.select((state) => state.callOffMyPageState.allDraftsFilter.columnSubject);
    columnSubject: CheckListColumn = null;
    columnStatus$ = this.store.select((state) => state.callOffMyPageState.allDraftsFilter.columnStatus);
    columnStatus: CheckListColumn = null;
    columnOriginator$ = this.store.select((state) => state.callOffMyPageState.allDraftsFilter.columnOriginator);
    columnOriginator: CheckListColumn = null;
    columnRaisedDate$ = this.store.select((state) => state.callOffMyPageState.allDraftsFilter.columnRaisedDate);
    columnRaisedDate: CalendarColumn = null;
    columnContractNo$ = this.store.select((state) => state.callOffMyPageState.allDraftsFilter.columnContractNo);
    columnContractNo: CheckListColumn = null;

    isLoading$ = this.store.select((store) => store.callOffMyPageState.isAllDraftsSectionLoading);
    allDraftsPagination$ = this.store.select((store) => store.callOffMyPageState.allDraftsPagination);
    filter$ = this.store.select((store) => store.callOffMyPageState.allDraftsFilter);

    constructor(
        private formService: FormService,
        private store: Store<ApplicationState>,
        private ngZone: NgZone,
        private router: Router,
        private popupService: PopupService,
        private myPageService: MyPageService
    ) {
        super();
        this.allDraftsForm = this.formService.createSimpleForm(new MyPageCallOffRow());
    }

    ngOnInit() {
        this.filter$.pipe(takeWhile(() => this.isAlive)).subscribe((filter) => {
            this.paginator.pageIndex = filter.page;
            this.paginator.pageSize = filter.take;
            this.pageSize = filter.take;
            this.direction = filter.direction;
            this.sortBy = filter.sortBy;
        });
        this.sort.sortChange.pipe(takeWhile(() => this.isAlive)).subscribe((sortChange: Sort) => {
            this.store.dispatch(
                new AllDraftsUpdateFilterProperty({
                    key: 'sortBy',
                    value: sortChange,
                })
            );
            this.paginator.pageIndex = 0;
            this.store.dispatch(new AllDraftsRequest());
        });

        this.columnNumber$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnNumber = data));
        this.columnRevision$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnRevision = data));
        this.columnDiscipline$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnDiscipline = data));
        this.columnSubject$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnSubject = data));
        this.columnStatus$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnStatus = data));
        this.columnOriginator$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnOriginator = data));
        this.columnRaisedDate$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnRaisedDate = data));
        this.columnContractNo$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnContractNo = data));
    }

    onPaginatorChange(pageEvent: PageEvent) {
        if (this.pageSize !== pageEvent.pageSize) {
            this.store.dispatch(
                new AllDraftsUpdateFilterProperty({
                    key: 'take',
                    value: pageEvent.pageSize,
                })
            );
            this.pageSize = pageEvent.pageSize;
        } else {
            this.store.dispatch(
                new AllDraftsUpdateFilterProperty({
                    key: 'page',
                    value: pageEvent.pageIndex,
                })
            );
        }

        this.store.dispatch(new AllDraftsRequest());
    }

    onPageChange(newPage: number) {
        if (newPage < 1) {
            newPage = 1;
        } else if (newPage > this.paginator.getNumberOfPages()) {
            newPage = this.paginator.getNumberOfPages();
        }

        const pageEvt = new PageEvent();
        pageEvt.pageIndex = newPage - 1;
        pageEvt.pageSize = this.pageSize;
        this.paginator.pageIndex = pageEvt.pageIndex;
        this.onPaginatorChange(pageEvt);
    }

    openDetailsPage(id: number) {
        this.ngZone.run(() => this.router.navigate([`details/${id}`]));
    }

    openHeaderCheckListFilter(
        columnName: string,
        searchFunc: any,
        propertyName: string,
        placeholder: string,
        selected: CheckListColumn,
        showCounts: boolean = false,
        showInputSearch: boolean = true
    ) {
        this.popupService.openPopup(
            new PopupSettings<HeaderCheckListFilter>(HeaderChecklistFilterComponent, 400, 590, {
                isAscendingSort: this.sortBy === columnName && this.direction === OrderDirection.Asc,
                isDescendingSort: this.sortBy === columnName && this.direction === OrderDirection.Desc,
                placeHolder: placeholder,
                searchFunc: searchFunc,
                selectedItems: selected ? [...selected.items] : [],
                isSortingOff: false,
                showCounts,
                showInputSearch: showInputSearch,
                column: columnName,
                action: (data) => {
                    let value = new CheckListColumn();
                    value.items = data.selectedItems.length > 0 ? data.selectedItems : [];

                    this.store.dispatch(
                        new AllDraftsUpdateFilterProperty({
                            key: propertyName,
                            value: value
                        })
                    );

                    this.sortUpdate(data.isAscendingSort, data.isDescendingSort, columnName);

                    this.search();
                },
                resetColumnAction: () => {
                    this.store.dispatch(
                        new AllDraftsUpdateFilterProperty({
                            key: propertyName,
                            value: null,
                        })
                    );
                },
            })
        );
    }

    openHeaderDateFilter(
        columnName: string,
        propertyName: string,
        placeholder: string,
        calendarColumn: CalendarColumn
    ) {
        const excludeBlanks = calendarColumn === null ? false : calendarColumn.excludeBlanks;
        const onlyBlanks = calendarColumn === null ? false : calendarColumn.onlyBlanks;
        const rangeDates = calendarColumn === null ? [] : calendarColumn.rangeDates;
        this.popupService.openPopup(
            new PopupSettings<HeaderDateFilter>(HeaderDateFilterComponent, 345, 625, {
                isAscendingSort: this.sortBy === columnName && this.direction === OrderDirection.Asc,
                isDescendingSort: this.sortBy === columnName && this.direction === OrderDirection.Desc,
                calendarColumn: { rangeDates, excludeBlanks, onlyBlanks },
                placeHolder: placeholder,
                action: (data) => {
                    this.store.dispatch(
                        new AllDraftsUpdateFilterProperty({
                            key: propertyName,
                            value: data.calendarColumn
                        })
                    );

                    this.sortUpdate(data.isAscendingSort, data.isDescendingSort, columnName);

                    this.search();
                },
            })
        );
    }

    private sortUpdate(isAscendingSort: boolean, isDescendingSort: boolean, columnName: string) {
        if (isAscendingSort || isDescendingSort) {
            const direction: OrderDirection = isAscendingSort ? OrderDirection.Asc : OrderDirection.Desc;
            this.store.dispatch(
                new AllDraftsUpdateFilterProperty({
                    key: 'sortBy',
                    value: { active: columnName, direction: direction },
                })
            );
        } else {
            this.store.dispatch(
                new AllDraftsUpdateFilterProperty({
                    key: 'sortBy',
                    value: { active: 'callOffNumber', direction: OrderDirection.Desc },
                })
            );
        }
    }

    search() {
        this.paginator.pageIndex = 0;
        this.store.dispatch(
            new AllDraftsUpdateFilterProperty({
                key: 'page',
                value: 0,
            })
        );

        this.store.dispatch(new AllDraftsRequest());
    }

    private getLatestFilterData(): MyPageFilter {
        let filter: MyPageFilter;
        this.store.pipe(select((x) => x.callOffMyPageState, take(1))).subscribe((data) => (filter = data.allDraftsFilter));
        return filter;
    }

    getTableFilterValues = (search = '', take = 10, page = 0, column?: string) =>
        this.myPageService.getTableFilterValues(column, search, take, page, CallOffMyPageSections.Draft, this.getLatestFilterData());
}
