import { InjectionToken } from '@angular/core';
import {
    BrowserCacheLocation,
    InteractionType,
    IPublicClientApplication,
    PublicClientApplication,
} from '@azure/msal-browser';
import { MsalGuardConfiguration, MsalInterceptorConfiguration } from '@azure/msal-angular';

export const APP_INSIGHTS_CONFIG = new InjectionToken<ApplicationInsightsConfig>('appInsights.config');

interface AppConfig {
    production: boolean;
    apiEndpoint: string;
    clientId: string;
    tenant: string;
    redirectUri: string;
    instrumentationKey: string;
}

export interface ApplicationInsightsConfig {
    instrumentationKey: string;
}

enum AppUrl {
    local = 'https://localhost:44304',
    dev = 'https://fgpcof-dev.azure.chevron.com',
    test = 'https://fgpcof-test.azure.chevron.com',
    prod = 'https://fgpcof.azure.chevron.com',
}

enum ApiUrl {
    local = 'https://localhost:44391',
    dev = 'https://fgpcof-api-dev.azure.chevron.com',
    test = 'https://fgpcof-api-test.azure.chevron.com',
    prod = 'https://fgpcof-api.azure.chevron.com',
}

enum ClientId {
    test = '6f6606e2-4d5f-495c-9578-3eba3430bff6',
    prod = 'f42b444b-4401-4ac3-a90d-225640c2506a',
}

enum InstrumentationKey {
    local = '',
    dev = 'bb1e4523-b2f9-41c9-8a05-57eab58b35a7',
    test = 'f7cec0b5-8def-4ec7-ad6c-de1deda75f30',
    prod = 'ebe2557e-68f8-40b6-aa89-384156de0af5',
}

function getAppConfig(): AppConfig {
    const redirectUri = location.origin;
    const tenant = 'fd799da1-bfc1-4234-a91c-72b3a1cb9e26';

    switch (location.origin) {
        case AppUrl.prod:
            return {
                apiEndpoint: ApiUrl.prod,
                clientId: ClientId.prod,
                redirectUri,
                production: true,
                tenant,
                instrumentationKey: InstrumentationKey.prod,
            };
        case AppUrl.test:
            return {
                apiEndpoint: ApiUrl.test,
                clientId: ClientId.test,
                redirectUri,
                production: true,
                tenant,
                instrumentationKey: InstrumentationKey.test,
            };
        case AppUrl.dev:
            return {
                apiEndpoint: ApiUrl.dev,
                clientId: ClientId.test,
                redirectUri,
                production: true,
                tenant,
                instrumentationKey: InstrumentationKey.dev,
            };
        case AppUrl.local:
        default:
            return {
                apiEndpoint: ApiUrl.local,
                clientId: ClientId.test,
                redirectUri,
                production: false,
                tenant,
                instrumentationKey: InstrumentationKey.local,
            };
    }
}

export const appConfig = getAppConfig();

export function MSALInscanceFactory(): IPublicClientApplication {
    return new PublicClientApplication({
        auth: {
            clientId: appConfig.clientId,
            authority: 'https://login.microsoftonline.com/' + appConfig.tenant,
            redirectUri: appConfig.redirectUri,
            postLogoutRedirectUri: appConfig.redirectUri,
        },
        cache: {
            cacheLocation: BrowserCacheLocation.LocalStorage,
        },
    });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
    const protectedResourceMap = new Map<string, Array<string>>();
    protectedResourceMap.set(appConfig.apiEndpoint, [`${appConfig.clientId}/User.Read`]);

    return {
        interactionType: InteractionType.Redirect,
        protectedResourceMap,
    };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
    return {
        interactionType: InteractionType.Redirect,
        authRequest: {
            scopes: ['user.read'],
        },
        loginFailedRoute: '/notauthorized',
    };
}

export const appInsightsConfig: ApplicationInsightsConfig = {
    instrumentationKey: appConfig.instrumentationKey,
};
