import { Action } from '@ngrx/store';
import { AweRegisterDataPagination } from './model';
import { AWE } from '../awe-form/model';

export enum AweRegisterActionTypes {
    AweRegisterFilterRequest = '[AweRegister] Filter Request',
    AweRegisterFilterSuccess = '[AweRegister] Filter Success',
    AweRegisterFilterError = '[AweRegister] Filter Error',
    AweDeleteRequest = '[AweRegister] AWE Delete Request',
    AweDeleteSuccess = '[AweRegister] AWE Delete Success',
    AweDeleteError = '[AweRegister] AWE Delete Error',
    AweRegisterFilterPropertyUpdate = '[AweRegister] Property Update',
    AweRegisterFilterReset = '[AweRegister] Filter Reset',
    AweRegisterExportToExcelRequest = '[AwefRegister] Export to Excel Request',
    AweRegisterExportToExcelSuccess = '[AweRegister] Export to Excel Success',
    AweRegisterExportToExcelError = '[AweRegister] Export to Excel Error'
}

export class AweRegisterExportToExcelRequest implements Action {
    readonly type = AweRegisterActionTypes.AweRegisterExportToExcelRequest;
}

export class AweRegisterExportToExcelSuccess implements Action {
    readonly type = AweRegisterActionTypes.AweRegisterExportToExcelSuccess;

    constructor(public payload: any) {}
}

export class AweRegisterExportToExcelError implements Action {
    readonly type = AweRegisterActionTypes.AweRegisterExportToExcelError;

    constructor(public payload: string) {}
}

export class AweRegisterFilterRequest implements Action {
    readonly type = AweRegisterActionTypes.AweRegisterFilterRequest;
}

export class AweRegisterFilterSuccess implements Action {
    readonly type = AweRegisterActionTypes.AweRegisterFilterSuccess;

    constructor(public payload: AweRegisterDataPagination) {}
}

export class AweRegisterFilterError implements Action {
    readonly type = AweRegisterActionTypes.AweRegisterFilterError;

    constructor(public payload: string) {}
}

export class AweDeleteRequest implements Action {
    readonly type = AweRegisterActionTypes.AweDeleteRequest;

    constructor(public payload: AWE) {}
}

export class AweDeleteSuccess implements Action {
    readonly type = AweRegisterActionTypes.AweDeleteSuccess;

    constructor(public payload: AWE) {}
}

export class AweDeleteError implements Action {
    readonly type = AweRegisterActionTypes.AweDeleteError;

    constructor(public payload: string) {}
}

export class AweRegisterFilterPropertyUpdate implements Action {
    readonly type = AweRegisterActionTypes.AweRegisterFilterPropertyUpdate;

    constructor(public payload: { key: string; value: any }) {}
}

export class AweRegisterFilterReset implements Action {
    readonly type = AweRegisterActionTypes.AweRegisterFilterReset;
}

export type AweRegisterActions =
    | AweRegisterFilterRequest
    | AweRegisterFilterSuccess
    | AweRegisterFilterError
    | AweDeleteRequest
    | AweDeleteSuccess
    | AweDeleteError
    | AweRegisterFilterPropertyUpdate
    | AweRegisterFilterReset
    | AweRegisterExportToExcelRequest
    | AweRegisterExportToExcelSuccess
    | AweRegisterExportToExcelError;
