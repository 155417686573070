import { AbstractControl } from '@angular/forms';

export const LimitExceeded = (limit: number) => {
    return (control: AbstractControl) => {

        if (limit === null) {
            return null;
        }

        return control.value > limit ? { limitExceeded: true } : null;
    }
}