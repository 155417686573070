import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApprovalMatrixDTO } from '../models/approval-matrix-dto';
import { appConfig } from '../app.config';

@Injectable({
    providedIn: 'root',
})
export class ApprovalMatrixService {
    private readonly rootUrl: string = appConfig.apiEndpoint;
    private readonly endpoint: string = '/approvalmatrix';

    constructor(private http: HttpClient) {}

    get() {
        return this.http.get<ApprovalMatrixDTO[]>(`${this.rootUrl}${this.endpoint}`);
    }

    add(approvalMatrix: ApprovalMatrixDTO) {
        return this.http.post(`${this.rootUrl}${this.endpoint}`, approvalMatrix);
    }

    remove(id: number) {
        return this.http.delete<boolean>(`${this.rootUrl}${this.endpoint}?id=${id}`);
    }

    update(approvalMatrix: ApprovalMatrixDTO) {
        return this.http.patch(`${this.rootUrl}${this.endpoint}`, approvalMatrix);
    }
}
