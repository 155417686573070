import { AppRole, UserDetail } from "../common.model";
import { ReasonForInstruction } from "../common/model";

export class AweFormGroup {
    contractor: string = '';
    aweNumber: string = '';
    aweTitle: string = '';
    aweNte: string = '';
    revision = 0;
    nteConsumed: number = 0;
    nteRemaining: number = 0;
    attachments: AWEAttachment[] = [];
    isEdit = false;
    usd = 0;
    kzt = 0;
    rub = 0;
    eur = 0;
    kztExchanged = 0;
    rubExchanged = 0;
    eurExchanged = 0;
    defaultReviewer: UserDetail = null;
    defaultApprovers: DefaultApprover[] = [];
}

export class DefaultApprover {
    aweId: number = null;
    approverType: AppRole = null;
    approver: UserDetail = null;
    reasonForInstruction: ReasonForInstruction = null;
}

export class AWEAttachment {
    name: string;
    link: string;
}

export class AWE {
    id: number = null;
    contractNo: string = '';
    projectTeamName: string = '';
    number: string = '';
    subject: string = '';
    nte: number = null;
    revision: number = null;
    nteConsumed = 0;
    nteRemaining = 0;
    usdConsumed = 0;
    usdRemaining = 0;
    kztConsumed = 0;
    kztRemaining = 0;
    rubConsumed = 0;
    rubRemaining = 0;
    eurConsumed = 0;
    eurRemaining = 0;
    attachments: AWEAttachment[] = [];
    selected? = false;
    isActive: boolean = false;
    usd = 0;
    kzt = 0;
    rub = 0;
    eur = 0;
    defaultReviewer: UserDetail = null;
    defaultApprovers: DefaultApprover[] = [];
}
