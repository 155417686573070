import { Component, EventEmitter, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { of } from 'rxjs';
import { BaseComponent } from 'src/app/components/base.component';
import { SetInputEventArgs } from 'src/app/models/set-input';
import { UserWithRoleDetail } from 'src/app/store/common.model';
import { ConstructionApproverMatrixDTO } from 'src/app/store/construction-approver-matrix/models';

@Component({
    selector: 'app-add-construction-approver-popup',
    templateUrl: './add-construction-approver-popup.component.html',
    styleUrls: ['./add-construction-approver-popup.component.scss'],
})
export class AddConstructionApproverPopupComponent extends BaseComponent {
    loading: boolean;
    addApprovalMatrixForm: FormGroup;
    users: UserWithRoleDetail[] = [];
    setUserInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    autocompleteDisplayedColumns = ['name', 'email'];

    constructor(public dialogRef: MatDialogRef<AddConstructionApproverPopupComponent>, @Inject(MAT_DIALOG_DATA) data) {
        super();

        this.users = data.users;

        this.addApprovalMatrixForm = new FormGroup({
            approver: new FormControl('', Validators.required),
            emails: new FormControl('', Validators.required),
        });
    }

    ngOnInit(): void {}

    public onCancel(): void {
        this.dialogRef.close();
    }

    public onConfirm(): void {
        this.loading = true;
        const form = new ConstructionApproverMatrixDTO();

        form.emails = this.addApprovalMatrixForm.controls.emails.value;
        form.approver = this.addApprovalMatrixForm.controls.approver.value[0];

        this.dialogRef.close({ success: true, data: form });
    }

    getUsers = (search = '', take = 10, page = 0) => {
        let elements = this.users.filter((s) => s.name.toLowerCase().indexOf(search.toLowerCase()) > -1);
        if (elements.length) {
            return of(elements.slice(page * take, page * take + take));
        } else {
            return of([]);
        }
    };

    onUsersClosed() {
        this.setUserInput.emit(new SetInputEventArgs(true));
    }
}
