import { Component, OnInit, ViewChild, ChangeDetectorRef, EventEmitter } from '@angular/core';
import { AWE, AWEAttachment } from '../../store/awe-form/model';
import { MatExpansionPanel } from '@angular/material/expansion';
import { PageEvent, MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { FormGroup, Validators } from '@angular/forms';
import { AweRegisterFilter } from '../../store/awe-register/model';
import { takeWhile, take, map, catchError, finalize } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import {
    AweRegisterFilterPropertyUpdate,
    AweRegisterFilterRequest,
    AweRegisterFilterReset,
    AweDeleteRequest,
    AweRegisterExportToExcelRequest,
} from '../../store/awe-register/actions';
import { ApplicationState } from 'src/app/store/model';
import { SetInputEventArgs } from 'src/app/models/set-input';
import { LookupService } from '../../services/lookup.service';
import { BaseComponent } from '../base.component';
import { OrderDirection, Contractor, CheckListColumn, RangeColumn } from '../../store/common.model';
import { FormService } from '../../services/shared/form.service';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { PopupService } from 'src/app/services/shared/popup.service';
import { ConfirmDialogPopupSettings } from 'src/app/models/confirm-dialog-popup-settings';
import { PopupSettings } from 'src/app/models/popup-settings';
import { AweFormComponent } from '../awe-form/awe-form.component';
import { AWEService } from 'src/app/services/awe.service';
import { of, throwError } from 'rxjs';
import { ToastService } from 'src/app/services/shared/toast.service';
import { Dictionary } from 'typescript-collections';
import { RoleService } from 'src/app/services/shared/role.service';
import { Constants } from 'src/app/constants';
import { HeaderCheckListFilter, HeaderRangeFilter } from 'src/app/models/filter-settings';
import { HeaderChecklistFilterComponent } from '../filter/header-checklist-filter/header-checklist-filter.component';
import { HeaderRangeFilterComponent } from '../filter/header-range-filter/header-range-filter.component';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-awe-register',
    templateUrl: './awe-register.component.html',
    styleUrls: ['./awe-register.component.scss'],
})
export class AweRegisterComponent extends BaseComponent implements OnInit {
    displayedColumns: string[] = [
        'edit',
        'active',
        'remove',
        'number',
        'revision',
        'subject',
        'contractNo',
        'nte',
        'nteConsumed',
        'nteRemaining',
        'status',
        'attachments',
    ];

    isLoading: boolean = false;
    pageSize;
    data: AWE[] = [];
    direction: OrderDirection = OrderDirection.Desc;
    sortBy = 'number';
    todayDate = new Date();
    resultsLength = 0;
    filterForm: FormGroup;
    uploadedAttachmentsActionInProgress = new Dictionary<string, boolean>();

    autocompleteDisplayedColumns = ['contractno', 'contractor'];
    setContractorInput: EventEmitter<SetInputEventArgs> = new EventEmitter();

    filter$ = this.store.select((state) => state.aweRegisterState.filter);
    loader$ = this.store.select((state) => state.aweRegisterState.isLoading);
    sortBy$ = this.store.select((state) => state.aweRegisterState.filter.sortBy);
    direction$ = this.store.select((state) => state.aweRegisterState.filter.direction);
    dataPagination$ = this.store.select((state) => state.aweRegisterState.dataPagination);
    currentPage$ = this.store.select((state) => state.aweRegisterState.filter.page);
    columnIsActive$ = this.store.select((state) => state.aweRegisterState.filter.columnIsActive);
    columnIsActive: CheckListColumn = null;
    columnAWENumbers$ = this.store.select((state) => state.aweRegisterState.filter.columnAWENumbers);
    columnAWENumbers: CheckListColumn = null;
    columnRevisions$ = this.store.select((state) => state.aweRegisterState.filter.columnRevisions);
    columnRevisions: CheckListColumn = null;
    columnTitles$ = this.store.select((state) => state.aweRegisterState.filter.columnTitles);
    columnTitles: CheckListColumn = null;
    columnContracts$ = this.store.select((state) => state.aweRegisterState.filter.columnContracts);
    columnContracts: CheckListColumn = null;
    columnNte$ = this.store.select((state) => state.aweRegisterState.filter.columnNte);
    columnNte: RangeColumn = null;
    columnCofValue$ = this.store.select((state) => state.aweRegisterState.filter.columnCofValue);
    columnCofValue: RangeColumn = null;
    columnRemaining$ = this.store.select((state) => state.aweRegisterState.filter.columnRemaining);
    columnRemaining: RangeColumn = null;
    canEditAwe = false;
    isAdmin = false;
    isReadOnly = false;
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;
    @ViewChild(MatExpansionPanel, { static: true }) filterExpansionPanel: MatExpansionPanel;

    constructor(
        private formSvc: FormService,
        private store: Store<ApplicationState>,
        private changeDetectorRef: ChangeDetectorRef,
        private lookupService: LookupService,
        private iconRegistry: MatIconRegistry,
        private sanitizer: DomSanitizer,
        private popupService: PopupService,
        private aweService: AWEService,
        private roleService: RoleService,
        private toastService: ToastService,
        private route: ActivatedRoute
    ) {
        super();
        this.filterForm = this.formSvc.createSimpleForm(new AweRegisterFilter());
        this.filterForm.controls['aweNumber'].setValidators([Validators.maxLength(40)]);
        this.filterForm.controls['aweTitle'].setValidators([Validators.maxLength(200)]);
        this.filterForm.controls['aweNumber'].updateValueAndValidity();
        this.filterForm.controls['aweTitle'].updateValueAndValidity();
        this.iconRegistry.addSvgIcon(
            'edit',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/edit.svg')
        );
        this.iconRegistry.addSvgIcon(
            'close',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/inactive.svg')
        );
        this.iconRegistry.addSvgIcon(
            'delete',
            sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/delete.svg')
        );
        this.iconRegistry.addSvgIcon(
            'active',
            sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/checked.svg')
        );
        this.canEditAwe = this.roleService.isInAnyRole([
            Constants.applicableGroups.Admin,
            Constants.applicableGroups.Contracts,
        ]);
        this.isAdmin = this.roleService.isInRole(Constants.applicableGroups.Admin);
    }

    watchFormChanges() {
        for (const key of Object.keys(this.filterForm.controls)) {
            this.filterForm.controls[key].valueChanges.pipe(takeWhile(() => this.isAlive)).subscribe((value) => {
                this.store.dispatch(new AweRegisterFilterPropertyUpdate({ key, value }));
            });
        }
    }

    getIsActive = () => of(['Active', 'Closed']);

    private getLatestFilterData(): AweRegisterFilter {
        let filter: AweRegisterFilter;
        this.store.pipe(select((x) => x.aweRegisterState, take(1))).subscribe((data) => (filter = data.filter));
        return filter;
    }

    getAWENumbers = (search = '', take = 10, page = 0) =>
        this.lookupService.getAweNumbers(search, take, page, this.getLatestFilterData());

    getRevisions = (search = '', take = 10, page = 0) =>
        this.lookupService.getRevisions(search, take, page, this.getLatestFilterData());

    getSubjects = (search = '', take = 10, page = 0) =>
        this.lookupService.getSubjects(search, take, page, this.getLatestFilterData());

    getContracts = (search = '', take = 10, page = 0) =>
        this.lookupService.getContractors(search, take, page, this.getLatestFilterData());


    ngOnInit() {
        this.watchFormChanges();
        this.filterExpansionPanel.open();
        this.isReadOnly = this.roleService.isReadOnly();
        this.currentPage$.pipe(take(1)).subscribe((currentPage) => (this.paginator.pageIndex = currentPage));

        this.filter$.pipe(takeWhile(() => this.isAlive)).subscribe((filter) => {
            this.filterForm.patchValue(filter, { emitEvent: false });
            this.sortBy = filter.sortBy;
            this.direction = filter.direction;
            this.pageSize = filter.take;
        });

        this.loader$.pipe(takeWhile(() => this.isAlive)).subscribe((isLoading) => {
            this.isLoading = isLoading;
            // hack for the Angular bug:
            // https://stackoverflow.com/questions/39741293/why-is-ngonchanges-not-firing-when-a-boolean-value-changed-in-angularjs-2
            this.changeDetectorRef.detectChanges();
        });

        this.dataPagination$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => {
            this.data = data.items;
            this.resultsLength = data.totalCount;
        });

        this.sort.sortChange.pipe(takeWhile(() => this.isAlive)).subscribe((sortChange: Sort) => {
            this.store.dispatch(
                new AweRegisterFilterPropertyUpdate({
                    key: 'sortBy',
                    value: sortChange,
                })
            );
            this.paginator.pageIndex = 0;
            this.store.dispatch(new AweRegisterFilterRequest());
        });

        this.sortBy$.pipe(takeWhile(() => this.isAlive)).subscribe((sortBy) => (this.sortBy = sortBy));
        this.direction$.pipe(takeWhile(() => this.isAlive)).subscribe((direction) => (this.direction = direction));
        this.columnIsActive$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnIsActive = data));
        this.columnRevisions$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnRevisions = data));
        this.columnAWENumbers$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnAWENumbers = data));
        this.columnTitles$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnTitles = data));
        this.columnContracts$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnContracts = data));
        this.columnNte$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnNte = data));
        this.columnCofValue$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnCofValue = data));
        this.columnRemaining$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnRemaining = data));
        this.route.queryParamMap.pipe(takeWhile(() => this.isAlive)).subscribe((paramMap) => {
            let aweNumber = paramMap.get('aweNumber');
            if (aweNumber) {
                this.filterForm.controls.aweNumber.setValue(aweNumber);
                this.filterExpansionPanel.close();
            }

            this.store.dispatch(new AweRegisterFilterRequest());
        });
    }

    changeIsActive(element: AWE) {
        let awe = { ...element };
        let text = awe.isActive
            ? 'Closing an AWE will mean no further Call-Offs can be generated against the AWE and AWE can no longer be edited. Are you sure you want to close AWE?'
            : 'Are you sure you want to activate AWE?';

        this.popupService
            .openPopup(new ConfirmDialogPopupSettings({ title: 'Confirm action', text: text }))
            .afterClosed()
            .pipe(takeWhile(() => this.isAlive === true))
            .subscribe((answer) => {
                if (answer === true) {
                    this.isLoading = true;
                    awe.isActive = !awe.isActive;
                    this.aweService
                        .updateAWE(awe)
                        .pipe(
                            takeWhile(() => this.isAlive),
                            finalize(() => (this.isLoading = false)),
                            catchError((err) => {
                                this.toastService.Error(
                                    'Error has occurred while updating AWE. Please contact Program Administrator.'
                                );
                                return throwError(err);
                            })
                        )
                        .subscribe(() => {
                            this.toastService.Success('AWE status saved correctly.');
                            this.store.dispatch(new AweRegisterFilterRequest());
                        });
                }
            });
    }

    openHeaderCheckListFilter(
        columnName: string,
        searchFunc: any,
        propertyName: string,
        placeholder: string,
        selected: CheckListColumn,
        showCounts: boolean = false,
        showInputSearch: boolean = true
    ) {
        this.popupService.openPopup(
            new PopupSettings<HeaderCheckListFilter>(HeaderChecklistFilterComponent, 400, 590, {
                isAscendingSort: this.sortBy === columnName && this.direction === OrderDirection.Asc,
                isDescendingSort: this.sortBy === columnName && this.direction === OrderDirection.Desc,
                placeHolder: placeholder,
                searchFunc: searchFunc,
                selectedItems: selected ? [...selected.items] : [],
                isSortingOff: false,
                showCounts,
                showInputSearch: showInputSearch,
                action: (data) => {
                    let value = new CheckListColumn();
                    value.items = data.selectedItems.length > 0 ? data.selectedItems : [];
                    this.filterForm.controls[propertyName].setValue(value);

                    this.sortUpdate(data.isAscendingSort, data.isDescendingSort, columnName);

                    this.search();
                },
                resetColumnAction: () => {
                    this.store.dispatch(
                        new AweRegisterFilterPropertyUpdate({
                            key: propertyName,
                            value: null,
                        })
                    );
                },
            })
        );
    }

    openHeaderRangeFilter(columnName: string, propertyName: string, placeholder: string, rangeColumn: RangeColumn) {
        const ranges = rangeColumn === null ? [] : rangeColumn.ranges;
        this.popupService.openPopup(
            new PopupSettings<HeaderRangeFilter>(HeaderRangeFilterComponent, 425, 260, {
                isAscendingSort: this.sortBy === columnName && this.direction === OrderDirection.Asc,
                isDescendingSort: this.sortBy === columnName && this.direction === OrderDirection.Desc,
                rangeColumn: { ranges },
                placeHolder: placeholder,
                action: (data) => {
                    this.filterForm.controls[propertyName].setValue(data.rangeColumn);
                    this.sortUpdate(data.isAscendingSort, data.isDescendingSort, columnName);
                    this.search();
                },
            })
        );
    }

    private sortUpdate(isAscendingSort: boolean, isDescendingSort: boolean, columnName: string) {
        if (isAscendingSort || isDescendingSort) {
            const direction: OrderDirection = isAscendingSort ? OrderDirection.Asc : OrderDirection.Desc;
            this.store.dispatch(
                new AweRegisterFilterPropertyUpdate({
                    key: 'sortBy',
                    value: { active: columnName, direction: direction },
                })
            );
        } else {
            this.store.dispatch(
                new AweRegisterFilterPropertyUpdate({
                    key: 'sortBy',
                    value: { active: 'number', direction: OrderDirection.Desc },
                })
            );
        }
    }

    onPaginatorChange(pageEvent: PageEvent) {
        if (this.pageSize !== pageEvent.pageSize) {
            this.store.dispatch(
                new AweRegisterFilterPropertyUpdate({
                    key: 'take',
                    value: pageEvent.pageSize,
                })
            );
            this.filterForm.controls.take.setValue(pageEvent.pageSize);
            this.pageSize = pageEvent.pageSize;
        } else {
            this.store.dispatch(
                new AweRegisterFilterPropertyUpdate({
                    key: 'page',
                    value: pageEvent.pageIndex,
                })
            );
        }
        this.store.dispatch(new AweRegisterFilterRequest());
    }

    onPageChange(newPage: number) {
        if (newPage < 1) {
            newPage = 1;
        } else if (newPage > this.paginator.getNumberOfPages()) {
            newPage = this.paginator.getNumberOfPages();
        }
        var pageEvt = new PageEvent();
        pageEvt.pageIndex = newPage - 1;
        pageEvt.pageSize = this.pageSize;
        this.paginator.pageIndex = pageEvt.pageIndex;
        this.onPaginatorChange(pageEvt);
    }

    clearFilterProperty(propertyName: string) {
        if (Array.isArray(this.filterForm.controls[propertyName].value)) {
            this.filterForm.controls[propertyName].setValue([]);
        } else {
            this.filterForm.controls[propertyName].setValue('');
        }
    }

    exportToExcel() {
        this.store.dispatch(new AweRegisterExportToExcelRequest());
    }

    search() {
        this.paginator.pageIndex = 0;
        this.store.dispatch(
            new AweRegisterFilterPropertyUpdate({
                key: 'page',
                value: 0,
            })
        );
        this.store.dispatch(new AweRegisterFilterRequest());
    }

    resetFilters() {
        this.filterExpansionPanel.expanded = true;
        this.store.dispatch(new AweRegisterFilterReset());
        this.setContractorInput.emit(new SetInputEventArgs(false, ''));
    }

    getContractors = (search = '', take = 10, page = 0) => {
        return this.lookupService
            .searchContractors(search, take, page, [])
            .pipe(map((contractors: Contractor[]) => contractors));
    };

    onContractorsClosed() {
        this.setContractorInput.emit(new SetInputEventArgs(true));
    }

    getMappedContractorNumbers() {
        return this.filterForm.controls.contractors.value.map((x) => x.contractNo).join(', ');
    }

    remove(element: AWE) {
        this.popupService
            .openPopup(new ConfirmDialogPopupSettings({ title: 'Confirm action', text: 'Do you want to remove AWE?' }))
            .afterClosed()
            .pipe(takeWhile(() => this.isAlive === true))
            .subscribe((answer) => {
                if (answer === true) {
                    this.store.dispatch(new AweDeleteRequest(element));
                }
            });
    }

    edit(element: AWE) {
        this.popupService.openPopup(new PopupSettings(AweFormComponent, null, null, element, 480, 560, 564, 720, true));
    }

    download(attachment: AWEAttachment) {
        this.uploadedAttachmentsActionInProgress.setValue(attachment.link, true);
        this.aweService
            .downloadAWEAttachment(attachment.link)
            .pipe(
                take(1),
                finalize(() => this.uploadedAttachmentsActionInProgress.setValue(attachment.link, false)),
                catchError(() => {
                    this.toastService.Error(
                        'Error has occurred while downloading attachment. Please contact Program Administrator.'
                    );
                    return of(null);
                })
            )
            .subscribe((file) => {
                const blob = new Blob([file], {
                    type: 'application/octet-stream',
                });
                saveAs(blob, attachment.name);
            });
    }
}
