import {Moment} from 'moment';

export class UserDetail {
    id = null;
    cai = '';
    name = '';
    position = '';
    email = '';
}

export class UserWithRoleDetail extends UserDetail {
   role: AppRole;
}

export class Contractor {
    contractNo = '';
    contract = '';
    tcopm = '';
    mcPlusId = '';
    projectTeamName = '';
    masterSubsystemListKey = '';
    name = '';
}

export enum OrderDirection {
    Asc = 'asc',
    Desc = 'desc',
}

export enum AppRole
{
    None = 0,
    Admin = 1,
    ReadOnly = 2,
    CallOffUser = 4,
    Contracts = 8,
    TCOApprover = 16,
    ContractorApprover = 32,
}

export class CalendarColumn {
    rangeDates: Date[] = [];
    excludeBlanks = false;
    onlyBlanks = false;
}

export class CheckListColumn {
    items: string[] = [];
}

export class RangeColumn {
    ranges: number[] = [];
}
