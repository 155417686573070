<footer class="doNotPrint">
    <ul>
        <li>&copy; 2020 Chevron Corporation</li>
        <li>Company Confidential</li>
        <li>OpCo: <a href="http://tps.chevron.com/itc/">ITC</a></li>
    </ul>
    <ul>
        <li>
            Content contact: <a href="mailto:fgp3gpsccoor@tengizchevroil.com?subject=COF Application">3GP SC Team</a>
        </li>
        <li>
            Technical contact:
            <a href="mailto:fgp3gpsccoor@tengizchevroil.com?subject=COF Application">CRA 3GP SC Team</a>
        </li>
    </ul>
</footer>
<div id="overlay">
    <div id="overlay-outerwrap">
        <a id="overlay-close"></a>
        <div id="overlay-wrap">
            <div id="overlay-wrapinside"></div>
        </div>
    </div>
</div>
