import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { forkJoin, ReplaySubject } from 'rxjs';
import { OrderDirection, Contractor } from 'src/app/store/common.model';
import { CurrenciesToContract } from 'src/app/models/currencies-to-contract';
import { BaseComponent } from 'src/app/components/base.component';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { LookupService } from 'src/app/services/lookup.service';
import { ToastService } from 'src/app/services/shared/toast.service';
import { finalize, take, takeWhile } from 'rxjs/operators';
import _ from 'lodash';

@Component({
    selector: 'app-currencies',
    templateUrl: './currencies.component.html',
    styleUrls: ['./currencies.component.scss'],
})
export class CurrenciesComponent extends BaseComponent implements OnInit {
    direction: OrderDirection.Desc;
    displayedColumns = ['key', 'value', 'edit'];
    currencies: CurrenciesToContract[] = [];
    contractors: Contractor[] = [];
    filteredContractors: ReplaySubject<Contractor[]> = new ReplaySubject<Contractor[]>();
    isLoading = false;
    sortBy: string = 'key';
    filteredContractorCtrl: FormControl = new FormControl();
    selectCtrl: FormControl = new FormControl();

    constructor(
        private iconRegistry: MatIconRegistry,
        private sanitizer: DomSanitizer,
        private configurationService: ConfigurationService,
        private lookupService: LookupService,
        private toastService: ToastService
    ) {
        super();

        this.iconRegistry.addSvgIcon(
            'edit',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/edit.svg')
        );
        this.iconRegistry.addSvgIcon(
            'delete',
            sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/delete.svg')
        );
    }

    ngOnInit() {
        this.isLoading = true;

        forkJoin([this.configurationService.getCurrenciesToContractors(), this.lookupService.searchContractors('')])
            .pipe(
                take(1),
                finalize(() => (this.isLoading = false))
            )
            .subscribe(([currencies, contractors]) => {
                this.currencies = _.map(currencies, (c) => {
                    c.currencies = c.currenciesString
                        .split(',')
                        .map((i) => i.trim())
                        .reduce((a, x) => ({ ...a, [x]: true }), {});
                    return c;
                });
                this.contractors = contractors;

                this.filteredContractors.next(
                    this.contractors
                        .filter(
                            (x) =>
                                !this.currencies
                                    .filter((y) => !y.isInEditMode)
                                    .map((y) => y.contractNo)
                                    .includes(x.contractNo)
                        )
                        .slice()
                );
            });

        this.filteredContractorCtrl.valueChanges
            .pipe(takeWhile(() => this.isAlive))
            .subscribe(() => this.filterContractors());
    }

    filterContractors(): void {
        let search = this.filteredContractorCtrl.value;
        if (!search) {
            this.filteredContractors.next(
                this.contractors
                    .filter(
                        (x) =>
                            !this.currencies
                                .filter((y) => !y.isInEditMode)
                                .map((y) => y.contractNo)
                                .includes(x.contractNo)
                    )
                    .slice()
            );
            return;
        } else {
            search = search.toLowerCase();
        }

        const contractors = this.contractors
            .filter(
                (x) =>
                    !this.currencies
                        .filter((y) => !y.isInEditMode)
                        .map((y) => y.contractNo)
                        .includes(x.contractNo)
            )
            .slice();
        this.filteredContractors.next(contractors.filter((item) => item.contractNo.toLowerCase().indexOf(search) > -1));
    }

    anyItemInEditMode(): boolean {
        return this.currencies.filter((x) => x.isInEditMode === true).length > 0;
    }

    anyRowInEditMode(skipIndex: number = null) {
        return this.currencies.filter((r, i) => i !== skipIndex).some((r) => r.isInEditMode);
    }

    addAssignment() {
        this.currencies = [
            ...this.currencies,
            Object.assign({}, new CurrenciesToContract(), {
                contractNo: '',
                currencies: { USD: false, KZT: false, RUB: false, EUR: false },
                isInEditMode: true,
            }),
        ];
    }

    isAcceptDisabled(element: CurrenciesToContract) {
        return (
            element.contractNo === null ||
            element.contractNo === '' ||
            element.currencies === null ||
            _.every(
                _.map(element.currencies, (value) => value),
                (v) => !v
            )
        );
    }

    enterEditMode(element: CurrenciesToContract) {
        element.isInEditMode = !element.isInEditMode;
        element.currencies = element.currenciesString
            .split(',')
            .map((i) => i.trim())
            .reduce((a, x) => ({ ...a, [x]: true }), {});
        element.prevContractNo = element.contractNo;
        element.prevCurrencies = element.currencies;

        this.filterContractors();
    }

    cancelEdit(element: CurrenciesToContract) {
        if (!element.prevContractNo && !element.prevCurrencies) {
            this.currencies.pop();
            this.currencies = [...this.currencies];
        }

        element.isInEditMode = !element.isInEditMode;
        element.currencies = element.prevCurrencies;
        element.contractNo = element.prevContractNo;
        this.filterContractors();
    }

    save(element: CurrenciesToContract) {
        element.isInEditMode = !element.isInEditMode;
        element.currenciesString = _.map(element.currencies, (value, key) => (value ? key : null))
            .filter((item) => item !== null)
            .join(', ');

        this.filterContractors();
        this.updateCurrenciesToContract(element);
    }

    updateCurrenciesToContract(element: CurrenciesToContract) {
        this.isLoading = true;
        this.configurationService
            .updateCurrenciesToContract(element)
            .pipe(
                take(1),
                finalize(() => (this.isLoading = false))
            )
            .subscribe(
                () => {
                    this.filteredContractors.next(
                        this.contractors
                            .filter(
                                (x) =>
                                    !this.currencies
                                        .filter((y) => !y.isInEditMode)
                                        .map((y) => y.contractNo)
                                        .includes(x.contractNo)
                            )
                            .slice()
                    );
                    this.toastService.Success('Currencies assignment has been updated.');
                },
                () => {
                    this.isLoading = false;
                    this.toastService.Error(
                        'An error occured while updating currencies assignment. Please contact Program Administrator.'
                    );
                }
            );
    }

    deleteCurrenciesToContract(element: CurrenciesToContract) {
        this.isLoading = true;
        this.configurationService
            .deleteCurrenciesToContract(element)
            .pipe(
                take(1),
                finalize(() => (this.isLoading = false))
            )
            .subscribe(
                () => {
                    this.filteredContractors.next(
                        this.contractors
                            .filter(
                                (x) =>
                                    !this.currencies
                                        .filter((y) => !y.isInEditMode)
                                        .map((y) => y.contractNo)
                                        .includes(x.contractNo)
                            )
                            .slice()
                    );
                    this.toastService.Success('Currencies assignment has been deleted.');
                },
                () => {
                    this.isLoading = false;
                    this.toastService.Error(
                        'An error occured while deleting currencies assignment. Please contact Program Administrator.'
                    );
                }
            );
    }

    remove(element: CurrenciesToContract) {
        this.currencies = [...this.currencies.filter((x) => x.contractNo !== element.contractNo)];
        this.deleteCurrenciesToContract(element);
    }
}
