import { Component, Inject, OnInit, HostListener, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup } from '@angular/forms';
import { takeWhile, finalize, take, catchError, filter } from 'rxjs/operators';
import { AweFormGroup, AWE, AWEAttachment, DefaultApprover } from '../../store/awe-form/model';
import { AWEService } from '../../services/awe.service';
import { BaseComponent } from '../base.component';
import { ToastService } from '../../services/shared/toast.service';
import { FormService } from '../../services/shared/form.service';
import { AWEFormValidators } from '../../validators/awe-form.validators';
import { LookupService } from 'src/app/services/lookup.service';
import { AppRole, Contractor, UserDetail } from 'src/app/store/common.model';
import _ from 'lodash';
import { Attachment } from 'src/app/store/call-off-attachments/model';
import { Dictionary } from 'typescript-collections';
import { saveAs } from 'file-saver';
import { forkJoin, of, throwError } from 'rxjs';
import { AweRegisterFilterRequest } from 'src/app/store/awe-register/actions';
import { ApplicationState } from 'src/app/store/model';
import { Store } from '@ngrx/store';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { ExchangeRate } from 'src/app/store/call-off/model';
import { Constants } from 'src/app/constants';
import { CallOffLookupService } from 'src/app/services/call-off-lookup.service';
import { ContractsTeamReviewersRequest } from 'src/app/store/action-users/actions';
import { ReasonForInstruction, ReasonStatus } from 'src/app/store/common/model';
import { MatSelectChange } from '@angular/material/select';
import { ActionUsersService } from 'src/app/services/action-users.service';
import { MatExpansionPanel } from '@angular/material/expansion';

@Component({
    selector: 'app-awe-form',
    templateUrl: './awe-form.component.html',
    styleUrls: ['./awe-form.component.scss'],
})
export class AweFormComponent extends BaseComponent implements OnInit {
    contractors: Contractor[] = [];
    aweFormGroup: FormGroup;
    isLoading: boolean = false;
    currenciesCheckIsLoading = false;
    revisions = [...Array(26).keys()];
    newAttachments: Attachment[] = [];
    deletedAttachments: Attachment[] = [];
    isUploadInProgress = false;
    editAWE: AweFormGroup = null;
    titleText = 'create new AWE';
    exchangeRates: ExchangeRate[] = [];
    contractorCurrencies: { [currency: string]: boolean } = { USD: true, KZT: true, RUB: true, EUR: true };
    anyContractorCurrency = true;
    contractsTeamReviewers$ = this.store.select((state) => state.actionUsersState.contractsTeamReviewers);
    contractsTeamReviewers: UserDetail[] = [];
    reasons: ReasonForInstruction[] = [];
    appRoles = AppRole;
    approvers: DefaultApprover[] = [];
    @ViewChild(MatExpansionPanel, { static: true }) expansionPanel: MatExpansionPanel;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: AWE,
        private dialogRef: MatDialogRef<AweFormComponent>,
        private toastService: ToastService,
        private aweService: AWEService,
        private formSvc: FormService,
        private validators: AWEFormValidators,
        private lookupService: LookupService,
        private store: Store<ApplicationState>,
        private configurationService: ConfigurationService,
        private callOffLookupService: CallOffLookupService,
        private actionUserService: ActionUsersService
    ) {
        super();
        this.aweFormGroup = this.formSvc.createForm(new AweFormGroup(), this.validators);
        this.aweFormGroup.controls.aweNte.disable();
    }

    ngOnInit() {
        this.aweFormGroup.controls.contractor.valueChanges
            .pipe(
                takeWhile(() => this.isAlive),
                filter((contractor) => !!contractor)
            )
            .subscribe((contractor: string) => {
                this.checkCurrenciesToContractAssignment(contractor);
                this.getReviewersAndApproversByContractNo(contractor);
            });

        for (const controlName of Object.keys(this.aweFormGroup.controls)) {
            if (
                [
                    Constants.Currency.usd,
                    Constants.Currency.kzt,
                    Constants.Currency.rub,
                    Constants.Currency.eur,
                ].indexOf(controlName) > -1
            ) {
                this.aweFormGroup.controls[controlName].valueChanges
                    .pipe(takeWhile(() => this.isAlive))
                    .subscribe(() => {
                        this.exchangeCurrency(controlName);
                        this.calculateNteUsdEquiv();
                    });
            }
        }

        if (this.data) {
            this.editAWE = {
                ...new AweFormGroup(),
                contractor: this.data.contractNo,
                aweNte: this.data.nte.toString(),
                aweNumber: this.data.number,
                aweTitle: this.data.subject,
                revision: this.data.revision,
                attachments: this.data.attachments,
                nteConsumed: this.data.nteConsumed,
                nteRemaining: this.data.nteRemaining,
                isEdit: true,
                defaultReviewer: this.data.defaultReviewer,
                defaultApprovers: this.data.defaultApprovers,
            };
            this.aweFormGroup.controls.aweNumber.disable();
            this.aweFormGroup.controls.contractor.disable();
            this.aweFormGroup.patchValue(this.editAWE, { emitEvent: false });
            this.aweFormGroup.updateValueAndValidity();
            this.titleText = 'edit AWE';
            this.checkCurrenciesToContractAssignment(this.data.contractNo);
            this.getReviewersAndApproversByContractNo(this.data.contractNo);
        }
        this.isLoading = true;

        forkJoin([
            this.callOffLookupService.getReasons(),
            this.lookupService.searchContractors('', -1, -1, [], 'newAWE'),
            this.configurationService.getExchangeRates(),
        ])
            .pipe(
                take(1),
                finalize(() => (this.isLoading = false))
            )
            .subscribe(([reasons, contractors, exchangeRates]) => {
                this.reasons = reasons.filter((s) => s.status === ReasonStatus.Active);
                this.contractors = contractors;
                this.exchangeRates = exchangeRates;
                if (this.data) {
                    this.aweFormGroup.patchValue({
                        usd: this.data.usd,
                        kzt: this.data.kzt,
                        rub: this.data.rub,
                        eur: this.data.eur,
                    });
                    this.calculateNteUsdEquiv();
                }
            });

        this.contractsTeamReviewers$
            .pipe(takeWhile(() => this.isAlive === true))
            .subscribe((data) => (this.contractsTeamReviewers = data));
    }

    compareDefaultUsers(e1: DefaultApprover, e2: DefaultApprover) {
        return e1 && e2
            ? e1.approver.email.toLowerCase() === e2.approver.email.toLowerCase() &&
                  e1.approverType === e2.approverType &&
                  e1.reasonForInstruction.id === e2.reasonForInstruction.id
            : e1 === e2;
    }

    getReviewersAndApproversByContractNo(contractor: string) {
        this.store.dispatch(new ContractsTeamReviewersRequest(contractor));
        this.actionUserService
            .getRepresentatives(contractor)
            .pipe(take(1))
            .subscribe((data: DefaultApprover[]) => {
                this.approvers = data;
            });
    }

    approverSelected(change: MatSelectChange) {
        let approvers = this.aweFormGroup.controls.defaultApprovers.value.filter(
            (s) =>
                s.approverType !== change.value.approverType ||
                s.reasonForInstruction.id !== change.value.reasonForInstruction.id
        );
        if (this.data) {
            change.value.aweId = this.data.id;
        }
        if (change.value.approver) {
            approvers.push(change.value);
        }
        this.aweFormGroup.controls.defaultApprovers.setValue(approvers);
    }

    getEmptyApprover(reason: ReasonForInstruction, appRole: AppRole) {
        let approver = new DefaultApprover();
        approver.approverType = appRole;
        approver.reasonForInstruction = reason;
        return approver;
    }

    getDefaultApprover(reason: ReasonForInstruction, appRole: AppRole) {
        return this.aweFormGroup.controls.defaultApprovers.value.find(
            (item) => item.reasonForInstruction.id === reason.id && AppRole[item.approverType] === AppRole[appRole]
        );
    }

    close() {
        this.deleteAllAttachments();
        this.dialogRef.close();
    }

    submit() {
        if (this.deletedAttachments.length > 0) {
            this.deletedAttachments.forEach((element: AWEAttachment) => {
                this.deleteFile(element);
            });
            this.deletedAttachments = [];
        }
        if (this.newAttachments.length === 0) {
            this.submitAWE();
        } else {
            this.uploadNewAttachments();
        }
    }

    submitAWE() {
        const awe = new AWE();
        awe.contractNo = this.aweFormGroup.controls.contractor.value;
        awe.number = this.aweFormGroup.controls.aweNumber.value;
        awe.subject = this.aweFormGroup.controls.aweTitle.value;
        awe.nte = Number(this.aweFormGroup.controls.aweNte.value);
        awe.revision = Number(this.aweFormGroup.controls.revision.value);
        awe.attachments = this.aweFormGroup.controls.attachments.value;
        awe.usd = this.aweFormGroup.controls.usd.value;
        awe.kzt = this.aweFormGroup.controls.kzt.value;
        awe.rub = this.aweFormGroup.controls.rub.value;
        awe.eur = this.aweFormGroup.controls.eur.value;
        awe.defaultReviewer = this.aweFormGroup.controls.defaultReviewer.value;
        awe.defaultApprovers = this.aweFormGroup.controls.defaultApprovers.value;
        this.isLoading = true;

        if (this.data) {
            awe.id = this.data.id;
            awe.number = this.data.number;
            awe.contractNo = this.data.contractNo;
            awe.isActive = true;
            this.aweService
                .updateAWE(awe)
                .pipe(
                    takeWhile(() => this.isAlive),
                    finalize(() => (this.isLoading = false)),
                    catchError((err) => {
                        if (_.some(['USD', 'KZT', 'RUB', 'EUR'], (currency) => err.error.indexOf(currency) > -1)) {
                            this.toastService.Error(err.error);
                        } else {
                            this.toastService.Error(
                                'Error has occurred while updating AWE. Please contact Program Administrator.'
                            );
                        }
                        return throwError(err);
                    })
                )
                .subscribe(() => {
                    this.toastService.Success('AWE saved correctly.');
                    this.store.dispatch(new AweRegisterFilterRequest());
                    this.dialogRef.close();
                });
        } else {
            this.aweService
                .addAWE(awe)
                .pipe(
                    takeWhile(() => this.isAlive),
                    finalize(() => (this.isLoading = false)),
                    catchError(() => {
                        this.toastService.Error(
                            'Error has occurred while creating new AWE. Please contact Program Administrator.'
                        );
                        return of(null);
                    })
                )
                .subscribe(() => {
                    this.toastService.Success('AWE saved correctly.');
                    this.store.dispatch(new AweRegisterFilterRequest());
                    this.dialogRef.close();
                });
        }
    }

    fileChangeEvent(files: Array<File>) {
        const filesWrapper = new Array<File>();
        filesWrapper.push(...files);
        filesWrapper.forEach((file) => {
            if (file.size === 0) {
                this.toastService.Info(`File ${file.name} has 0B size. Please upload a valid file.`);
                return;
            }
            if (
                this.newAttachments.filter((x) => x.file.name === file.name).length > 0 ||
                (this.aweFormGroup.controls.attachments.value &&
                    this.aweFormGroup.controls.attachments.value.filter((x: AWEAttachment) => x.name === file.name)
                        .length > 0)
            ) {
                this.toastService.Info(
                    'File ' + file.name + ' already uploaded. Please delete existing file and re-upload.'
                );
            } else {
                const attachment = new Attachment();
                attachment.file = file;
                attachment.name = file.name;
                attachment.isValid = true;
                this.newAttachments.push(attachment);

                const aweAttachment = new AWEAttachment();
                aweAttachment.name = file.name;
                this.aweFormGroup.controls.attachments.setValue([
                    ...this.aweFormGroup.controls.attachments.value,
                    aweAttachment,
                ]);
            }
        });
    }

    uploadNewAttachments() {
        const formData = new FormData();
        this.newAttachments.forEach((file) => {
            formData.append(file.file.name, file.file);
        });
        this.isUploadInProgress = true;
        this.aweService
            .uploadAWEAttachments(formData)
            .pipe(
                take(1),
                finalize(() => (this.isUploadInProgress = false)),
                catchError(() => {
                    this.toastService.Error(
                        'Error has occurred while uploading attachment(s). Please contact Program Administrator.'
                    );
                    return of(null);
                })
            )
            .subscribe((uploadedAttachments: Dictionary<string, string>) => {
                this.newAttachments = [];
                var attachments = this.aweFormGroup.controls.attachments.value;
                attachments.forEach((element: AWEAttachment) => {
                    if (!element.link) {
                        element.link = uploadedAttachments[element.name];
                    }
                });
                this.aweFormGroup.controls.attachments.setValue(attachments);
                this.submitAWE();
            });
    }

    downloadUploadedAttachment(uploadedAttachment: AWEAttachment) {
        this.aweService
            .downloadAWEAttachment(uploadedAttachment.link)
            .pipe(
                take(1),
                catchError(() => {
                    this.toastService.Error(
                        'Error has occurred while downloading attachment. Please contact Program Administrator.'
                    );
                    return of(null);
                })
            )
            .subscribe((file) => {
                const blob = new Blob([file], {
                    type: 'application/octet-stream',
                });
                saveAs(blob, uploadedAttachment.name);
            });
    }

    deleteUploadedAttachment(uploadedAttachment: AWEAttachment) {
        let deleteIndexFormGroup = this.aweFormGroup.controls.attachments.value.findIndex(
            (s) => s.name === uploadedAttachment.name
        );

        if (!uploadedAttachment.link) {
            let deleteIndex = this.newAttachments.findIndex((s) => s.name === uploadedAttachment.name);
            this.newAttachments.splice(deleteIndex, 1);
        } else {
            this.deletedAttachments.push(this.aweFormGroup.controls.attachments.value[deleteIndexFormGroup]);
        }

        this.aweFormGroup.controls.attachments.setValue([
            ...this.aweFormGroup.controls.attachments.value.slice(0, deleteIndexFormGroup),
            ...this.aweFormGroup.controls.attachments.value.slice(deleteIndexFormGroup + 1),
        ]);
    }

    deleteFile(uploadedAttachment: AWEAttachment) {
        this.aweService
            .deleteAWEAttachment([uploadedAttachment.link])
            .pipe(
                take(1),
                catchError(() => {
                    this.toastService.Error(
                        'Error has occurred while deleting attachment. Please contact Program Administrator.'
                    );
                    return of(null);
                })
            )
            .subscribe(() => {});
    }

    @HostListener('window:popstate')
    onPopState() {
        this.deleteAllAttachments();
    }

    @HostListener('window:beforeunload')
    onBeforeUnload() {
        this.deleteAllAttachments();
    }

    private deleteAllAttachments() {
        if (
            (!this.editAWE || !this.editAWE.isEdit) &&
            this.aweFormGroup.controls.attachments.value &&
            this.aweFormGroup.controls.attachments.value.length
        ) {
            this.aweService
                .deleteAWEAttachment(this.aweFormGroup.controls.attachments.value.map((a: AWEAttachment) => a.link))
                .pipe(take(1))
                .subscribe();
            this.aweFormGroup.controls.attachments.setValue([]);
        }
    }

    private exchangeCurrency(currencyName: string) {
        if (currencyName !== Constants.Currency.usd) {
            const exchangeRate = this.exchangeRates.find((e) => e.currency === currencyName.toUpperCase());
            this.aweFormGroup.controls[`${currencyName}Exchanged`].patchValue(
                exchangeRate ? this.aweFormGroup.controls[currencyName].value / exchangeRate.value : 0
            );
        }
    }

    private calculateNteUsdEquiv() {
        this.aweFormGroup.controls.aweNte.patchValue(
            this.aweFormGroup.controls[Constants.Currency.usd].value +
                this.aweFormGroup.controls[`${Constants.Currency.kzt}Exchanged`].value +
                this.aweFormGroup.controls[`${Constants.Currency.rub}Exchanged`].value +
                this.aweFormGroup.controls[`${Constants.Currency.eur}Exchanged`].value
        );
    }

    private checkCurrenciesToContractAssignment(contractor: string) {
        this.currenciesCheckIsLoading = true;
        this.aweFormGroup.patchValue(
            {
                usd: 0,
                kzt: 0,
                rub: 0,
                eur: 0,
                kztExchanged: 0,
                rubExchanged: 0,
                eurExchanged: 0,
                aweNte: 0,
            },
            { emitEvent: false }
        );
        this.callOffLookupService
            .getContractorLimitByContractNo(contractor)
            .pipe(
                take(1),
                finalize(() => (this.currenciesCheckIsLoading = false))
            )
            .subscribe((data) => {
                this.contractorCurrencies =
                    data && data.currencies
                        ? data.currencies
                              .split(',')
                              .map((c) => c.trim())
                              .reduce((a, x) => ({ ...a, [x]: true }), {})
                        : {};
                this.anyContractorCurrency = Object.keys(this.contractorCurrencies).length > 0;
            });
    }
}
