<div class="status-container">
    <div class="title-container">
        <div class="title">current workflow status</div>
    </div>
    <span class="align-status cof-status-big cof-status" [ngClass]="getClassName(callOffStatus)">
        {{ getStatusName(callOffStatus) }}
    </span>
    <span class="material-icons email" title="share" (click)="shareEmail()">
        forward_to_inbox
    </span>
    <div class="status-buttons">
        <button
            *ngIf="revisions && revisions.length"
            class="cof-button cof-button-white"
            (click)="openViewPreviousRevisions()"
        >
            view previous revisions
        </button>
        <button class="cof-button cof-button-white" (click)="printCallOff()">
            print
        </button>
        <div *ngIf="commentsCount > 0 || callOffStatus !== 1" class="comments-number">{{ commentsCount }}</div>
        <button
            *ngIf="commentsCount > 0 || callOffStatus !== 1"
            class="cof-button cof-button-white comments-icon"
            (click)="scroll('app-cof-comments-history')"
        >
            comments
        </button>
        <button class="cof-button cof-button-white history-icon" (click)="openHistoryPopup()">
            status history
        </button>
    </div>
</div>
