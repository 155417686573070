import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../model';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ToastService } from '../../services/shared/toast.service';
import {
    ConstructionApproverMatrixActionTypes,
    ConstructionApproverMatrixCreateRequest,
    ConstructionApproverMatrixCreateRequestError,
    ConstructionApproverMatrixCreateRequestSuccess,
    ConstructionApproverMatrixDataRequest,
    ConstructionApproverMatrixDataRequestError,
    ConstructionApproverMatrixDataRequestSuccess,
    ConstructionApproverMatrixDeleteRequest,
    ConstructionApproverMatrixDeleteRequestError,
    ConstructionApproverMatrixDeleteRequestSuccess,
    ConstructionApproverMatrixUpdateRequest,
    ConstructionApproverMatrixUpdateRequestError,
    ConstructionApproverMatrixUpdateRequestSuccess,
} from './actions';
import { withLatestFrom, mergeMap, map, catchError } from 'rxjs/operators';
import { ConstructionApproverMatrixService } from '../../services/constructionApproverMatrix.service';
import { of } from 'rxjs';
import { ConstructionApproverMatrixDTO, ConstructionApproverMatrixResultData } from './models';

@Injectable()
export class ConstructionApproverMatrixEffects {
    constructor(
        private store: Store<ApplicationState>,
        private actions$: Actions,
        private caMatrixService: ConstructionApproverMatrixService,
        private toastService: ToastService
    ) {}

    
    getDataRequestAction$ = createEffect(() => this.actions$.pipe(
        ofType<ConstructionApproverMatrixDataRequest>(ConstructionApproverMatrixActionTypes.DataRequest),
        withLatestFrom(this.store.select((state) => state.constructionApproverMatrixState.filter)),
        mergeMap(([, filter]) =>
            this.caMatrixService.getAllData(filter).pipe(
                map((data: ConstructionApproverMatrixResultData) =>
                    {
                       return new ConstructionApproverMatrixDataRequestSuccess(data)
                    }

                ),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while loading Construction Approver Matrix. Please contact Program Administrator.'
                    );
                    return of(new ConstructionApproverMatrixDataRequestError(error));
                })
            )
        )
    ));

    
    createRequestAction$ = createEffect(() => this.actions$.pipe(
        ofType(ConstructionApproverMatrixActionTypes.CreateRequest),
        mergeMap((action: ConstructionApproverMatrixCreateRequest) =>
            this.caMatrixService.add(action.payload).pipe(
                map((data: ConstructionApproverMatrixDTO) =>
                    {
                       return new ConstructionApproverMatrixCreateRequestSuccess()
                    }

                ),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while adding Construction Approver Matrix. Please contact Program Administrator'
                    );
                    return of(new ConstructionApproverMatrixCreateRequestError(error));
                })
            )
        )
    ));

    
    createRequestActionSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(ConstructionApproverMatrixActionTypes.CreateRequestSuccess),
        map(() => new ConstructionApproverMatrixDataRequest())
    ));

    
    deleteRequestAction$ = createEffect(() => this.actions$.pipe(
        ofType(ConstructionApproverMatrixActionTypes.DeleteRequest),
        mergeMap((action: ConstructionApproverMatrixDeleteRequest) =>
            this.caMatrixService.delete(action.payload).pipe(
                map(() =>
                    {
                       return new ConstructionApproverMatrixDeleteRequestSuccess()
                    }

                ),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while removing Construction Approver Matrix. Please contact Program Administrator'
                    );
                    return of(new ConstructionApproverMatrixDeleteRequestError(error));
                })
            )
        )
    ));

    
    deleteRequestActionSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(ConstructionApproverMatrixActionTypes.DeleteRequestSuccess),
        map(() => new ConstructionApproverMatrixDataRequest())
    ));

    
    updateRequestAction$ = createEffect(() => this.actions$.pipe(
        ofType(ConstructionApproverMatrixActionTypes.UpdateRequest),
        mergeMap((action: ConstructionApproverMatrixUpdateRequest) =>
            this.caMatrixService.update(action.payload).pipe(
                map((data) =>
                    {
                       return new ConstructionApproverMatrixUpdateRequestSuccess(data)
                    }

                ),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while adding Construction Approver Matrix. Please contact Program Administrator'
                    );
                    return of(new ConstructionApproverMatrixUpdateRequestError(error));
                })
            )
        )
    ));

    
    updateRequestActionSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(ConstructionApproverMatrixActionTypes.UpdateRequestSuccess),
        map(() => new ConstructionApproverMatrixDataRequest())
    ));
}
