import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { PopupService } from 'src/app/services/shared/popup.service';
import { PopupSettings } from 'src/app/models/popup-settings';
import { CofHistoryPopupComponent } from '../cof-history-popup/cof-history-popup.component';
import { BaseComponent } from 'src/app/components/base.component';
import { Store } from '@ngrx/store';
import { ApplicationState } from 'src/app/store/model';
import { combineLatest } from 'rxjs';
import { filter, take, takeWhile } from 'rxjs/operators';
import { CallOffStatusType } from 'src/app/models/enums';
import { CallOffService } from 'src/app/services/call-off.service';
import { CofRevisionsPopupComponent } from '../cof-revisions-popup/cof-revisions-popup.component';
import { RevisionDTO } from 'src/app/models/revision-dto';
import { CofShareEmailPopupComponent } from '../cof-share-email-popup/cof-share-email-popup.component';

@Component({
    selector: 'app-cof-workflow-status',
    templateUrl: './cof-workflow-status.component.html',
    styleUrls: ['./cof-workflow-status.component.scss'],
})
export class CofWorkflowStatusComponent extends BaseComponent implements OnInit {
    @Input() callOffForm: FormGroup;
    @Output() printEvent = new EventEmitter();
    callOffStatus: CallOffStatusType;
    commentsCount = 0;
    revisions: RevisionDTO[] = [];
    callOffStatus$ = this.store.select((store) => store.callOffState.form.status);
    comments$ = this.store.select((store) => store.callOffState.form.comments);
    callOffId$ = this.store.select((store) => store.callOffState.form.id);
    constructor(
        private popupService: PopupService,
        private store: Store<ApplicationState>,
        private callOffService: CallOffService
    ) {
        super();
    }

    ngOnInit() {
        this.callOffId$
            .pipe(
                takeWhile(() => this.isAlive),
                filter((id) => id !== null)
            )
            .subscribe((id: number) => {
                this.callOffService
                    .getCallOffPreviousRevisionsRequest(id)
                    .pipe(take(1))
                    .subscribe((revs: RevisionDTO[]) => {
                        this.revisions = revs;
                    });
            });

        combineLatest([this.callOffStatus$, this.comments$])
            .pipe(takeWhile(() => this.isAlive))
            .subscribe(([status, comments]) => {
                this.callOffStatus = status;
                this.commentsCount = comments.length;
            });
    }
    scroll(tagName) {
        const el = document.getElementsByTagName(tagName);
        if (el) {
            el[0].scrollIntoView({ behavior: 'smooth' });
        }
    }
    openHistoryPopup() {
        this.popupService.openPopup(
            new PopupSettings(
                CofHistoryPopupComponent,
                null,
                null,
                { callOffId: this.callOffForm.controls['id'].value },
                500,
                200
            )
        );
    }

    printCallOff() {
        this.printEvent.emit();
    }

    openViewPreviousRevisions() {
        this.popupService.openPopup(
            new PopupSettings(CofRevisionsPopupComponent, null, null, { revisions: this.revisions }, 500, 200)
        );
    }

    shareEmail() {
        this.popupService.openPopup(
            new PopupSettings(
                CofShareEmailPopupComponent,
                null,
                null,
                {
                    subject: this.callOffForm.controls.subject.value,
                    number: this.callOffForm.controls.callOffNumber.value,
                    id: this.callOffForm.controls.id.value,
                },
                500,
                200,
                null,
                null,
                false,
                'email-dropdown'
            )
        );
    }
}
