<main class="main">
    <form [formGroup]="callOffForm">
        <app-cof-workflow-status [callOffForm]="callOffForm" (printEvent)="printCallOff()"></app-cof-workflow-status>
        <div *ngIf="isLatestRevision" [ngClass]="{ 'is-sticky': isSticky }">
            <app-cof-buttons
                [callOffForm]="callOffForm"
                (disableFormEvent)="disableForm()"
                (enableFormEvent)="enableForm()"
            ></app-cof-buttons>
        </div>
        <app-cof-top-section [callOffForm]="callOffForm"></app-cof-top-section>
        <div class="title" *ngIf="printMode">Status: {{ getStatusName(status) }}</div>
        <app-cof-header [callOffForm]="callOffForm"></app-cof-header>
        <div #scrollTargetScopeOfWork></div>
        <app-cof-scope-of-work
            [callOffForm]="callOffForm"
            (openJodit)="openJoditPopup($event)"
            (scopeOfWorkDescriptionSet)="onEditorValueSet($event)"
        >
        </app-cof-scope-of-work>
        <app-cof-attachments [callOffForm]="callOffForm" [printMode]="printMode"></app-cof-attachments>
        <div #scrollTargetEstimate></div>
        <app-cof-contractors-estimate
            *ngIf="!isReadOnly"
            class="page-break-inside"
            [callOffForm]="callOffForm"
            (openJodit)="openJoditPopup($event)"
            (estimateDetailsSet)="onEditorValueSet($event)"
        >
        </app-cof-contractors-estimate>
        <app-cof-workflow [callOffForm]="callOffForm" class="page-break"></app-cof-workflow>
        <app-cof-completed *ngIf="status === statuses.Completed || status === statuses.CompanyCompleted" [callOffForm]="callOffForm"></app-cof-completed>
        <app-cof-comments-history
            *ngIf="comments.length > 0 || (status$ | async) !== 1"
            [comments]="comments"
            [callOffId]="callOffId"
            [callOffNumber]="callOffNumber$ | async"
            [printMode]="printMode"
        ></app-cof-comments-history>
        <app-loading-indicator *ngIf="isLoading"> </app-loading-indicator>
    </form>
</main>
<div class="editor-jodit-container hidden-jodit">
    <textarea id="editor"></textarea>
</div>
