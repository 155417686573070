import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {
    MsalModule,
    MsalInterceptor,
    MsalService,
    MSAL_INSTANCE,
    MSAL_GUARD_CONFIG,
    MSAL_INTERCEPTOR_CONFIG,
    MsalGuard,
    MsalBroadcastService,
    MsalRedirectComponent,
} from '@azure/msal-angular';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CallOfFormComponent } from './components/call-of-form/call-of-form.component';
import { NewCallOffComponent } from './components/new-call-off/new-call-off.component';
import { CallOffRegisterComponent } from './components/call-off-register/call-off-register.component';
import { CallOfFormHeaderComponent } from './components/call-of-form/cof-header/cof-header.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDialogModule } from '@angular/material/dialog';
import { CofContractorsEstimateComponent } from './components/call-of-form/cof-contractors-estimate/cof-contractors-estimate.component';
import { AweFormComponent } from './components/awe-form/awe-form.component';
import { CofButtonsComponent } from './components/call-of-form/cof-buttons/cof-buttons.component';
import { EscapeHtmlPipe } from './pipes/keep-html.pipe';
import { AweRegisterComponent } from './components/awe-register/awe-register.component';
import { CofWorkflowComponent } from './components/call-of-form/cof-workflow/cof-workflow.component';
import { CofScopeOfWorkComponent } from './components/call-of-form/cof-scope-of-work/cof-scope-of-work.component';
import { CofCommentsHistoryComponent } from './components/call-of-form/cof-comments-history/cof-comments-history.component';
import { CofAttachmentsComponent } from './components/call-of-form/cof-attachments/cof-attachments.component';
import { CofAttachmentSupportingInformationAttachmentComponent } from './components/call-of-form/cof-attachments/app-cof-attachment-sia/cof-attachment-sia.component';
import { CofWorkflowStatusComponent } from './components/call-of-form/cof-workflow-status/cof-workflow-status.component';
import { CofHistoryPopupComponent } from './components/call-of-form/cof-history-popup/cof-history-popup.component';
import { CofRevisionsPopupComponent } from './components/call-of-form/cof-revisions-popup/cof-revisions-popup.component';
import { DragAndDropDirective } from './directives/drag-and-drop.directive';
import { CallOffMypageComponent } from './components/call-off-mypage/call-off-mypage.component';
import { CofMyPendingActionsComponent } from './components/call-off-mypage/cof-my-pending-actions/cof-my-pending-actions.component';
import { CofSummaryComponent } from './components/call-off-mypage/cof-summary/cof-summary.component';
import { CofAllDraftsComponent } from './components/call-off-mypage/cof-all-drafts/cof-all-drafts.component';
import { NumericDirective } from './directives/numeric.directive';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { StoreModule } from './store/store.module';
import { TopHeaderComponent } from './components/top-header/top-header.component';
import { FooterComponent } from './components/footer/footer.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { MultipleItemsSelectorComponent } from './components/multiple-items-selector/multiple-items-selector.component';
import { InformationDialogComponent } from './components/information-dialog/information-dialog.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { DeleteConfirmationPopupComponent } from './components/delete-confirmation-popup/delete-confirmation-popup.component';
import { CommentPopupComponent } from './components/comment-popup/comment-popup.component';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EnumKeyValueListPipe } from './pipes/enumlist.pipe';
import { UserListPipe } from './pipes/userlist.pipe';
import { ReasonFilterPipe } from './pipes/reason-filter.pipe';
import { RangeDatePipe } from './pipes/date-range.pipe';
import { InfiniteScrollDirective } from './directives/infinite-scroll.directive';
import { LoadingIndicatorComponent } from './components/loading-indicator/loading-indicator.component';
import { CofTopSectionComponent } from './components/call-of-form/cof-top-section/cof-top-section.component';
import { AdminComponent } from './components/admin-section/admin.component';
import { DashboardComponent } from './components/admin-section/dashboard/dashboard.component';
import { GroupsMaintenanceComponent } from './components/admin-section/groups-maintenance/groups-maintenance.component';
import { NotAuthorizedComponent } from './components/error/not-authorized/not-authorized.component';
import { NotFoundComponent } from './components/error/not-found/not-found.component';
import { AddApprovalMatrixPopupComponent } from './components/admin-section/groups-maintenance/approval-matrix/add-approval-matrix-popup/add-approval-matrix-popup.component';
import { ApprovalMatrixComponent } from './components/admin-section/groups-maintenance/approval-matrix/approval-matrix.component';
import { AddUserToContractsPopupComponent } from './components/admin-section/groups-maintenance/user-to-contracts/add-user-to-contracts-popup/add-user-to-contracts-popup.component';
import { UserToContractsComponent } from './components/admin-section/groups-maintenance/user-to-contracts/user-to-contracts.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { PopupService } from './services/shared/popup.service';
import { RoleService } from './services/shared/role.service';
import { MatNativeDateModule, MAT_RIPPLE_GLOBAL_OPTIONS, RippleGlobalOptions } from '@angular/material/core';
import { CacheInterceptor } from './interceptors/cache-interceptor';
import { HttpErrorInterceptor } from './interceptors/http-error-interceptor';
import { ConfigurationComponent } from './components/admin-section/configuration/configuration.component';
import { ExchangeRateComponent } from './components/admin-section/configuration/exchange-rate/exchange-rate.component';
import { SettingsComponent } from './components/admin-section/configuration/settings/settings.component';
import { ColResizeDirective } from './directives/col-resize.directive';
import { NgxCurrencyModule } from 'ngx-currency';
import { LimitsComponent } from './components/admin-section/configuration/limits/limits.component';
import { AWESearchComponent } from './components/new-call-off/awe-search/awe-search.component';
import { AscDescOrderComponent } from './components/filter/asc-desc-order/asc-desc-order.component';
import { HeaderChecklistFilterComponent } from './components/filter/header-checklist-filter/header-checklist-filter.component';
import { HeaderDateFilterComponent } from './components/filter/header-date-filter/header-date-filter.component';
import { HeaderRangeFilterComponent } from './components/filter/header-range-filter/header-range-filter.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { NTENotificationComponent } from './components/admin-section/configuration/nte-notification/nte-notification.component';
import { AddNTENotificationPopupComponent } from './components/admin-section/configuration/nte-notification/add-nte-notification-popup/add-nte-notification-popup.component';
import { MatSliderModule } from '@angular/material/slider';
import { CurrenciesComponent } from './components/admin-section/configuration/currencies/currencies.component';
import { CofDelegationsComponent } from './components/call-off-mypage/cof-delegations/cof-delegations.component';
import { DelegationsPopupComponent } from './components/call-off-mypage/cof-delegations/delegations-popup/delegations-popup.component';
import {
    appInsightsConfig,
    APP_INSIGHTS_CONFIG,
    MSALGuardConfigFactory,
    MSALInscanceFactory,
    MSALInterceptorConfigFactory,
} from './app.config';
import { CofShareEmailPopupComponent } from 'src/app/components/call-of-form/cof-share-email-popup/cof-share-email-popup.component';
import { MentionModule } from 'angular-mentions';
import { COFCompletedPopupComponent } from 'src/app/components/call-of-form/cof-completed-popup/cof-completed-popup.component';
import { COFCompletedComponent } from 'src/app/components/call-of-form/cof-completed/cof-completed.component';
import { ConstructionApproverMatrixComponent } from './components/admin-section/groups-maintenance/construction-approver-matrix/construction-approver-matrix.component';
import { AddConstructionApproverPopupComponent } from './components/admin-section/groups-maintenance/construction-approver-matrix/add-construction-approver-popup/add-construction-approver-popup.component';
import { NgxTextDiffModule } from 'ngx-text-diff';

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;
const globalRippleConfig: RippleGlobalOptions = {
    disabled: true,
};
@NgModule({
    declarations: [
        AppComponent,
        TopHeaderComponent,
        FooterComponent,
        NavbarComponent,
        EnumKeyValueListPipe,
        UserListPipe,
        RangeDatePipe,
        CallOfFormComponent,
        NewCallOffComponent,
        CallOffRegisterComponent,
        CallOfFormHeaderComponent,
        CofContractorsEstimateComponent,
        CofButtonsComponent,
        AweFormComponent,
        EscapeHtmlPipe,
        AweRegisterComponent,
        CofWorkflowComponent,
        CofScopeOfWorkComponent,
        CofWorkflowStatusComponent,
        CofHistoryPopupComponent,
        CofCommentsHistoryComponent,
        CofAttachmentsComponent,
        CofAttachmentSupportingInformationAttachmentComponent,
        DragAndDropDirective,
        CallOffMypageComponent,
        CofMyPendingActionsComponent,
        CofSummaryComponent,
        CofAllDraftsComponent,
        NumericDirective,
        InfiniteScrollDirective,
        MultipleItemsSelectorComponent,
        InformationDialogComponent,
        ConfirmDialogComponent,
        DeleteConfirmationPopupComponent,
        CommentPopupComponent,
        LoadingIndicatorComponent,
        CofTopSectionComponent,
        AdminComponent,
        DashboardComponent,
        GroupsMaintenanceComponent,
        NotAuthorizedComponent,
        NotFoundComponent,
        ApprovalMatrixComponent,
        AddApprovalMatrixPopupComponent,
        ConfigurationComponent,
        ExchangeRateComponent,
        UserToContractsComponent,
        AddUserToContractsPopupComponent,
        SettingsComponent,
        ColResizeDirective,
        LimitsComponent,
        CurrenciesComponent,
        AWESearchComponent,
        AscDescOrderComponent,
        HeaderChecklistFilterComponent,
        HeaderDateFilterComponent,
        HeaderRangeFilterComponent,
        NTENotificationComponent,
        AddNTENotificationPopupComponent,
        CofRevisionsPopupComponent,
        CofDelegationsComponent,
        DelegationsPopupComponent,
        CofShareEmailPopupComponent,
        COFCompletedPopupComponent,
        ReasonFilterPipe,
        COFCompletedComponent,
        ConstructionApproverMatrixComponent,
        AddConstructionApproverPopupComponent,
    ],
    imports: [
        CommonModule,
        BrowserModule,
        HttpClientModule,
        FormsModule,
        AppRoutingModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        MatFormFieldModule,
        MatSelectModule,
        MatChipsModule,
        MatIconModule,
        MatAutocompleteModule,
        MatExpansionModule,
        MatInputModule,
        MatCheckboxModule,
        MatButtonModule,
        MatTooltipModule,
        MatDialogModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        MatProgressSpinnerModule,
        MatGridListModule,
        MatNativeDateModule,
        MatDatepickerModule,
        MatMenuModule,
        MatRadioModule,
        MatTabsModule,
        MatToolbarModule,
        MatListModule,
        StoreModule,
        NgxMatSelectSearchModule,
        MatSlideToggleModule,
        ScrollingModule,
        MatSliderModule,
        ToastrModule.forRoot(),
        MsalModule,
        NgxCurrencyModule,
        MentionModule,
        NgxTextDiffModule,
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MsalInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: CacheInterceptor,
            multi: true,
        },
        PopupService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpErrorInterceptor,
            multi: true,
        },
        {
            provide: MSAL_INSTANCE,
            useFactory: MSALInscanceFactory,
        },
        {
            provide: MSAL_GUARD_CONFIG,
            useFactory: MSALGuardConfigFactory,
        },
        {
            provide: MSAL_INTERCEPTOR_CONFIG,
            useFactory: MSALInterceptorConfigFactory,
        },
        RoleService,
        { provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: globalRippleConfig },
        { provide: APP_INSIGHTS_CONFIG, useValue: appInsightsConfig },
        MsalService,
        MsalGuard,
        MsalBroadcastService,
    ],
    bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {}
