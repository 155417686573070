import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { UserDetail } from 'src/app/store/common.model';
import { combineLatest } from 'rxjs';
import { Store } from '@ngrx/store';
import { BaseComponent } from 'src/app/components/base.component';
import { takeWhile } from 'rxjs/operators';
import { ApplicationState } from 'src/app/store/model';
import _ from 'lodash';

@Component({
    selector: 'app-cof-workflow',
    templateUrl: './cof-workflow.component.html',
    styleUrls: ['./cof-workflow.component.scss'],
})
export class CofWorkflowComponent extends BaseComponent implements OnInit {
    @Input() callOffForm: FormGroup;

    companyRepresentatives: UserDetail[] = [];
    contractorRepresentatives: UserDetail[] = [];
    contractsTeamReviewers: UserDetail[] = [];

    companyRepresentatives$ = this.store.select((state) => state.actionUsersState.companyRepresentatives);
    contractorRepresentatives$ = this.store.select((state) => state.actionUsersState.contractorRepresentatives);
    contractsTeamReviewers$ = this.store.select((state) => state.actionUsersState.contractsTeamReviewers);
    companyRepresentative$ = this.store.select((state) => state.callOffState.form.companyRepresentative);
    contractorRepresentative$ = this.store.select((state) => state.callOffState.form.contractorRepresentative);
    contractsTeamReviewer$ = this.store.select((state) => state.callOffState.form.contractsTeamReviewer);
    callOffForm$ = this.store.select((state) => state.callOffState.form);

    constructor(private store: Store<ApplicationState>) {
        super();
    }

    ngOnInit() {
        combineLatest([
            this.companyRepresentatives$,
            this.contractorRepresentatives$,
            this.contractsTeamReviewers$,
            this.companyRepresentative$,
            this.contractorRepresentative$,
            this.contractsTeamReviewer$,
        ])
            .pipe(takeWhile(() => this.isAlive))
            .subscribe(
                ([
                    companyRepresentatives,
                    contractorRepresentatives,
                    contractsTeamReviewers,
                    companyRepresentative,
                    contractorRepresentative,
                    contractsTeamReviewer,
                ]) => {
                    this.companyRepresentatives = _.sortBy(
                        this.patchRepresentative(companyRepresentatives, companyRepresentative),
                        'name'
                    );
                    this.contractorRepresentatives = _.sortBy(
                        this.patchRepresentative(contractorRepresentatives, contractorRepresentative),
                        'name'
                    );
                    this.contractsTeamReviewers = _.sortBy(
                        this.patchRepresentative(contractsTeamReviewers, contractsTeamReviewer),
                        'name'
                    );

                    this.callOffForm.patchValue({ companyRepresentative }, { emitEvent: false });
                    this.callOffForm.patchValue({ contractorRepresentative }, { emitEvent: false });
                    this.callOffForm.patchValue({ contractsTeamReviewer }, { emitEvent: false });
                }
            );

        this.callOffForm$.pipe(takeWhile(() => this.isAlive)).subscribe((callOff) => {
            this.callOffForm.controls.companyApproveDate.patchValue(callOff.companyApproveDate, { emitEvent: false });
            this.callOffForm.controls.contractorApproveDate.patchValue(callOff.contractorApproveDate, {
                emitEvent: false,
            });
            this.callOffForm.controls.contractsTeamReviewDate.patchValue(callOff.contractsTeamReviewDate, {
                emitEvent: false,
            });
            this.callOffForm.controls.routeDate.patchValue(callOff.routeDate, { emitEvent: false });
        });
    }

    patchRepresentative(representativeCollection: UserDetail[], representative: UserDetail): UserDetail[] {
        if (
            representative &&
            representative !== undefined &&
            representative.email !== '' &&
            !representativeCollection.some((c) => c.email === representative.email)
        ) {
            representativeCollection = [...representativeCollection, representative];
        }

        return representativeCollection;
    }
}
