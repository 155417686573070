import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { switchMap, mergeMap, map, catchError, withLatestFrom, filter, tap } from 'rxjs/operators';
import {
    CallOffActionTypes,
    GetCallOffRequest,
    CallOffActionUsersRequestSuccess,
    CallOffUpdateStatusRequestSuccess,
    GetCallOffRequestSuccess,
    CallOffUpdateProperty,
} from '../call-off/actions';
import {
    ContractorRepresentativesRequest,
    CompanyRepresentativesRequest,
    ActionUsersActionTypes,
    ContractorRepresentativesRequestSuccess,
    ContractorRepresentativesRequestError,
    CompanyRepresentativesRequestSuccess,
    CompanyRepresentativesRequestError,
    AddPowerUserToContractorRepresentatives,
    AddPowerUserToCompanyRepresentatives,
    ContractsTeamReviewersRequest,
    ContractsTeamReviewersRequestSuccess,
    ContractsTeamReviewersRequestError,
} from './actions';
import { of, combineLatest } from 'rxjs';
import { Store } from '@ngrx/store';
import { ToastService } from '../../services/shared/toast.service';
import { UserDetail } from '../common.model';
import { ActionUsersService } from '../../services/action-users.service';
import { ApplicationState } from '../model';
import { CallOffStatusType } from 'src/app/models/enums';
import { UserToContractsService } from 'src/app/services/user-to-contracts.service';

@Injectable()
export class ActionUsersEffects {
    constructor(
        private actions$: Actions,
        private actionUsersService: ActionUsersService,
        private store: Store<ApplicationState>,
        private toastService: ToastService,
        private userToContractsService: UserToContractsService
    ) {}

    
    actionUserListRequest$ = createEffect(() => this.actions$.pipe(
        ofType(CallOffActionTypes.GetCallOffRequestSuccess),
        switchMap((action: GetCallOffRequestSuccess) => [
            new ContractorRepresentativesRequest({
                contractNo: action.payload.awe.contractNo,
                reasonId: action.payload.reasonForInstructionId,
            }),
            new CompanyRepresentativesRequest({
                contractNo: action.payload.awe.contractNo,
                reasonId: action.payload.reasonForInstructionId,
            }),
            new ContractsTeamReviewersRequest(action.payload.awe.contractNo),
        ])
    ));

    
    loadContractorRepresentatives$ = createEffect(() => this.actions$.pipe(
        ofType(ActionUsersActionTypes.ContractorRepresentativesRequest),
        mergeMap((action: ContractorRepresentativesRequest) =>
            this.actionUsersService
                .getContractorRepresentatives(action.payload.contractNo, action.payload.reasonId)
                .pipe(
                    map(
                        (contractorRepresentatives: UserDetail[]) =>
                            new ContractorRepresentativesRequestSuccess(contractorRepresentatives)
                    ),
                    catchError((error) => {
                        this.toastService.Error(
                            'Error occurred while getting Contractor Representatives. Please contact Program Administrator.'
                        );
                        return of(new ContractorRepresentativesRequestError(error));
                    })
                )
        )
    ));

    
    loadCompanyRepresentatives$ = createEffect(() => this.actions$.pipe(
        ofType(ActionUsersActionTypes.CompanyRepresentativesRequest),
        mergeMap((action: CompanyRepresentativesRequest) =>
            this.actionUsersService.getCompanyRepresentatives(action.payload.contractNo, action.payload.reasonId).pipe(
                map(
                    (companyRepresentatives: UserDetail[]) =>
                        new CompanyRepresentativesRequestSuccess(companyRepresentatives)
                ),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while getting Company Representatives. Please contact Program Administrator.'
                    );
                    return of(new CompanyRepresentativesRequestError(error));
                })
            )
        )
    ));

    
    loadContractsTeamReviewers$ = createEffect(() => this.actions$.pipe(
        ofType(ActionUsersActionTypes.ContractsTeamReviewersRequest),
        mergeMap((action: ContractsTeamReviewersRequest) =>
            this.userToContractsService.getContractsTeamReviewers(action.payload).pipe(
                map(
                    (contractsTeamReviewers: UserDetail[]) =>
                        new ContractsTeamReviewersRequestSuccess(contractsTeamReviewers)
                ),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while getting Contracts Team Reviewers. Please contact Program Administrator.'
                    );
                    return of(new ContractsTeamReviewersRequestError(error));
                })
            )
        )
    ));

    
    clearCompanyRepresentatives$ = createEffect(() => this.actions$.pipe(
        ofType<CompanyRepresentativesRequestSuccess>(ActionUsersActionTypes.CompanyRepresentativesRequestSuccess),
        withLatestFrom(this.store.select((store) => store.callOffState.form)),
        tap(([action, callOff]) => {
            if (
                action.payload &&
                callOff.companyRepresentative &&
                !(callOff.status === CallOffStatusType.Cancelled || callOff.status === CallOffStatusType.Approved) &&
                !callOff.wasCompanyRepresentativeAdmin &&
                action.payload.findIndex(
                    (u) =>
                        callOff.companyRepresentative &&
                        u.email.toLowerCase() === callOff.companyRepresentative.email.toLowerCase()
                ) === -1
            ) {
                this.store.dispatch(
                    new CallOffUpdateProperty({ key: 'companyRepresentative', value: null, updatedProperties: [] })
                );
            }
        })
    ), { dispatch: false });

    
    clearContractorRepresentatives$ = createEffect(() => this.actions$.pipe(
        ofType<ContractorRepresentativesRequestSuccess>(ActionUsersActionTypes.ContractorRepresentativesRequestSuccess),
        withLatestFrom(this.store.select((store) => store.callOffState.form)),
        tap(([action, callOff]) => {
            if (
                action.payload &&
                callOff.contractorRepresentative &&
                !(callOff.status === CallOffStatusType.Cancelled || callOff.status === CallOffStatusType.Approved) &&
                !callOff.wasContractorRepresentativeAdmin &&
                action.payload.findIndex(
                    (u) =>
                        callOff.contractorRepresentative &&
                        u.email.toLowerCase() === callOff.contractorRepresentative.email.toLowerCase()
                ) === -1
            ) {
                this.store.dispatch(
                    new CallOffUpdateProperty({ key: 'contractorRepresentative', value: null, updatedProperties: [] })
                );
            }
        })
    ), { dispatch: false });

    
    addPowerUserToContractorRepresentativesOnStatusUpdate$ = createEffect(() => this.actions$.pipe(
        ofType<CallOffUpdateStatusRequestSuccess>(CallOffActionTypes.CallOffUpdateStatusRequestSuccess),
        withLatestFrom(this.store.select((store) => store.actionUsersState.contractorRepresentatives)),
        filter(([{ payload }, contractorRepresentatives]) => {
            const filteredContractorRepresentatives = contractorRepresentatives.filter((r) => r !== null);
            return (
                payload.contractorRepresentative &&
                filteredContractorRepresentatives.findIndex(
                    (r) => r.email.toLowerCase() === payload.contractorRepresentative.email.toLowerCase()
                ) === -1
            );
        }),
        map(([{ payload }]) => new AddPowerUserToContractorRepresentatives(payload.contractorRepresentative))
    ));

    
    addPowerUserToContractorRepresentativeOnGetCallOffRequestSuccess$ = createEffect(() => this.actions$.pipe(
        ofType<GetCallOffRequestSuccess>(CallOffActionTypes.GetCallOffRequestSuccess),
        (action) =>
            combineLatest([action, this.store.select((store) => store.actionUsersState.contractorRepresentatives)]),
        filter(([{ payload }, contractorRepresentatives]) => {
            const filteredContractorRepresentatives = contractorRepresentatives.filter((r) => r !== null);
            return (
                payload.contractorRepresentative &&
                filteredContractorRepresentatives &&
                Array.isArray(filteredContractorRepresentatives) &&
                filteredContractorRepresentatives.length > 0 &&
                filteredContractorRepresentatives.findIndex(
                    (r) => r.email.toLowerCase() === payload.contractorRepresentative.email.toLowerCase()
                ) === -1
            );
        }),
        map(([{ payload }]) => new AddPowerUserToContractorRepresentatives(payload.contractorRepresentative))
    ));

    
    addPowerUserToCompanyRepresentativesOnStatusUpdate$ = createEffect(() => this.actions$.pipe(
        ofType<CallOffUpdateStatusRequestSuccess>(CallOffActionTypes.CallOffUpdateStatusRequestSuccess),
        withLatestFrom(this.store.select((store) => store.actionUsersState.companyRepresentatives)),
        filter(([{ payload }, companyRepresentatives]) => {
            return (
                payload.companyRepresentative &&
                companyRepresentatives.findIndex(
                    (r) => r.email.toLowerCase() === payload.companyRepresentative.email.toLowerCase()
                ) === -1
            );
        }),
        map(([{ payload }]) => new AddPowerUserToCompanyRepresentatives(payload.companyRepresentative))
    ));

    
    addPowerUserToCompanyRepresentativesOnGetCallOffRequestSuccess$ = createEffect(() => this.actions$.pipe(
        ofType<GetCallOffRequestSuccess>(CallOffActionTypes.GetCallOffRequestSuccess),
        (action) =>
            combineLatest([action, this.store.select((store) => store.actionUsersState.companyRepresentatives)]),
        filter(([{ payload }, companyRepresentatives]) => {
            return (
                payload.companyRepresentative &&
                companyRepresentatives &&
                Array.isArray(companyRepresentatives) &&
                companyRepresentatives.length > 0 &&
                companyRepresentatives.findIndex(
                    (r) => r.email.toLowerCase() === payload.companyRepresentative.email.toLowerCase()
                ) === -1
            );
        }),
        map(([{ payload }]) => new AddPowerUserToCompanyRepresentatives(payload.companyRepresentative))
    ));
    
    addNewActionUserToCompanyRepresentativesOnActionUsersSuccess$ = createEffect(() => this.actions$.pipe(
        ofType<CallOffActionUsersRequestSuccess>(CallOffActionTypes.CallOffActionUsersRequestSuccess),
        withLatestFrom(this.store.select((store) => store.actionUsersState.companyRepresentatives)),
        filter(([{ payload: actionUsers }, companyRepresentatives]) => {
            return (
                actionUsers.companyRepresentative &&
                companyRepresentatives &&
                Array.isArray(companyRepresentatives) &&
                companyRepresentatives.length > 0 &&
                companyRepresentatives.findIndex(
                    (r) => r.email.toLowerCase() === actionUsers.companyRepresentative.email.toLowerCase()
                ) === -1
            );
        }),
        map(([{ payload: actionUsers }]) => new AddPowerUserToCompanyRepresentatives(actionUsers.companyRepresentative))
    ));

    
    addNewActionUserToContractorRepresentativesOnActionUsersSuccess$ = createEffect(() => this.actions$.pipe(
        ofType<CallOffActionUsersRequestSuccess>(CallOffActionTypes.CallOffActionUsersRequestSuccess),
        withLatestFrom(this.store.select((store) => store.actionUsersState.contractorRepresentatives)),
        filter(([{ payload: actionUsers }, contractorRepresentatives]) => {
            const filteredContractorRepresentatives = contractorRepresentatives.filter((r) => r !== null);
            return (
                actionUsers.contractorRepresentative &&
                filteredContractorRepresentatives &&
                Array.isArray(filteredContractorRepresentatives) &&
                filteredContractorRepresentatives.length > 0 &&
                filteredContractorRepresentatives.findIndex(
                    (r) => r.email.toLowerCase() === actionUsers.contractorRepresentative.email.toLowerCase()
                ) === -1
            );
        }),
        map(
            ([{ payload: actionUsers }]) =>
                new AddPowerUserToContractorRepresentatives(actionUsers.contractorRepresentative)
        )
    ));

    
    addEmptyToContractorRepresentativesOnActionUsersSuccess$ = createEffect(() => this.actions$.pipe(
        ofType<CallOffActionUsersRequestSuccess>(CallOffActionTypes.CallOffActionUsersRequestSuccess),
        withLatestFrom(this.store.select((store) => store.actionUsersState.contractorRepresentatives)),
        filter(([{ payload: actionUsers }, contractorRepresentatives]) => {
            return actionUsers.contractorRepresentative === null && contractorRepresentatives.indexOf(null) === -1;
        }),
        map(
            ([{ payload: actionUsers }]) =>
                new AddPowerUserToContractorRepresentatives(actionUsers.contractorRepresentative)
        )
    ));
}
