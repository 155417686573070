import { Component, OnInit } from '@angular/core';
import { RoleService } from 'src/app/services/shared/role.service';
import { Constants } from 'src/app/constants';
import { BaseComponent } from '../base.component';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { filter } from 'rxjs/operators';
import { EventMessage, EventType } from '@azure/msal-browser';

@Component({
    selector: 'app-top-header',
    templateUrl: './top-header.component.html',
    styleUrls: ['./top-header.component.scss'],
})
export class TopHeaderComponent extends BaseComponent implements OnInit {
    isAdmin = false;
    userName = '';
    emailAddress = '';
    roles: string[] = [];

    constructor(
        private authService: MsalService,
        private roleService: RoleService,
        private broadcastService: MsalBroadcastService
    ) {
        super();
    }

    ngOnInit() {
        this.broadcastService.msalSubject$
            .pipe(
                filter(
                    (msg: EventMessage) =>
                        msg.eventType === EventType.INITIALIZE_END || msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS
                )
            )
            .subscribe((result: EventMessage) => {
                setTimeout(() => {
                    this.isAdmin = this.roleService.isInRole(Constants.applicableGroups.Admin);
                    const account = this.authService.instance.getActiveAccount();
                    if (!!account) {
                        this.userName = account.name;
                        this.emailAddress = account.username;
                        this.roles = this.roleService.userRoleNames;
                    }
                });
            });
    }
}
