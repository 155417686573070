import { Action } from '@ngrx/store';
import { CallOffRegisterDataPagination } from './model';

export enum CallOffRegisterActionTypes {
    CallOffRegisterFilterRequest = '[Call Off Register] Filter Request',
    CallOffRegisterFilterSuccess = '[Call Off Register] Filter Success',
    CallOffRegisterFilterError = '[Call Off Register] Filter Error',
    CallOffRegisterFilterPropertyUpdate = '[Call Off Register] Filter Property Update',
    CallOffRegisterFilterReset = '[Call Off Register] Filter Reset',
    CallOffRegisterExportToExcelRequest = '[CallOffRegister] Export to Excel Request',
    CallOffRegisterExportToExcelSuccess = '[CallOffRegister] Export to Excel Success',
    CallOffRegisterExportToExcelError = '[CallOffRegister] Export to Excel Error',
    CallOffRegisterToggleColumnShowSetting = '[CallOffRegister] Toggle Column Show Setting',
}

export class CallOffRegisterFilterRequest implements Action {
    readonly type = CallOffRegisterActionTypes.CallOffRegisterFilterRequest;
}

export class CallOffRegisterFilterSuccess implements Action {
    readonly type = CallOffRegisterActionTypes.CallOffRegisterFilterSuccess;

    constructor(public payload: CallOffRegisterDataPagination) {}
}

export class CallOffRegisterFilterError implements Action {
    readonly type = CallOffRegisterActionTypes.CallOffRegisterFilterError;

    constructor(public payload: string) {}
}

export class CallOffRegisterFilterPropertyUpdate implements Action {
    readonly type = CallOffRegisterActionTypes.CallOffRegisterFilterPropertyUpdate;

    constructor(public payload: { key: string; value: any }) {}
}

export class CallOffRegisterFilterReset implements Action {
    readonly type = CallOffRegisterActionTypes.CallOffRegisterFilterReset;
}

export class CallOffRegisterExportToExcelRequest implements Action {
    readonly type = CallOffRegisterActionTypes.CallOffRegisterExportToExcelRequest;
}

export class CallOffRegisterExportToExcelSuccess implements Action {
    readonly type = CallOffRegisterActionTypes.CallOffRegisterExportToExcelSuccess;

    constructor(public payload: any) {}
}

export class CallOffRegisterExportToExcelError implements Action {
    readonly type = CallOffRegisterActionTypes.CallOffRegisterExportToExcelError;

    constructor(public payload: string) {}
}

export class CallOffRegisterToggleColumnShowSetting implements Action {
    readonly type = CallOffRegisterActionTypes.CallOffRegisterToggleColumnShowSetting;

    constructor(public columnName: string) {}
}

export type CallOffRegisterActions =
    | CallOffRegisterFilterRequest
    | CallOffRegisterFilterSuccess
    | CallOffRegisterFilterError
    | CallOffRegisterFilterPropertyUpdate
    | CallOffRegisterFilterReset
    | CallOffRegisterExportToExcelRequest
    | CallOffRegisterExportToExcelSuccess
    | CallOffRegisterExportToExcelError
    | CallOffRegisterToggleColumnShowSetting;
