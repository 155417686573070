<div class="call-off-form-workflow-container">
    <div [formGroup]="callOffForm">
        <div class="col-workflow-container filter-container">
            <div class="title">review and approve</div>
            <div>
                <mat-form-field class="container300 margin-right20">
                    <input
                        matInput
                        placeholder="Originator"
                        [value]="
                            callOffForm.controls['originator'].value && callOffForm.controls['originator'].value.name
                        "
                        readonly
                    />
                </mat-form-field>
                <mat-form-field class="container300 margin-right20">
                    <input
                        matInput
                        placeholder="Position"
                        [value]="
                            callOffForm.controls['originator'].value &&
                            callOffForm.controls['originator'].value.position
                        "
                        readonly
                    />
                </mat-form-field>
                <mat-form-field class="container300 margin-right20">
                    <input
                        matInput
                        placeholder="Signature"
                        [value]="
                            callOffForm.controls['routeDate'].value &&
                            callOffForm.controls['originator'].value &&
                            'Approved Electronically' +
                                (callOffForm.controls['originator'].value.cai
                                    ? ' (' + callOffForm.controls['originator'].value.cai + ')'
                                    : '')
                        "
                        readonly
                    />
                </mat-form-field>
                <mat-form-field class="container300">
                    <input
                        matInput
                        placeholder="Date"
                        [value]="callOffForm.controls['routeDate'].value | date: 'd/MMM/yy'"
                        readonly
                    />
                </mat-form-field>
            </div>
            <div>
                <mat-form-field class="container300 margin-right20">
                    <mat-label>Contracts Team Reviewer</mat-label>
                    <mat-select [compareWith]="compareUsers" formControlName="contractsTeamReviewer" required>
                        <mat-option
                            *ngFor="let contractsTeamReviewer of contractsTeamReviewers"
                            [value]="contractsTeamReviewer"
                        >
                            {{ contractsTeamReviewer !== null ? contractsTeamReviewer.name : '' }}
                        </mat-option>
                    </mat-select>
                    <mat-error
                        *ngIf="
                            callOffForm.controls['contractsTeamReviewer'].invalid &&
                            callOffForm.controls['contractsTeamReviewer'].errors.required
                        "
                    >
                        Contracts Team Reviewer is required.
                    </mat-error>
                </mat-form-field>
                <mat-form-field class="container300 margin-right20">
                    <input
                        matInput
                        placeholder="Position"
                        [value]="
                            (callOffForm.controls['contractsTeamReviewer'] &&
                                callOffForm.controls['contractsTeamReviewer'].value &&
                                callOffForm.controls['contractsTeamReviewer'].value.position) ||
                            ''
                        "
                        readonly
                    />
                </mat-form-field>
                <mat-form-field class="container300 margin-right20">
                    <input
                        matInput
                        placeholder="Signature"
                        [value]="
                            callOffForm.controls['contractsTeamReviewDate'].value &&
                            callOffForm.controls['contractsTeamReviewer'] &&
                            callOffForm.controls['contractsTeamReviewer'].value &&
                            'Approved Electronically' +
                                (callOffForm.controls['contractsTeamReviewer'].value.cai
                                    ? ' (' + callOffForm.controls['contractsTeamReviewer'].value.cai + ')'
                                    : '')
                        "
                        readonly
                    />
                </mat-form-field>
                <mat-form-field class="container300">
                    <input
                        matInput
                        placeholder="Date"
                        [value]="callOffForm.controls['contractsTeamReviewDate'].value | date: 'd/MMM/yy'"
                        readonly
                    />
                </mat-form-field>
            </div>
            <div>
                <mat-form-field class="container300 margin-right20">
                    <div
                        class="tooltip-icon"
                        matTooltip="1) Construction review required for 'Hold Placement' and 'VCNi/Vendor COW' instructions categories
                    (refer to 'Reason for Instruction' Section 8.2.5). &#13; 2) Engineering review required for 'Field Change' instruction category to ensure that they are within
                    the Fabrication Technical Authority Matrix and redline - markups are captured in the fabrication
                    site master redlines."
                        [matTooltipClass]="'cofftooltip'"
                    ></div>
                    <mat-label>Contractor Representative</mat-label>
                    <mat-select [compareWith]="compareUsers" formControlName="contractorRepresentative" required>
                        <mat-option
                            *ngFor="let contractorRepresentative of contractorRepresentatives"
                            [value]="contractorRepresentative"
                        >
                            {{ contractorRepresentative !== null ? contractorRepresentative.name : '' }}
                        </mat-option>
                    </mat-select>
                    <mat-error
                        *ngIf="
                            callOffForm.controls['contractorRepresentative'].invalid &&
                            callOffForm.controls['contractorRepresentative'].errors.required
                        "
                    >
                        Contractor Representative is required.
                    </mat-error>
                </mat-form-field>
                <mat-form-field class="container300 margin-right20">
                    <input
                        matInput
                        placeholder="Position"
                        [value]="
                            (callOffForm.controls['contractorRepresentative'] &&
                                callOffForm.controls['contractorRepresentative'].value &&
                                callOffForm.controls['contractorRepresentative'].value.position) ||
                            ''
                        "
                        readonly
                    />
                </mat-form-field>

                <mat-form-field class="container300 margin-right20">
                    <input
                        matInput
                        placeholder="Signature"
                        [value]="
                            callOffForm.controls['contractorApproveDate'].value &&
                            callOffForm.controls['contractorRepresentative'] &&
                            callOffForm.controls['contractorRepresentative'].value &&
                            'Approved Electronically' +
                                (callOffForm.controls['contractorRepresentative'].value.cai
                                    ? ' (' + callOffForm.controls['contractorRepresentative'].value.cai + ')'
                                    : '')
                        "
                        readonly
                    />
                </mat-form-field>
                <mat-form-field class="container300">
                    <input
                        matInput
                        placeholder="Date"
                        [value]="callOffForm.controls['contractorApproveDate'].value | date: 'd/MMM/yy'"
                        readonly
                    />
                </mat-form-field>
            </div>
            <div>
                <mat-form-field class="container300 margin-right20">
                    <mat-label>Company Representative</mat-label>
                    <mat-select [compareWith]="compareUsers" formControlName="companyRepresentative" required>
                        <mat-option
                            *ngFor="let companyRepresentative of companyRepresentatives"
                            [value]="companyRepresentative"
                        >
                            {{ companyRepresentative !== null ? companyRepresentative.name : '' }}
                        </mat-option>
                    </mat-select>
                    <mat-error
                        *ngIf="
                            callOffForm.controls['companyRepresentative'].invalid &&
                            callOffForm.controls['companyRepresentative'].errors.required
                        "
                    >
                        Company Representative is requried.
                    </mat-error>
                </mat-form-field>
                <mat-form-field class="container300 margin-right20">
                    <input
                        matInput
                        placeholder="Position"
                        [value]="
                            callOffForm.controls['companyRepresentative'] &&
                            callOffForm.controls['companyRepresentative'].value &&
                            callOffForm.controls['companyRepresentative'].value.position
                        "
                        readonly
                    />
                </mat-form-field>

                <mat-form-field class="container300 margin-right20">
                    <input
                        matInput
                        placeholder="Signature"
                        [value]="
                            callOffForm.controls['companyApproveDate'].value &&
                            callOffForm.controls['companyRepresentative'] &&
                            callOffForm.controls['companyRepresentative'].value &&
                            'Approved Electronically' +
                                (callOffForm.controls['companyRepresentative'].value.cai
                                    ? ' (' + callOffForm.controls['companyRepresentative'].value.cai + ')'
                                    : '')
                        "
                        readonly
                    />
                </mat-form-field>
                <mat-form-field class="container300">
                    <input
                        matInput
                        placeholder="Date"
                        [value]="callOffForm.controls['companyApproveDate'].value | date: 'd/MMM/yy'"
                        readonly
                    />
                </mat-form-field>
            </div>
        </div>
    </div>
</div>
