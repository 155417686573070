export enum CallOffAttachmentType {
    SupportingDocument = 1,
    Estimate = 2,
    COFCompleted = 3,
    COFCompanyCompleted = 4,
}

export class CallOffAttachments {
    callOffId: number;
    attachments: Attachment[];
}

export class Attachment {
    id: number;
    name = '';
    link = '';
    documentNo = '';
    loading: boolean;
    callOffAttachmentType: CallOffAttachmentType = null;
    callOffId: number;
    file: File;
    isValid: boolean;
    isBeingProcessed: boolean;
}
