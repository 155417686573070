import { Component, OnInit, ViewChild, Inject, NgZone } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { BaseComponent } from 'src/app/components/base.component';
import { RevisionDTO } from 'src/app/models/revision-dto';

@Component({
    selector: 'app-cof-revisions-popup',
    templateUrl: './cof-revisions-popup.component.html',
    styleUrls: ['./cof-revisions-popup.component.scss'],
})
export class CofRevisionsPopupComponent extends BaseComponent implements OnInit {
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;

    revisions: RevisionDTO[] = [];
    displayedColumns: string[] = ['revision', 'raiseDate', 'approveDate'];
    resultsLength = 0;
    pageSize = 5;
    sortBy = 'revision';
    direction = 'desc';
    dataSource = new MatTableDataSource<RevisionDTO>();
    isLoading = false;
    constructor(
        public dialogRef: MatDialogRef<CofRevisionsPopupComponent>,
        @Inject(MAT_DIALOG_DATA) data,
        private router: Router,
        private ngZone: NgZone
    ) {
        super();
        this.revisions = data.revisions;
    }

    ngOnInit() {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.dataSource.data = this.revisions;
        this.resultsLength = this.revisions.length;
    }

    public onCancel(): void {
        this.dialogRef.close();
    }

    openRevision(id: number) {
        this.dialogRef.close();
        this.ngZone.run(() => this.router.navigate([`details/${id}`]));
    }
}
