import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { takeWhile } from 'rxjs/operators';
import { BaseComponent } from 'src/app/components/base.component';
import { CallOffService } from 'src/app/services/call-off.service';
import { LookupService } from 'src/app/services/lookup.service';
import { ToastService } from 'src/app/services/shared/toast.service';
import { ShareEmail } from 'src/app/store/call-off/model';
import { UserDetail } from 'src/app/store/common.model';

@Component({
    selector: 'app-cof-share-email-popup',
    templateUrl: './cof-share-email-popup.component.html',
    styleUrls: ['./cof-share-email-popup.component.scss'],
})
export class CofShareEmailPopupComponent extends BaseComponent {
    number: string;
    subject: string;
    id: number;
    emailForm: FormGroup;
    loading = false;
    mentionConfig = {
        labelKey: 'name',
        triggerChar: '@',
        maxItems: 10,
        mentionSelect: (item: UserDetail) => {
            this.emailForm.controls.email.setValue('');
            return item.email;
        },
    };
    users: UserDetail[] = [];

    constructor(
        public dialogRef: MatDialogRef<CofShareEmailPopupComponent>,
        @Inject(MAT_DIALOG_DATA) data,
        private callOffService: CallOffService,
        private toastService: ToastService,
        private lookupSvc: LookupService
    ) {
        super();
        this.number = data.number;
        this.subject = data.subject;
        this.id = data.id;
        this.emailForm = new FormGroup({
            email: new FormControl('', [Validators.required, Validators.email]),
        });
    }

    ngOnInit(): void {
        this.lookupSvc
            .searchUsers('', 0, 1000)
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((users) => (this.users = users));
    }

    public onCancel(): void {
        this.dialogRef.close();
    }

    sendEmail() {
        this.loading = true;
        var shareDto = new ShareEmail();
        shareDto.number = this.number;
        shareDto.subject = this.subject;
        shareDto.recipient = this.emailForm.controls.email.value;
        shareDto.id = this.id;
        this.callOffService
            .shareEmail(shareDto)
            .pipe(takeWhile(() => this.isAlive))
            .subscribe(
                () => {
                    this.dialogRef.close();
                    this.loading = false;
                    this.toastService.Success(`Call-Off has been shared.`);
                },
                (error) => {
                    this.loading = false;
                    this.toastService.Error(
                        `Error occurred while sharing Call-Off. Please contact Program Administrator.`
                    );
                }
            );
    }
}
