<main class="main">
    <form>
        <span class="close" (click)="close()">&times;</span>
        <p>enter comment</p>
        <textarea
            class="text-area"
            [(ngModel)]="comment"
            [ngModelOptions]="{ standalone: true }"
            placeholder="comment"
        ></textarea>
        <div class="buttons-container flex center">
            <button type="button" (click)="close()" class="cof-button cof-button-white">cancel</button>
            <button type="button" (click)="onSubmit()" class="cof-button cof-button-light ok-button">ok</button>
        </div>
    </form>
</main>
