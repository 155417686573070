<div class="popup">
    <span class="close" (click)="onCancel()">&times;</span>
    <p mat-dialog-title class="title">
        <span class="font-gotham-narrow-bold">create new Call-Off</span>
    </p>
    <div>
        <form [formGroup]="callOffForm">
            <mat-form-field>
                <mat-label>Call-off Subject</mat-label>
                <input matInput placeholder="Call-off Subject" formControlName="subject" required minlength="5" />
                <mat-error
                    *ngIf="callOffForm.controls['subject'].invalid && callOffForm.controls['subject'].errors.required"
                >
                    Call-off Subject is required.
                </mat-error>
                <mat-error
                    *ngIf="
                        (callOffForm.controls['subject'].dirty || callOffForm.controls['subject'].touched) &&
                        callOffForm.controls['subject'].invalid &&
                        callOffForm.controls['subject'].errors.minlength
                    "
                >
                    Subject need to be 5 or more characters length.
                </mat-error>
            </mat-form-field>
            <mat-form-field class="margin-top20">
                <mat-label>AWE No</mat-label>
                <mat-select matNativeControl formControlName="aweNumber" required #aweSelect>
                    <mat-option>
                        <ngx-mat-select-search
                            formControlName="filteredAWEsControl"
                            placeholderLabel="Search..."
                            noEntriesFoundLabel="No AWEs found"
                        ></ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let awe of filteredAWEs | async" [value]="awe.number">{{
                        awe.number
                    }}</mat-option>
                </mat-select>
                <mat-icon matSuffix (click)="openAWESearchPopup($event)">search</mat-icon>
                <mat-error
                    *ngIf="
                        callOffForm.controls['aweNumber'].invalid && callOffForm.controls['aweNumber'].errors.required
                    "
                >
                    AWE No is required.
                </mat-error>
            </mat-form-field>

            <mat-form-field class="margin-top20">
                <mat-label>Area</mat-label>
                <mat-select matNativeControl formControlName="areaId" required>
                    <mat-option *ngFor="let area of areas" [value]="area.id">{{ area.name }}</mat-option>
                </mat-select>
                <mat-error
                    *ngIf="callOffForm.controls['areaId'].invalid && callOffForm.controls['areaId'].errors.required"
                >
                    Area is required.
                </mat-error>
            </mat-form-field>

            <mat-form-field class="margin-top20">
                <mat-label>Discipline</mat-label>
                <mat-select matNativeControl formControlName="discipline" required>
                    <mat-option [value]="discipline" *ngFor="let discipline of disciplines"
                        >{{ discipline }}
                    </mat-option>
                </mat-select>
                <mat-error
                    *ngIf="
                        callOffForm.controls['discipline'].invalid && callOffForm.controls['discipline'].errors.required
                    "
                >
                    Discipline is required.
                </mat-error>
            </mat-form-field>

            <mat-form-field class="margin-top20">
                <mat-label>Reason For Instruction</mat-label>
                <mat-select matNativeControl formControlName="reasonForInstructionId" required>
                    <mat-option *ngFor="let reason of reasons" [value]="reason.id">{{ reason.name }}</mat-option>
                </mat-select>
                <mat-error
                    *ngIf="
                        callOffForm.controls['reasonForInstructionId'].invalid &&
                        callOffForm.controls['reasonForInstructionId'].errors.required
                    "
                >
                    Reason is required.
                </mat-error>
            </mat-form-field>

            <div class="row">
                <button type="button" (click)="onCancel()" class="cof-button cof-button-white margin-right20">
                    cancel
                </button>
                <button
                    type="button"
                    (click)="onConfirm()"
                    class="cof-button cof-button-light"
                    [disabled]="!callOffForm.valid"
                >
                    submit
                </button>
            </div>
            <app-loading-indicator *ngIf="loading"> </app-loading-indicator>
        </form>
    </div>
</div>
