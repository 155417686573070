import { Action } from '@ngrx/store';
import {
    ConstructionApproverMatrixDTO,
    ConstructionApproverMatrixResultData,
} from './models';

export enum ConstructionApproverMatrixActionTypes {
    DataRequest = '[Construction Approver Matrix] Data Request',
    DataRequestSuccess = '[Construction Approver Matrix] Data Request Success',
    DataRequestError = '[Construction Approver Matrix] Data Request Error',

    CreateRequest = '[Construction Approver Matrix] Create Request',
    CreateRequestSuccess = '[Construction Approver Matrix] Create Request Success',
    CreateRequestError = '[Construction Approver Matrix] Create Request Error',

    DeleteRequest = '[Construction Approver Matrix] Delete Request',
    DeleteRequestSuccess = '[Construction Approver Matrix] Delete Request Success',
    DeleteRequestError = '[Construction Approver Matrix] Delete Request Error',

    UpdateRequest = '[Construction Approver Matrix] Update Request',
    UpdateRequestSuccess = '[Construction Approver Matrix] Update Request Success',
    UpdateRequestError = '[Construction Approver Matrix] Update Request Error',

    FilterPropertyUpdate = '[Construction Approver Matrix] Filter Property Update',
}

export class ConstructionApproverMatrixDataRequest implements Action {
    readonly type = ConstructionApproverMatrixActionTypes.DataRequest;
}

export class ConstructionApproverMatrixDataRequestSuccess implements Action {
    readonly type = ConstructionApproverMatrixActionTypes.DataRequestSuccess;

    constructor(public payload: ConstructionApproverMatrixResultData) {}
}

export class ConstructionApproverMatrixDataRequestError implements Action {
    readonly type = ConstructionApproverMatrixActionTypes.DataRequestError;

    constructor(public payload: string) {}
}

export class ConstructionApproverMatrixDeleteRequest implements Action {
    readonly type = ConstructionApproverMatrixActionTypes.DeleteRequest;

    constructor(public payload: number) {}
}

export class ConstructionApproverMatrixDeleteRequestSuccess implements Action {
    readonly type = ConstructionApproverMatrixActionTypes.DeleteRequestSuccess;
}

export class ConstructionApproverMatrixDeleteRequestError implements Action {
    readonly type = ConstructionApproverMatrixActionTypes.DeleteRequestError;

    constructor(public payload: string) {}
}

export class ConstructionApproverMatrixCreateRequest implements Action {
    readonly type = ConstructionApproverMatrixActionTypes.CreateRequest;

    constructor(public payload: ConstructionApproverMatrixDTO) {}
}

export class ConstructionApproverMatrixCreateRequestSuccess implements Action {
    readonly type = ConstructionApproverMatrixActionTypes.CreateRequestSuccess;

}

export class ConstructionApproverMatrixCreateRequestError implements Action {
    readonly type = ConstructionApproverMatrixActionTypes.CreateRequestError;

    constructor(public payload: string) {}
}

export class ConstructionApproverMatrixUpdateRequest implements Action {
    readonly type = ConstructionApproverMatrixActionTypes.UpdateRequest;

    constructor(public payload: ConstructionApproverMatrixDTO) {}
}

export class ConstructionApproverMatrixUpdateRequestSuccess implements Action {
    readonly type = ConstructionApproverMatrixActionTypes.UpdateRequestSuccess;

    constructor(public payload: ConstructionApproverMatrixDTO) {}
}

export class ConstructionApproverMatrixUpdateRequestError implements Action {
    readonly type = ConstructionApproverMatrixActionTypes.UpdateRequestError;

    constructor(public payload: string) {}
}

export class ConstructionApproverMatrixFilterPropertyUpdate implements Action {
    readonly type = ConstructionApproverMatrixActionTypes.FilterPropertyUpdate;

    constructor(public payload: { key: string; value: any;}) {}
}

export type ConstructionApproverMatrixActions =
    | ConstructionApproverMatrixDataRequest
    | ConstructionApproverMatrixDataRequestSuccess
    | ConstructionApproverMatrixDataRequestError
    | ConstructionApproverMatrixDeleteRequest
    | ConstructionApproverMatrixDeleteRequestSuccess
    | ConstructionApproverMatrixDeleteRequestError
    | ConstructionApproverMatrixCreateRequest
    | ConstructionApproverMatrixCreateRequestSuccess
    | ConstructionApproverMatrixCreateRequestError
    | ConstructionApproverMatrixUpdateRequest
    | ConstructionApproverMatrixUpdateRequestSuccess
    | ConstructionApproverMatrixUpdateRequestError
    | ConstructionApproverMatrixFilterPropertyUpdate;
