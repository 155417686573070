import { Component, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { takeWhile, tap } from 'rxjs/operators';
import { ConfigurationService } from '../../../../services/configuration.service';
import { ToastService } from '../../../../services/shared/toast.service';
import { BaseComponent } from '../../../base.component';
import { OrderDirection } from '../../../../store/common/model';
import { Setting } from '../../../../models/setting';

@Component({
    selector: 'app-settings',
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent extends BaseComponent implements OnInit {
    displayedColumns = ['key', 'value', 'edit'];
    settings: Setting[];
    isLoading = false;
    sortBy: string = 'key';
    direction: OrderDirection.Desc;

    constructor(
        private configurationService: ConfigurationService,
        private toastService: ToastService,
        private iconRegistry: MatIconRegistry,
        private sanitizer: DomSanitizer
    ) {
        super();
        this.iconRegistry.addSvgIcon(
            'edit',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/edit.svg')
        );
    }

    ngOnInit() {
        this.isLoading = true;
        this.configurationService
            .getConfigData()
            .pipe(
                takeWhile(() => this.isAlive),
                tap(() => (this.isLoading = true))
            )
            .subscribe(
                (settings) => {
                    this.settings = settings;
                    this.isLoading = false;
                },
                () => {
                    this.isLoading = false;
                    this.toastService.Error(
                        'An error occured while loading configuration. Please contact Program Administrator.'
                    );
                }
            );
    }

    updateConfigData() {
        this.isLoading = true;
        this.configurationService
            .updateConfigData(this.settings)
            .pipe(
                takeWhile(() => this.isAlive),
                tap(() => (this.isLoading = true))
            )
            .subscribe(
                () => {
                    this.isLoading = false;
                    this.toastService.Success('Configuration has been updated.');
                },
                () => {
                    this.isLoading = false;
                    this.toastService.Error(
                        'An error occured while updating configuration. Configuration has not been updated.'
                    );
                }
            );
    }

    enterEditMode(config: Setting) {
        config.isInEditMode = !config.isInEditMode;
        config.prevValue = config.value;
    }

    save(setting: Setting) {
        setting.isInEditMode = !setting.isInEditMode;

        if (setting.value !== setting.prevValue) {
            this.updateConfigData();
        }
    }

    cancelEdit(setting: Setting) {
        setting.isInEditMode = !setting.isInEditMode;
        setting.value = setting.prevValue;
    }

    checkIfBoolean(value: string) {
        if (value.toLowerCase() === 'true' || value.toLowerCase() === 'false') {
            return true;
        }
        return false;
    }

    changeBooleanValue(element: Setting) {
        if (element.value.toLowerCase() === 'true') {
            element.value = 'false';
        } else {
            element.value = 'true';
        }
    }

    getBoolean(value: string) {
        switch (value.toLowerCase()) {
            case 'true':
                return true;
            default:
                return false;
        }
    }
}
