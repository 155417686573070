import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../base.component';

@Component({
    selector: 'app-groups-maintenance',
    templateUrl: './groups-maintenance.component.html',
    styleUrls: ['./groups-maintenance.component.scss'],
})
export class GroupsMaintenanceComponent extends BaseComponent implements OnInit {
    navLinks: { label: string; link: string; index: number }[] = [];

    constructor() {
        super();
        this.navLinks = [
            {
                label: 'approval matrix',
                link: './approval-matrix',
                index: 0,
            },
            {
                label: 'user to contracts',
                link: './user-to-contracts',
                index: 1,
            },
            {
                label: 'construction approver matrix',
                link: './construction-approver-matrix',
                index: 2,
            },
        ];
    }

    ngOnInit(): void {}
}
