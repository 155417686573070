import { createEffect, Actions, ofType } from '@ngrx/effects';
import { map, mergeMap, catchError, withLatestFrom, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ToastService } from '../../services/shared/toast.service';
import {
    AweRegisterActionTypes,
    AweRegisterFilterError,
    AweRegisterFilterSuccess,
    AweDeleteRequest,
    AweDeleteSuccess,
    AweDeleteError,
    AweRegisterFilterRequest,
    AweRegisterExportToExcelSuccess,
} from './actions';
import { AWEService } from '../../services/awe.service';
import { ApplicationState } from '../model';
import moment from 'moment';

@Injectable()
export class AweRegisterEffects {
    constructor(
        private actions$: Actions,
        private store: Store<ApplicationState>,
        private toastService: ToastService,
        private aweService: AWEService
    ) {}

    
    filterResults$ = createEffect(() => this.actions$.pipe(
        ofType(AweRegisterActionTypes.AweRegisterFilterRequest),
        withLatestFrom(this.store.select((state) => state.aweRegisterState.filter)),
        mergeMap(([, aweRegisterFilter]) => {
            return this.aweService.getAweRegisterData(aweRegisterFilter).pipe(
                map((dataPagination) => {
                    return new AweRegisterFilterSuccess(dataPagination);
                }),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while filtering awe summary page. Please contact Program Administrator.'
                    );
                    return of(new AweRegisterFilterError(error));
                })
            );
        })
    ));

    
    deleteAwe$ = createEffect(() => this.actions$.pipe(
        ofType(AweRegisterActionTypes.AweDeleteRequest),
        mergeMap((action: AweDeleteRequest) =>
            this.aweService.deleteAwe(action.payload).pipe(
                switchMap(() => [new AweDeleteSuccess(action.payload), new AweRegisterFilterRequest()]),
                catchError((error) => {
                    if (error.error.indexOf('There are Call-Offs against this AWE.') > -1) {
                        this.toastService.Error('There are Call-Offs against this AWE. Unable to delete AWE.');
                        return of(new AweDeleteError(error));
                    }
                    this.toastService.Error('Error occurred while removing AWE. Please contact Program Administrator.');
                    return of(new AweDeleteError(error));
                })
            )
        )
    ));

    
    exportToExcel$ = createEffect(() => this.actions$.pipe(
        ofType(AweRegisterActionTypes.AweRegisterExportToExcelRequest),
        withLatestFrom(this.store.select((store) => store.aweRegisterState.filter)),
        mergeMap(([, aweRegisterFilter]) => {
            let filter = Object.assign({}, aweRegisterFilter);
            filter.timezoneOffset = new Date().getTimezoneOffset();
            return this.aweService.generateExcel(filter).pipe(
                map((excelData) => new AweRegisterExportToExcelSuccess(excelData)),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while exporting data to Excel. Please contact Program Administrator.'
                    );
                    return of(new AweRegisterExportToExcelSuccess(error));
                })
            );
        })
    ));

    
    saveExcelData = createEffect(() => this.actions$.pipe(
        ofType(AweRegisterActionTypes.AweRegisterExportToExcelSuccess),
        map((action: AweRegisterExportToExcelSuccess) => {
            this.toastService.Success('Data successfully exported to Excel.');

            const blob = new Blob([action.payload], {
                type: 'application/octet-stream',
            });

            saveAs(blob, `AWE_${moment().format('YYYYMMDD_HHmmss')}` + '.xlsx');
        })
    ), { dispatch: false });
}
