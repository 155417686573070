import { Component, EventEmitter, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ToastService } from 'src/app/services/shared/toast.service';
import { BaseComponent } from 'src/app/components/base.component';
import { ApprovalMatrixService } from 'src/app/services/approval-matrix.service';
import { Contractor, UserWithRoleDetail, AppRole } from 'src/app/store/common.model';
import { takeWhile } from 'rxjs/operators';
import { ApprovalMatrixDTO } from 'src/app/models/approval-matrix-dto';
import { ReasonForInstruction } from 'src/app/store/common/model';
import { SetInputEventArgs } from 'src/app/models/set-input';
import { of } from 'rxjs';

@Component({
    selector: 'app-add-approval-matrix-popup',
    templateUrl: './add-approval-matrix-popup.component.html',
    styleUrls: ['./add-approval-matrix-popup.component.scss'],
})
export class AddApprovalMatrixPopupComponent extends BaseComponent {
    loading: boolean;
    addApprovalMatrixForm: FormGroup;
    contractors: Contractor[] = [];
    reasons: ReasonForInstruction[] = [];
    users: UserWithRoleDetail[] = [];
    roleCurrent: AppRole;
    types = [];
    setUserInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    autocompleteDisplayedColumns = ['name', 'email'];

    constructor(
        public dialogRef: MatDialogRef<AddApprovalMatrixPopupComponent>,
        @Inject(MAT_DIALOG_DATA) data,
        private toastService: ToastService,
        private groupsService: ApprovalMatrixService
    ) {
        super();
        this.contractors = data.contractors;
        this.reasons = data.reasons;
        this.users = data.users;
        this.types = data.types;

        this.addApprovalMatrixForm = new FormGroup({
            approverType: new FormControl('', Validators.required),
            reasonForInstructions: new FormControl('', Validators.required),
            contractNo: new FormControl('', Validators.required),
            approver: new FormControl('', Validators.required),
        });
    }

    public onCancel(): void {
        this.dialogRef.close();
    }

    public onConfirm(): void {
        this.loading = true;
        const form = new ApprovalMatrixDTO();

        form.contractNo = this.addApprovalMatrixForm.controls.contractNo.value;
        form.reasonForInstructions = this.addApprovalMatrixForm.controls.reasonForInstructions.value;
        form.approverType = this.addApprovalMatrixForm.controls.approverType.value;
        form.approver = this.addApprovalMatrixForm.controls.approver.value[0];

        this.groupsService
            .add(form)
            .pipe(takeWhile(() => this.isAlive === true))
            .subscribe(
                (data) => {
                    this.loading = false;
                    this.toastService.Success(`${form.approver.name} has been added to the approval matrix.`);
                    this.dialogRef.close({ success: true, data: data });
                },
                (error) => {
                    this.loading = false;
                    if (error.status === 409) {
                        this.toastService.Error(
                            `${this.findRole(this.types, form.approverType)} ${form.approver.name} with Contract ${
                                form.contractNo
                            } already exists in the approval matrix.`
                        );
                    } else {
                        this.toastService.Error(
                            'An error occurred while adding an user to the approval matrix. Please contact a Program Administrator.'
                        );
                    }
                }
            );
    }

    onRoleClosed(isOpen: boolean) {
        if (!isOpen && this.roleCurrent !== this.addApprovalMatrixForm.controls.approverType.value) {
            this.roleCurrent = this.addApprovalMatrixForm.controls.approverType.value;
            this.addApprovalMatrixForm.controls['approver'].setValue('');
            this.setUserInput.emit(new SetInputEventArgs(false, ''));
        }
    }

    getUsers = (search = '', take = 10, page = 0) => {
        let elements = this.users.filter(
            (s) => s.role === this.roleCurrent && s.name.toLowerCase().indexOf(search.toLowerCase()) > -1
        );
        if (elements.length) {
            return of(elements.slice(page * take, page * take + take));
        } else {
            return of([]);
        }
    };

    onUsersClosed() {
        this.setUserInput.emit(new SetInputEventArgs(true));
    }
}
