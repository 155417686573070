import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { FormService } from 'src/app/services/shared/form.service';
import { AWE } from 'src/app/store/awe-form/model';
import { Observable, Subject } from 'rxjs';
import { ApplicationState } from 'src/app/store/model';
import { Store } from '@ngrx/store';
import { BaseComponent } from '../../base.component';
import { takeWhile, tap } from 'rxjs/operators';
import { CallOffLookupService } from 'src/app/services/call-off-lookup.service';
import { MatSelect } from '@angular/material/select';
import { CallOffStatusType } from 'src/app/models/enums';
import { Constants } from 'src/app/constants';
import {
    CompanyRepresentativesRequest,
    ContractorRepresentativesRequest,
    ContractsTeamReviewersRequest,
} from 'src/app/store/action-users/actions';
import { CurrencyPipe } from '@angular/common';
import { LimitExceeded } from 'src/app/validators/call-off-form-nte-validator';
import { AWEService } from 'src/app/services/awe.service';

@Component({
    selector: 'app-cof-top-section',
    templateUrl: './cof-top-section.component.html',
    styleUrls: ['./cof-top-section.component.scss'],
})
export class CofTopSectionComponent extends BaseComponent implements OnInit {
    @Input() callOffForm: FormGroup;
    @ViewChild('aweSelect', { static: true }) aweSelect: MatSelect;

    isLoading = false;
    awes: AWE[] = [];
    //current remaining value - in status > Draft = AWE Remaining + COF NTE
    remaining: number;
    aweRemaining: number;
    statusType = CallOffStatusType;
    filteredAwes: Subject<AWE[]> = new Subject<AWE[]>();
    public filteredAwesCtrl: FormControl = new FormControl();
    aweForm: FormGroup;
    awe$: Observable<AWE> = this.store.select((state) => state.callOffState.form.awe);
    status$ = this.store.select((state) => state.callOffState.form.status);
    isLocked$ = this.store.select((state) => state.callOffState.isLocked);
    contractorCurrencies$ = this.store.select((state) => state.callOffState.form.contractorCurrencies);
    contractorCurrencies = {};
    anyContractorCurrency = false;

    constructor(
        private formService: FormService,
        private store: Store<ApplicationState>,
        private callOffLookupService: CallOffLookupService,
        private aweService: AWEService
    ) {
        super();
        this.aweForm = this.formService.createSimpleForm(new AWE());
        this.aweForm.addControl('filteredAwesCtrl', this.filteredAwesCtrl);
    }

    ngOnInit(): void {
        this.isLocked$.pipe(takeWhile(() => this.isAlive)).subscribe((isLocked) => {
            if (isLocked) {
                if (this.aweForm.enabled) {
                    this.aweForm.disable({ emitEvent: false });
                }
            } else {
                if (this.aweForm.disabled) {
                    this.aweForm.enable({ emitEvent: false });
                }
            }
        });
        this.awe$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => {
            if (data && data.id !== null) {
                this.aweForm.patchValue({ ...data });
                this.aweRemaining = data.nteRemaining;
                this.getNteRemaining(data.id);
            }
        });

        this.status$.pipe(takeWhile(() => this.isAlive)).subscribe((status) => {
            if (status === CallOffStatusType.Draft) {
                this.callOffLookupService
                    .getAwes()
                    .pipe(
                        takeWhile(() => this.isAlive),
                        tap(() => (this.isLoading = true))
                    )
                    .subscribe((data) => {
                        this.awes = data;
                        this.filteredAwes.next(this.awes.slice());
                        this.isLoading = false;
                    });
            }
            if (this.aweForm.controls.id.value !== null) {
                this.aweService
                    .getAweById(this.aweForm.controls.id.value)
                    .pipe(takeWhile(() => this.isAlive))
                    .subscribe((awe) => {
                        this.aweRemaining = awe.nteRemaining;
                    });
            }
        });

        this.filteredAwesCtrl.valueChanges.pipe(takeWhile(() => this.isAlive)).subscribe(() => {
            this.filterAwes();
        });

        this.contractorCurrencies$.pipe(takeWhile(() => this.isAlive)).subscribe((currencies) => {
            this.contractorCurrencies = currencies
                ? currencies
                      .split(',')
                      .map((c) => c.trim())
                      .reduce((a, x) => ({ ...a, [x]: true }), {})
                : {};
            this.anyContractorCurrency = Object.keys(this.contractorCurrencies).length > 0;
        });
    }

    aweSelected(awe: AWE) {
        if (awe.contractNo !== this.aweForm.controls.contractNo.value) {
            this.callOffForm.patchValue({
                usd: 0,
                kzt: 0,
                rub: 0,
                eur: 0,
                nteUsd: 0
            });
        }
        this.aweForm.patchValue({ ...awe });
        this.callOffForm.controls['aweId'].setValue(awe.id);
        this.callOffForm.controls['awe'].patchValue({ ...awe });
        this.getNteRemaining(awe.id);
        this.getContractorLimit(awe.contractNo);

        this.store.dispatch(
            new CompanyRepresentativesRequest({
                contractNo: awe.contractNo,
                reasonId: this.callOffForm.controls['reasonForInstructionId'].value,
            })
        );
        this.store.dispatch(
            new ContractorRepresentativesRequest({
                contractNo: awe.contractNo,
                reasonId: this.callOffForm.controls['reasonForInstructionId'].value,
            })
        );
        this.store.dispatch(new ContractsTeamReviewersRequest(awe.contractNo));
    }

    getContractorLimit(contractNo: string) {
        this.callOffLookupService
            .getContractorLimitByContractNo(contractNo)
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((x) => {
                if (x) {
                    this.callOffForm.controls['contractorLimit'].setValue(x.limit);
                    this.callOffForm.controls['contractorCurrencies'].setValue(x.currencies);
                    this.callOffForm.controls[Constants.Currency.nteUsd].setValidators([LimitExceeded(x.limit)]);
                    this.callOffForm.controls[Constants.Currency.nteUsd].updateValueAndValidity({ emitEvent: false });
                }
            });
    }

    getNteRemaining(aweId: number) {
        this.callOffLookupService
            .getNteRemaining(aweId, this.callOffForm.controls.id.value)
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((remaining) => {
                this.remaining = remaining;
            });
    }

    filterAwes(): void {
        let search = this.filteredAwesCtrl.value;
        if (!search) {
            this.filteredAwes.next(this.awes.slice());
            return;
        } else {
            search = search.toLowerCase();
        }
        this.filteredAwes.next(this.awes.filter((awe) => awe.number.toLowerCase().indexOf(search) > -1));
    }
}
