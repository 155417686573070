import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { BaseComponent } from 'src/app/components/base.component';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { takeWhile, startWith, filter, take, distinctUntilChanged } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { CallOffEstimateDetailsDescriptionRequest } from 'src/app/store/call-off/actions';
import { ApplicationState } from 'src/app/store/model';
import { CallOffPricingMechanism } from 'src/app/models/enums';
import { ExchangeRate } from 'src/app/store/call-off/model';
import { Constants } from 'src/app/constants';
import { CallOffAttachmentType } from 'src/app/store/call-off-attachments/model';
import { JoditDescriptionType } from 'src/app/store/jodit-description/model';

@Component({
    selector: 'app-cof-contractors-estimate',
    templateUrl: './cof-contractors-estimate.component.html',
    styleUrls: ['./cof-contractors-estimate.component.scss'],
})
export class CofContractorsEstimateComponent extends BaseComponent implements OnInit {
    @Input() callOffForm: FormGroup;
    @Input() printMode = false;
    @Output() openJodit = new EventEmitter<{
        description: string;
        type: JoditDescriptionType;
    }>();
    @Output() estimateDetailsSet = new EventEmitter<{ controlName: string; value: string }>();

    reasonValues = [];
    exchangeRates: ExchangeRate[] = null;
    callOffId: number = null;
    isEstimateDetailsLoading: boolean;
    pricingMechanismValues = [];
    isEstimateDetailsLoading$ = this.store.select((state) => state.callOffState.isEstimateDetailsLoading);
    mainForm$ = this.store.select((state) => state.callOffState.form);
    reasonId$ = this.store.select((state) => state.callOffState.form.reasonForInstructionId);
    coPricingMechanism$ = this.store.select((state) => state.callOffState.form.pricingMechanism);
    isMainFormLoaded$ = this.store.select((state) => state.callOffState.isLoading);
    estimateDetails$ = this.store.select((state) => state.callOffState.form.estimateDetails);
    usd$ = this.store.select((state) => state.callOffState.form.usd);
    kzt$ = this.store.select((state) => state.callOffState.form.kzt);
    eur$ = this.store.select((state) => state.callOffState.form.eur);
    rub$ = this.store.select((state) => state.callOffState.form.rub);
    contractorCurrencies$ = this.store.select((state) => state.callOffState.form.contractorCurrencies);
    contractorCurrencies = {};
    anyContractorCurrency = false;
    initialized = false;
    attachmentTypes = CallOffAttachmentType;

    constructor(private configurationService: ConfigurationService, private store: Store<ApplicationState>) {
        super();
    }

    ngOnInit() {
        this.pricingMechanismValues = Object.entries(CallOffPricingMechanism)
            .filter((e) => !isNaN(e[0] as any) && (e[0] as any) > 0)
            .map((e) => ({ value: e[1], id: e[0] }));

        this.contractorCurrencies$.pipe(takeWhile(() => this.isAlive)).subscribe((currencies) => {
            this.contractorCurrencies = currencies
                ? currencies
                      .split(',')
                      .map((c) => c.trim())
                      .reduce((a, x) => ({ ...a, [x]: true }), {})
                : {};
            this.anyContractorCurrency = Object.keys(this.contractorCurrencies).length > 0;
        });

        this.isEstimateDetailsLoading$
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((isLoading) => (this.isEstimateDetailsLoading = isLoading));

        this.mainForm$
            .pipe(
                takeWhile(() => this.isAlive),
                filter((form) => !!form.id),
                distinctUntilChanged((prev, curr) => prev.id === curr.id)
            )
            .subscribe((form) => {
                this.callOffId = form.id;
                this.setContractorsEstimateSectionState(form.reasonForInstructionId);
                this.estimateDetails$
                    .pipe(
                        filter((details) => details !== null),
                        take(1)
                    )
                    .subscribe((details) =>
                        this.estimateDetailsSet.emit({ controlName: 'estimateDetails', value: details })
                    );
                this.store.dispatch(new CallOffEstimateDetailsDescriptionRequest(form.id));
            });

        this.configurationService
            .getExchangeRates()
            .pipe(take(1))
            .subscribe((exchangeRates) => {
                this.exchangeRates = exchangeRates;
            });

        this.callOffForm.controls.reasonForInstructionId.valueChanges
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((reasonId: number) => this.setContractorsEstimateSectionState(reasonId));
    }

    openJoditPopup(description: string) {
        this.openJodit.emit({ description, type: JoditDescriptionType.ContractorsEstimate });
    }

    init() {
        this.usd$.subscribe((currency) => {
            if (this.exchangeRates !== null && !isNaN(Number(currency))) {
                this.calculateNteUsd('usd');
            }
        });
        this.kzt$
            .pipe(
                takeWhile(() => this.isAlive),
                startWith('')
            )
            .subscribe((currency) => {
                if (this.exchangeRates !== null && !isNaN(Number(currency))) {
                    this.calculateNteUsd('kzt');
                }
            });
        this.eur$
            .pipe(
                takeWhile(() => this.isAlive),
                startWith('')
            )
            .subscribe((currency) => {
                if (this.exchangeRates !== null && !isNaN(Number(currency))) {
                    this.calculateNteUsd('eur');
                }
            });
        this.rub$
            .pipe(
                takeWhile(() => this.isAlive),
                startWith('')
            )
            .subscribe((currency) => {
                if (this.exchangeRates !== null && !isNaN(Number(currency))) {
                    this.calculateNteUsd('rub');
                }
            });
    }

    resetCurrency(value: string, currency: string) {
        if (value === '') {
            this.callOffForm.controls[currency].setValue('0');
        }
    }

    calculateNteUsd(currencyString: string) {
        if (
            this.callOffForm.controls[currencyString.toString()].value === undefined ||
            this.callOffForm.controls[currencyString.toString()].value === null
        ) {
            this.callOffForm.controls[currencyString.toString()].setValue('0');
        }
        if (isNaN(this.callOffForm.controls[currencyString.toString()].value)) {
            return;
        }

        const nteUsd =
            +this.callOffForm.controls[Constants.Currency.usd].value /
                this.exchangeRates.find((x) => x.currency === Constants.Currency.usd.toUpperCase()).value +
            +this.callOffForm.controls[Constants.Currency.kzt].value /
                this.exchangeRates.find((x) => x.currency === Constants.Currency.kzt.toUpperCase()).value +
            +this.callOffForm.controls[Constants.Currency.rub].value /
                this.exchangeRates.find((x) => x.currency === Constants.Currency.rub.toUpperCase()).value +
            +this.callOffForm.controls[Constants.Currency.eur].value /
                this.exchangeRates.find((x) => x.currency === Constants.Currency.eur.toUpperCase()).value;

        this.callOffForm.controls[Constants.Currency.nteUsd].setValue(nteUsd.toFixed(2));
    }

    public setContractorsEstimateSectionState(reasonId: number) {
        if (reasonId === null) {
            return;
        }
        //if Field Clarification then disable
        if (reasonId !== 1) {
            this.callOffForm.controls.estimateDetails.enable({ emitEvent: false, onlySelf: true });
            if (this.callOffForm.controls.estimateDetails.value === null) {
                this.callOffForm.controls.estimateDetails.setValue('', { emitEvent: false });
            }
            this.callOffForm.controls.pricingMechanism.enable({ emitEvent: false, onlySelf: true });
            this.callOffForm.controls.usd.enable({ emitEvent: false, onlySelf: true });
            this.callOffForm.controls.kzt.enable({ emitEvent: false, onlySelf: true });
            this.callOffForm.controls.rub.enable({ emitEvent: false, onlySelf: true });
            this.callOffForm.controls.eur.enable({ emitEvent: false, onlySelf: true });
            this.callOffForm.controls.nteUsd.enable({ emitEvent: false, onlySelf: true });
            if (!this.initialized && this.exchangeRates !== null) {
                this.init();
                this.initialized = true;
            }
            return;
        }

        this.callOffForm.controls.estimateDetails.disable({ emitEvent: false, onlySelf: true });
        this.callOffForm.controls.pricingMechanism.disable({ emitEvent: false, onlySelf: true });
        this.callOffForm.controls.usd.setValue(0, { emitEvent: false });
        this.callOffForm.controls.usd.disable({ emitEvent: false, onlySelf: true });
        this.callOffForm.controls.kzt.disable({ emitEvent: false, onlySelf: true });
        this.callOffForm.controls.kzt.setValue(0, { emitEvent: false });
        this.callOffForm.controls.rub.disable({ emitEvent: false, onlySelf: true });
        this.callOffForm.controls.rub.setValue(0, { emitEvent: false });
        this.callOffForm.controls.eur.disable({ emitEvent: false, onlySelf: true });
        this.callOffForm.controls.eur.setValue(0, { emitEvent: false });
        this.callOffForm.controls.nteUsd.disable({ emitEvent: false, onlySelf: true });
        this.callOffForm.controls.nteUsd.setValue(0, { emitEvent: false });
        this.callOffForm.controls.nteUsd.updateValueAndValidity({ emitEvent: false, onlySelf: true });
        this.initialized = false;
    }
}
