<main class="main">
    <form [formGroup]="filterForm">
        <div class="title-container">
            <div class="title">
                AWE filter and search
            </div>
        </div>
        <mat-accordion class="filter-container">
            <mat-expansion-panel #filterExpansionPanel="matExpansionPanel">
                <mat-expansion-panel-header collapsedHeight="auto">
                    <div *ngIf="!filterExpansionPanel.expanded">
                        <mat-chip-list aria-label="Active filters">
                            <mat-basic-chip *ngIf="filterForm.controls.contractors.value.length" removable>
                                Contract Number: {{ getMappedContractorNumbers() }}
                                <mat-icon matChipRemove (click)="clearFilterProperty('contractors')">cancel</mat-icon>
                            </mat-basic-chip>
                            <mat-basic-chip *ngIf="filterForm.controls.aweTitle.value.length" removable>
                                AWE Title: {{ filterForm.controls.aweTitle.value }}
                                <mat-icon matChipRemove (click)="clearFilterProperty('aweTitle')">cancel</mat-icon>
                            </mat-basic-chip>
                            <mat-basic-chip *ngIf="filterForm.controls.aweNumber.value.length" removable>
                                AWE Number: {{ filterForm.controls.aweNumber.value }}
                                <mat-icon matChipRemove (click)="clearFilterProperty('aweNumber')">cancel</mat-icon>
                            </mat-basic-chip>
                        </mat-chip-list>
                    </div>
                </mat-expansion-panel-header>

                <div class="container620-mh margin-right20">
                    <app-multiple-items-selector
                        class="container620"
                        [setInput]="setContractorInput"
                        formControlName="contractors"
                        [searchFunc]="getContractors"
                        [isAsync]="true"
                        [isTableAutoComplete]="true"
                        [displayedColumns]="autocompleteDisplayedColumns"
                        (autocompleteClosed)="onContractorsClosed()"
                        (itemRemoved)="onContractorsClosed()"
                        [filterForm]="filterForm"
                        [formSetter]="'contractors'"
                        [propertyToShow]="'contractNo'"
                        [placeholder]="'Contractor'"
                        [ngStyle]="{ display: isIE === true ? 'inline' : null }"
                    >
                    </app-multiple-items-selector>
                </div>
                <div class="mixedapp-container">
                    <mat-form-field class="container620 margin-right20">
                        <mat-label>AWE Number</mat-label>
                        <input matInput formControlName="aweNumber" />
                        <mat-error
                            *ngIf="
                                (filterForm.controls['aweNumber'].dirty || filterForm.controls['aweNumber'].touched) &&
                                filterForm.controls['aweNumber'].invalid &&
                                filterForm.controls['aweNumber'].errors.maxlength
                            "
                        >
                            AWE Number can be no more than 40 characters length.
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field class="container620">
                        <mat-label>AWE Title</mat-label>
                        <input matInput formControlName="aweTitle" />
                        <mat-error
                            *ngIf="
                                (filterForm.controls['aweTitle'].dirty || filterForm.controls['aweTitle'].touched) &&
                                filterForm.controls['aweTitle'].invalid &&
                                filterForm.controls['aweTitle'].errors.maxlength
                            "
                        >
                            AWE Title can be no more than 200 characters length.
                        </mat-error>
                    </mat-form-field>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
        <div class="buttons-container flex">
            <button
                *ngIf="!isReadOnly"
                type="button"
                class="cof-button cof-button-light margin-right20"
                (click)="exportToExcel()"
            >
                export to excel
            </button>
            <button type="button" (click)="resetFilters()" class="cof-button cof-button-light margin-right20">
                reset filters
            </button>
            <button type="button" (click)="search()" class="cof-button cof-button-light">search</button>
        </div>
        <div class="results-container">
            <div class="results-title">
                <div class="title-date">{{ todayDate | date: 'MMM d yyyy' }}</div>
            </div>
            <div class="scroll">
                <table
                    mat-table
                    matSort
                    [dataSource]="data"
                    [matSortActive]="sortBy"
                    matSortDisableClear
                    [matSortDirection]="direction"
                    class="awe-table"
                >
                    <ng-container matColumnDef="subject">
                        <th mat-header-cell app-col-resize disableClear *matHeaderCellDef>
                            <div class="sort-container center">
                                <span
                                    [ngClass]="{
                                        'header-underline': showUnderlineForCheckListColumn(columnTitles)
                                    }"
                                    class="hover-pointer"
                                    (click)="
                                        openHeaderCheckListFilter(
                                            'subject',
                                            getSubjects,
                                            'columnTitles',
                                            'Title',
                                            columnTitles
                                        )
                                    "
                                    >AWE Title</span
                                >
                                <div class="sort-icon" *ngIf="sortBy === 'subject'">
                                    <mat-icon *ngIf="direction === 'asc'">keyboard_arrow_up</mat-icon>
                                    <mat-icon *ngIf="direction === 'desc'">keyboard_arrow_down</mat-icon>
                                </div>
                            </div>
                        </th>
                        <td mat-cell *matCellDef="let element" class="awe-title">{{ element.subject }}</td>
                    </ng-container>
                    <ng-container matColumnDef="contractNo">
                        <th mat-header-cell app-col-resize disableClear *matHeaderCellDef>
                            <div class="sort-container center">
                                <span
                                    [ngClass]="{
                                        'header-underline': showUnderlineForCheckListColumn(columnContracts)
                                    }"
                                    class="hover-pointer"
                                    (click)="
                                        openHeaderCheckListFilter(
                                            'contractNo',
                                            getContracts,
                                            'columnContracts',
                                            'Contract No',
                                            columnContracts,
                                            true
                                        )
                                    "
                                    >Contract No</span
                                >
                                <div class="sort-icon" *ngIf="sortBy === 'contractNo'">
                                    <mat-icon *ngIf="direction === 'asc'">keyboard_arrow_up</mat-icon>
                                    <mat-icon *ngIf="direction === 'desc'">keyboard_arrow_down</mat-icon>
                                </div>
                            </div>
                        </th>
                        <td mat-cell *matCellDef="let element" class="contract-no">{{ element.contractNo }}</td>
                    </ng-container>
                    <ng-container matColumnDef="nte">
                        <th
                            mat-header-cell
                            disableClear
                            *matHeaderCellDef
                            class="hover-pointer"
                            (click)="openHeaderRangeFilter('nte', 'columnNte', 'NTE (USDE)', columnNte)"
                        >
                            <div class="sort-container center">
                                <span
                                    [ngClass]="{
                                        'header-underline': showUnderlineForRangeColumn(columnNte)
                                    }"
                                    >NTE (USDE)</span
                                >
                                <div class="sort-icon" *ngIf="sortBy === 'nte'">
                                    <mat-icon *ngIf="direction === 'asc'">keyboard_arrow_up</mat-icon>
                                    <mat-icon *ngIf="direction === 'desc'">keyboard_arrow_down</mat-icon>
                                </div>
                            </div>
                        </th>
                        <td mat-cell *matCellDef="let element" class="awe-nte">
                            {{ element.nte | currency: 'USD':'symbol':'1.2-2' }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="nteConsumed">
                        <th
                            mat-header-cell
                            disableClear
                            *matHeaderCellDef
                            class="hover-pointer"
                            (click)="
                                openHeaderRangeFilter(
                                    'nteConsumed',
                                    'columnCofValue',
                                    'COF Value (USDE)',
                                    columnCofValue
                                )
                            "
                        >
                            <div class="sort-container center">
                                <span
                                    [ngClass]="{
                                        'header-underline': showUnderlineForRangeColumn(columnCofValue)
                                    }"
                                    >COF Value (USDE)</span
                                >
                                <div class="sort-icon" *ngIf="sortBy === 'nteConsumed'">
                                    <mat-icon *ngIf="direction === 'asc'">keyboard_arrow_up</mat-icon>
                                    <mat-icon *ngIf="direction === 'desc'">keyboard_arrow_down</mat-icon>
                                </div>
                            </div>
                        </th>
                        <td mat-cell *matCellDef="let element" class="awe-nte">
                            {{ element.nteConsumed | currency: 'USD':'symbol':'1.2-2' }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="nteRemaining">
                        <th
                            mat-header-cell
                            disableClear
                            *matHeaderCellDef
                            class="hover-pointer"
                            (click)="
                                openHeaderRangeFilter(
                                    'nteRemaining',
                                    'columnRemaining',
                                    'Remaining (USDE)',
                                    columnRemaining
                                )
                            "
                        >
                            <div class="sort-container center">
                                <span
                                    [ngClass]="{
                                        'header-underline': showUnderlineForRangeColumn(columnRemaining)
                                    }"
                                    >Remaining (USDE)</span
                                >
                                <div class="sort-icon" *ngIf="sortBy === 'nteRemaining'">
                                    <mat-icon *ngIf="direction === 'asc'">keyboard_arrow_up</mat-icon>
                                    <mat-icon *ngIf="direction === 'desc'">keyboard_arrow_down</mat-icon>
                                </div>
                            </div>
                        </th>
                        <td mat-cell *matCellDef="let element" class="awe-nte">
                            {{ element.nteRemaining | currency: 'USD':'symbol':'1.2-2' }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="number" sticky>
                        <th mat-header-cell app-col-resize disableClear *matHeaderCellDef>
                            <div class="sort-container center">
                                <span
                                    class="hover-pointer"
                                    (click)="
                                        openHeaderCheckListFilter(
                                            'number',
                                            getAWENumbers,
                                            'columnAWENumbers',
                                            'AWE Number',
                                            columnAWENumbers
                                        )
                                    "
                                    [ngClass]="{
                                        'header-underline': showUnderlineForCheckListColumn(columnAWENumbers)
                                    }"
                                    >AWE Number</span
                                >
                                <div class="sort-icon" *ngIf="sortBy === 'number'">
                                    <mat-icon *ngIf="direction === 'asc'">keyboard_arrow_up</mat-icon>
                                    <mat-icon *ngIf="direction === 'desc'">keyboard_arrow_down</mat-icon>
                                </div>
                            </div>
                        </th>
                        <td mat-cell *matCellDef="let element" class="awe-number">{{ element.number }}</td>
                    </ng-container>
                    <ng-container matColumnDef="revision">
                        <th mat-header-cell app-col-resize disableClear *matHeaderCellDef>
                            <div class="sort-container center">
                                <span
                                    [ngClass]="{
                                        'header-underline': showUnderlineForCheckListColumn(columnRevisions)
                                    }"
                                    class="hover-pointer"
                                    (click)="
                                        openHeaderCheckListFilter(
                                            'revision',
                                            getRevisions,
                                            'columnRevisions',
                                            'Revision',
                                            columnRevisions
                                        )
                                    "
                                    >Revision</span
                                >
                                <div class="sort-icon" *ngIf="sortBy === 'revision'">
                                    <mat-icon *ngIf="direction === 'asc'">keyboard_arrow_up</mat-icon>
                                    <mat-icon *ngIf="direction === 'desc'">keyboard_arrow_down</mat-icon>
                                </div>
                            </div>
                        </th>
                        <td mat-cell *matCellDef="let element" class="revision">{{ element.revision }}</td>
                    </ng-container>
                    <ng-container matColumnDef="status">
                        <th
                            mat-header-cell
                            disableClear
                            *matHeaderCellDef
                            class="hover-pointer center"
                            (click)="
                                openHeaderCheckListFilter(
                                    'isActive',
                                    getIsActive,
                                    'columnIsActive',
                                    'Status',
                                    columnIsActive,
                                    false,
                                    false
                                )
                            "
                        >
                            <div class="sort-container center">
                                <span
                                    [ngClass]="{
                                        'header-underline': showUnderlineForCheckListColumn(columnIsActive)
                                    }"
                                    >Status</span
                                >
                                <div class="sort-icon" *ngIf="sortBy === 'status'">
                                    <mat-icon *ngIf="direction === 'asc'">keyboard_arrow_up</mat-icon>
                                    <mat-icon *ngIf="direction === 'desc'">keyboard_arrow_down</mat-icon>
                                </div>
                            </div>
                        </th>
                        <td mat-cell *matCellDef="let element" class="status center">
                            {{ element.isActive ? 'Active' : 'Closed' }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="attachments">
                        <th mat-header-cell disableClear *matHeaderCellDef class="center">Attachments</th>
                        <td mat-cell *matCellDef="let element" class="attachments">
                            <div class="uploaded-attachment-buttons" *ngFor="let att of element.attachments">
                                <div
                                    class="lds-hourglass"
                                    *ngIf="uploadedAttachmentsActionInProgress.getValue(att.link) || false"
                                ></div>
                                <button
                                    *ngIf="!uploadedAttachmentsActionInProgress.getValue(att.link)"
                                    [title]="att.name"
                                    type="button"
                                    class="download-uploaded-attachment"
                                    (click)="download(att)"
                                ></button>
                            </div>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="edit" sticky>
                        <th mat-header-cell disableClear *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let element">
                            <button
                                *ngIf="canEditAwe && element.isActive"
                                mat-icon-button
                                (click)="edit(element)"
                                title="edit"
                            >
                                <mat-icon svgIcon="edit"></mat-icon>
                            </button>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="active" sticky>
                        <th mat-header-cell disableClear *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let element">
                            <button
                                *ngIf="isAdmin && !element.isActive"
                                mat-icon-button
                                matTooltip="Set Active"
                                (click)="changeIsActive(element)"
                                title="active"
                            >
                                <mat-icon svgIcon="active"></mat-icon>
                            </button>
                            <button
                                *ngIf="canEditAwe && element.isActive"
                                mat-icon-button
                                matTooltip="Set Closed"
                                matTooltipClass="cofftooltip"
                                (click)="changeIsActive(element)"
                                title="close"
                            >
                                <mat-icon svgIcon="close"></mat-icon>
                            </button>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="remove" sticky>
                        <th mat-header-cell disableClear *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let element">
                            <button
                                *ngIf="canEditAwe && element.isActive"
                                mat-icon-button
                                (click)="remove(element)"
                                title="remove"
                            >
                                <mat-icon svgIcon="delete"></mat-icon>
                            </button>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                </table>
            </div>
            <div class="paginator page-counter">
                <mat-paginator
                    [length]="resultsLength"
                    [pageSize]="pageSize"
                    [pageSizeOptions]="[10, 15, 20]"
                    (page)="onPaginatorChange($event)"
                    showFirstLastButtons
                >
                </mat-paginator>
                <input
                    type="number"
                    [value]="this.paginator.pageIndex + 1"
                    (change)="onPageChange($event.target.value)"
                />
            </div>
        </div>
        <app-loading-indicator *ngIf="isLoading"></app-loading-indicator>
    </form>
</main>
