import { Component, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { of } from 'rxjs';
import { catchError, finalize, map, take } from 'rxjs/operators';
import { SetInputEventArgs } from 'src/app/models/set-input';
import { AWEService } from 'src/app/services/awe.service';
import { LookupService } from 'src/app/services/lookup.service';
import { ToastService } from 'src/app/services/shared/toast.service';
import { AWE, AWEAttachment } from 'src/app/store/awe-form/model';
import { AweRegisterFilter } from 'src/app/store/awe-register/model';
import { Contractor, CheckListColumn, OrderDirection } from 'src/app/store/common.model';
import { Dictionary } from 'typescript-collections';

@Component({
    selector: 'app-awe-search',
    templateUrl: './awe-search.component.html',
    styleUrls: ['./awe-search.component.scss'],
})
export class AWESearchComponent implements OnInit {
    displayedColumns = ['number', 'subject', 'nteRemaining', 'attachments'];
    autocompleteDisplayedColumns = ['contractno', 'contractor'];
    filterForm: FormGroup;
    resultsLength = 0;
    pageSize = 10;
    data: AWE[] = [];
    selectedAWE: AWE = null;
    aweFilter = new AweRegisterFilter();
    isLoading = false;
    uploadedAttachmentsActionInProgress = new Dictionary<string, boolean>();

    setContractorInput: EventEmitter<SetInputEventArgs> = new EventEmitter();

    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

    constructor(
        private dialogRef: MatDialogRef<AWESearchComponent>,
        private lookupService: LookupService,
        private aweService: AWEService,
        private toastService: ToastService
    ) {
        this.filterForm = new FormGroup({
            contractors: new FormControl(''),
            aweTitle: new FormControl(''),
        });
    }

    ngOnInit() {
        this.searchAWE();
    }

    getContractors = (search = '', take = 10, page = 0) => {
        return this.lookupService
            .searchContractors(search, take, page, [])
            .pipe(map((contractors: Contractor[]) => contractors));
    };

    onContractorsClosed() {
        this.setContractorInput.emit(new SetInputEventArgs(true));
    }

    resetFilters() {
        this.filterForm.setValue({ contractors: [], aweTitle: '' });
        this.setContractorInput.emit(new SetInputEventArgs(false, ''));
    }

    search() {
        this.paginator.pageIndex = 0;
        this.aweFilter.page = 0;
        this.aweFilter.contractors = this.filterForm.controls.contractors.value;
        this.aweFilter.aweTitle = this.filterForm.controls.aweTitle.value;
        this.searchAWE();
    }

    download(attachment: AWEAttachment) {
        this.uploadedAttachmentsActionInProgress.setValue(attachment.link, true);
        this.aweService
            .downloadAWEAttachment(attachment.link)
            .pipe(
                take(1),
                finalize(() => this.uploadedAttachmentsActionInProgress.setValue(attachment.link, false)),
                catchError(() => {
                    this.toastService.Error(
                        'Error has occurred while downloading attachment. Please contact Program Administrator.'
                    );
                    return of(null);
                })
            )
            .subscribe((file) => {
                const blob = new Blob([file], {
                    type: 'application/octet-stream',
                });
                saveAs(blob, attachment.name);
            });
    }

    cancel() {
        this.dialogRef.close();
    }

    confirm() {
        this.dialogRef.close(this.selectedAWE);
    }

    onPaginatorChange(pageEvent: PageEvent) {
        this.aweFilter.take = pageEvent.pageSize;
        this.aweFilter.page = pageEvent.pageIndex;
        this.searchAWE();
    }

    onPageChange(newPage: number) {
        if (newPage < 1) {
            newPage = 1;
        } else if (newPage > this.paginator.getNumberOfPages()) {
            newPage = this.paginator.getNumberOfPages();
        }
        var pageEvt = new PageEvent();
        pageEvt.pageIndex = newPage - 1;
        pageEvt.pageSize = this.pageSize;
        this.paginator.pageIndex = pageEvt.pageIndex;
        this.onPaginatorChange(pageEvt);
    }

    selectAWE(awe: AWE) {
        if (this.selectedAWE) {
            this.selectedAWE.selected = false;
        }
        this.selectedAWE = awe;
        this.selectedAWE.selected = true;
    }

    private searchAWE() {
        this.isLoading = true;
        this.selectedAWE = null;
        let acitveColumn = new CheckListColumn();
        acitveColumn.items = ['Active'];
        this.aweFilter.columnIsActive = acitveColumn;
        this.aweFilter.direction = OrderDirection.Asc;
        this.aweService
            .getAweRegisterData(this.aweFilter)
            .pipe(
                take(1),
                finalize(() => (this.isLoading = false))
            )
            .subscribe((response) => {
                this.resultsLength = response.totalCount;
                this.data = response.items;
            });
    }
}
