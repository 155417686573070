export class CurrenciesToContract {
    contractNo: string;
    prevContractNo: string;
    currencies: Currencies;
    prevCurrencies: Currencies;
    isInEditMode: boolean;
    currenciesString: string;
}

export interface Currencies {
    [currency: string]: boolean;
}
