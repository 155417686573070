import { Pipe, PipeTransform } from '@angular/core';
import { DefaultApprover } from '../store/awe-form/model';
import { AppRole } from '../store/common.model';
import { ReasonForInstruction } from '../store/common/model';

@Pipe({
    name: 'reasonFilter',
    pure: false,
})
export class ReasonFilterPipe implements PipeTransform {
    transform(items: DefaultApprover[], reason: ReasonForInstruction, appRole: AppRole): any {
        if (!items || reason === null) {
            return items;
        }
        return items.filter(
            (item) => item.reasonForInstruction.id === reason.id && AppRole[item.approverType] === AppRole[appRole]
        );
    }
}
