import {
    AsyncValidator,
    AbstractControl,
    ValidationErrors,
    AsyncValidatorFn,
    ValidatorFn,
    FormControl,
} from '@angular/forms';
import { Observable, of } from 'rxjs';
import { AWEService } from '../services/awe.service';
import { map, catchError } from 'rxjs/operators';
import { ToastService } from '../services/shared/toast.service';
import { Injectable } from '@angular/core';
import { IValidators } from './validators';
import { Dictionary } from 'typescript-collections';

@Injectable({
    providedIn: 'root',
})
export class AWEFormValidators implements IValidators {
    private validators: Dictionary<string, ValidatorFn[]>;
    private asyncValidators: Dictionary<string, AsyncValidatorFn[]>;

    constructor(private aweService: AWEService, private toastService: ToastService) {
        this.validators = new Dictionary<string, ValidatorFn[]>();
        this.asyncValidators = new Dictionary<string, AsyncValidatorFn[]>();
        this.configure();
    }

    getValidators(fieldName: string) {
        return this.validators.getValue(fieldName) || [];
    }

    getAsyncValidators(fieldName: string) {
        return this.asyncValidators.getValue(fieldName) || [];
    }

    private configure() {
        this.asyncValidators.setValue('aweNumber', [this.uniqueAWENumberValidator]);
        this.validators.setValue('aweNumber', [this.validateAweNumberCharacters]);
    }

    private uniqueAWENumberValidator: AsyncValidatorFn = (
        control: FormControl
    ): Observable<ValidationErrors | null> => {
        if (!control.parent || control.parent.controls['isEdit'].value) {
            return of(null);
        }
        return this.aweService.isAWENumberTaken(control.value).pipe(
            map((isTaken) => (isTaken ? { uniqueAWENumber: true } : null)),
            catchError(() => {
                this.toastService.Error(
                    'Error has occurred while validating AWE Number. Please contact Program Administrator.'
                );
                return of(null);
            })
        );
    };

    private validateAweNumberCharacters: ValidatorFn = (control: FormControl): ValidationErrors | null => {
        var regex = new RegExp(/[!@#$%^&*{}:;'<,>.?/\\()]/);
        if (regex.test(control.value)) {
            return { aweNumberChars: true };
        }
        return null;
    };
}
