<div>
    <app-asc-desc-order
        *ngIf="!isSortingOff"
        [(isAscendingSort)]="isAscendingSort"
        [(isDescendingSort)]="isDescendingSort"
    ></app-asc-desc-order>
    <div class="search-block">
        <mat-form-field *ngIf="showInputSearch" class="input-search">
            <input matInput placeholder="{{ placeholder }}" [formControl]="itemCtrl" />
        </mat-form-field>
        <div *ngIf="isLoading" class="lds-hourglass small-spinner"></div>
    </div>

    <div class="list-container">
        <h4 *ngIf="noResultFound && !isLoading">No results found</h4>
        <cdk-virtual-scroll-viewport class="virtual-scroll" itemSize="32" (scrolledIndexChange)="nextBatch($event)">
            <mat-selection-list #items>
                <ng-container>
                    <mat-list-option
                        class="smaller-item-option"
                        checkboxPosition="before"
                        *cdkVirtualFor="let element of elements; trackBy: trackByFn"
                        [value]="getElementValue(element)"
                    >
                        {{ getElementValue(element) === '' ? '(Blanks)' : getElementValue(element) }} <ng-container *ngIf="showCounts">- {{ element.count }} </ng-container>
                    </mat-list-option>
                </ng-container>
            </mat-selection-list>
        </cdk-virtual-scroll-viewport>
    </div>
    <mat-divider class="divider-space"></mat-divider>
    <span class="reset-filters" (click)="resetFilters()">Reset filters &times;</span><br />
    <div class="buttons-container flex center save-close-space">
        <button type="button" (click)="close()" class="cof-button cof-button-small cof-button-white margin-right20">
            cancel
        </button>
        <button type="button" (click)="onSubmit()" class="cof-button cof-button-small cof-button-light ok-button">
            ok
        </button>
    </div>
</div>
