import { CheckListColumn, OrderDirection, UserDetail } from '../common.model';

export interface ConstructionApproverMatrixState {
    data: ConstructionApproverMatrixResultData;
    isLoading: boolean;
    filter: ConstructionApproverMatrixFilter;
}

export class ConstructionApproverMatrixResultData {
    totalCount = 0;
    items: ConstructionApproverMatrixDTO[] = [];
}

export class ConstructionApproverMatrixDTO {
    id: number;
    approver: UserDetail;
    emails: string;
    prevEmails = '';
    isInEditMode = false;
}

export class ConstructionApproverMatrixFilter {
    page: number = 0;
    take: number = 10;
    sortBy: string = 'approver';
    direction: OrderDirection = OrderDirection.Asc;
    columnApprovers?: CheckListColumn = null;
}
