<div class="call-off-form-top-section-container">
    <div [formGroup]="aweForm">
        <div class="col-header-container filter-container">
            <mat-form-field *ngIf="(status$ | async) !== statusType.Draft" class="container620 margin-right20">
                <input matInput placeholder="AWE Number" formControlName="number" readonly />
            </mat-form-field>
            <mat-form-field
                *ngIf="(status$ | async) === statusType.Draft"
                class="container620 margin-top20 margin-right20"
            >
                <mat-label>AWE Number</mat-label>
                <mat-select matNativeControl formControlName="id" #aweSelect>
                    <mat-option>
                        <ngx-mat-select-search
                            formControlName="filteredAwesCtrl"
                            placeholderLabel="Search..."
                            noEntriesFoundLabel="No entries found"
                        ></ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let awe of filteredAwes | async" [value]="awe.id" (click)="aweSelected(awe)">{{
                        awe.number
                    }}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field class="container300 margin-right20">
                <input matInput placeholder="Contract No" readonly formControlName="contractNo" />
            </mat-form-field>
            <mat-form-field class="container300">
                <input matInput placeholder="AWE Revision" formControlName="revision" readonly />
            </mat-form-field>

            <div class="remainings-container">
                <mat-form-field [ngClass]="{ 'bold-red': remaining === 0 }" *ngIf="anyContractorCurrency">
                    <input
                        matInput
                        placeholder="NTE Remaining (USDE)"
                        currencyMask
                        [options]="{ prefix: '$ ' }"
                        readonly
                        formControlName="nteRemaining"
                    />
                </mat-form-field>
                <mat-form-field class="margin-right20" *ngIf="contractorCurrencies['EUR']"
                    ><input
                        matInput
                        placeholder="NTE Remaining EUR"
                        currencyMask
                        [options]="{ prefix: '€ ' }"
                        readonly
                        formControlName="eurRemaining"
                /></mat-form-field>
                <mat-form-field class="margin-right20" *ngIf="contractorCurrencies['RUB']"
                    ><input
                        matInput
                        placeholder="NTE Remaining RUB"
                        currencyMask
                        [options]="{ prefix: 'RUB ' }"
                        readonly
                        formControlName="rubRemaining"
                /></mat-form-field>
                <mat-form-field class="margin-right20" *ngIf="contractorCurrencies['KZT']"
                    ><input
                        matInput
                        placeholder="NTE Remaining KZT"
                        currencyMask
                        [options]="{ prefix: 'KZT ' }"
                        readonly
                        formControlName="kztRemaining"
                /></mat-form-field>
                <mat-form-field class="margin-right20" *ngIf="contractorCurrencies['USD']"
                    ><input
                        matInput
                        placeholder="NTE Remaining USD"
                        currencyMask
                        [options]="{ prefix: '$ ' }"
                        readonly
                        formControlName="usdRemaining"
                /></mat-form-field>
            </div>

            <mat-form-field class="container-fit">
                <textarea
                    matInput
                    placeholder="AWE Title"
                    readonly
                    formControlName="subject"
                    cdkTextareaAutosize
                    cdkAutosizeMinRows="2"
                ></textarea>
            </mat-form-field>
        </div>
    </div>
</div>
