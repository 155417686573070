import { Action } from '@ngrx/store';
import { CallOff, CallOffInitialFields, CallOffComment, CallOffHistory } from './model';
import { ActionUsers } from '../action-users/model';
import { CallOffStatusChangeAction } from '../../models/enums';
import { HttpErrorResponse } from '@angular/common/http';
import { Attachment, CallOffAttachments, CallOffAttachmentType } from '../call-off-attachments/model';

export enum CallOffActionTypes {
    AddCallOffRequest = '[Call-Off] Add Call-Off Request',
    AddCallOffRequestSuccess = '[Call-Off] Add Call-Off Request Success',
    AddCallOffRequestError = '[Call-Off] Add Call-Off Request Error',

    GetCallOffRequest = '[Call-Off] Get Call-Off Request',
    GetCallOffRequestSuccess = '[Call-Off] Get Call-Off Request Success',
    GetCallOffRequestError = '[Call-Off] Get Call-Off Request Error',

    CallOffUpdateRequest = '[Call-Off] Call-Off Update Request',
    CallOffUpdateRequestSuccess = '[Call-Off] Call-Off Update Request Success',
    CallOffUpdateRequestError = '[Call-Off] Call-Off Update Request Error',

    CallOffSetButtonStatus = '[Call-Off] Call-Off Set Button Status',
    CallOffUpdateProperty = '[Call-Off] Call-Off Update Property',
    CallOffClear = '[Call-Off] Call-Off Clear',

    CallOffScopeOfWorkDescriptionRequest = '[Call-Off] Call-Off Scope of work description request',
    CallOffScopeOfWorkDescriptionRequestSuccess = '[Call-Off] Call-Off Scope of work description request success',
    CallOffScopeOfWorkDescriptionRequestError = '[Call-Off] Call-Off Scope of work description request error',

    CallOffEstimateDetailsDescriptionRequest = '[Call-Off] Call-Off Estimate Details description request',
    CallOffEstimateDetailsDescriptionRequestSuccess = '[Call-Off] Call-Off Estimate Details description request success',
    CallOffEstimateDetailsDescriptionRequestError = '[Call-Off] Call-Off Estimate Details description request error',

    CallOffActionUsersRequest = '[Call-Off] Action Users Request',
    CallOffActionUsersRequestSuccess = '[Call-Off] Action Users Request Success',
    CallOffActionUsersRequestError = '[Call-Off] Action Users Request Error',

    CallOffUpdateStatusRequest = '[Call-Off] Update Status Request',
    CallOffUpdateStatusRequestSuccess = '[Call-Off] Update Status Request Success',
    CallOffUpdateStatusRequestError = '[Call-Off] Update Status Request Error',

    CallOffRemoveCommentRequest = '[CallOff] Remove Comment Request',
    CallOffRemoveCommentSuccess = '[CallOff] Remove Comment Success',
    CallOffRemoveCommentError = '[CallOff] Remove Comment Error',

    CallOffAddCommentRequest = '[CallOff] Add Comment Request',
    CallOffAddCommentSuccess = '[CallOff] Add Comment Success',
    CallOffAddCommentError = '[CallOff] Add Comment Error',

    CallOffHistoryRequest = '[CallOff] History Request',
    CallOffHistoryRequestSuccess = '[CallOff] History Success',
    CallOffHistoryRequestError = '[CallOff] History Error',

    CallOffAutosaveRequest = '[CallOff] Autosave Request',
    CallOffAutosaveSuccess = '[CallOff] Autosave Success',
    CallOffAutosaveError = '[CallOff] Autosave Error',

    CallOffSaveAndUpdateStatusRequest = '[Call-Off] Save And Update Status Request',
    LockCallOffFormIfNoChanges = '[Call-Off] Lock Call-Off if no changes made',
    LockCallOffForm = '[Call-Off] Lock Call-Off',
    UnLockCallOffForm = '[Call-Off] UnLock Call-Off',
    CallOffNewRevisionRequest = '[Call-Off] New Revision Request',
    CallOffNewRevisionRequestSuccess = '[Call-Off] New Revision Success',
    CallOffNewRevisionRequestError = '[Call-Off] New Revision Error',

    CallOffAttachmentsRequest = '[Call-Off] Attachments Request',
    CallOffAttachmentsSuccess = '[Call-Off] Attachments Success',
    CallOffAttachmentsError = '[Call-Off] Attachments Error',
    CallOffUpdateInitialFormWithAttachments = '[Call-Off] Update Initial Form With Attachments',
    CallOffUpdateAttachmentLinks = '[Call-Off] Update Attachment Links',
    CallOffDownloadAttachmentRequest = '[Call-Off] Download Attachment Request',
    CallOffDownloadAttachmentSuccess = '[Call-Off] Download Attachment Success',
    CallOffDownloadAttachmentError = '[Call-Off] Download Attachment Error',
}

export class AddCallOffRequest implements Action {
    readonly type = CallOffActionTypes.AddCallOffRequest;

    constructor(public payload: { callOff: CallOffInitialFields }) {}
}
export class AddCallOffRequestSuccess implements Action {
    readonly type = CallOffActionTypes.AddCallOffRequestSuccess;

    constructor(public payload: { callOffId: number; callOff?: CallOffInitialFields }) {}
}
export class AddCallOffRequestError implements Action {
    readonly type = CallOffActionTypes.AddCallOffRequestError;
    constructor(public payload: string) {}
}

export class GetCallOffRequest implements Action {
    readonly type = CallOffActionTypes.GetCallOffRequest;

    constructor(public payload: number) {}
}
export class GetCallOffRequestSuccess implements Action {
    readonly type = CallOffActionTypes.GetCallOffRequestSuccess;

    constructor(public payload: CallOff) {}
}
export class GetCallOffRequestError implements Action {
    readonly type = CallOffActionTypes.GetCallOffRequestError;
    constructor(public payload: string) {}
}

export class CallOffUpdateRequest implements Action {
    readonly type = CallOffActionTypes.CallOffUpdateRequest;

    constructor(public payload: CallOff) {}
}
export class CallOffUpdateRequestSuccess implements Action {
    readonly type = CallOffActionTypes.CallOffUpdateRequestSuccess;

    constructor(public payload: CallOff) {}
}
export class CallOffUpdateRequestError implements Action {
    readonly type = CallOffActionTypes.CallOffUpdateRequestError;
    constructor(public payload: { error: string; unlockForm: boolean }) {}
}

export class CallOffUpdateProperty implements Action {
    readonly type = CallOffActionTypes.CallOffUpdateProperty;
    constructor(public payload: { key: string; value: any; updatedProperties: string[] }) {}
}

export class CallOffClear implements Action {
    readonly type = CallOffActionTypes.CallOffClear;
}

export class CallOffEstimateDetailsDescriptionRequest implements Action {
    readonly type = CallOffActionTypes.CallOffEstimateDetailsDescriptionRequest;
    constructor(public payload: number) {}
}

export class CallOffEstimateDetailsDescriptionRequestSuccess implements Action {
    readonly type = CallOffActionTypes.CallOffEstimateDetailsDescriptionRequestSuccess;
    constructor(public payload: string) {}
}

export class CallOffEstimateDetailsDescriptionRequestError implements Action {
    readonly type = CallOffActionTypes.CallOffEstimateDetailsDescriptionRequestError;
    constructor(public payload: string) {}
}

export class CallOffScopeOfWorkDescriptionRequest implements Action {
    readonly type = CallOffActionTypes.CallOffScopeOfWorkDescriptionRequest;
    constructor(public payload: number) {}
}

export class CallOffScopeOfWorkDescriptionRequestSuccess implements Action {
    readonly type = CallOffActionTypes.CallOffScopeOfWorkDescriptionRequestSuccess;
    constructor(public payload: string) {}
}
export class CallOffScopeOfWorkDescriptionRequestError implements Action {
    readonly type = CallOffActionTypes.CallOffScopeOfWorkDescriptionRequestError;
    constructor(public payload: string) {}
}

export class CallOffActionUsersRequest implements Action {
    readonly type = CallOffActionTypes.CallOffActionUsersRequest;

    constructor(public contractNo: string, public reasonId: number) {}
}

export class CallOffActionUsersRequestSuccess implements Action {
    readonly type = CallOffActionTypes.CallOffActionUsersRequestSuccess;

    constructor(public payload: ActionUsers) {}
}

export class CallOffActionUsersRequestError implements Action {
    readonly type = CallOffActionTypes.CallOffActionUsersRequestError;

    constructor(public payload: string) {}
}

export class CallOffUpdateStatusRequest implements Action {
    readonly type = CallOffActionTypes.CallOffUpdateStatusRequest;

    constructor(public payload: { id: number; changeAction: CallOffStatusChangeAction }) {}
}
export class CallOffUpdateStatusRequestSuccess implements Action {
    readonly type = CallOffActionTypes.CallOffUpdateStatusRequestSuccess;

    constructor(public payload: CallOff) {}
}

export class CallOffUpdateStatusRequestError implements Action {
    readonly type = CallOffActionTypes.CallOffUpdateStatusRequestError;

    constructor(public payload: { unlockForm: boolean }) {}
}

export class CallOffSaveAndUpdateStatusRequest implements Action {
    readonly type = CallOffActionTypes.CallOffSaveAndUpdateStatusRequest;

    constructor(public payload: { form: CallOff; changeAction: CallOffStatusChangeAction }) {}
}

export class CallOffRemoveCommentRequest implements Action {
    readonly type = CallOffActionTypes.CallOffRemoveCommentRequest;

    constructor(public payload: { comment: CallOffComment }) {}
}

export class CallOffRemoveCommentSuccess implements Action {
    readonly type = CallOffActionTypes.CallOffRemoveCommentSuccess;

    constructor(public payload: { comment: CallOffComment }) {}
}
export class CallOffRemoveCommentError implements Action {
    readonly type = CallOffActionTypes.CallOffRemoveCommentError;

    constructor(public payload: HttpErrorResponse) {}
}

export class CallOffAddCommentRequest implements Action {
    readonly type = CallOffActionTypes.CallOffAddCommentRequest;

    constructor(public payload: { comment: CallOffComment }) {}
}

export class CallOffAddCommentSuccess implements Action {
    readonly type = CallOffActionTypes.CallOffAddCommentSuccess;

    constructor(public payload: { comment: CallOffComment }) {}
}
export class CallOffAddCommentError implements Action {
    readonly type = CallOffActionTypes.CallOffAddCommentError;

    constructor(public payload: string) {}
}

export class CallOffHistoryRequest implements Action {
    readonly type = CallOffActionTypes.CallOffHistoryRequest;

    constructor(public payload: number) {}
}
export class CallOffHistoryRequestSuccess implements Action {
    readonly type = CallOffActionTypes.CallOffHistoryRequestSuccess;

    constructor(public payload: CallOffHistory[]) {}
}
export class CallOffHistoryRequestError implements Action {
    readonly type = CallOffActionTypes.CallOffHistoryRequestError;
    constructor(public payload: string) {}
}

export class LockCallOffFormIfNoChanges implements Action {
    readonly type = CallOffActionTypes.LockCallOffFormIfNoChanges;

    constructor() {}
}
export class LockCallOffForm implements Action {
    readonly type = CallOffActionTypes.LockCallOffForm;

    constructor() {}
}
export class UnLockCallOffForm implements Action {
    readonly type = CallOffActionTypes.UnLockCallOffForm;

    constructor() {}
}

export class CallOffNewRevisionRequest implements Action {
    readonly type = CallOffActionTypes.CallOffNewRevisionRequest;

    constructor(public payload: { callOffId: number; callOff: CallOffInitialFields }) {}
}

export class CallOffNewRevisionRequestSuccess implements Action {
    readonly type = CallOffActionTypes.CallOffNewRevisionRequestSuccess;

    constructor(public payload: { callOffId: number; callOff: CallOffInitialFields }) {}
}

export class CallOffNewRevisionRequestError implements Action {
    readonly type = CallOffActionTypes.CallOffNewRevisionRequestError;

    constructor(public payload: string) {}
}

export class CallOffAttachmentsRequest implements Action {
    readonly type = CallOffActionTypes.CallOffAttachmentsRequest;

    constructor(public payload: { id: number; type: CallOffAttachmentType }) {}
}

export class CallOffAttachmentsSuccess implements Action {
    readonly type = CallOffActionTypes.CallOffAttachmentsSuccess;

    constructor(public payload: { attachments: CallOffAttachments; type: CallOffAttachmentType }) {}
}

export class CallOffAttachmentsError implements Action {
    readonly type = CallOffActionTypes.CallOffAttachmentsError;

    constructor(public payload: string) {}
}

export class CallOffUpdateInitialFormWithAttachments implements Action {
    readonly type = CallOffActionTypes.CallOffUpdateInitialFormWithAttachments;

    constructor(public payload: { attachments: CallOffAttachments; type: CallOffAttachmentType }) {}
}

export class CallOffUpdateAttachmentLinks implements Action {
    readonly type = CallOffActionTypes.CallOffUpdateAttachmentLinks;
}

export class CallOffDownloadAttachmentRequest implements Action {
    readonly type = CallOffActionTypes.CallOffDownloadAttachmentRequest;

    constructor(public payload: { attachment: Attachment; type: CallOffAttachmentType }) {}
}

export class CallOffDownloadAttachmentSuccess implements Action {
    readonly type = CallOffActionTypes.CallOffDownloadAttachmentSuccess;

    constructor(public payload: { content: Blob; fileName: string; type: CallOffAttachmentType }) {}
}

export class CallOffDownloadAttachmentError implements Action {
    readonly type = CallOffActionTypes.CallOffDownloadAttachmentError;

    constructor(public payload: { attachment: Attachment; type: CallOffAttachmentType }) {}
}

export class CallOffAutosaveRequest implements Action {
    readonly type = CallOffActionTypes.CallOffAutosaveRequest;

    constructor(public payload: CallOff) {}
}

export class CallOffAutosaveSuccess implements Action {
    readonly type = CallOffActionTypes.CallOffAutosaveSuccess;

    constructor(public payload: CallOff) {}
}

export class CallOffAutosaveError implements Action {
    readonly type = CallOffActionTypes.CallOffAutosaveError;

    constructor(public payload: string) {}
}

export type CallOffActions =
    | AddCallOffRequest
    | AddCallOffRequestSuccess
    | AddCallOffRequestError
    | GetCallOffRequest
    | GetCallOffRequestSuccess
    | GetCallOffRequestError
    | CallOffUpdateRequest
    | CallOffUpdateRequestSuccess
    | CallOffUpdateRequestError
    | CallOffUpdateProperty
    | CallOffClear
    | CallOffScopeOfWorkDescriptionRequest
    | CallOffScopeOfWorkDescriptionRequestSuccess
    | CallOffScopeOfWorkDescriptionRequestError
    | CallOffEstimateDetailsDescriptionRequest
    | CallOffEstimateDetailsDescriptionRequestSuccess
    | CallOffEstimateDetailsDescriptionRequestError
    | CallOffActionUsersRequest
    | CallOffActionUsersRequestSuccess
    | CallOffActionUsersRequestError
    | CallOffUpdateStatusRequest
    | CallOffUpdateStatusRequestSuccess
    | CallOffUpdateStatusRequestError
    | CallOffSaveAndUpdateStatusRequest
    | CallOffRemoveCommentRequest
    | CallOffRemoveCommentSuccess
    | CallOffRemoveCommentError
    | CallOffAddCommentRequest
    | CallOffAddCommentSuccess
    | CallOffAddCommentError
    | CallOffHistoryRequest
    | CallOffHistoryRequestError
    | CallOffHistoryRequestSuccess
    | LockCallOffFormIfNoChanges
    | LockCallOffForm
    | UnLockCallOffForm
    | CallOffNewRevisionRequest
    | CallOffNewRevisionRequestSuccess
    | CallOffNewRevisionRequestError
    | CallOffAttachmentsRequest
    | CallOffAttachmentsSuccess
    | CallOffAttachmentsError
    | CallOffUpdateInitialFormWithAttachments
    | CallOffUpdateAttachmentLinks
    | CallOffDownloadAttachmentRequest
    | CallOffDownloadAttachmentSuccess
    | CallOffDownloadAttachmentError
    | CallOffAutosaveRequest
    | CallOffAutosaveSuccess
    | CallOffAutosaveError;
