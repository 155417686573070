import { Component, OnInit, ViewChild } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DomSanitizer } from '@angular/platform-browser';
import { takeWhile, take, catchError } from 'rxjs/operators';
import { OrderDirection, Contractor, AppRole, UserWithRoleDetail, CheckListColumn } from 'src/app/store/common.model';
import { PopupService } from 'src/app/services/shared/popup.service';
import { ConfirmDialogPopupSettings } from 'src/app/models/confirm-dialog-popup-settings';
import { PopupSettings } from 'src/app/models/popup-settings';
import { AddApprovalMatrixPopupComponent } from './add-approval-matrix-popup/add-approval-matrix-popup.component';
import { BaseComponent } from 'src/app/components/base.component';
import { ApprovalMatrixService } from 'src/app/services/approval-matrix.service';
import { ToastService } from 'src/app/services/shared/toast.service';
import { ApprovalMatrixDTO } from 'src/app/models/approval-matrix-dto';
import { ReasonForInstruction } from 'src/app/store/common/model';
import { forkJoin, of } from 'rxjs';
import { CallOffLookupService } from 'src/app/services/call-off-lookup.service';
import { LookupService } from 'src/app/services/lookup.service';
import { HeaderCheckListFilter } from 'src/app/models/filter-settings';
import { HeaderChecklistFilterComponent } from 'src/app/components/filter/header-checklist-filter/header-checklist-filter.component';
import _ from 'lodash';

@Component({
    selector: 'app-approval-matrix',
    templateUrl: './approval-matrix.component.html',
    styleUrls: ['./approval-matrix.component.scss'],
})
export class ApprovalMatrixComponent extends BaseComponent implements OnInit {
    resultsLength = 0;
    pageSize = 10;
    sortBy: string = 'approverType';
    direction = OrderDirection.Asc;
    isLoading = false;
    displayedColumns = ['approverType', 'approver', 'contractNo', 'reasonForInstructions', 'edit', 'remove'];
    dataSource = new MatTableDataSource<ApprovalMatrixDTO>();
    contractors: Contractor[] = [];
    types = [
        { value: AppRole.TCOApprover, key: 'TCO Approver' },
        { value: AppRole.ContractorApprover, key: 'Contractor Approver' },
    ];
    reasons: ReasonForInstruction[];
    users: UserWithRoleDetail[];
    allElements: ApprovalMatrixDTO[] = [];
    columnApproverTypes: CheckListColumn = null;
    columnApprovers: CheckListColumn = null;
    columnContracts: CheckListColumn = null;
    columnReasonForInstructions: CheckListColumn = null;

    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;

    constructor(
        private iconRegistry: MatIconRegistry,
        private sanitizer: DomSanitizer,
        private popupService: PopupService,
        private approvalMatrixService: ApprovalMatrixService,
        private toastService: ToastService,
        private callOffLookupService: CallOffLookupService,
        private lookupService: LookupService
    ) {
        super();

        this.iconRegistry.addSvgIcon(
            'edit',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/edit.svg')
        );
        this.iconRegistry.addSvgIcon(
            'delete',
            sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/delete.svg')
        );
        this.iconRegistry.addSvgIcon('add', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/add.svg'));
    }

    ngOnInit(): void {
        this.isLoading = true;
        forkJoin([
            this.lookupService.searchUsersWithRoles([AppRole.TCOApprover, AppRole.ContractorApprover]),
            this.lookupService.searchContractors(),
            this.callOffLookupService.getReasons(),
            this.approvalMatrixService.get(),
        ])
            .pipe(
                take(1),
                catchError(() => {
                    throw new Error(
                        'Error occurred while getting approval matrix data. Please contact Program Administrator.'
                    );
                })
            )
            .subscribe(
                ([users, contractors, reasons, approvalMatrix]) => {
                    this.users = users;
                    this.contractors = contractors;
                    this.reasons = reasons;
                    this.allElements = approvalMatrix;
                    this.populateDataTable(approvalMatrix);
                    this.isLoading = false;
                },
                (error) => {
                    this.isLoading = false;
                    this.toastService.Error(error);
                }
            );
    }

    populateDataTable(approvalMatrix: ApprovalMatrixDTO[]) {
        this.dataSource = new MatTableDataSource(approvalMatrix);
        this.resultsLength = this.dataSource.data.length;
        this.dataSource.sort = this.sort;
        this.dataSource.sortingDataAccessor = (item, property) => {
            switch (property) {
                case 'approverType':
                    return this.types.find((f) => f.value === item.approverType).key;
                case 'approver':
                    return item.approver.name;
                case 'reasonForInstructions':
                    return item.reasonForInstructions.map((s) => s.name).join();
                default:
                    return item[property];
            }
        };
        this.dataSource.paginator = this.paginator;
    }

    remove(element: ApprovalMatrixDTO) {
        this.popupService
            .openPopup(
                new ConfirmDialogPopupSettings({
                    title: 'Confirm action',
                    text: `Are you sure you want to delete ${element.approver.name} from approval matrix?`,
                })
            )
            .afterClosed()
            .pipe(takeWhile(() => this.isAlive === true))
            .subscribe((answer) => {
                if (answer) {
                    this.isLoading = true;
                    this.approvalMatrixService
                        .remove(element.id)
                        .pipe(takeWhile(() => this.isAlive === true))
                        .subscribe(
                            () => {
                                var data = this.dataSource.data.filter((s) => s.id !== element.id);
                                this.allElements = data;
                                this.populateDataTable(data);
                                this.isLoading = false;
                            },
                            () => {
                                this.isLoading = false;
                                this.toastService.Error(
                                    `Error occurred while deleting ${element.approver.name}. Please contact Program Administrator.`
                                );
                            }
                        );
                }
            });
    }

    add() {
        const dialogRef = this.popupService.openPopup(
            new PopupSettings(AddApprovalMatrixPopupComponent, 450, 500, {
                contractors: this.contractors,
                users: this.users,
                reasons: this.reasons,
                types: this.types,
            })
        );

        dialogRef
            .afterClosed()
            .pipe(takeWhile(() => this.isAlive === true))
            .subscribe((result) => {
                if (result && result.success) {
                    const data = this.dataSource.data;
                    data.push(result.data);
                    this.allElements = data;
                    this.populateDataTable(data);
                }
            });
    }

    enterEditMode(approvalMatrix: ApprovalMatrixDTO) {
        approvalMatrix.isInEditMode = !approvalMatrix.isInEditMode;
        approvalMatrix.prevContractNo = approvalMatrix.contractNo;
        approvalMatrix.prevApproverType = approvalMatrix.approverType;
        approvalMatrix.prevReasonForInstructions = Object.assign([], approvalMatrix.reasonForInstructions);
        approvalMatrix.prevApprover = Object.assign({}, approvalMatrix.approver);
    }

    displayReasons(reasons: ReasonForInstruction[]) {
        return reasons.map((s) => s.name).join(', ');
    }

    save(approvalMatrix: ApprovalMatrixDTO) {
        approvalMatrix.isInEditMode = !approvalMatrix.isInEditMode;

        if (
            approvalMatrix.contractNo !== approvalMatrix.prevContractNo ||
            approvalMatrix.approver.email !== approvalMatrix.prevApprover.email ||
            approvalMatrix.approverType !== approvalMatrix.prevApproverType ||
            !this.arraysEqual(
                approvalMatrix.reasonForInstructions.map((s) => s.id),
                approvalMatrix.prevReasonForInstructions.map((s) => s.id)
            )
        ) {
            this.isLoading = true;
            approvalMatrix.approver = Object.assign(
                {},
                this.users.find((s) => s.email === approvalMatrix.approver.email)
            );
            this.approvalMatrixService
                .update(approvalMatrix)
                .pipe(takeWhile(() => this.isAlive === true))
                .subscribe(
                    () => {
                        this.isLoading = false;
                        this.toastService.Success(
                            `Successfully updated ${approvalMatrix.approver.name} in the approval matrix.`
                        );
                    },
                    (error) => {
                        this.isLoading = false;
                        if (error.status === 409) {
                            this.toastService.Error(
                                `${this.findRole(this.types, approvalMatrix.approverType)} ${
                                    approvalMatrix.approver.name
                                } with Contract ${approvalMatrix.contractNo} already exists in the approval matrix.`
                            );
                        } else {
                            this.toastService.Error(
                                `Error occurred while updating ${approvalMatrix.approver.name} approval matrix. Please contact Program Administrator.`
                            );
                        }
                        this.rollbackChanges(approvalMatrix);
                    }
                );
        }
    }

    cancelEdit(approvalMatrix: ApprovalMatrixDTO) {
        approvalMatrix.isInEditMode = !approvalMatrix.isInEditMode;
        this.rollbackChanges(approvalMatrix);
    }

    onPageChange(newPage: number) {
        if (newPage < 1) {
            newPage = 1;
        } else if (newPage > this.paginator.getNumberOfPages()) {
            newPage = this.paginator.getNumberOfPages();
        }
        this.paginator.pageIndex = newPage - 1;
        this.dataSource.paginator = this.paginator;
    }

    private rollbackChanges(approvalMatrix: ApprovalMatrixDTO) {
        approvalMatrix.contractNo = approvalMatrix.prevContractNo;
        approvalMatrix.approverType = approvalMatrix.prevApproverType;
        approvalMatrix.reasonForInstructions = approvalMatrix.prevReasonForInstructions;
        approvalMatrix.approver = approvalMatrix.prevApprover;
    }

    getValues = (search = '', take = 30, page = 0, column = 'number') => {
        let elements = this.allElements;
        if (elements.length) {
            let el = this.mapColumnsForFilter(elements, column);
            if (search !== '') {
                el = el.filter((s) => s.toLowerCase().indexOf(search.toLowerCase()) > -1);
            }
            let counts = _.countBy(el);
            let keys = _.keys(counts);
            return of(keys.sort().slice(page * take, page * take + take));
        } else {
            return of([]);
        }
    };

    private mapColumnsForFilter(elements: ApprovalMatrixDTO[], column: string) {
        let result = [];
        if (column === 'approver') {
            if (this.columnApproverTypes) {
                elements = elements.filter((x) =>
                    this.columnApproverTypes.items.includes(this.types.find((f) => f.value === x.approverType).key)
                );
            }
            if (this.columnContracts) {
                elements = elements.filter((x) => this.columnContracts.items.includes(x.contractNo));
            }
            if (this.columnReasonForInstructions) {
                elements = elements.filter((x) =>
                    this.columnReasonForInstructions.items.filter((value) =>
                        x.reasonForInstructions.map((x) => x.name).includes(value)
                    )
                );
            }
            result = elements.map((s) => `${s[column].name} (${s[column].email})`);
        } else if (column === 'approverType') {
            result = elements.map((s) => this.types.find((f) => f.value === s[column]).key);
        } else if (column === 'reasonForInstructions') {
            result = elements.map((s) => s[column].map((m) => m.name));
            result = [].concat(...result);
        } else {
            result = elements.map((s) => s[column]);
        }
        return result;
    }

    openHeaderCheckListFilter(
        columnName: string,
        searchFunc: any,
        propertyName: string,
        placeholder: string,
        selected: CheckListColumn,
        showCounts: boolean = false,
        showInputSearch: boolean = true
    ) {
        this.popupService.openPopup(
            new PopupSettings<HeaderCheckListFilter>(HeaderChecklistFilterComponent, 400, 590, {
                isAscendingSort: this.sortBy === columnName && this.direction === OrderDirection.Asc,
                isDescendingSort: this.sortBy === columnName && this.direction === OrderDirection.Desc,
                placeHolder: placeholder,
                searchFunc: searchFunc,
                selectedItems: selected ? [...selected.items] : [],
                isSortingOff: false,
                showCounts,
                showInputSearch: showInputSearch,
                column: columnName,
                action: (data) => {
                    let value = new CheckListColumn();
                    value.items = data.selectedItems.length > 0 ? data.selectedItems : [];
                    this[propertyName] = value;

                    let results = this.search();
                    this.populateDataTable(results);
                    this.sortUpdate(data.isAscendingSort, data.isDescendingSort, columnName);
                },
                resetColumnAction: () => {
                    this[propertyName] = new CheckListColumn();
                },
            })
        );
    }

    private search() {
        let results = this.allElements;
        if (this.columnContracts && this.columnContracts.items.length) {
            results = results.filter((s) => this.columnContracts.items.indexOf(s.contractNo) > -1);
        }
        if (this.columnApprovers && this.columnApprovers.items.length) {
            results = results.filter(
                (s) => this.columnApprovers.items.indexOf(`${s.approver.name} (${s.approver.email})`) > -1
            );
        }
        if (this.columnApproverTypes && this.columnApproverTypes.items.length) {
            results = results.filter(
                (s) =>
                    this.columnApproverTypes.items.indexOf(this.types.find((f) => f.value === s.approverType).key) > -1
            );
        }
        if (this.columnReasonForInstructions && this.columnReasonForInstructions.items.length) {
            results = results.filter((s) =>
                this.columnReasonForInstructions.items.some(
                    (a) => s.reasonForInstructions.map((m) => m.name).indexOf(a) > -1
                )
            );
        }
        return results;
    }

    private sortUpdate(isAscendingSort: boolean, isDescendingSort: boolean, columnName: string) {
        if (isAscendingSort || isDescendingSort) {
            const direction = isAscendingSort ? 'asc' : 'desc';
            const matSort = this.dataSource.sort;
            const disableClear = false;

            matSort.sort({ id: null, start: direction, disableClear });
            matSort.sort({ id: columnName, start: direction, disableClear });

            this.dataSource.sort = matSort;
            this.direction = isAscendingSort ? OrderDirection.Asc : OrderDirection.Desc;
            this.sortBy = columnName;
        } else {
            this.direction = OrderDirection.Asc;
            this.sortBy = 'approverType';
            const matSort = this.dataSource.sort;
            const disableClear = false;

            matSort.sort({ id: null, start: 'asc', disableClear });
            matSort.sort({ id: this.sortBy, start: 'asc', disableClear });
        }
    }
}
