import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/components/base.component';
import { ExchangeRateEdit } from 'src/app/store/call-off/model';
import { MatTableDataSource } from '@angular/material/table';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { finalize, take, catchError, takeWhile } from 'rxjs/operators';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { ToastService } from 'src/app/services/shared/toast.service';
import { of } from 'rxjs';
import { ConfirmDialogPopupSettings } from 'src/app/models/confirm-dialog-popup-settings';
import { PopupService } from 'src/app/services/shared/popup.service';

@Component({
    selector: 'app-exchange-rate',
    templateUrl: './exchange-rate.component.html',
    styleUrls: ['./exchange-rate.component.scss'],
})
export class ExchangeRateComponent extends BaseComponent implements OnInit {
    resultsLength = 0;
    isLoading = false;
    displayedColumns = ['currency', 'value', 'edit'];

    tooltipDefaultValue = [];
    dataSource = new MatTableDataSource<ExchangeRateEdit>();
    constructor(
        private iconRegistry: MatIconRegistry,
        private sanitizer: DomSanitizer,
        private configurationService: ConfigurationService,
        private toastService: ToastService,
        private popupService: PopupService
    ) {
        super();
        this.iconRegistry.addSvgIcon(
            'edit',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/edit.svg')
        );
    }

    ngOnInit(): void {
        this.getExchangeRates();
    }

    getExchangeRates() {
        this.isLoading = true;
        this.configurationService
            .getExchangeRates()
            .pipe(
                take(1),
                finalize(() => (this.isLoading = false)),
                catchError(() => {
                    this.toastService.Error(
                        'Error has occurred while getting exchange rates. Please contact Program Administrator.'
                    );
                    return of(null);
                })
            )
            .subscribe((rates) => {
                this.dataSource = new MatTableDataSource(rates);
                this.dataSource.data.forEach((x) => {
                    this.tooltipDefaultValue.push('● USD:' + x.currency + ' = 1:' + x.default);
                });
            });
    }

    enterEditMode(exchange: ExchangeRateEdit) {
        exchange.isInEditMode = !exchange.isInEditMode;
        exchange.prevValue = exchange.value;
    }

    save(exchange: ExchangeRateEdit) {
        exchange.isInEditMode = !exchange.isInEditMode;

        if (exchange.value !== exchange.prevValue) {
            this.isLoading = true;
            this.configurationService
                .updateExchangeRate(exchange)
                .pipe(takeWhile(() => this.isAlive === true))
                .subscribe(
                    () => {
                        this.isLoading = false;
                    },
                    (error) => {
                        this.isLoading = false;
                        `Error occurred while updating exchange rate. Please contact Program Administrator.`;
                        this.rollbackChanges(exchange);
                    }
                );
        }
    }

    setDefaults() {
        this.popupService
            .openPopup(
                new ConfirmDialogPopupSettings({
                    title: 'Confirm action',
                    text: `Are you sure you want to reset current exchange rates to default?`,
                })
            )
            .afterClosed()
            .pipe(takeWhile(() => this.isAlive === true))
            .subscribe((answer) => {
                if (answer) {
                    this.isLoading = true;
                    this.configurationService
                        .updateExchangeRateToDefault()
                        .pipe(takeWhile(() => this.isAlive === true))
                        .subscribe(
                            () => {
                                this.isLoading = false;
                                this.getExchangeRates();
                            },
                            () => {
                                this.isLoading = false;
                                `Error occurred while updating exchange rate. Please contact Program Administrator.`;
                            }
                        );
                }
            });
    }

    cancelEdit(exchange: ExchangeRateEdit) {
        exchange.isInEditMode = !exchange.isInEditMode;
        this.rollbackChanges(exchange);
    }

    private rollbackChanges(exchange: ExchangeRateEdit) {
        exchange.value = exchange.prevValue;
    }
}
