import { ActionUsersActionTypes, ActionUsersAction } from './actions';
import { ActionUsersState } from './model';

const initialState: ActionUsersState = {
    companyRepresentatives: [],
    isCompanyRepresentativesLoading: false,
    contractorRepresentatives: [],
    isContractorRepresentativesLoading: false,
    contractsTeamReviewers: [],
    isContractsTeamReviewersLoading: false,
};

export function reducer(state: ActionUsersState = initialState, action: ActionUsersAction) {
    switch (action.type) {
        case ActionUsersActionTypes.ContractorRepresentativesRequest: {
            return {
                ...state,
                isContractorRepresentativesLoading: true,
            };
        }
        case ActionUsersActionTypes.ContractorRepresentativesRequestSuccess: {
            return {
                ...state,
                contractorRepresentatives: action.payload,
                isContractorRepresentativesLoading: false,
            };
        }
        case ActionUsersActionTypes.ContractorRepresentativesRequestError: {
            return {
                ...state,
                isContractorRepresentativesLoading: false,
            };
        }
        case ActionUsersActionTypes.CompanyRepresentativesRequest: {
            return {
                ...state,
                isCompanyRepresentativesLoading: true,
            };
        }
        case ActionUsersActionTypes.CompanyRepresentativesRequestSuccess: {
            return {
                ...state,
                companyRepresentatives: action.payload,
                isCompanyRepresentativesLoading: false,
            };
        }
        case ActionUsersActionTypes.CompanyRepresentativesRequestError: {
            return {
                ...state,
                isCompanyRepresentativesLoading: false,
            };
        }
        case ActionUsersActionTypes.ContractsTeamReviewersRequest: {
            return {
                ...state,
                isContractsTeamReviewersLoading: true,
            };
        }
        case ActionUsersActionTypes.ContractsTeamReviewersRequestSuccess: {
            return {
                ...state,
                contractsTeamReviewers: action.payload,
                isContractsTeamReviewersLoading: false,
            };
        }
        case ActionUsersActionTypes.ContractsTeamReviewersRequestError: {
            return {
                ...state,
                isContractsTeamReviewersLoading: false,
            };
        }
        case ActionUsersActionTypes.AddPowerUserToContractorRepresentatives: {
            return {
                ...state,
                contractorRepresentatives: [action.payload, ...state.contractorRepresentatives],
            };
        }
        case ActionUsersActionTypes.AddPowerUserToCompanyRepresentatives: {
            return {
                ...state,
                companyRepresentatives: [...state.companyRepresentatives, action.payload],
            };
        }
        default: {
            return state;
        }
    }
}
