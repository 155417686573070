import { Component, EventEmitter, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ToastService } from 'src/app/services/shared/toast.service';
import { BaseComponent } from 'src/app/components/base.component';
import { Contractor, UserWithRoleDetail, AppRole } from 'src/app/store/common.model';
import { takeWhile } from 'rxjs/operators';
import { UserToContractsDTO } from 'src/app/models/user-to-contracts-dto';
import { UserToContractsService } from 'src/app/services/user-to-contracts.service';
import { of } from 'rxjs';
import { SetInputEventArgs } from 'src/app/models/set-input';

@Component({
    selector: 'app-add-user-to-contracts-popup',
    templateUrl: './add-user-to-contracts-popup.component.html',
    styleUrls: ['./add-user-to-contracts-popup.component.scss'],
})
export class AddUserToContractsPopupComponent extends BaseComponent {
    loading: boolean;
    addUserToContractsForm: FormGroup;
    contractors: Contractor[] = [];
    users: UserWithRoleDetail[] = [];
    roleCurrent: AppRole;
    types = [];
    setUserInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    autocompleteDisplayedColumns = ['name', 'email'];

    constructor(
        public dialogRef: MatDialogRef<AddUserToContractsPopupComponent>,
        @Inject(MAT_DIALOG_DATA) data,
        private toastService: ToastService,
        private userToContractsService: UserToContractsService
    ) {
        super();
        this.contractors = data.contractors;
        this.users = data.users;
        this.types = data.types;

        this.addUserToContractsForm = new FormGroup({
            userRole: new FormControl('', Validators.required),
            contracts: new FormControl('', Validators.required),
            user: new FormControl('', Validators.required),
        });
    }

    public onCancel(): void {
        this.dialogRef.close();
    }

    public onConfirm(): void {
        this.loading = true;
        const form = new UserToContractsDTO();

        form.contracts = this.addUserToContractsForm.controls.contracts.value;
        form.userRole = this.addUserToContractsForm.controls.userRole.value;
        form.user = this.addUserToContractsForm.controls.user.value[0];

        this.userToContractsService
            .add(form)
            .pipe(takeWhile(() => this.isAlive === true))
            .subscribe(
                (data) => {
                    this.loading = false;
                    this.toastService.Success(`${form.user.name} has been added to the user contracts.`);
                    this.dialogRef.close({ success: true, data: data });
                },
                (error) => {
                    this.loading = false;
                    if (error.status === 409) {
                        this.toastService.Error(
                            `${this.findRole(this.types, form.userRole)} ${
                                form.user.name
                            } already exists in user contracts.`
                        );
                    } else {
                        this.toastService.Error(
                            'An error occurred while adding an user to the user contracts. Please contact a Program Administrator.'
                        );
                    }
                }
            );
    }

    onRoleClosed(isOpen: boolean) {
        if (!isOpen && this.roleCurrent !== this.addUserToContractsForm.controls.userRole.value) {
            this.roleCurrent = this.addUserToContractsForm.controls.userRole.value;
            this.addUserToContractsForm.controls['user'].setValue('');
            this.setUserInput.emit(new SetInputEventArgs(false, ''));
        }
    }

    getUsers = (search = '', take = 10, page = 0) => {
        let elements = this.users.filter(
            (s) => s.role === this.roleCurrent && s.name.toLowerCase().indexOf(search.toLowerCase()) > -1
        );
        if (elements.length) {
            return of(elements.slice(page * take, page * take + take));
        } else {
            return of([]);
        }
    };

    onUsersClosed() {
        this.setUserInput.emit(new SetInputEventArgs(true));
    }
}
