<div *ngIf="isIE" class="IEnotification doNotPrint">
    {{ userName }}, CallOff works best when accessed on newer browsers. Please use Google Chrome or Microsoft Edge
    browsers to access CallOff.
</div>
<header class="doNotPrint">
    <div class="header-container">
        <div class="header-side--left">
            <div class="header-logo">
                <a id="chevron-logo-tco" [routerLink]="['/']"></a>
            </div>
            <div class="header-title">
                <div class="header-title--main">TENGIZCHEVROIL</div>
                <div class="header-title--sub">FGP Call-Off Register</div>
            </div>
        </div>
        <div class="header-side--right">
            <div class="person-icon">
                <div id="hover-content">
                    Email: {{ emailAddress }}
                    Application Roles:
                    <ul>
                        <li *ngFor="let role of roles">
                            {{ role }}
                        </li>
                    </ul>
                </div>
            </div>
            <div *ngIf="!isAdmin" class="username-container">
                <div class="username">
                    {{ userName }}
                </div>
            </div>
            <div *ngIf="isAdmin" class="username-container-pointer" [routerLink]="['/admin']">
                <div class="username">
                    {{ userName }}
                </div>
                <div class="username-role">Admin Manager</div>
            </div>
        </div>
    </div>
</header>
