<div class="popup">
    <p mat-dialog-title class="title"><span class="font-gotham-narrow-bold">add approval matrix</span></p>
    <div>
        <form [formGroup]="addApprovalMatrixForm">
            <mat-form-field>
                <mat-label>Approver Type</mat-label>
                <mat-select [compareWith]="compareRoles" formControlName="approverType" (openedChange)="onRoleClosed($event)">
                    <mat-option *ngFor="let c of types" [value]="c.value">
                        {{ c.key }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <div class="user-selector">
                <app-multiple-items-selector
                    formControlName="approver"
                    [filterForm]="addApprovalMatrixForm"
                    [formSetter]="'approver'"
                    [isAsync]="true"
                    [searchFunc]="getUsers"
                    propertyToShow="name"
                    placeholder="Approver"
                    [setInput]="setUserInput"
                    [ngStyle]="{ display: isIE === true ? 'inline' : null }"
                    [oneItemMode]="true"
                    (autocompleteClosed)="onUsersClosed()"
                    (itemRemoved)="onUsersClosed()"
                    [isTableAutoComplete]="true"
                    [displayedColumns]="autocompleteDisplayedColumns"
                >
                </app-multiple-items-selector>
            </div>
            <mat-form-field>
                <mat-label>Reason For Instruction</mat-label>
                <mat-select formControlName="reasonForInstructions" multiple>
                    <mat-option *ngFor="let reason of reasons" [value]="reason">
                        {{ reason.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field>
                <mat-label>Contractor No</mat-label>
                <mat-select formControlName="contractNo">
                    <mat-option *ngFor="let c of contractors" [value]="c.contractNo">{{ c.contractNo }}</mat-option>
                </mat-select>
            </mat-form-field>
            <div class="row">
                <button mat-button type="button" class="cof-button cof-button-white" (click)="onCancel()">
                    cancel
                </button>
                <button
                    mat-button
                    type="button"
                    [disabled]="!addApprovalMatrixForm.valid"
                    class="cof-button cof-button-light"
                    (click)="onConfirm()"
                >
                    add
                </button>
            </div>
            <app-loading-indicator *ngIf="loading"> </app-loading-indicator>
        </form>
    </div>
</div>
