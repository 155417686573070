<div class="contractors-estimate-container">
    <div [formGroup]="callOffForm">
        <div class="position">
            <div class="title">
                contractor's estimate{{
                    callOffForm.controls.reasonForInstructionId.value === 1 ? ' - nil value field clarification' : ''
                }}
            </div>
            <div class="estimate-details-container">
                <div class="doc-title">
                    <div
                        [ngClass]="{
                            'mat-error':
                                callOffForm.controls['estimateDetails'].invalid &&
                                callOffForm.controls['estimateDetails'].errors.required
                        }"
                    >
                        Estimate Details
                    </div>
                </div>
                <div class="separator-10px">
                    <div
                        class="jodit-container"
                        [ngClass]="{
                            'description-error':
                                callOffForm.controls['estimateDetails'].invalid &&
                                callOffForm.controls['estimateDetails'].errors.required,
                            'description-disabled':
                                callOffForm.disabled || callOffForm.controls.estimateDetails.disabled
                        }"
                        (click)="openJoditPopup(callOffForm.controls['estimateDetails']?.value)"
                    >
                        <div
                            *ngIf="!isEstimateDetailsLoading"
                            [innerHTML]="callOffForm.controls['estimateDetails']?.value | keepHtml"
                        ></div>
                        <div *ngIf="isEstimateDetailsLoading" class="jodit-loader">
                            <div class="lds-hourglass"></div>
                        </div>
                    </div>
                </div>
                <mat-error
                    class="error-description"
                    *ngIf="
                        callOffForm.controls['estimateDetails'].invalid &&
                        callOffForm.controls['estimateDetails'].errors.required
                    "
                >
                    Solution Description is required.
                </mat-error>
                <div class="nte-container">
                    <div class="container-nte-radiobuttons">
                        <mat-label class="title-nte-radiobuttons" class="margin-right20">Pricing Mechanism</mat-label>
                        <mat-radio-group formControlName="pricingMechanism">
                            <mat-radio-button
                                *ngFor="let pmv of pricingMechanismValues"
                                [value]="+pmv.id"
                                class="margin-right20"
                            >
                                {{
                                    pmv.value === 'Reimbursable'
                                        ? 'Reimbursable / Day Works'
                                        : pmv.value === 'UnitRate'
                                        ? 'Unit Rate / Star Rate'
                                        : pmv.value
                                }}
                            </mat-radio-button>
                        </mat-radio-group>
                    </div>
                    <div class="container-nte-text-inputs">
                        <mat-form-field *ngIf="contractorCurrencies['USD']" class="margin-right20">
                            <mat-label>USD</mat-label>
                            <input
                                matInput
                                currencyMask
                                formControlName="usd"
                                (focusout)="this.calculateNteUsd('usd')"
                            />
                            <mat-error *ngIf="callOffForm.controls.usd.errors?.limitExceeded">
                                Value of USD cannot be greater than remaining value of $ {{callOffForm.value.awe.usdRemaining}}
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field *ngIf="contractorCurrencies['KZT']" class="margin-right20">
                            <mat-label>KZT</mat-label>
                            <input
                                matInput
                                currencyMask
                                formControlName="kzt"
                                [options]="{ prefix: 'KZT ' }"
                                (focusout)="calculateNteUsd('kzt')"
                            />
                            <mat-error *ngIf="callOffForm.controls.kzt.errors?.limitExceeded">
                                Value of KZT cannot be greater than remaining value of KZT {{callOffForm.value.awe.kztRemaining}}
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field *ngIf="contractorCurrencies['RUB']" class="margin-right20">
                            <mat-label>RUB</mat-label>
                            <input
                                matInput
                                currencyMask
                                formControlName="rub"
                                [options]="{ prefix: 'RUB ' }"
                                (focusout)="calculateNteUsd('rub')"
                            />
                            <mat-error *ngIf="callOffForm.controls.rub.errors?.limitExceeded">
                                Value of RUB cannot be greater than remaining value of RUB {{callOffForm.value.awe.rubRemaining}}
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field *ngIf="contractorCurrencies['EUR']" class="margin-right20">
                            <mat-label>EUR</mat-label>
                            <input
                                matInput
                                currencyMask
                                formControlName="eur"
                                [options]="{ prefix: '€ ' }"
                                (focusout)="calculateNteUsd('eur')"
                            />
                            <mat-error *ngIf="callOffForm.controls.eur.errors?.limitExceeded">
                                Value of EUR cannot be greater than remaining value of € {{callOffForm.value.awe.eurRemaining}}
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field *ngIf="anyContractorCurrency" class="nte-equiv">
                            <mat-label>NTE USD Equiv.</mat-label>
                            <input
                                matInput
                                currencyMask
                                formControlName="nteUsd"
                                readonly
                                [options]="{ align: 'left' }"
                            />
                            <mat-error *ngIf="callOffForm.controls['nteUsd'].errors?.limitExceeded">
                                Value of Call-Off exceeds authorised individual amount of ${{
                                    callOffForm.controls['contractorLimit'].value
                                }}
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div>
                    <app-cof-attachment-sia
                        formControlName="EstimateAttachments"
                        headerText="Estimate Attachments - Attachments not visible to Read-Only users."
                        [callOffForm]="callOffForm"
                        [printMode]="printMode"
                        [type]="attachmentTypes.Estimate"
                        [displayNote]="false"
                    >
                    </app-cof-attachment-sia>
                </div>
            </div>
        </div>
    </div>
</div>
