import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatIconRegistry } from '@angular/material/icon';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DomSanitizer } from '@angular/platform-browser';
import { takeWhile, take, catchError, tap } from 'rxjs/operators';
import { Contractor, OrderDirection } from 'src/app/store/common.model';
import { PopupService } from 'src/app/services/shared/popup.service';
import { ConfirmDialogPopupSettings } from 'src/app/models/confirm-dialog-popup-settings';
import { PopupSettings } from 'src/app/models/popup-settings';
import { AddNTENotificationPopupComponent } from './add-nte-notification-popup/add-nte-notification-popup.component';
import { BaseComponent } from 'src/app/components/base.component';
import { ToastService } from 'src/app/services/shared/toast.service';
import { forkJoin } from 'rxjs';
import { NTENotificationDTO } from '../../../../models/nte-notification-dto';
import { NTENotificationService } from '../../../../services/nte-notification.service';
import { isCommaSepEmail } from 'src/app/validators/comma-seperated-emails-validator';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { Setting } from 'src/app/models/setting';

@Component({
    selector: 'app-nte-notification',
    templateUrl: './nte-notification.component.html',
    styleUrls: ['./nte-notification.component.scss'],
})
export class NTENotificationComponent extends BaseComponent implements OnInit {
    resultsLength = 0;
    pageSize = 10;
    sortBy: string = 'contract';
    direction = OrderDirection.Desc;
    isLoading = false;
    filterForm: FormGroup;
    displayedColumns = ['contract', 'percentageLimit', 'emails', 'edit', 'remove'];
    dataSource = new MatTableDataSource<NTENotificationDTO>();
    contractors: Contractor[] = [];
    emailsNotValid = false;
    notificationsEnabled = false;
    settings: Setting[];

    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;

    constructor(
        private iconRegistry: MatIconRegistry,
        private sanitizer: DomSanitizer,
        private popupService: PopupService,
        private nteNotificationService: NTENotificationService,
        private toastService: ToastService,
        private configurationService: ConfigurationService
    ) {
        super();

        this.iconRegistry.addSvgIcon(
            'edit',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/edit.svg')
        );
        this.iconRegistry.addSvgIcon(
            'delete',
            sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/delete.svg')
        );
        this.iconRegistry.addSvgIcon('add', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/add.svg'));
    }

    ngOnInit(): void {
        this.isLoading = true;
        forkJoin([this.nteNotificationService.get(), this.configurationService.getConfigData()])
            .pipe(
                take(1),
                catchError(() => {
                    throw new Error(
                        'Error occurred while getting user to contracts data. Please contact Program Administrator.'
                    );
                })
            )
            .subscribe(
                ([notifications, settings]) => {
                    this.dataSource = new MatTableDataSource(notifications);
                    this.resultsLength = this.dataSource.data.length;
                    this.dataSource.sort = this.sort;
                    this.dataSource.paginator = this.paginator;
                    this.settings = settings;
                    this.notificationsEnabled = this.settings.find((s) => s.id === 2).value === 'true';
                    this.isLoading = false;
                },
                (error) => {
                    this.isLoading = false;
                    this.toastService.Error(error);
                }
            );
    }

    toggleEnableNotifications() {
        this.settings.find((s) => s.id === 2).value = String(!this.notificationsEnabled);
        this.updateConfigData();
    }

    updateConfigData() {
        this.isLoading = true;
        this.configurationService
            .updateConfigData(this.settings)
            .pipe(
                takeWhile(() => this.isAlive),
                tap(() => (this.isLoading = true))
            )
            .subscribe(
                () => {
                    this.isLoading = false;
                    this.toastService.Success('Configuration has been updated.');
                },
                () => {
                    this.isLoading = false;
                    this.toastService.Error(
                        'An error occured while updating configuration. Configuration has not been updated.'
                    );
                }
            );
    }

    onEmailsChange(input: string) {
        this.emailsNotValid = !input || isCommaSepEmail(input);
    }

    remove(element: NTENotificationDTO) {
        this.popupService
            .openPopup(
                new ConfirmDialogPopupSettings({
                    title: 'Confirm action',
                    text: `Are you sure you want to delete ${element.contract} from automated AWE NTE notification records?`,
                })
            )
            .afterClosed()
            .pipe(takeWhile(() => this.isAlive === true))
            .subscribe((answer) => {
                if (answer) {
                    this.isLoading = true;
                    this.nteNotificationService
                        .remove(element.contract)
                        .pipe(takeWhile(() => this.isAlive === true))
                        .subscribe(
                            () => {
                                var data = this.dataSource.data.filter((s) => !(s.contract === element.contract));
                                this.dataSource = new MatTableDataSource(data);
                                this.resultsLength = this.dataSource.data.length;
                                this.dataSource.sort = this.sort;
                                this.dataSource.paginator = this.paginator;
                                this.isLoading = false;
                            },
                            () => {
                                this.isLoading = false;
                                this.toastService.Error(
                                    `Error occurred while deleting ${element.contract}. Please contact Program Administrator.`
                                );
                            }
                        );
                }
            });
    }

    add() {
        const dialogRef = this.popupService.openPopup(new PopupSettings(AddNTENotificationPopupComponent, 450, 450));

        dialogRef
            .afterClosed()
            .pipe(takeWhile(() => this.isAlive === true))
            .subscribe((result) => {
                if (result && result.success) {
                    const data = this.dataSource.data;
                    data.push(result.data);
                    this.dataSource.data = data;
                    this.resultsLength = this.dataSource.data.length;
                    this.dataSource.sort = this.sort;
                    this.dataSource.paginator = this.paginator;
                }
            });
    }

    enterEditMode(notification: NTENotificationDTO) {
        notification.isInEditMode = !notification.isInEditMode;
        notification.prevEmails = notification.emails;
        notification.prevPercentageLimit = notification.percentageLimit;
    }

    save(notification: NTENotificationDTO) {
        notification.isInEditMode = !notification.isInEditMode;

        if (
            notification.emails !== notification.prevEmails ||
            notification.percentageLimit !== notification.prevPercentageLimit
        ) {
            this.isLoading = true;
            this.nteNotificationService
                .update(notification)
                .pipe(takeWhile(() => this.isAlive === true))
                .subscribe(
                    () => {
                        this.isLoading = false;
                    },
                    (error) => {
                        this.isLoading = false;
                        if (error.status === 409) {
                            this.toastService.Error(
                                `${notification.contract} already exists in the user to contracts.`
                            );
                        } else {
                            this.toastService.Error(
                                `Error occurred while updating ${notification.contract}. Please contact Program Administrator.`
                            );
                        }
                        this.rollbackChanges(notification);
                    }
                );
        }
    }

    cancelEdit(notification: NTENotificationDTO) {
        notification.isInEditMode = !notification.isInEditMode;
        this.rollbackChanges(notification);
    }

    onPageChange(newPage: number) {
        if (newPage < 1) {
            newPage = 1;
        } else if (newPage > this.paginator.getNumberOfPages()) {
            newPage = this.paginator.getNumberOfPages();
        }
        this.paginator.pageIndex = newPage - 1;
        this.dataSource.paginator = this.paginator;
    }

    private rollbackChanges(userToContracts: NTENotificationDTO) {
        userToContracts.emails = userToContracts.prevEmails;
        userToContracts.percentageLimit = userToContracts.prevPercentageLimit;
    }
}
