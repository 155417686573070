<h3>close-out COF as work complete</h3>
<form [formGroup]="data.callOffForm">
    <app-cof-attachment-sia
        *ngIf="!isCOFCompleted"
        formControlName="COFCompletedAttachments"
        [callOffForm]="data.callOffForm"
        [type]="attachmentTypes.COFCompleted"
        [displayNote]="false"
        headerText="attach COF Completion Certificate"
    >
    </app-cof-attachment-sia>
    <app-cof-attachment-sia
        *ngIf="isCOFCompleted"
        formControlName="COFCompanyCompletedAttachments"
        [callOffForm]="data.callOffForm"
        [type]="attachmentTypes.COFCompanyCompleted"
        [displayNote]="false"
        headerText="attach COF Company Completion Certificate"
    >
    </app-cof-attachment-sia>
    <div class="buttons-container flex center">
        <button class="cof-button cof-button-white" (click)="cancel()">cancel</button>
        <button
            type="button"
            class="cof-button cof-buton-light"
            (click)="signAndClose()"
            [disabled]="!data.callOffForm.valid"
        >
            sign & close-out COF
        </button>
    </div>
</form>
