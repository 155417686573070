import { Component, Inject, OnInit } from '@angular/core';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { Router } from '@angular/router';
import { ApplicationInsightsConfig, APP_INSIGHTS_CONFIG } from './app.config';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { filter } from 'rxjs/operators';
import { AuthenticationResult, EventMessage, EventType } from '@azure/msal-browser';
import { ApplicationState } from './store/model';
import { Store } from '@ngrx/store';
import { synchronizeUser } from './store/user/actions';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    isIframe = false;

    constructor(
        @Inject(APP_INSIGHTS_CONFIG) config: ApplicationInsightsConfig,
        private authService: MsalService,
        private router: Router,
        private broadcastService: MsalBroadcastService,
        private store: Store<ApplicationState>
    ) {
        if(config.instrumentationKey) {
            const appInsights = new ApplicationInsights({
                config: {
                    instrumentationKey: config.instrumentationKey
                }
            });
            appInsights.loadAppInsights();
            appInsights.trackPageView();
        }
    }

    ngOnInit() {
        this.broadcastService.msalSubject$
            .pipe(filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS))
            .subscribe((result: EventMessage) => {
                const payload = result.payload as AuthenticationResult;
                this.authService.instance.setActiveAccount(payload.account);
            });

        this.broadcastService.msalSubject$
            .pipe(filter((msg: EventMessage) => msg.eventType === EventType.HANDLE_REDIRECT_END))
            .subscribe(() => this.store.dispatch(synchronizeUser()));
            //.subscribe(() => this.router.navigate(['/synchronize']));
    }
}
