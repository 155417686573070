import { ConstructionApproverMatrixActions, ConstructionApproverMatrixActionTypes } from './actions';
import {
    ConstructionApproverMatrixFilter,
    ConstructionApproverMatrixResultData,
    ConstructionApproverMatrixState,
} from './models';

const initialState: ConstructionApproverMatrixState = {
    data: new ConstructionApproverMatrixResultData(),
    isLoading: false,
    filter: new ConstructionApproverMatrixFilter(),
};

export function reducer(state = initialState, action: ConstructionApproverMatrixActions) {
    switch (action.type) {
        case ConstructionApproverMatrixActionTypes.DataRequest: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case ConstructionApproverMatrixActionTypes.DataRequestSuccess: {
            return {
                ...state,
                data: action.payload,
                isLoading: false,
            };
        }
        case ConstructionApproverMatrixActionTypes.DataRequestError: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case ConstructionApproverMatrixActionTypes.CreateRequest: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case ConstructionApproverMatrixActionTypes.CreateRequestSuccess: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case ConstructionApproverMatrixActionTypes.CreateRequestError: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case ConstructionApproverMatrixActionTypes.DeleteRequest: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case ConstructionApproverMatrixActionTypes.DeleteRequestSuccess: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case ConstructionApproverMatrixActionTypes.DeleteRequestError: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case ConstructionApproverMatrixActionTypes.UpdateRequest: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case ConstructionApproverMatrixActionTypes.UpdateRequestSuccess: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case ConstructionApproverMatrixActionTypes.UpdateRequestError: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case ConstructionApproverMatrixActionTypes.FilterPropertyUpdate: {
            if (action.payload.key === 'sortBy') {
                state = {
                    ...state,
                    filter: {
                        ...state.filter,
                        sortBy: action.payload.value.active,
                        direction: action.payload.value.direction,
                    },
                };
            } else {
                state = {
                    ...state,
                    filter: {
                        ...state.filter,
                        [action.payload.key]: Array.isArray(state.filter[action.payload.key])
                            ? [...action.payload.value]
                            : action.payload.value,
                    },
                };
            }

            return {
                ...state,
            };
        }
        default: {
            return state;
        }
    }
}
