import { AWE } from '../awe-form/model';
import { OrderDirection, Contractor, CheckListColumn, RangeColumn } from 'src/app/store/common.model';

export class AweRegisterFilter {
    page = 0;
    take = 10;
    sortBy = 'number';
    direction: OrderDirection = OrderDirection.Desc;
    contractors: Contractor[] = [];
    aweTitle = '';
    aweNumber = '';
    columnIsActive: CheckListColumn = null;
    columnNte: RangeColumn = null;
    columnCofValue: RangeColumn = null;
    columnRemaining: RangeColumn = null;
    columnAWENumbers: CheckListColumn = null;
    columnTitles: CheckListColumn = null;
    columnRevisions: CheckListColumn = null;
    columnContracts: CheckListColumn = null;
    timezoneOffset = 0;
}

export class AweRegisterDataPagination {
    items: AWE[] = [];
    totalCount = 0;
}

export interface AweRegisterState {
    dataPagination: AweRegisterDataPagination;
    isLoading: boolean;
    filter: AweRegisterFilter;
}
