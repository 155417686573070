import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Store } from '@ngrx/store';
import { takeWhile } from 'rxjs/operators';
import { BaseComponent } from 'src/app/components/base.component';
import { PopupSettings } from 'src/app/models/popup-settings';
import { PopupService } from 'src/app/services/shared/popup.service';
import { AppRole, CheckListColumn, OrderDirection, UserWithRoleDetail } from 'src/app/store/common.model';
import { ApplicationState } from 'src/app/store/model';
import {
    ConstructionApproverMatrixDTO,
    ConstructionApproverMatrixFilter,
} from '../../../../store/construction-approver-matrix/models';
import {
    ConstructionApproverMatrixCreateRequest,
    ConstructionApproverMatrixDataRequest,
    ConstructionApproverMatrixDeleteRequest,
    ConstructionApproverMatrixFilterPropertyUpdate,
    ConstructionApproverMatrixUpdateRequest,
} from '../../../../store/construction-approver-matrix/actions';
import { AddConstructionApproverPopupComponent } from './add-construction-approver-popup/add-construction-approver-popup.component';
import { LookupService } from 'src/app/services/lookup.service';
import { ConfirmDialogPopupSettings } from 'src/app/models/confirm-dialog-popup-settings';
import { isCommaSepEmail } from 'src/app/validators/comma-seperated-emails-validator';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { ConstructionApproverMatrixService } from 'src/app/services/constructionApproverMatrix.service';
import { HeaderCheckListFilter } from 'src/app/models/filter-settings';
import { HeaderChecklistFilterComponent } from 'src/app/components/filter/header-checklist-filter/header-checklist-filter.component';
import { MatTableDataSource } from '@angular/material/table';

@Component({
    selector: 'app-construction-approver-matrix',
    templateUrl: './construction-approver-matrix.component.html',
    styleUrls: ['./construction-approver-matrix.component.scss'],
})
export class ConstructionApproverMatrixComponent extends BaseComponent implements OnInit {
    displayedColumns = ['originator', 'emails', 'edit', 'remove'];
    dataSource = new MatTableDataSource<ConstructionApproverMatrixDTO>();
    dataSource$ = this.store.select((state) => state.constructionApproverMatrixState.data);
    isLoading = false;
    isLoading$ = this.store.select((state) => state.constructionApproverMatrixState.isLoading);

    filter: ConstructionApproverMatrixFilter;
    filter$ = this.store.select((state) => state.constructionApproverMatrixState.filter);

    pageSize = 10;
    resultsLength = 0;
    sortBy: string = 'approver';
    direction: OrderDirection = OrderDirection.Asc;
    direction$ = this.store.select((state) => state.constructionApproverMatrixState.filter.direction);
    sortBy$ = this.store.select((state) => state.constructionApproverMatrixState.filter.sortBy);

    users: UserWithRoleDetail[];
    emailsNotValid = false;

    columnApprovers: CheckListColumn = null;
    columnApprovers$ = this.store.select((state) => state.constructionApproverMatrixState.filter.columnApprovers);

    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;

    constructor(
        private popupService: PopupService,
        private store: Store<ApplicationState>,
        private lookupService: LookupService,
        private iconRegistry: MatIconRegistry,
        private sanitizer: DomSanitizer,
        private constructionApproverMatrixService: ConstructionApproverMatrixService
    ) {
        super();

        this.iconRegistry.addSvgIcon(
            'edit',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/edit.svg')
        );
        this.iconRegistry.addSvgIcon(
            'delete',
            sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/delete.svg')
        );
        this.iconRegistry.addSvgIcon('add', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/add.svg'));
    }

    ngOnInit(): void {
        this.dataSource$.pipe(takeWhile(() => this.isAlive)).subscribe((data) =>
            this.populateDataTable(
                data.items.map((d) => Object.assign({}, d, { isInEditMode: false, prevEmails: null })),
                data.totalCount
            )
        );

        this.isLoading$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.isLoading = data));
        this.filter$.pipe(takeWhile(() => this.isAlive)).subscribe((filter) => (this.filter = filter));
        this.direction$.pipe(takeWhile(() => this.isAlive)).subscribe((direction) => (this.direction = direction));
        this.sortBy$.pipe(takeWhile(() => this.isAlive)).subscribe((sortBy) => (this.sortBy = sortBy));

        this.columnApprovers$
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((column) => (this.columnApprovers = column));

        this.lookupService
            .searchUsersWithRoles([AppRole.CallOffUser])
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((data) => (this.users = data));

        this.store.dispatch(new ConstructionApproverMatrixDataRequest());
    }

    onPageChange(newPage: number) {
        if (newPage < 1) {
            newPage = 1;
        } else if (newPage > this.paginator.getNumberOfPages()) {
            newPage = this.paginator.getNumberOfPages();
        }
        this.paginator.pageIndex = newPage - 1;

        var pageEvt = new PageEvent();
        pageEvt.pageIndex = newPage - 1;
        pageEvt.pageSize = this.pageSize;
        this.paginator.pageIndex = pageEvt.pageIndex;
        this.onPaginatorChange(pageEvt);
    }

    onPaginatorChange(pageEvent: PageEvent) {
        if (this.pageSize !== pageEvent.pageSize) {
            this.store.dispatch(
                new ConstructionApproverMatrixFilterPropertyUpdate({
                    key: 'take',
                    value: pageEvent.pageSize,
                })
            );
            this.pageSize = pageEvent.pageSize;
        } else {
            this.store.dispatch(
                new ConstructionApproverMatrixFilterPropertyUpdate({
                    key: 'page',
                    value: pageEvent.pageIndex,
                })
            );
        }
        this.store.dispatch(new ConstructionApproverMatrixDataRequest());
    }

    add() {
        const dialogRef = this.popupService.openPopup(
            new PopupSettings(AddConstructionApproverPopupComponent, 450, 500, {
                users: this.users,
            })
        );

        dialogRef
            .afterClosed()
            .pipe(takeWhile(() => this.isAlive === true))
            .subscribe((result) => {
                if (result && result.success) {
                    this.store.dispatch(new ConstructionApproverMatrixCreateRequest(result.data));
                }
            });
    }

    populateDataTable(approvalMatrix: ConstructionApproverMatrixDTO[], totalCount: number) {
        this.dataSource.data = approvalMatrix;
        this.resultsLength = totalCount;
    }

    enterEditMode(approvalMatrix: ConstructionApproverMatrixDTO) {
        approvalMatrix.isInEditMode = !approvalMatrix.isInEditMode;
        approvalMatrix.prevEmails = approvalMatrix.emails;
    }

    save(approvalMatrix: ConstructionApproverMatrixDTO) {
        approvalMatrix.isInEditMode = false;
        this.store.dispatch(new ConstructionApproverMatrixUpdateRequest(approvalMatrix));
    }

    cancelEdit(approvalMatrix: ConstructionApproverMatrixDTO) {
        approvalMatrix.isInEditMode = false;
        this.rollbackChanges(approvalMatrix);
    }

    private rollbackChanges(approvalMatrix: ConstructionApproverMatrixDTO) {
        approvalMatrix.emails = approvalMatrix.prevEmails;
    }

    remove(element: ConstructionApproverMatrixDTO) {
        this.popupService
            .openPopup(
                new ConfirmDialogPopupSettings({
                    title: 'Confirm action',
                    text: `Are you sure you want to delete ${element.approver.name} from construction approval matrix?`,
                })
            )
            .afterClosed()
            .pipe(takeWhile(() => this.isAlive === true))
            .subscribe((answer) => {
                if (answer) {
                    this.isLoading = true;
                    this.store.dispatch(new ConstructionApproverMatrixDeleteRequest(element.id));
                }
            });
    }

    onEmailsChange(input: string) {
        this.emailsNotValid = !input || isCommaSepEmail(input);
    }

    getApprovers = (search: string, take = 10, page = 0) => {
        return this.constructionApproverMatrixService.getApproversForFilter(search, take, page, this.filter);
    };

    openHeaderCheckListFilter(
        columnName: string,
        searchFunc: any,
        propertyName: string,
        placeholder: string,
        selected: CheckListColumn,
        showCounts: boolean = false,
        showInputSearch: boolean = true
    ) {
        this.popupService.openPopup(
            new PopupSettings<HeaderCheckListFilter>(HeaderChecklistFilterComponent, 400, 590, {
                isAscendingSort: this.sortBy === columnName && this.direction === OrderDirection.Asc,
                isDescendingSort: this.sortBy === columnName && this.direction === OrderDirection.Desc,
                placeHolder: placeholder,
                searchFunc: searchFunc,
                selectedItems: selected ? [...selected.items] : [],
                isSortingOff: false,
                showCounts,
                showInputSearch: showInputSearch,
                column: columnName,
                action: (data) => {
                    let value = new CheckListColumn();
                    value.items = data.selectedItems.length > 0 ? data.selectedItems : [];

                    this.store.dispatch(
                        new ConstructionApproverMatrixFilterPropertyUpdate({
                            key: propertyName,
                            value: value,
                        })
                    );

                    this.sortUpdate(data.isAscendingSort, data.isDescendingSort, columnName);
                    this.store.dispatch(new ConstructionApproverMatrixDataRequest());
                },
                resetColumnAction: () => {
                    this.store.dispatch(
                        new ConstructionApproverMatrixFilterPropertyUpdate({
                            key: propertyName,
                            value: null,
                        })
                    );
                },
            })
        );
    }

    private sortUpdate(isAscendingSort: boolean, isDescendingSort: boolean, columnName: string) {
        if (isAscendingSort || isDescendingSort) {
            const direction: OrderDirection = isAscendingSort ? OrderDirection.Asc : OrderDirection.Desc;
            this.store.dispatch(
                new ConstructionApproverMatrixFilterPropertyUpdate({
                    key: 'sortBy',
                    value: { active: columnName, direction: direction },
                })
            );
        } else {
            this.store.dispatch(
                new ConstructionApproverMatrixFilterPropertyUpdate({
                    key: 'sortBy',
                    value: { active: 'approver', direction: OrderDirection.Desc },
                })
            );
        }
    }
}
