import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { UserDetail } from 'src/app/store/common.model';
import { Store } from '@ngrx/store';
import {
    CallOffUpdateRequest,
    CallOffSaveAndUpdateStatusRequest,
    CallOffUpdateStatusRequest,
    CallOffNewRevisionRequest,
} from 'src/app/store/call-off/actions';
import { BaseComponent } from 'src/app/components/base.component';
import { takeWhile } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { RoleService } from 'src/app/services/shared/role.service';
import { Constants } from 'src/app/constants';
import { ConfirmDialogPopupSettings } from 'src/app/models/confirm-dialog-popup-settings';
import { PopupService } from 'src/app/services/shared/popup.service';
import { PopupSettings } from 'src/app/models/popup-settings';
import { ApplicationState } from 'src/app/store/model';
import { CallOffStatusType, CallOffStatusChangeAction } from 'src/app/models/enums';
import { InformationDialogComponent } from '../../information-dialog/information-dialog.component';
import { MsalService } from '@azure/msal-angular';
import { AccountInfo } from '@azure/msal-common';
import { ToastService } from 'src/app/services/shared/toast.service';
import { COFCompletedPopupComponent } from '../cof-completed-popup/cof-completed-popup.component';

@Component({
    selector: 'app-cof-buttons',
    templateUrl: './cof-buttons.component.html',
    styleUrls: ['./cof-buttons.component.scss'],
})
export class CofButtonsComponent extends BaseComponent implements OnInit {
    @Input() callOffForm: FormGroup;
    @Output() disableFormEvent = new EventEmitter<void>();
    @Output() enableFormEvent = new EventEmitter<void>();

    isDownloadDataButtonVisible = false;
    isRevertButtonVisible = false;
    isSubmitButtonVisible = false;
    isContractsTeamReviewedButtonVisible = false;
    isContractorRepresentativeApprovedButtonVisible = false;
    isCompanyRepresentativeApprovedButtonVisible = false;
    isCancelButtonVisible = false;
    isSaveButtonDisabled = false;
    isSaveButtonVisible = false;
    isDownloadDataButtonDisabled = true;
    isSubmitButtonDisabled = false;
    isCompanyRepresentativeApprovedButtonDisabled = false;
    isEditButtonVisible = false;
    isCOFCompletedButtonVisible = false;
    isCOFCompanyCompletedButtonVisible = false;
    loaded = true;
    updatedProperties: string[] = [];
    cancelText = 'cancel Call-Off';
    isReadOnly = false;
    user: AccountInfo;
    originator: UserDetail;
    status: CallOffStatusType;
    callOffId: number;
    canSendAutomatedEmails = false;
    isCompanyRepresentative = false;
    isContractorRepresentative = false;
    isContractsTeamReviewer = false;
    isOriginator = false;
    isNewRevisionButtonDisabled = false;
    isAutosaveInProgress = false;

    status$ = this.store.select((state) => state.callOffState.form.status);
    isLocked$ = this.store.select((state) => state.callOffState.isLocked);
    isAutosaveInProgress$ = this.store.select((state) => state.callOffState.isAutosaveInProgress);
    originator$ = this.store.select((state) => state.callOffState.form.originator);
    contractsTeamReviewer$ = this.store.select((state) => state.callOffState.form.contractsTeamReviewer);
    contractsTeamReviewDate$ = this.store.select((state) => state.callOffState.form.contractsTeamReviewDate);
    companyRepresentative$ = this.store.select((state) => state.callOffState.form.companyRepresentative);
    contractorRepresentative$ = this.store.select((state) => state.callOffState.form.contractorRepresentative);
    contractorApproveDate$ = this.store.select((state) => state.callOffState.form.contractorApproveDate);
    companyApproveDate$ = this.store.select((state) => state.callOffState.form.companyApproveDate);
    updatedProperties$ = this.store.select((state) => state.callOffState.updatedProperties);
    callOffId$ = this.store.select((state) => state.callOffState.form.id);
    reason$ = this.store.select((state) => state.callOffState.form.reasonForInstruction);

    constructor(
        private store: Store<ApplicationState>,
        private roleService: RoleService,
        private popupService: PopupService,
        private authService: MsalService,
        private toastService: ToastService
    ) {
        super();
    }

    ngOnInit() {
        this.isReadOnly = this.roleService.isReadOnly();
        this.callOffId$.pipe(takeWhile(() => this.isAlive)).subscribe((callOffId) => (this.callOffId = callOffId));

        combineLatest(
            this.status$,
            this.originator$,
            this.updatedProperties$,
            this.companyRepresentative$,
            this.contractorRepresentative$,
            this.contractorApproveDate$,
            this.companyApproveDate$,
            this.contractsTeamReviewer$,
            this.contractsTeamReviewDate$,
            this.isLocked$,
            this.isAutosaveInProgress$
        )
            .pipe(takeWhile(() => this.isAlive))
            .subscribe(
                ([
                    status,
                    originator,
                    updatedProperties,
                    companyRepresentative,
                    contractorRepresentative,
                    contractorApproveDate,
                    companyApproveDate,
                    contractsTeamReviewer,
                    contractsTeamReviewDate,
                    isLocked,
                    isAutosaveInProgress,
                ]) => {
                    this.user = this.roleService.getAccount();
                    this.originator = originator;
                    this.status = status;
                    this.updatedProperties = updatedProperties;
                    const isAdmin = this.roleService.isInRole(Constants.applicableGroups.Admin);
                    this.isOriginator = this.compareUsersWithAccount(this.user, originator);
                    this.isCompanyRepresentative =
                        isAdmin || this.compareUsersWithAccount(this.user, companyRepresentative);
                    this.isContractorRepresentative =
                        isAdmin || this.compareUsersWithAccount(this.user, contractorRepresentative);
                    this.isContractsTeamReviewer =
                        isAdmin || this.compareUsersWithAccount(this.user, contractsTeamReviewer);
                    const isContractsTeamReviewCompleted = contractsTeamReviewDate !== null;
                    const isContractorRepresentativeApprovalCompleted = contractorApproveDate !== null;
                    const isCompanyRepresentativeApprovalCompleted = companyApproveDate !== null;
                    this.cancelText = status === CallOffStatusType.Approved ? 'void Call-Off' : 'cancel Call-Off';
                    this.isAutosaveInProgress = isAutosaveInProgress;

                    this.showOrHideButtons(
                        this.isOriginator,
                        status,
                        isAdmin,
                        updatedProperties,
                        this.isCompanyRepresentative,
                        this.isContractorRepresentative,
                        isContractorRepresentativeApprovalCompleted,
                        isCompanyRepresentativeApprovalCompleted,
                        this.isContractsTeamReviewer,
                        isContractsTeamReviewCompleted,
                        isLocked
                    );
                }
            );

        this.store
            .select((state) => state.callOffState.isLocked)
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((isLocked) => {
                this.isSaveButtonVisible = !isLocked;
            });
    }

    cancel() {
        if (this.status !== CallOffStatusType.Cancelled) {
            let changeAction = CallOffStatusChangeAction.Cancel;
            let text = 'Do you want to cancel Call-Off?';

            if (this.status === CallOffStatusType.Approved) {
                changeAction = CallOffStatusChangeAction.Void;
                text = 'Do you want to void Call-Off?';
            }
            this.runUpdateStatusAction(changeAction, 'Confirm action', text);
        }
    }

    submit() {
        this.popupService
            .openPopup(
                new ConfirmDialogPopupSettings({ title: 'Confirm action', text: 'Do you want to submit draft?' })
            )
            .afterClosed()
            .pipe(takeWhile(() => this.isAlive === true))
            .subscribe((answer) => {
                if (answer === true) {
                    if (!this.callOffForm.value.awe.isActive) {
                        this.toastService.Error(
                            'AWE Closed. Liaise with the TCO Contract Administrator to select an alternative AWE'
                        );
                        return;
                    }
                    this.confirmSubmitAction();
                }
            });
    }

    confirmSubmitAction() {
        this.loaded = true;
        if (this.isNotNullAndNotUndefined(this.updatedProperties) && this.updatedProperties.length > 0) {
            this.saveAndupdateStatusAction(CallOffStatusChangeAction.Submit);
        } else {
            this.updateStatusAction(CallOffStatusChangeAction.Submit);
        }
    }

    downloadData() {}
    createNewRevision() {
        this.store.dispatch(
            new CallOffNewRevisionRequest({ callOffId: this.callOffId, callOff: this.callOffForm.value })
        );
    }

    revertToDraft = () => {
        let text =
            this.isCompanyRepresentative || this.isContractorRepresentative || this.isContractsTeamReviewer
                ? `Are you sure you want to reject Call-Off back to the Originator? \n Consider adding comments (see section at the bottom of page) to provide reason for rejection.`
                : 'Do you want to revert to draft?';
        this.updateStatusWithChangesCheck({ title: 'Confirm action', text }, () => {
            this.disableFormEvent.emit();
            this.store.dispatch(
                new CallOffUpdateStatusRequest({
                    id: this.callOffForm.controls['id'].value,
                    changeAction: CallOffStatusChangeAction.Draft,
                })
            );
        });
    };

    contractsTeamReviewed() {
        if (this.status === CallOffStatusType.Submitted) {
            this.runUpdateStatusAction(
                CallOffStatusChangeAction.ContractsTeamReview,
                'Confirm action',
                'Do you want to complete Contracts Review and send to the Approvers?'
            );
        }
    }

    contractorRepresentativeApproved() {
        if (this.status === CallOffStatusType.Reviewed) {
            this.runUpdateStatusAction(
                CallOffStatusChangeAction.ContractorApprove,
                'Confirm action',
                'Do you want to submit contractor representative approval?'
            );
        }
    }

    companyRepresentativeApproved() {
        if (this.status === CallOffStatusType.Reviewed) {
            this.runUpdateStatusAction(
                CallOffStatusChangeAction.CompanyApprove,
                'Confirm action',
                'Do you want to submit company representative approval?'
            );
        }
    }

    edit() {
        this.enableFormEvent.emit();
        if (this.status !== CallOffStatusType.Draft) {
            this.showAutosaveDisabledPopup();
        }
    }

    cofCompleted() {
        this.popupService
            .openPopup(new PopupSettings(COFCompletedPopupComponent, null, null, { callOffForm: this.callOffForm }))
            .afterClosed()
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((attachmentConfirmed) => {
                if (attachmentConfirmed) {
                    this.saveAndupdateStatusAction(CallOffStatusChangeAction.Complete);
                }
            });
    }

    cofCompanyCompleted() {
        this.popupService
            .openPopup(new PopupSettings(COFCompletedPopupComponent, null, null, { callOffForm: this.callOffForm }))
            .afterClosed()
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((attachmentConfirmed) => {
                if (attachmentConfirmed) {
                    this.saveAndupdateStatusAction(CallOffStatusChangeAction.CompanyComplete);
                }
            });
    }

    save() {
        this.callOffForm.updateValueAndValidity();
        this.store.dispatch(new CallOffUpdateRequest(this.callOffForm.value));
        if (
            this.isNotNullAndNotUndefined(this.status) &&
            (this.status !== CallOffStatusType.Draft ||
                (this.status === CallOffStatusType.Draft &&
                    !this.isOriginator &&
                    this.roleService.isInRole(Constants.applicableGroups.Admin)))
        ) {
            this.disableFormEvent.emit();
        }
    }

    cancelEdit() {
        location.reload();
    }

    private showAutosaveDisabledPopup() {
        return this.popupService
            .openPopup(
                new PopupSettings(
                    InformationDialogComponent,
                    null,
                    null,
                    {
                        title: 'Autosave disabled',
                        text:
                            'Autosave function is not enabled in mode other than Draft. Please click on Save after making edits.',
                    },
                    1,
                    1
                )
            )
            .afterClosed();
    }

    private saveAndupdateStatusAction(changeAction: CallOffStatusChangeAction) {
        this.store.dispatch(
            new CallOffSaveAndUpdateStatusRequest({
                form: this.callOffForm.value,
                changeAction: changeAction,
            })
        );
        this.disableFormEvent.emit();
    }

    private updateStatusAction(changeAction: CallOffStatusChangeAction) {
        this.store.dispatch(
            new CallOffUpdateStatusRequest({
                id: this.callOffForm.controls['id'].value,
                changeAction: changeAction,
            })
        );
        this.disableFormEvent.emit();
    }

    private showOrHideButtons(
        isOriginator: boolean,
        status: CallOffStatusType,
        isAdmin: boolean,
        updatedProperties: string[],
        isCompanyRepresentative: boolean,
        isContractorRepresentative: boolean,
        isContractorRepresentativeApprovalComplete: boolean,
        isCompanyRepresentativeApprovalComplete: boolean,
        isContractsTeamReviewer: boolean,
        isContractsTeamReviewCompleted: boolean,
        isLocked: boolean
    ) {
        this.isRevertButtonVisible =
            (isContractsTeamReviewer && status === CallOffStatusType.Submitted) ||
            ((isCompanyRepresentative || isContractorRepresentative) && status === CallOffStatusType.Reviewed) ||
            (isAdmin &&
                this.isNotNullAndNotUndefined(status) &&
                status !== CallOffStatusType.Draft &&
                status !== CallOffStatusType.Approved &&
                status !== CallOffStatusType.Completed &&
                status !== CallOffStatusType.CompanyCompleted &&
                status !== CallOffStatusType.Void &&
                status !== CallOffStatusType.Cancelled);

        this.isEditButtonVisible =
            (isAdmin || isCompanyRepresentative || isContractorRepresentative || isContractsTeamReviewer) &&
            (status !== CallOffStatusType.Draft || !isOriginator) &&
            isLocked &&
            status !== CallOffStatusType.Completed &&
            status !== CallOffStatusType.CompanyCompleted;

        this.isNewRevisionButtonDisabled = !this.callOffForm.disabled;

        this.isSubmitButtonVisible = status === CallOffStatusType.Draft && isOriginator;

        this.isContractsTeamReviewedButtonVisible =
            status === CallOffStatusType.Submitted && isContractsTeamReviewer && !isContractsTeamReviewCompleted;

        this.isContractorRepresentativeApprovedButtonVisible =
            status === CallOffStatusType.Reviewed &&
            isContractsTeamReviewCompleted &&
            isContractorRepresentative &&
            !isContractorRepresentativeApprovalComplete;

        this.isCompanyRepresentativeApprovedButtonVisible =
            status === CallOffStatusType.Reviewed &&
            isContractsTeamReviewCompleted &&
            isCompanyRepresentative &&
            !isCompanyRepresentativeApprovalComplete;

        this.isCancelButtonVisible =
            (status !== CallOffStatusType.Void &&
                status !== CallOffStatusType.Cancelled &&
                status !== CallOffStatusType.Completed &&
                status !== CallOffStatusType.CompanyCompleted &&
                isAdmin) ||
            (status === CallOffStatusType.Draft && isOriginator);

        this.isSaveButtonDisabled =
            updatedProperties.length === 0 || this.callOffForm.controls['nteUsd'].errors?.limitExceeded;

        this.isCOFCompletedButtonVisible =
            status === CallOffStatusType.Approved &&
            (isAdmin || this.roleService.isInRole(Constants.applicableGroups.CallOffUser));

        this.isCOFCompanyCompletedButtonVisible =
            status === CallOffStatusType.Completed && (isAdmin || isCompanyRepresentative);
    }

    private compareUsersWithAccount(account: AccountInfo, userDetail: UserDetail) {
        return (
            this.isNotNullAndNotUndefined(account) &&
            this.isNotNullAndNotUndefined(userDetail) &&
            account.username.toLowerCase() === userDetail.email.toLowerCase()
        );
    }

    private updateStatusWithChangesCheck(
        updateStatusPopupSettings: { title: string; text: string },
        successAction: () => void,
        discardAction?: () => void
    ) {
        if (
            this.status !== CallOffStatusType.Draft &&
            this.isNotNullAndNotUndefined(this.updatedProperties) === true &&
            this.updatedProperties.length > 0
        ) {
            this.popupService
                .openPopup(
                    new ConfirmDialogPopupSettings({
                        title: 'Changes are not saved!',
                        text: "You haven't save your changes. Do you want to discard them?",
                        isDiscardChanges: true,
                    })
                )
                .afterClosed()
                .pipe(takeWhile(() => this.isAlive))
                .subscribe((answer) => {
                    if (answer === true) {
                        this.popupService
                            .openPopup(new ConfirmDialogPopupSettings(updateStatusPopupSettings))
                            .afterClosed()
                            .pipe(takeWhile(() => this.isAlive === true))
                            .subscribe((ans) => {
                                if (ans === true) {
                                    successAction();
                                } else if (discardAction) {
                                    discardAction();
                                }
                            });
                    }
                });
        } else {
            this.popupService
                .openPopup(new ConfirmDialogPopupSettings(updateStatusPopupSettings))
                .afterClosed()
                .pipe(takeWhile(() => this.isAlive))
                .subscribe((answer) => {
                    if (answer === true) {
                        successAction();
                    } else if (discardAction) {
                        discardAction();
                    }
                });
        }
    }

    private runUpdateStatusAction(changeAction: CallOffStatusChangeAction, title: string, text: string) {
        this.updateStatusWithChangesCheck({ title, text }, () => this.updateStatusAction(changeAction));
    }
}
