<div class="completed-container">
    <div class="position">
        <div class="title">close-out documentation</div>
        <div *ngFor="let attachment of attachments$ | async">
            <div class="attachment-container">
                <label>{{ attachment.name }}</label>
                <button
                    *ngIf="attachment.link"
                    [disabled]="attachment.isBeingProcessed"
                    (click)="downloadAttachment(attachment)"
                    class="download-icon"
                ></button>
            </div>
        </div>
        <div *ngFor="let attachment of companyCompletedAttachments$ | async">
            <div class="attachment-container">
                <label>{{ attachment.name }}</label>
                <button
                    *ngIf="attachment.link"
                    [disabled]="attachment.isBeingProcessed"
                    (click)="downloadAttachment(attachment)"
                    class="download-icon"
                ></button>
            </div>
        </div>
        <div class="completed-signature">
            {{ callOffForm.value.callOffNumber }}, Revision {{ callOffForm.value.revision }}R closed-out as work
            completed by {{ (closeOutDetails$ | async).name }} on {{ (closeOutDetails$ | async).date | date: 'medium' }}
        </div>
        <div *ngIf="(callOffStatus$ | async) === cofStatuses.CompanyCompleted" class="completed-signature">
            {{ callOffForm.value.callOffNumber }}, Revision {{ callOffForm.value.revision }}R verified as complete by 
            {{ (companyCloseOutDetails$ | async).name }} on {{ (companyCloseOutDetails$ | async).date | date: 'medium' }}
        </div>
    </div>
</div>
