<div>
    <app-asc-desc-order
        [(isAscendingSort)]="isAscendingSort"
        [(isDescendingSort)]="isDescendingSort"
    ></app-asc-desc-order>
    <div class="search-block">
        <mat-form-field class="margin-right15">
            <input
                type="number"
                matInput
                placeholder="From {{ placeHolder }}"
                [(ngModel)]="ranges[0]"
                [ngModelOptions]="{ standalone: true }"
                autocomplete="off"
            />
        </mat-form-field>
        <mat-form-field>
            <input
                type="number"
                matInput
                placeholder="To {{ placeHolder }}"
                [(ngModel)]="ranges[1]"
                [ngModelOptions]="{ standalone: true }"
                autocomplete="off"
            />
        </mat-form-field>
    </div>

    <span class="reset-filters" (click)="clear()">Reset filters &times;</span><br />
    <div class="buttons-container flex center save-close-space">
        <button type="button" (click)="close()" class="cof-button cof-button-small cof-button-white margin-right20">
            cancel
        </button>
        <button type="button" (click)="onSubmit()" class="cof-button cof-button-small cof-button-light ok-button">
            ok
        </button>
    </div>
</div>
