import { OnDestroy, Component } from '@angular/core';
import { UserDetail, CheckListColumn, CalendarColumn, AppRole, RangeColumn } from '../store/common.model';
import { CallOffStatusType } from '../models/enums';
import { ValidStatuses } from '../models/validation-statuses';
import { ReasonForInstruction } from '../store/common/model';
import { DelegationType } from '../store/my-page/models';
import _ from 'lodash';
import { AccountInfo } from '@azure/msal-common';

@Component({
    template: '',
})
export class BaseComponent implements OnDestroy {
    isAlive = true;
    readonly isIE: boolean = /msie\s|trident\//i.test(window.navigator.userAgent);
    ngOnDestroy() {
        this.isAlive = false;
    }

    isNotNullAndNotUndefined(item: any): boolean {
        return item !== null && item !== undefined;
    }

    areUsersEqual(user: AccountInfo, decisionUser: UserDetail) {
        return (
            this.isNotNullAndNotUndefined(user) &&
            this.isNotNullAndNotUndefined(decisionUser) &&
            user.username.toLowerCase() === decisionUser.email.toLowerCase()
        );
    }

    compareStatuses(r1: CallOffStatusType, r2: CallOffStatusType): boolean {
        return CallOffStatusType[r1] === CallOffStatusType[r2];
    }

    compareRoles(r1: AppRole, r2: AppRole): boolean {
        return AppRole[r1] === AppRole[r2];
    }

    compareDelegationTypes(r1: DelegationType, r2: DelegationType): boolean {
        return DelegationType[r1] === DelegationType[r2];
    }

    compareReasons(r1: ReasonForInstruction, r2: ReasonForInstruction): boolean {
        return r1.id === r2.id;
    }

    getStatusName(status: CallOffStatusType): string {
        switch(status){
            case CallOffStatusType.CompanyCompleted :
                return 'Company Completed';
            default:
                return CallOffStatusType[status];
        }
    }

    getClassName(status: CallOffStatusType) {
        switch (status) {
            case CallOffStatusType.Draft:
                return 'draft-status';
            case CallOffStatusType.Submitted:
                return 'submitted-status';
            case CallOffStatusType.Reviewed:
                return 'reviewed-status';
            case CallOffStatusType.Approved:
                return 'approved-status';
            case CallOffStatusType.Cancelled:
                return 'cancelled-status';
            case CallOffStatusType.Void:
                return 'void-status';
            case CallOffStatusType.CompanyCompleted:
                return 'completed-status';
            default:
                return '';
        }
    }

    getValidationClassName(status: string) {
        switch (status) {
            case ValidStatuses.NotStarted:
                return 'not-started-status';
            case ValidStatuses.InProgress:
                return 'in-progress-status';
            case ValidStatuses.Completed:
                return 'completed-status';
            case 'changes reviewed':
                return 'changes-reviewed-status';
            case 'active':
                return 'active-status';
            case 'inactive':
                return 'inactive-status';
        }
    }

    showUnderlineForCalendarColumn(calendarColumn: CalendarColumn): boolean {
        return (
            calendarColumn !== null &&
            (calendarColumn.rangeDates.length > 0 ||
                calendarColumn.excludeBlanks !== false ||
                calendarColumn.onlyBlanks !== false)
        );
    }

    showUnderlineForRangeColumn(rangeColumn: RangeColumn): boolean {
        return rangeColumn !== null && rangeColumn.ranges.length > 0;
    }

    showUnderlineForCheckListColumn(column: CheckListColumn): boolean {
        return (
            column !== null && column.items.length > 0
        );
    }

    arraysEqual(_arr1, _arr2) {
        if (!Array.isArray(_arr1) || !Array.isArray(_arr2) || _arr1.length !== _arr2.length) return false;

        var arr1 = _arr1.concat().sort();
        var arr2 = _arr2.concat().sort();

        for (var i = 0; i < arr1.length; i++) {
            if (arr1[i] !== arr2[i]) return false;
        }
        return true;
    }

    findRole(types: { key: string; value: number }[], appRole: AppRole) {
        return types.find((s) => s.value === appRole).key;
    }

    compareArraysOfObjectsByProperty(array1: {}[], array2: {}[], property: string) {
        if (!Array.isArray(array1) || !Array.isArray(array2) || array1.length !== array2.length) return false;

        const a1 = _.sortBy(array1, property);
        const a2 = _.sortBy(array2, property);

        const result = _.isEqualWith(a1, a2, (v1, v2) => v1[property] === v2[property]);
        return result;
    }

    compareUsers(e1: UserDetail, e2: UserDetail): boolean {
        return e1 && e2 ? e1.email.toLowerCase() === e2.email.toLowerCase() : e1 === e2;
    }
}
