import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatIconRegistry } from '@angular/material/icon';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DomSanitizer } from '@angular/platform-browser';
import { takeWhile, take, catchError } from 'rxjs/operators';
import { OrderDirection, Contractor, UserWithRoleDetail, AppRole, CheckListColumn } from 'src/app/store/common.model';
import { PopupService } from 'src/app/services/shared/popup.service';
import { ConfirmDialogPopupSettings } from 'src/app/models/confirm-dialog-popup-settings';
import { PopupSettings } from 'src/app/models/popup-settings';
import { AddUserToContractsPopupComponent } from './add-user-to-contracts-popup/add-user-to-contracts-popup.component';
import { BaseComponent } from 'src/app/components/base.component';
import { UserToContractsService } from 'src/app/services/user-to-contracts.service';
import { ToastService } from 'src/app/services/shared/toast.service';
import { UserToContractsDTO } from 'src/app/models/user-to-contracts-dto';
import { forkJoin, of } from 'rxjs';
import { LookupService } from 'src/app/services/lookup.service';
import { HeaderCheckListFilter } from 'src/app/models/filter-settings';
import { HeaderChecklistFilterComponent } from 'src/app/components/filter/header-checklist-filter/header-checklist-filter.component';
import _ from 'lodash';

@Component({
    selector: 'app-user-to-contracts',
    templateUrl: './user-to-contracts.component.html',
    styleUrls: ['./user-to-contracts.component.scss'],
})
export class UserToContractsComponent extends BaseComponent implements OnInit {
    resultsLength = 0;
    pageSize = 10;
    sortBy: string = 'userRole';
    direction = OrderDirection.Desc;
    isLoading = false;
    filterForm: FormGroup;
    displayedColumns = ['userRole', 'user', 'contracts', 'edit', 'remove'];
    dataSource = new MatTableDataSource<UserToContractsDTO>();
    contractors: Contractor[] = [];
    types = [
        { value: AppRole.CallOffUser, key: 'Call-Off User' },
        { value: AppRole.Contracts, key: 'Contracts Team' },
    ];
    users: UserWithRoleDetail[];
    allElements: UserToContractsDTO[] = [];
    columnUserRoles: CheckListColumn = null;
    columnUsers: CheckListColumn = null;
    columnContracts: CheckListColumn = null;

    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;

    constructor(
        private iconRegistry: MatIconRegistry,
        private sanitizer: DomSanitizer,
        private popupService: PopupService,
        private userToContractsService: UserToContractsService,
        private toastService: ToastService,
        private lookupService: LookupService
    ) {
        super();

        this.iconRegistry.addSvgIcon(
            'edit',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/edit.svg')
        );
        this.iconRegistry.addSvgIcon(
            'delete',
            sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/delete.svg')
        );
        this.iconRegistry.addSvgIcon('add', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/add.svg'));
    }

    ngOnInit(): void {
        this.isLoading = true;
        forkJoin([
            this.lookupService.searchUsersWithRoles([AppRole.CallOffUser, AppRole.Contracts]),
            this.lookupService.searchContractors(),
            this.userToContractsService.get(),
        ])
            .pipe(
                take(1),
                catchError(() => {
                    throw new Error(
                        'Error occurred while getting user to contracts data. Please contact Program Administrator.'
                    );
                })
            )
            .subscribe(
                ([users, contractors, userToContracts]) => {
                    this.users = users;
                    this.contractors = contractors;
                    this.allElements = userToContracts;
                    this.populateDataTable(userToContracts);
                    this.isLoading = false;
                },
                (error) => {
                    this.isLoading = false;
                    this.toastService.Error(error);
                }
            );
    }

    remove(element: UserToContractsDTO) {
        this.popupService
            .openPopup(
                new ConfirmDialogPopupSettings({
                    title: 'Confirm action',
                    text: `Are you sure you want to delete ${element.user.name} from user role contracts records?`,
                })
            )
            .afterClosed()
            .pipe(takeWhile(() => this.isAlive === true))
            .subscribe((answer) => {
                if (answer) {
                    this.isLoading = true;
                    this.userToContractsService
                        .remove(element.user.email, element.userRole)
                        .pipe(takeWhile(() => this.isAlive === true))
                        .subscribe(
                            () => {
                                var data = this.dataSource.data.filter(
                                    (s) => !(s.userRole === element.userRole && s.user.email === element.user.email)
                                );
                                this.populateDataTable(data);
                                this.allElements = data;
                                this.isLoading = false;
                            },
                            () => {
                                this.isLoading = false;
                                this.toastService.Error(
                                    `Error occurred while deleting ${element.user.name}. Please contact Program Administrator.`
                                );
                            }
                        );
                }
            });
    }

    add() {
        const dialogRef = this.popupService.openPopup(
            new PopupSettings(AddUserToContractsPopupComponent, 450, 450, {
                contractors: this.contractors,
                users: this.users,
                types: this.types,
            })
        );

        dialogRef
            .afterClosed()
            .pipe(takeWhile(() => this.isAlive === true))
            .subscribe((result) => {
                if (result && result.success) {
                    const data = this.dataSource.data;
                    data.push(result.data);
                    this.allElements = data;
                    this.populateDataTable(data);
                }
            });
    }

    enterEditMode(userToContracts: UserToContractsDTO) {
        userToContracts.isInEditMode = !userToContracts.isInEditMode;
        userToContracts.prevContracts = Object.assign([], userToContracts.contracts);
        userToContracts.prevUserRole = userToContracts.userRole;
        userToContracts.prevUser = Object.assign({}, userToContracts.user);
    }

    save(userToContracts: UserToContractsDTO) {
        userToContracts.isInEditMode = !userToContracts.isInEditMode;

        if (
            userToContracts.user.email !== userToContracts.prevUser.email ||
            userToContracts.userRole !== userToContracts.prevUserRole ||
            !this.arraysEqual(userToContracts.contracts, userToContracts.prevContracts)
        ) {
            this.isLoading = true;
            userToContracts.user = Object.assign(
                {},
                this.users.find((s) => s.email === userToContracts.user.email)
            );
            this.userToContractsService
                .update(userToContracts)
                .pipe(takeWhile(() => this.isAlive === true))
                .subscribe(
                    () => {
                        this.toastService.Success(
                            `Successfully updated ${userToContracts.user.name} user to contracts.`
                        );
                        this.isLoading = false;
                    },
                    (error) => {
                        this.isLoading = false;
                        if (error.status === 409) {
                            this.toastService.Error(
                                `${this.findRole(this.types, userToContracts.userRole)} ${
                                    userToContracts.user.name
                                } already exists in the user to contracts.`
                            );
                        } else {
                            this.toastService.Error(
                                `Error occurred while updating ${userToContracts.user.name} user to contracts. Please contact Program Administrator.`
                            );
                        }
                        this.rollbackChanges(userToContracts);
                    }
                );
        }
    }

    cancelEdit(userToContracts: UserToContractsDTO) {
        userToContracts.isInEditMode = !userToContracts.isInEditMode;
        this.rollbackChanges(userToContracts);
    }

    onPageChange(newPage: number) {
        if (newPage < 1) {
            newPage = 1;
        } else if (newPage > this.paginator.getNumberOfPages()) {
            newPage = this.paginator.getNumberOfPages();
        }
        this.paginator.pageIndex = newPage - 1;
        this.dataSource.paginator = this.paginator;
    }

    private rollbackChanges(userToContracts: UserToContractsDTO) {
        userToContracts.contracts = userToContracts.prevContracts;
        userToContracts.userRole = userToContracts.prevUserRole;
        userToContracts.user = userToContracts.prevUser;
    }

    populateDataTable(approvalMatrix: UserToContractsDTO[]) {
        this.dataSource = new MatTableDataSource(approvalMatrix);
        this.resultsLength = this.dataSource.data.length;
        this.dataSource.sort = this.sort;
        this.dataSource.sortingDataAccessor = (item, property) => {
            switch (property) {
                case 'userRole':
                    return this.types.find((f) => f.value === item.userRole).key;
                case 'user':
                    return item.user.name;
                case 'contracts':
                    return item.contracts.join();
                default:
                    return item[property];
            }
        };
        this.dataSource.paginator = this.paginator;
    }

    getValues = (search = '', take = 30, page = 0, column = 'number') => {
        let elements = this.allElements;
        if (elements.length) {
            let el = this.mapColumnsForFilter(elements, column);
            if (search !== '') {
                el = el.filter((s) => s.toLowerCase().indexOf(search.toLowerCase()) > -1);
            }
            let counts = _.countBy(el);
            let keys = _.keys(counts);
            return of(keys.sort().slice(page * take, page * take + take));
        } else {
            return of([]);
        }
    };

    private mapColumnsForFilter(elements: UserToContractsDTO[], column: string) {
        let result = [];
        if (column === 'user') {
            if (this.columnUserRoles) {
                elements = elements.filter((x) =>
                    this.columnUserRoles.items.includes(this.types.find((f) => f.value === x.userRole).key)
                );
            }
            if (this.columnContracts) {
                elements = elements.filter((x) => _.intersection(this.columnContracts.items, x.contracts).length > 0);
            }
            result = elements.map((s) => `${s[column].name} (${s[column].email})`);
        } else if (column === 'userRole') {
            result = elements.map((s) => this.types.find((f) => f.value === s[column]).key);
        } else if (column === 'contracts') {
            result = elements.map((s) => s[column]);
            result = [].concat(...result);
        }
        return result;
    }

    openHeaderCheckListFilter(
        columnName: string,
        searchFunc: any,
        propertyName: string,
        placeholder: string,
        selected: CheckListColumn,
        showCounts: boolean = false,
        showInputSearch: boolean = true
    ) {
        this.popupService.openPopup(
            new PopupSettings<HeaderCheckListFilter>(HeaderChecklistFilterComponent, 400, 590, {
                isAscendingSort: this.sortBy === columnName && this.direction === OrderDirection.Asc,
                isDescendingSort: this.sortBy === columnName && this.direction === OrderDirection.Desc,
                placeHolder: placeholder,
                searchFunc: searchFunc,
                selectedItems: selected ? [...selected.items] : [],
                isSortingOff: false,
                showCounts,
                showInputSearch: showInputSearch,
                column: columnName,
                action: (data) => {
                    let value = new CheckListColumn();
                    value.items = data.selectedItems.length > 0 ? data.selectedItems : [];
                    this[propertyName] = value;

                    let results = this.search();
                    this.populateDataTable(results);
                    this.sortUpdate(data.isAscendingSort, data.isDescendingSort, columnName);
                },
                resetColumnAction: () => {
                    this[propertyName] = new CheckListColumn();
                },
            })
        );
    }

    private search() {
        let results = this.allElements;
        if (this.columnContracts && this.columnContracts.items.length) {
            results = results.filter((s) => this.columnContracts.items.some((a) => s.contracts.indexOf(a) > -1));
        }
        if (this.columnUsers && this.columnUsers.items.length) {
            results = results.filter((s) => this.columnUsers.items.indexOf(`${s.user.name} (${s.user.email})`) > -1);
        }
        if (this.columnUserRoles && this.columnUserRoles.items.length) {
            results = results.filter(
                (s) => this.columnUserRoles.items.indexOf(this.types.find((f) => f.value === s.userRole).key) > -1
            );
        }
        return results;
    }

    private sortUpdate(isAscendingSort: boolean, isDescendingSort: boolean, columnName: string) {
        if (isAscendingSort || isDescendingSort) {
            const direction = isAscendingSort ? 'asc' : 'desc';
            const matSort = this.dataSource.sort;
            const disableClear = false;

            matSort.sort({ id: null, start: direction, disableClear });
            matSort.sort({ id: columnName, start: direction, disableClear });

            this.dataSource.sort = matSort;
            this.direction = isAscendingSort ? OrderDirection.Asc : OrderDirection.Desc;
            this.sortBy = columnName;
        } else {
            this.direction = OrderDirection.Desc;
            this.sortBy = 'userRole';
            const matSort = this.dataSource.sort;
            const disableClear = false;

            matSort.sort({ id: null, start: 'desc', disableClear });
            matSort.sort({ id: this.sortBy, start: 'desc', disableClear });
        }
    }
}
