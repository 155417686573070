<div class="call-off-form-header-container">
    <div [formGroup]="callOffForm">
        <div class="col-header-container filter-container">
            <mat-form-field class="container250 margin-right20">
                <input matInput placeholder="Call-Off Number" formControlName="callOffNumber" readonly />
            </mat-form-field>
            <mat-form-field class="container45 margin-right20">
                <input matInput placeholder="Revision" [value]="addR(callOffForm.controls.revision.value)" readonly />
            </mat-form-field>
            <mat-form-field class="container115 margin-right20">
                <mat-label>Discipline</mat-label>
                <mat-select matNativeControl formControlName="discipline">
                    <mat-option *ngFor="let disc of disciplines" [value]="disc">
                        {{ disc }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field class="container115 margin-right20">
                <mat-label>Area</mat-label>
                <mat-select matNativeControl formControlName="areaId" required>
                    <mat-option *ngFor="let area of areas" [value]="area.id">{{ area.name }}</mat-option>
                </mat-select>
                <mat-error
                    *ngIf="callOffForm.controls['areaId'].invalid && callOffForm.controls['areaId'].errors.required"
                >
                    Area is required.
                </mat-error>
            </mat-form-field>

            <mat-form-field class="container100 margin-right20">
                <input
                    matInput
                    placeholder="Raised Date"
                    readonly
                    [value]="callOffForm.controls.raisedDate.value | date: 'd/MMM/yy'"
                />
            </mat-form-field>
            <mat-form-field class="container250 margin-right20">
                <input
                    matInput
                    placeholder="Originator"
                    readonly
                    [value]="callOffForm.controls.originator.value.name"
                />
            </mat-form-field>
            <mat-form-field class="container250">
                <input matInput placeholder="Email" readonly [value]="callOffForm.controls.originator.value.email" />
            </mat-form-field>
            <mat-form-field class="container-fit title">
                <textarea
                    matInput
                    placeholder="Subject"
                    formControlName="subject"
                    required
                    minlength="5"
                    cdkTextareaAutosize
                    cdkAutosizeMinRows="2"
                ></textarea>
                <mat-error
                    *ngIf="
                        (callOffForm.controls['subject'].dirty || callOffForm.controls['subject'].touched) &&
                        callOffForm.controls['subject'].invalid &&
                        callOffForm.controls['subject'].errors.minlength
                    "
                >
                    Subject need to be 5 or more characters length.
                </mat-error>
                <mat-error
                    *ngIf="callOffForm.controls['subject'].invalid && callOffForm.controls['subject'].errors.required"
                >
                    Subject is required.
                </mat-error>
            </mat-form-field>
            <div class="tip doNotPrint">
                Select Impacted Subsystems or Tags (Tip: Only <strong>Subsystems</strong> or <strong>Tags</strong> can
                be selected. Remove any entries in <strong>Subsystems</strong> field to enable <strong>Tags</strong> /
                Remove entries in <strong>Tags</strong> field to enable <strong>Subsystems</strong>)
            </div>
            <div class="container-multiselector">
                <div class="container620 margin-right20">
                    <app-multiple-items-selector
                        class="container620"
                        formControlName="subsystems"
                        [searchFunc]="getSubsystems"
                        [isValueLoading]="isSubsystemsLoading"
                        [setInput]="setSubsystemInput"
                        [isAsync]="true"
                        [filterForm]="callOffForm"
                        [formSetter]="'subsystems'"
                        [placeholder]="'Subsystems'"
                        [isDisabled]="isLocked"
                        required
                        [invalid]="
                            callOffForm.controls['subsystems'].invalid &&
                            callOffForm.controls['subsystems'].errors.required
                        "
                        [errorMessage]="'At least one subsystem need to be set'"
                    >
                    </app-multiple-items-selector>
                </div>
                <div class="container620 margin-right20">
                    <app-multiple-items-selector
                        class="container620"
                        formControlName="tags"
                        [searchFunc]="getTags"
                        [setInput]="setTagInput"
                        [isAsync]="true"
                        [filterForm]="callOffForm"
                        [formSetter]="'tags'"
                        [placeholder]="'Tags'"
                        [isDisabled]="isLocked"
                        (autocompleteClosed)="onTagsClosed()"
                        (itemRemoved)="onTagsClosed()"
                        [isTableAutoComplete]="true"
                        [displayedColumns]="autocompleteDisplayedColumns"
                        [propertyToShow]="'tagNo'"
                    >
                    </app-multiple-items-selector>
                </div>
            </div>
            <div class="cwp-container">
                <div class="slide-container">
                    <mat-slide-toggle
                        *ngIf="
                            callOffForm.value &&
                            callOffForm.value.awe &&
                            callOffForm.value.awe.projectTeamName &&
                            callOffForm.enabled
                        "
                        [color]="'primary'"
                        [checked]="searchCurrentProjectSubsystems"
                        (toggleChange)="toggleSearchSubsystemProjectTeam()"
                    >
                        show {{ callOffForm.value.awe.projectTeamName }} subsystems only
                    </mat-slide-toggle>
                </div>
                <app-multiple-items-selector
                    class="container620"
                    formControlName="cwps"
                    [searchFunc]="getCWPs"
                    [isAsync]="true"
                    [filterForm]="callOffForm"
                    [setInput]="setCWPInput"
                    [formSetter]="'cwps'"
                    [placeholder]="'CWP'"
                >
                </app-multiple-items-selector>
            </div>
        </div>
    </div>
</div>
