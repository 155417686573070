import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect, act } from '@ngrx/effects';
import {
    CallOffActionTypes,
    GetCallOffRequest,
    GetCallOffRequestSuccess,
    GetCallOffRequestError,
    AddCallOffRequestSuccess,
    AddCallOffRequest,
    AddCallOffRequestError,
    CallOffUpdateRequest,
    CallOffUpdateRequestError,
    CallOffUpdateRequestSuccess,
    CallOffScopeOfWorkDescriptionRequest,
    CallOffScopeOfWorkDescriptionRequestError,
    CallOffScopeOfWorkDescriptionRequestSuccess,
    CallOffEstimateDetailsDescriptionRequest,
    CallOffEstimateDetailsDescriptionRequestSuccess,
    CallOffEstimateDetailsDescriptionRequestError,
    CallOffUpdateStatusRequest,
    CallOffUpdateStatusRequestSuccess,
    CallOffUpdateStatusRequestError,
    CallOffSaveAndUpdateStatusRequest,
    CallOffActionUsersRequest,
    CallOffActionUsersRequestSuccess,
    CallOffActionUsersRequestError,
    CallOffRemoveCommentRequest,
    CallOffRemoveCommentSuccess,
    CallOffRemoveCommentError,
    CallOffAddCommentRequest,
    CallOffAddCommentSuccess,
    CallOffAddCommentError,
    CallOffHistoryRequest,
    CallOffHistoryRequestSuccess,
    CallOffHistoryRequestError,
    CallOffUpdateProperty,
    CallOffNewRevisionRequest,
    CallOffNewRevisionRequestError,
    CallOffNewRevisionRequestSuccess,
    CallOffAttachmentsRequest,
    CallOffAttachmentsSuccess,
    CallOffAttachmentsError,
    CallOffUpdateInitialFormWithAttachments,
    CallOffUpdateAttachmentLinks,
    CallOffDownloadAttachmentRequest,
    CallOffDownloadAttachmentSuccess,
    CallOffDownloadAttachmentError,
    CallOffAutosaveError,
    CallOffAutosaveRequest,
    CallOffAutosaveSuccess,
} from './actions';
import { mergeMap, catchError, map, tap, withLatestFrom, switchMap, concatMap, finalize } from 'rxjs/operators';
import { CallOffService } from '../../services/call-off.service';
import { ToastService } from '../../services/shared/toast.service';
import { iif, of, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { CallOff } from './model';
import { EmailService } from '../../services/email.service';
import { ActionUsersService } from '../../services/action-users.service';
import { ActionUsers } from '../action-users/model';
import { CallOffStatusType } from '../../models/enums';
import { ApplicationState } from '../model';
import { LookupService } from 'src/app/services/lookup.service';
import { HttpErrorResponse } from '@angular/common/http';
import { AWEService } from 'src/app/services/awe.service';
import _ from 'lodash';
import { Attachment, CallOffAttachmentType } from '../call-off-attachments/model';

@Injectable()
export class CallOffEffects {
    constructor(
        private actions$: Actions,
        private callOffService: CallOffService,
        private toastService: ToastService,
        private router: Router,
        private store: Store<ApplicationState>,
        private emailService: EmailService,
        private actionUsersService: ActionUsersService,
        private lookupService: LookupService,
        private aweService: AWEService
    ) {}

    
    getCallOff$ = createEffect(() => this.actions$.pipe(
        ofType<GetCallOffRequest>(CallOffActionTypes.GetCallOffRequest),
        mergeMap(({ payload }) =>
            this.callOffService.getCallOffById(payload).pipe(
                map((callOffdata) => {
                    return new GetCallOffRequestSuccess(callOffdata);
                }),
                catchError((error) => {
                    this.toastService.Error(
                        `Error occurred while getting Call Off with id:${payload}. Please contact Program Administrator.`
                    );
                    return of(new GetCallOffRequestError(error));
                })
            )
        )
    ));

    
    addCallOff$ = createEffect(() => this.actions$.pipe(
        ofType<AddCallOffRequest>(CallOffActionTypes.AddCallOffRequest),
        mergeMap(({ payload }) =>
            this.callOffService.createCallOff(payload.callOff).pipe(
                map((callOffId) => {
                    return new AddCallOffRequestSuccess({
                        callOffId: +callOffId,
                        callOff: payload.callOff,
                    });
                }),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while adding Call Off. Please contact Program Administrator.'
                    );
                    return of(new AddCallOffRequestError(error));
                })
            )
        )
    ));

    
    addCallOffSuccess = createEffect(() => this.actions$.pipe(
        ofType<AddCallOffRequestSuccess>(CallOffActionTypes.AddCallOffRequestSuccess),
        tap(({ payload }) => {
            this.router.navigate(['details', payload.callOffId]);
        })
    ), { dispatch: false });

    
    autosaveCOF$ = createEffect(() => this.actions$.pipe(
        ofType<CallOffAutosaveRequest>(CallOffActionTypes.CallOffAutosaveRequest),
        withLatestFrom(
            this.store.select((store) => store.callOffState.updatedProperties),
            this.store.select((store) => store.callOffState.form)
        ),
        mergeMap(([action, updatedProperties, callOff]) => {
            this.toastService.Info('Call Off autosave in progress.');
            return this.uploadAttachments(callOff).pipe(
                concatMap((uploadedAttachments) =>
                    this.callOffUpdate(callOff, updatedProperties, uploadedAttachments?.attachments).pipe(
                        map((result) => {
                            return new CallOffAutosaveSuccess(result.payload);
                        }),
                        catchError(([error]) => {
                            this.toastService.Error('Call-Off autosave failed.');
                            return of(new CallOffAutosaveError(error));
                        })
                    )
                )
            );
        })
    ));

    
    updateCallOff$ = createEffect(() => this.actions$.pipe(
        ofType<CallOffUpdateRequest>(CallOffActionTypes.CallOffUpdateRequest),
        withLatestFrom(
            this.store.select((store) => store.callOffState.updatedProperties),
            this.store.select((store) => store.callOffState.form)
        ),
        mergeMap(([action, updatedProperties, callOff]) => {
            return this.uploadAttachments(callOff).pipe(
                concatMap((uploadedAttachments) =>
                    this.callOffUpdate(callOff, updatedProperties, uploadedAttachments?.attachments).pipe(
                        catchError(([error]) => {
                            this.toastService.Error('Call-Off update failed.');
                            return of(new CallOffUpdateRequestError({ error: error[0], unlockForm: error[1] }));
                        })
                    )
                )
            );
        })
    ));

    
    loadScopeOfWorkDescription = createEffect(() => this.actions$.pipe(
        ofType(CallOffActionTypes.CallOffScopeOfWorkDescriptionRequest),
        mergeMap((action: CallOffScopeOfWorkDescriptionRequest) =>
            this.callOffService.getScopeOfWorkDescription(action.payload).pipe(
                map((description) => new CallOffScopeOfWorkDescriptionRequestSuccess(description)),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while loading Call-Off scope of work description. Please contact Program Administrator.'
                    );
                    return of(new CallOffScopeOfWorkDescriptionRequestError(error));
                })
            )
        )
    ));

    
    loadEstimateDetailsDescription = createEffect(() => this.actions$.pipe(
        ofType(CallOffActionTypes.CallOffEstimateDetailsDescriptionRequest),
        mergeMap((action: CallOffEstimateDetailsDescriptionRequest) =>
            this.callOffService.getEstimateDetailsDescription(action.payload).pipe(
                map((description) => new CallOffEstimateDetailsDescriptionRequestSuccess(description)),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while loading Call-Off estimate details description. Please contact Program Administrator.'
                    );
                    return of(new CallOffEstimateDetailsDescriptionRequestError(error));
                })
            )
        )
    ));

    
    updateStatus$ = createEffect(() => this.actions$.pipe(
        ofType(CallOffActionTypes.CallOffUpdateStatusRequest),
        mergeMap((action: CallOffUpdateStatusRequest) => {
            return this.callOffService.updateCallOffStatus(action.payload.id, action.payload.changeAction).pipe(
                map((callOff) => {
                    this.lookupService.canSendAutomatedEmails().subscribe((canSend) => {
                        if (
                            !canSend &&
                            callOff.status === CallOffStatusType.Submitted &&
                            callOff.contractsTeamReviewDate === null
                        ) {
                            this.sendEmail(callOff, [callOff.contractsTeamReviewer.email]);
                        } else if (
                            !canSend &&
                            callOff.status === CallOffStatusType.Reviewed &&
                            callOff.contractorApproveDate === null &&
                            callOff.companyApproveDate === null
                        ) {
                            this.sendEmail(callOff, [
                                callOff.contractorRepresentative.email,
                                callOff.companyRepresentative.email,
                            ]);
                        }
                    });
                    this.toastService.Success(
                        `Call-Off was successfully updated to status: ${CallOffStatusType[callOff.status]}`
                    );
                    return new CallOffUpdateStatusRequestSuccess(callOff);
                }),
                catchError((response: HttpErrorResponse) => {
                    if (response.status === 400) {
                        this.toastService.Error(response.error);
                        return of(new CallOffUpdateStatusRequestError({ unlockForm: true }));
                    } else {
                        this.toastService.Error(
                            'Error occurred while updating Call-Off status. Please save Call-Off manually, refresh page and retry updating status.'
                        );
                        return of(new CallOffUpdateStatusRequestError({ unlockForm: false }));
                    }
                })
            );
        })
    ));

    
    updateEstimateDetailsOnCallOffStatusUpdate$ = createEffect(() => this.actions$.pipe(
        ofType<CallOffUpdateStatusRequestSuccess>(CallOffActionTypes.CallOffUpdateStatusRequestSuccess),
        map(({ payload }) => new CallOffEstimateDetailsDescriptionRequest(payload.id))
    ));

    
    updateScopeOfWorkOnCallOffStatusUpdate$ = createEffect(() => this.actions$.pipe(
        ofType<CallOffUpdateStatusRequestSuccess>(CallOffActionTypes.CallOffUpdateStatusRequestSuccess),
        map(({ payload }) => new CallOffScopeOfWorkDescriptionRequest(payload.id))
    ));

    
    saveAndUpdateStatus$ = createEffect(() => this.actions$.pipe(
        ofType<CallOffSaveAndUpdateStatusRequest>(CallOffActionTypes.CallOffSaveAndUpdateStatusRequest),
        withLatestFrom(
            this.store.select((store) => store.callOffState.updatedProperties),
            this.store.select((store) => store.callOffState.form)
        ),
        mergeMap(([action, updatedProperties, callOff]) => {
            return this.uploadAttachments(callOff).pipe(
                concatMap((uploadedAttachments) =>
                    this.callOffUpdate(callOff, updatedProperties, uploadedAttachments?.attachments).pipe(
                        ofType<CallOffUpdateRequestSuccess>(CallOffActionTypes.CallOffUpdateRequestSuccess),
                        switchMap((successAction: CallOffUpdateRequestSuccess) => {
                            return [
                                successAction,
                                new CallOffUpdateStatusRequest({
                                    id: action.payload.form.id,
                                    changeAction: action.payload.changeAction,
                                }),
                            ];
                        }),
                        catchError(([error]) => {
                            return of(new CallOffUpdateRequestError({ error: error[0], unlockForm: error[1] }));
                        })
                    )
                )
            );
        })
    ));

    
    updateAweAfterSave$ = createEffect(() => this.actions$.pipe(
        ofType<CallOffUpdateRequestSuccess>(CallOffActionTypes.CallOffUpdateRequestSuccess),
        withLatestFrom(
            this.store.select((store) => store.callOffState.updatedProperties),
            this.store.select((store) => store.callOffState.form)
        ),
        mergeMap(([action, updatedProperties, callOff]) => {
            return this.aweService.getAweById(callOff.aweId).pipe(
                mergeMap((awe) => {
                    return of(
                        new CallOffUpdateProperty({
                            key: 'awe',
                            value: awe,
                            updatedProperties: updatedProperties,
                        })
                    );
                }),
                catchError((error) => {
                    return of(new CallOffUpdateRequestError(error));
                })
            );
        })
    ));

    
    loadActionUsers$ = createEffect(() => this.actions$.pipe(
        ofType(CallOffActionTypes.CallOffActionUsersRequest),
        mergeMap((action: CallOffActionUsersRequest) =>
            this.actionUsersService.getActionUsers(action.contractNo, action.reasonId).pipe(
                map((actionUsers: ActionUsers) => new CallOffActionUsersRequestSuccess(actionUsers)),
                catchError((error) => {
                    this.toastService.Error('Error occurred while getting Action Users.');
                    return of(new CallOffActionUsersRequestError(error));
                })
            )
        )
    ));

    
    removeCallOffComment$ = createEffect(() => this.actions$.pipe(
        ofType(CallOffActionTypes.CallOffRemoveCommentRequest),
        mergeMap((action: CallOffRemoveCommentRequest) =>
            this.callOffService.removeComment(action.payload.comment).pipe(
                map(() => new CallOffRemoveCommentSuccess(action.payload)),
                catchError((error: HttpErrorResponse) => {
                    if (error.status === 403) {
                        this.toastService.Error('A comment can be deleted only by the author.');
                    } else {
                        this.toastService.Error(
                            'Error occurred while removing Call-off comment. Please contact Program Administrator.'
                        );
                    }
                    return of(new CallOffRemoveCommentError(error));
                })
            )
        )
    ));

    
    addCallOffComment$ = createEffect(() => this.actions$.pipe(
        ofType(CallOffActionTypes.CallOffAddCommentRequest),
        mergeMap((action: CallOffAddCommentRequest) =>
            this.callOffService.addComment(action.payload.comment).pipe(
                map((comment) => new CallOffAddCommentSuccess({ comment: comment })),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while adding Call-off comment. Please contact Program Administrator.'
                    );
                    return of(new CallOffAddCommentError(error));
                })
            )
        )
    ));

    
    loadHistory$ = createEffect(() => this.actions$.pipe(
        ofType(CallOffActionTypes.CallOffHistoryRequest),
        mergeMap((action: CallOffHistoryRequest) =>
            this.callOffService.loadHistory(action.payload).pipe(
                map((history) => {
                    return new CallOffHistoryRequestSuccess(history);
                }),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while loading Call-Off history. Please contact Program Administrator.'
                    );
                    return of(new CallOffHistoryRequestError(error));
                })
            )
        )
    ));

    
    newCallOffRevision$ = createEffect(() => this.actions$.pipe(
        ofType<CallOffNewRevisionRequest>(CallOffActionTypes.CallOffNewRevisionRequest),
        mergeMap((action) =>
            this.callOffService.newCallOffRevision(action.payload.callOffId).pipe(
                map((callOffId: number) => {
                    this.toastService.Success('Call-Off with new revision added successfully.');
                    return new CallOffNewRevisionRequestSuccess({ callOffId, callOff: action.payload.callOff });
                }),
                catchError((error: HttpErrorResponse) => {
                    if (error.status === 400) {
                        const urlToLatestRevision = `<a href=\"${window.location.origin}/details/${error.error.latestCallOffIdRevision}\">Click here to go to the latest revision.</a>`;
                        this.toastService.Error(error.error.error + '<br/>' + urlToLatestRevision);
                    } else {
                        this.toastService.Error(
                            'Error has occurred while adding new revision of Call-Off. Please contact Program Administrator.'
                        );
                    }

                    return of(new CallOffNewRevisionRequestError(error.error.error));
                })
            )
        )
    ));

    
    newCallOffRevisionSuccess$ = createEffect(() => this.actions$.pipe(
        ofType<CallOffNewRevisionRequestSuccess>(CallOffActionTypes.CallOffNewRevisionRequestSuccess),
        tap(({ payload }) => {
            this.router.navigate(['details', payload.callOffId]);
        })
    ), { dispatch: false });

    
    getAttachments$ = createEffect(() => this.actions$.pipe(
        ofType(CallOffActionTypes.CallOffAttachmentsRequest),
        mergeMap((action: CallOffAttachmentsRequest) =>
            this.callOffService.getCallOffAttachmentsRequest(action.payload).pipe(
                switchMap((uploadedAttachments: any) => {
                    return [
                        new CallOffUpdateInitialFormWithAttachments({
                            attachments: uploadedAttachments,
                            type: action.payload.type,
                        }),
                        new CallOffAttachmentsSuccess({
                            attachments: uploadedAttachments,
                            type: action.payload.type,
                        }),
                    ];
                }),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while displaying attachment. Please contact Program Administrator.'
                    );
                    return of(new CallOffAttachmentsError(error));
                })
            )
        )
    ));

    
    downloadCallOffAttachmentRequest = createEffect(() => this.actions$.pipe(
        ofType(CallOffActionTypes.CallOffDownloadAttachmentRequest),
        mergeMap((action: CallOffDownloadAttachmentRequest) =>
            this.callOffService.downloadCallOffAttachmentRequest(action.payload.attachment).pipe(
                map(
                    (attachmentBinaries) =>
                        new CallOffDownloadAttachmentSuccess({
                            content: attachmentBinaries,
                            fileName: action.payload.attachment.name,
                            type: action.payload.type,
                        })
                ),
                catchError((error) => {
                    this.toastService.Error(
                        `Error occurred while downloading attachment ${action.payload.attachment.name}. Please contact Program Administrator.`
                    );
                    return of(
                        new CallOffDownloadAttachmentError({
                            attachment: action.payload.attachment,
                            type: action.payload.type,
                        })
                    );
                })
            )
        )
    ));

    
    downloadCallOffAttachmentRequestSuccess = createEffect(() => this.actions$.pipe(
        ofType<CallOffDownloadAttachmentSuccess>(CallOffActionTypes.CallOffDownloadAttachmentSuccess),
        map((action) => {
            const blob = new Blob([action.payload.content], {
                type: 'application/octet-stream',
            });

            saveAs(blob, action.payload.fileName);
        })
    ), { dispatch: false });

    private uploadAttachments(callOff: CallOff) {
        return iif(
            () =>
                callOff.SupportingDocumentAttachments.some((attachment) => !!attachment.file) ||
                callOff.EstimateAttachments.some((attachment) => !!attachment.file) ||
                (callOff.COFCompletedAttachments || []).some((attachment) => !!attachment.file) || 
                (callOff.COFCompanyCompletedAttachments || []).some((attachment) => !!attachment.file),
            this.callOffService.uploadCallOffAttachmentsRequest(
                callOff.id,
                _.concat(
                    callOff.SupportingDocumentAttachments.filter((attachment) => !!attachment.file),
                    callOff.EstimateAttachments.filter((attachment) => !!attachment.file),
                    (callOff.COFCompletedAttachments || []).filter((attachment) => !!attachment.file),
                    (callOff.COFCompanyCompletedAttachments || []).filter((attachment) => !!attachment.file)
                )
            ),
            of(null)
        );
    }

    private callOffUpdate(form: CallOff, updatedProperties: string[], uploadedAttachments: Attachment[] = []) {
        return this.callOffService.updateCallOff(form, updatedProperties).pipe(
            mergeMap((result: CallOff) => {
                const payload = {
                    ...result,
                    SupportingDocumentAttachments: [
                        ...form.SupportingDocumentAttachments.map((supportingAttachment) => {
                            const attachment = uploadedAttachments.find(
                                (uploadedAttachment) =>
                                    uploadedAttachment.callOffAttachmentType ===
                                        CallOffAttachmentType.SupportingDocument &&
                                    uploadedAttachment.name === supportingAttachment.name
                            );
                            return {
                                ...supportingAttachment,
                                link: attachment ? attachment.link : supportingAttachment.link,
                                callOffId: attachment ? attachment.callOffId : supportingAttachment.callOffId,
                                file: null,
                            };
                        }),
                    ],
                    EstimateAttachments: [
                        ...form.EstimateAttachments.map((estimateAttachment) => {
                            const attachment = uploadedAttachments.find(
                                (uploadedAttachment) =>
                                    uploadedAttachment.callOffAttachmentType === CallOffAttachmentType.Estimate &&
                                    uploadedAttachment.name === estimateAttachment.name
                            );
                            return {
                                ...estimateAttachment,
                                link: attachment ? attachment.link : estimateAttachment.link,
                                callOffId: attachment ? attachment.callOffId : estimateAttachment.callOffId,
                                file: null,
                            };
                        }),
                    ],
                    COFCompletedAttachments: [
                        ...(form.COFCompletedAttachments?.map((cofCompletedAttachment) => {
                            const attachment = uploadedAttachments.find(
                                (uploadedAttachment) =>
                                    uploadedAttachment.callOffAttachmentType === CallOffAttachmentType.COFCompleted &&
                                    uploadedAttachment.name === cofCompletedAttachment.name
                            );
                            return {
                                ...cofCompletedAttachment,
                                link: attachment ? attachment.link : cofCompletedAttachment.link,
                                callOffId: attachment ? attachment.callOffId : cofCompletedAttachment.callOffId,
                                file: null,
                            };
                        }) || []),
                    ],
                    COFCompanyCompletedAttachments: [
                        ...(form.COFCompanyCompletedAttachments?.map((cofCompletedAttachment) => {
                            const attachment = uploadedAttachments.find(
                                (uploadedAttachment) =>
                                    uploadedAttachment.callOffAttachmentType === CallOffAttachmentType.COFCompanyCompleted &&
                                    uploadedAttachment.name === cofCompletedAttachment.name
                            );
                            return {
                                ...cofCompletedAttachment,
                                link: attachment ? attachment.link : cofCompletedAttachment.link,
                                callOffId: attachment ? attachment.callOffId : cofCompletedAttachment.callOffId,
                                file: null,
                            };
                        }) || []),
                    ],
                };
                payload.OldEstimateAttachments = payload.EstimateAttachments;
                payload.OldSupportingDocumentAttachments = payload.SupportingDocumentAttachments;
                payload.OldCOFCompletedAttachments = payload.COFCompletedAttachments;
                payload.OldCOFCompanyCompletedAttachments = payload.COFCompanyCompletedAttachments;
                this.toastService.Success('Call-Off successfully saved.');
                return of(new CallOffUpdateRequestSuccess(payload));
            }),
            catchError((response: HttpErrorResponse) => {
                if (response.status === 400) {
                    this.toastService.Error(response.error);
                } else {
                    this.toastService.Error(
                        'Error occurred while updating Call-Off. Please save Call-Off manually and retry updating fields.'
                    );
                }
                return throwError([response.error, response.status === 400]);
            })
        );
    }

    private sendEmail(callOff: CallOff, recipients: string[]) {
        const originatorMail = this.isNotNullAndNotUndefined(callOff.originator) ? callOff.originator.email : '';
        const recipient = recipients.filter((r) => !!r).join(';');
        const options = {
            cc: originatorMail,
            subject: callOff.callOffNumber + ' ' + callOff.subject,
            body:
                'The following Call-Off is submitted for approval, please follow the link and to review and provide your approval \n' +
                window.location.href,
        };

        const mailto = document.createElement('a');
        document.body.appendChild(mailto);
        mailto.setAttribute('style', 'display: none');
        const url = this.emailService.getMailToURI(recipient, options);
        mailto.href = url;
        mailto.click();
        mailto.remove();
    }

    private isNotNullAndNotUndefined(item: any): boolean {
        return item !== null && item !== undefined;
    }
}
