import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { CallOffStatusType } from 'src/app/models/enums';
import { Attachment, CallOffAttachmentType } from 'src/app/store/call-off-attachments/model';
import { CallOffAttachmentsRequest, CallOffDownloadAttachmentRequest } from 'src/app/store/call-off/actions';
import { ApplicationState } from 'src/app/store/model';
import { BaseComponent } from '../../base.component';

@Component({
    selector: 'app-cof-completed',
    templateUrl: './cof-completed.component.html',
    styleUrls: ['./cof-completed.component.scss'],
})
export class COFCompletedComponent extends BaseComponent implements OnInit {
    @Input() callOffForm: FormGroup;
    cofStatuses = CallOffStatusType;

    attachments$ = this.store.select((state) => state.callOffState.form.COFCompletedAttachments);
    closeOutDetails$ = this.store.select((state) => ({
        date: state.callOffState.form.completedDate,
        name: state.callOffState.form.completedUser?.name
    }))

    companyCompletedAttachments$ = this.store.select((state) => state.callOffState.form.COFCompanyCompletedAttachments);
    companyCloseOutDetails$ = this.store.select((state) => ({
        date: state.callOffState.form.companyCompletedDate,
        name: state.callOffState.form.companyCompletedUser?.name
    }))

    callOffStatus$ = this.store.select((state) => state.callOffState.form.status);

    constructor(private store: Store<ApplicationState>) {
        super();
    }

    ngOnInit() {
        this.store.dispatch(
            new CallOffAttachmentsRequest({ id: this.callOffForm.value.id, type: CallOffAttachmentType.COFCompleted })
        );
        this.store.dispatch(
            new CallOffAttachmentsRequest({ id: this.callOffForm.value.id, type: CallOffAttachmentType.COFCompanyCompleted })
        );
    }

    downloadAttachment(attachment: Attachment) {
        this.store.dispatch(
            new CallOffDownloadAttachmentRequest({ attachment, type: attachment.callOffAttachmentType})
        );
    }
}
