import { OrderDirection, Contractor, CheckListColumn, RangeColumn, CalendarColumn } from 'src/app/store/common.model';
import * as moment from 'moment';
import { CallOffStatusType } from '../../models/enums';

export class CallOffRegisterFilter {
    page = 0;
    take = 10;
    sortBy = 'id';
    direction: OrderDirection = OrderDirection.Desc;
    contractors: Contractor[] = [];
    subsystems: { id: string }[] = [];
    cwps: { id: string }[] = [];
    disciplines: string[] = [];
    statuses: string[] = [];
    timezoneOffset = 0;
    subject: string = '';
    revision: string = '';
    number: string = '';
    originators: string[] = [];
    columnAWE: CheckListColumn = null;
    columnNTE: RangeColumn = null;
    columnRaisedDate: CalendarColumn = null;
    columnCompanyApproveDate: CalendarColumn = null;
    columnContractorApproveDate: CalendarColumn = null;
    columnNumbers: CheckListColumn = null;
    columnRevisions: CheckListColumn = null;
    columnDisciplines: CheckListColumn = null;
    columnSubjects: CheckListColumn = null;
    columnContracts: CheckListColumn = null;
    columnStatuses: CheckListColumn = null;
    columnSubsystems: CheckListColumn = null;
    columnOriginators: CheckListColumn = null;
    columnCWPs: CheckListColumn = null;
    columnManualFormNos: CheckListColumn = null;
    columnParentNos: CheckListColumn = null;
}

export class CallOffRegisterDTO {
    id: number;
    number: string;
    revision: string;
    discipline: string;
    subject: string;
    originator: string;
    contractor: string;
    status: CallOffStatusType;
    nteUSD: number;
    subsystems: string[] = [];
    raisedDate: moment.Moment;
    contractorApproveDate: moment.Moment;
    companyApproveDate: moment.Moment;
    cwp: string;
    awe: string;
    manualFormNo: string;
    parentNo: string;
}

export interface CallOffRegisterState {
    dataPagination: CallOffRegisterDataPagination;
    filter: CallOffRegisterFilter;
    isLoading: boolean;
    showColumnSettings: ShowColumnSettings;
}

export class ShowColumnSettings {
    discipline = true;
    originator = true;
    raiseDate = true;
    AWE = false;
    companyApprDate = false;
    contractorApprDate = false;
    CWP = false;
    contractor = false;
    manualFormNo = false;
    parentNo = false;
}

export class CallOffRegisterDataPagination {
    items: CallOffRegisterDTO[] = [];
    totalCount = 0;
}
