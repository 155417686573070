<div class="title-container">
    <div class="dmtitle-container">
        <h4>amend limits</h4>
        <button
            type="submit"
            *ngIf="!anyItemInEditMode()"
            class="cof-button cof-button-light ok-button"
            (click)="addLimit()"
            [disabled]="anyRowInEditMode()"
        >
            add new limit
        </button>
    </div>
</div>
<div class="results-container">
    <table
        mat-table
        matSort
        [dataSource]="limits"
        [matSortActive]="sortBy"
        matSortDisableClear
        [matSortDirection]="direction"
    >
        <ng-container matColumnDef="key">
            <th mat-header-cell mat-sort-header disableClear *matHeaderCellDef>Contractor</th>
            <td mat-cell *matCellDef="let element; let i = index">
                <div *ngIf="!element.isInEditMode">
                    {{ element.contractNo }}
                </div>
                <mat-form-field class="margin-top20" *ngIf="element.isInEditMode">
                    <mat-select required [(ngModel)]="element.contractNo">
                        <mat-option>
                            <ngx-mat-select-search
                                [formControl]="filteredContractorCtrl"
                                placeholderLabel="Search..."
                                noEntriesFoundLabel="No entries found"
                            ></ngx-mat-select-search>
                        </mat-option>
                        <mat-option
                            *ngFor="let contractor of filteredContractors | async"
                            [value]="contractor.contractNo"
                            >{{ contractor.contractNo }}</mat-option
                        >
                    </mat-select>
                </mat-form-field>
            </td>
        </ng-container>
        <ng-container matColumnDef="value">
            <th mat-header-cell mat-sort-header disableClear *matHeaderCellDef>Limit</th>
            <td mat-cell *matCellDef="let element">
                <div *ngIf="!element.isInEditMode">{{ element.limit | currency: 'USD':'symbol':'1.2-2' }}</div>
                <div *ngIf="element.isInEditMode" class="editmode">
                    <mat-form-field>
                        <input
                            matInput
                            [ngModel]="element.limit"
                            (ngModelChange)="element.limit = $event"
                            [ngModelOptions]="{ standalone: true }"
                            name="limit"
                            type="number"
                            autocomplete="off"
                            required
                        />
                    </mat-form-field>
                </div>
            </td>
        </ng-container>
        <ng-container matColumnDef="edit">
            <th mat-header-cell disableClear *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element; let i = index">
                <button
                    *ngIf="!element.isInEditMode && !anyItemInEditMode()"
                    mat-icon-button
                    (click)="enterEditMode(element)"
                    title="edit"
                >
                    <mat-icon svgIcon="edit"></mat-icon>
                </button>
                <button *ngIf="!element.isInEditMode" mat-icon-button (click)="remove(element)" title="remove">
                    <mat-icon svgIcon="delete"></mat-icon>
                </button>
                <button
                    *ngIf="element.isInEditMode"
                    [disabled]="isAcceptDisabled(element)"
                    mat-icon-button
                    (click)="save(element)"
                    title="accept"
                >
                    <mat-icon>check_circle_outline</mat-icon>
                </button>
                <button *ngIf="element.isInEditMode" mat-icon-button (click)="cancelEdit(element)" title="cancel">
                    <mat-icon>cancel</mat-icon>
                </button>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
</div>
<app-loading-indicator *ngIf="isLoading"></app-loading-indicator>
