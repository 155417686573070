import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ExchangeRate } from '../store/call-off/model';
import { Setting } from '../../app/models/setting';
import { ContractorLimit } from '../models/contractor-limit';
import { CurrenciesToContract } from '../models/currencies-to-contract';
import { appConfig } from '../app.config';

@Injectable({
    providedIn: 'root',
})
export class ConfigurationService {
    private readonly rootUrl: string = appConfig.apiEndpoint;
    private readonly configurationEndpoint: string = '/configuration';

    constructor(private http: HttpClient) {}

    getExchangeRates() {
        return this.http.get<ExchangeRate[]>(`${this.rootUrl}${this.configurationEndpoint}/exchangerates`);
    }

    updateExchangeRate(exchange: ExchangeRate) {
        return this.http.patch(`${this.rootUrl}${this.configurationEndpoint}/exchangerates`, exchange);
    }

    updateExchangeRateToDefault() {
        return this.http.post(`${this.rootUrl}${this.configurationEndpoint}/exchangerates/default`, {});
    }

    getConfigData() {
        return this.http.get<Setting[]>(`${this.rootUrl}${this.configurationEndpoint}`);
    }

    getContractorsLimits() {
        return this.http.get<ContractorLimit[]>(`${this.rootUrl}${this.configurationEndpoint}/contractorslimits`);
    }

    getCurrenciesToContractors() {
        return this.http.get<CurrenciesToContract[]>(`${this.rootUrl}${this.configurationEndpoint}/currencies`);
    }

    updateConfigData(configData: Setting[]) {
        return this.http.patch(`${this.rootUrl}${this.configurationEndpoint}`, configData);
    }

    updateLimits(item: ContractorLimit) {
        return this.http.post(`${this.rootUrl}${this.configurationEndpoint}/contractorslimits`, item);
    }

    deleteLimit(item: ContractorLimit) {
        return this.http.request('delete', `${this.rootUrl}${this.configurationEndpoint}/contractorslimits`, { body: item});
    }

    updateCurrenciesToContract(item: CurrenciesToContract) {
        return this.http.post(`${this.rootUrl}${this.configurationEndpoint}/currencies`, item);
    }

    deleteCurrenciesToContract(item: CurrenciesToContract) {
        return this.http.request('delete', `${this.rootUrl}${this.configurationEndpoint}/currencies`, { body: item});
    }
}
