import { Component, OnInit, Input, forwardRef } from '@angular/core';
import { ToastService } from 'src/app/services/shared/toast.service';
import { Store } from '@ngrx/store';
import { takeWhile } from 'rxjs/operators';
import { BaseComponent } from 'src/app/components/base.component';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormGroup } from '@angular/forms';
import { Attachment, CallOffAttachmentType } from 'src/app/store/call-off-attachments/model';
import { ApplicationState } from 'src/app/store/model';
import { RoleService } from 'src/app/services/shared/role.service';
import _ from 'lodash';
import { CallOffAttachmentsRequest, CallOffDownloadAttachmentRequest } from 'src/app/store/call-off/actions';

@Component({
    selector: 'app-cof-attachment-sia',
    templateUrl: './cof-attachment-sia.component.html',
    styleUrls: ['./cof-attachment-sia.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CofAttachmentSupportingInformationAttachmentComponent),
            multi: true,
        },
    ],
})
export class CofAttachmentSupportingInformationAttachmentComponent extends BaseComponent
    implements ControlValueAccessor, OnInit {
    @Input() headerText = '';
    @Input() callOffForm: FormGroup;
    @Input() printMode = false;
    @Input() type: CallOffAttachmentType = CallOffAttachmentType.SupportingDocument;
    @Input() displayNote = true;

    propagateChange = (_: any) => {};

    attachments: Attachment[] = [];
    callOffId: number;
    isLocked = false;
    disabled = false;
    isReadOnly: boolean;
    attachmentTypes = CallOffAttachmentType;
    callOffId$ = this.store.select((state) => state.callOffState.form.id);
    isLocked$ = this.store.select((state) => state.callOffState.isLocked);
    attachments$ = this.store.select<Attachment[]>(
        (state) => state.callOffState.form[`${this.attachmentTypes[this.type]}Attachments`]
    );

    constructor(
        private toastService: ToastService,
        private store: Store<ApplicationState>,
        private roleService: RoleService
    ) {
        super();
    }

    ngOnInit() {
        this.isReadOnly = this.roleService.isReadOnly();
        this.callOffId$.pipe(takeWhile(() => this.isAlive)).subscribe((callOffId) => {
            this.callOffId = callOffId;
            if (this.callOffId !== null && this.type != CallOffAttachmentType.COFCompleted && this.type != CallOffAttachmentType.COFCompanyCompleted) {
                this.store.dispatch(new CallOffAttachmentsRequest({ id: this.callOffId, type: this.type }));
            }
        });

        this.isLocked$.pipe(takeWhile(() => this.isAlive)).subscribe((isLocked) => {
            this.isLocked = isLocked;
        });

        this.attachments$.pipe(takeWhile(() => this.isAlive)).subscribe((attachments) => {
            this.attachments = attachments;
        });
    }

    fileChangeEvent(files: Array<File>) {
        const filesWrapper = new Array<File>();
        filesWrapper.push(...files);
        filesWrapper.forEach((file) => {
            if (file.size === 0) {
                this.toastService.Info(`File ${file.name} has 0B size. Please upload a valid file.`);
                return;
            }
            if (_.some(this.attachments, (attachment) => attachment.name === file.name)) {
                this.toastService.Info(
                    'File ' + file.name + ' already uploaded. Please delete existing file and re-upload.'
                );
            } else {
                const attachment = new Attachment();
                attachment.file = file;
                attachment.name = file.name;
                attachment.isValid = true;
                attachment.callOffAttachmentType = this.type;
                this.attachments = [...(this.attachments || []), attachment];
            }
        });
        this.propagateChange(this.attachments);
    }

    removeAttachment(name: string) {
        this.attachments = [..._.filter(this.attachments, (attachment) => attachment.name !== name)];
        this.propagateChange(this.attachments);
    }

    downloadAttachment(attachment: Attachment) {
        this.store.dispatch(new CallOffDownloadAttachmentRequest({ attachment, type: this.type }));
    }

    writeValue(value: any) {}

    registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }
    registerOnTouched(fn: any): void {}
    setDisabledState?(isDisabled: boolean): void {}
}
