<div class="title-container">
    <div class="dmtitle-container">
        <h4>amend exchange rate</h4>
        <button
            class="cof-button cof-button-light"
            (click)="setDefaults()"
            matTooltip="Sets currency rates as follows:&#13;&#13;{{ tooltipDefaultValue.join('\n') }}"
            matTooltipClass="cofftooltip linebreak"
        >
            set to default
        </button>
    </div>
</div>
<div class="results-container">
    <table mat-table matSort [dataSource]="dataSource" matSortDisableClear>
        <ng-container matColumnDef="currency">
            <th mat-header-cell disableClear *matHeaderCellDef>currency</th>
            <td mat-cell *matCellDef="let element">
                <div>USD:{{ element.currency }}</div>
            </td>
        </ng-container>
        <ng-container matColumnDef="value">
            <th mat-header-cell disableClear *matHeaderCellDef>rate</th>
            <td mat-cell *matCellDef="let element">
                <div *ngIf="!element.isInEditMode">1 : {{ element.value }}</div>
                <div *ngIf="element.isInEditMode" class="editmode">
                    <input type="number" [(ngModel)]="element.value" />
                </div>
            </td>
        </ng-container>
        <ng-container matColumnDef="edit">
            <th mat-header-cell disableClear *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
                <button *ngIf="!element.isInEditMode" mat-icon-button (click)="enterEditMode(element)" title="edit">
                    <mat-icon svgIcon="edit"></mat-icon>
                </button>
                <button *ngIf="element.isInEditMode" mat-icon-button (click)="save(element)" title="accept">
                    <mat-icon>check_circle_outline</mat-icon>
                </button>
                <button *ngIf="element.isInEditMode" mat-icon-button (click)="cancelEdit(element)" title="cancel">
                    <mat-icon>cancel</mat-icon>
                </button>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
</div>
<app-loading-indicator *ngIf="isLoading"> </app-loading-indicator>
