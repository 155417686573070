<div class="title-container">
    <div class="dmtitle-container">
        <h4>amend automated AWE NTE notifications</h4>
        <mat-slide-toggle
            [color]="'primary'"
            [checked]="notificationsEnabled"
            (toggleChange)="toggleEnableNotifications()"
        >
            Enable AWE NTE Limit Notifications
        </mat-slide-toggle>
    </div>
</div>
<div class="results-container">
    <table
        mat-table
        matSort
        [dataSource]="dataSource"
        [matSortActive]="sortBy"
        matSortDisableClear
        [matSortDirection]="direction"
    >
        <ng-container matColumnDef="contract">
            <th mat-header-cell mat-sort-header disableClear *matHeaderCellDef>Contract</th>
            <td mat-cell *matCellDef="let element">
                <div>{{ element.contract }}</div>
            </td>
        </ng-container>
        <ng-container matColumnDef="percentageLimit">
            <th mat-header-cell mat-sort-header disableClear *matHeaderCellDef>Percentage Limit</th>
            <td mat-cell *matCellDef="let element">
                <div *ngIf="!element.isInEditMode">{{ element.percentageLimit }}%</div>
                <div *ngIf="element.isInEditMode">
                    <mat-slider
                        min="0"
                        max="100"
                        step="1"
                        value="element.percentageLimit"
                        thumbLabel
                        tickInterval="1"
                        [(ngModel)]="element.percentageLimit"
                    ></mat-slider>
                </div>
            </td>
        </ng-container>
        <ng-container matColumnDef="emails">
            <th mat-header-cell mat-sort-header disableClear *matHeaderCellDef>Emails</th>
            <td mat-cell *matCellDef="let element">
                <div *ngIf="!element.isInEditMode">{{ element.emails }}</div>
                <div *ngIf="element.isInEditMode" class="editmode">
                    <mat-form-field
                        ><input
                            matInput
                            [(ngModel)]="element.emails"
                            [ngModelOptions]="{ standalone: true }"
                            name="emails"
                            autocomplete="off"
                            (input)="onEmailsChange($event.target.value)"
                        />
                    </mat-form-field>
                </div>
            </td>
        </ng-container>
        <ng-container matColumnDef="edit">
            <th mat-header-cell disableClear *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
                <button *ngIf="!element.isInEditMode" mat-icon-button (click)="enterEditMode(element)" title="edit">
                    <mat-icon svgIcon="edit"></mat-icon>
                </button>
                <button
                    *ngIf="element.isInEditMode"
                    mat-icon-button
                    (click)="save(element)"
                    title="accept"
                    [disabled]="emailsNotValid"
                >
                    <mat-icon>check_circle_outline</mat-icon>
                </button>
                <button *ngIf="element.isInEditMode" mat-icon-button (click)="cancelEdit(element)" title="cancel">
                    <mat-icon>cancel</mat-icon>
                </button>
            </td>
        </ng-container>
        <ng-container matColumnDef="remove">
            <th mat-header-cell disableClear *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
                <button mat-icon-button (click)="remove(element)" title="remove">
                    <mat-icon svgIcon="delete"></mat-icon>
                </button>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    <mat-toolbar>
        <span>Add new automated AWE NTE notification</span>
        <button mat-icon-button (click)="add()" title="add">
            <mat-icon svgIcon="add"></mat-icon>
        </button>
    </mat-toolbar>
    <div class="paginator page-counter">
        <mat-paginator
            [length]="resultsLength"
            [pageSize]="pageSize"
            [pageSizeOptions]="[5, 10, 15, 20]"
            showFirstLastButtons
        >
        </mat-paginator>
        <input type="number" [value]="this.paginator.pageIndex + 1" (change)="onPageChange($event.target.value)" />
    </div>
</div>
<app-loading-indicator *ngIf="isLoading"> </app-loading-indicator>
