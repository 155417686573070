<div class="popup">
    <p mat-dialog-title class="title">
        <span class="font-gotham-narrow-bold">add automated AWE NTE notification</span>
    </p>
    <div>
        <form [formGroup]="nteNotificationForm">
            <mat-form-field>
                <mat-label>Contract</mat-label>
                <mat-select formControlName="contract">
                    <mat-option *ngFor="let c of contractors" [value]="c.contractNo">{{ c.contractNo }}</mat-option>
                </mat-select>
            </mat-form-field>

            <div>Percentage Limit</div>
            <mat-slider
                min="0"
                max="100"
                step="1"
                value="50"
                thumbLabel
                tickInterval="1"
                formControlName="percentageLimit"
                required
            ></mat-slider>
            <mat-form-field>
                <mat-label>Emails</mat-label>
                <input matInput formControlName="emails" placeholder="email@chevron.com, email@chevron.com" required />
                <mat-error
                    *ngIf="
                        nteNotificationForm.controls['emails'].invalid &&
                        nteNotificationForm.controls['emails'].errors.required
                    "
                >
                    Email is required.
                </mat-error>
            </mat-form-field>
            <div class="row">
                <button mat-button type="button" class="cof-button cof-button-white" (click)="onCancel()">
                    cancel
                </button>
                <button
                    mat-button
                    type="button"
                    [disabled]="!nteNotificationForm.valid"
                    class="cof-button cof-button-light"
                    (click)="onConfirm()"
                >
                    add
                </button>
            </div>
            <app-loading-indicator *ngIf="loading"> </app-loading-indicator>
        </form>
    </div>
</div>
