import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CallOffMypageComponent } from './components/call-off-mypage/call-off-mypage.component';
import { CallOfFormComponent } from './components/call-of-form/call-of-form.component';
import { CallOffRegisterComponent } from './components/call-off-register/call-off-register.component';
import { AweRegisterComponent } from './components/awe-register/awe-register.component';
import { CanDeactivateCallOffGuard } from './guards/can-deactivate-calloff.guard';
import { UserSynchronizationComponent } from './components/user-synchronization/user-synchronization.component';
import { AdminComponent } from './components/admin-section/admin.component';
import { DashboardComponent } from './components/admin-section/dashboard/dashboard.component';
import { GroupsMaintenanceComponent } from './components/admin-section/groups-maintenance/groups-maintenance.component';
import { IsAdminGuard } from './guards/is-admin-guard';
import { IsInGroupGuard } from './guards/is-in-group-guard';
import { NotFoundComponent } from './components/error/not-found/not-found.component';
import { NotAuthorizedComponent } from './components/error/not-authorized/not-authorized.component';
import { ApprovalMatrixComponent } from './components/admin-section/groups-maintenance/approval-matrix/approval-matrix.component';
import { ConfigurationComponent } from './components/admin-section/configuration/configuration.component';
import { ExchangeRateComponent } from './components/admin-section/configuration/exchange-rate/exchange-rate.component';
import { UserToContractsComponent } from './components/admin-section/groups-maintenance/user-to-contracts/user-to-contracts.component';
import { SettingsComponent } from './components/admin-section/configuration/settings/settings.component';
import { NTENotificationComponent } from './components/admin-section/configuration/nte-notification/nte-notification.component';
import { LimitsComponent } from './components/admin-section/configuration/limits/limits.component';
import { CurrenciesComponent } from './components/admin-section/configuration/currencies/currencies.component';
import { ConstructionApproverMatrixComponent } from './components/admin-section/groups-maintenance/construction-approver-matrix/construction-approver-matrix.component';
import { MsalGuard } from '@azure/msal-angular';

const routes: Routes = [
    {
        path: '',
        canActivateChild: [MsalGuard],
        children: [
            { path: '', component: CallOffMypageComponent },
            {
                path: 'details/:id',
                component: CallOfFormComponent,
                canDeactivate: [CanDeactivateCallOffGuard],
            },
            { path: 'register', component: CallOffRegisterComponent },
            { path: 'awe/register', component: AweRegisterComponent },
            { path: 'synchronize', component: UserSynchronizationComponent },
            {
                path: 'admin',
                component: AdminComponent,
                children: [
                    {
                        path: '',
                        canActivateChild: [IsAdminGuard],
                        children: [
                            { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
                            { path: 'dashboard', component: DashboardComponent },
                            {
                                path: 'groups-maintenance',
                                component: GroupsMaintenanceComponent,
                                children: [
                                    { path: '', redirectTo: 'approval-matrix', pathMatch: 'full' },
                                    { path: 'approval-matrix', component: ApprovalMatrixComponent },
                                    { path: 'user-to-contracts', component: UserToContractsComponent },
                                    { path: 'construction-approver-matrix', component: ConstructionApproverMatrixComponent },
                                ],
                            },
                            {
                                path: 'configuration',
                                component: ConfigurationComponent,
                                children: [
                                    { path: '', redirectTo: 'exchange-rate', pathMatch: 'full' },
                                    { path: 'exchange-rate', component: ExchangeRateComponent },
                                    { path: 'settings', component: SettingsComponent },
                                    { path: 'nte-notification', component: NTENotificationComponent },
                                    { path: 'limits', component: LimitsComponent },
                                    { path: 'currencies', component: CurrenciesComponent },
                                ],
                            },
                        ],
                    },
                ],
            },
            { path: 'notfound', component: NotFoundComponent, canActivate: [IsInGroupGuard] },
        ],
    },
    { path: 'notauthorized', component: NotAuthorizedComponent },
    { path: '**', component: NotFoundComponent, canActivate: [IsInGroupGuard] },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
    exports: [RouterModule],
})
export class AppRoutingModule {}
