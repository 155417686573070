export class ReasonForInstruction {
    id: number;
    name: string;
    status: ReasonStatus;
}
export class Area {
    id: number;
    name: string;
    code: string;
}

export enum ReasonStatus {
    Active = 'Active',
    Void = 'Void',
}

export enum OrderDirection {
    Asc = 'asc',
    Desc = 'desc',
}
