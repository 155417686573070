import {
    CallOffMyPageState,
    MyPageCounters,
    MyPageCollection,
    MyPageCallOffRow,
    MyPageFilter,
    CallOffMyPageSections,
    DelegationsRow,
    DelegationFilter,
} from './models';
import { CallOffMyPageActionTypes, CallOffMyPageActions } from './actions';
import { Constants } from '../../constants';

const initialState: CallOffMyPageState = {
    counters: new MyPageCounters(),
    isCountersSectionLoading: false,

    myPendingActionsPagination: new MyPageCollection<MyPageCallOffRow>(),
    myPendingActionsFilter: new MyPageFilter(Constants.CallOffDefaults.defaultSortField),
    isMyPendingActionsSectonLoading: false,

    allDraftsPagination: new MyPageCollection<MyPageCallOffRow>(),
    allDraftsFilter: new MyPageFilter(Constants.CallOffDefaults.defaultSortField),
    isAllDraftsSectionLoading: false,

    summaryPagination: new MyPageCollection<MyPageCallOffRow>(),
    summaryFilter: new MyPageFilter(Constants.CallOffDefaults.defaultSortField),
    isSummarySectionLoading: false,
    selectedSummarySection: CallOffMyPageSections.Total,

    delegationsPagination: new MyPageCollection<DelegationsRow>(),
    isDelegationsSectionLoading: false,
    delegationsFilter: new DelegationFilter(Constants.CallOffDefaults.defaultSortField),
    selectedItems: [],
};

export function reducer(state: CallOffMyPageState = initialState, action: CallOffMyPageActions) {
    switch (action.type) {
        case CallOffMyPageActionTypes.MyPageCountersRequest: {
            return {
                ...state,
                isCountersSectionLoading: true,
            };
        }
        case CallOffMyPageActionTypes.MyPageCountersRequestSuccess: {
            return {
                ...state,
                counters: action.payload,
                isCountersSectionLoading: false,
            };
        }
        case CallOffMyPageActionTypes.MyPageCountersRequestError: {
            return {
                ...state,
                isCountersSectionLoading: false,
            };
        }

        case CallOffMyPageActionTypes.MyPendingActionsRequest: {
            return {
                ...state,
                isMyPendingActionsSectonLoading: true,
            };
        }
        case CallOffMyPageActionTypes.MyPendingActionsRequestSuccess: {
            return {
                ...state,
                isMyPendingActionsSectonLoading: false,
                myPendingActionsPagination: action.payload,
            };
        }
        case CallOffMyPageActionTypes.MyPendingActionsRequestError: {
            return {
                ...state,
                isMyPendingActionsSectonLoading: false,
            };
        }
        case CallOffMyPageActionTypes.MyPendingActionsUpdateFileterProperty: {
            if (action.payload.key === 'sortBy') {
                return {
                    ...state,
                    myPendingActionsFilter: {
                        ...state.myPendingActionsFilter,
                        sortBy: action.payload.value.active,
                        direction: action.payload.value.direction,
                        page: 0,
                    },
                };
            } else {
                return {
                    ...state,
                    myPendingActionsFilter: {
                        ...state.myPendingActionsFilter,
                        [action.payload.key]: Array.isArray(state.myPendingActionsFilter[action.payload.key])
                            ? [...action.payload.value]
                            : action.payload.value,
                    },
                };
            }
        }

        case CallOffMyPageActionTypes.SummaryRequest: {
            return {
                ...state,
                isSummarySectionLoading: true,
            };
        }
        case CallOffMyPageActionTypes.SummaryRequestSuccess: {
            return {
                ...state,
                isSummarySectionLoading: false,
                summaryPagination: action.payload,
            };
        }
        case CallOffMyPageActionTypes.SummaryRequestError: {
            return {
                ...state,
                isSummarySectionLoading: false,
            };
        }
        case CallOffMyPageActionTypes.SummaryUpdateFilterProperty: {
            if (action.payload.key === 'sortBy') {
                return {
                    ...state,
                    summaryFilter: {
                        ...state.summaryFilter,
                        sortBy: action.payload.value.active,
                        direction: action.payload.value.direction,
                        page: 0,
                    },
                };
            } else {
                return {
                    ...state,
                    summaryFilter: {
                        ...state.summaryFilter,
                        [action.payload.key]: Array.isArray(state.summaryFilter[action.payload.key])
                            ? [...action.payload.value]
                            : action.payload.value,
                    },
                };
            }
        }
        case CallOffMyPageActionTypes.MyPageCountersSetSummarySection: {
            return {
                ...state,
                selectedSummarySection: action.payload,
                summaryFilter: initialState.summaryFilter,
            };
        }
        case CallOffMyPageActionTypes.AllDraftsRequest: {
            return {
                ...state,
                isAllDraftsSectionLoading: true,
            };
        }
        case CallOffMyPageActionTypes.AllDraftsRequestSuccess: {
            return {
                ...state,
                isAllDraftsSectionLoading: false,
                allDraftsPagination: action.payload,
            };
        }
        case CallOffMyPageActionTypes.AllDraftsRequestError: {
            return {
                ...state,
                isAllDraftsSectionLoading: false,
            };
        }
        case CallOffMyPageActionTypes.AllDraftsUpdateFilterProperty: {
            if (action.payload.key === 'sortBy') {
                return {
                    ...state,
                    allDraftsFilter: {
                        ...state.allDraftsFilter,
                        sortBy: action.payload.value.active,
                        direction: action.payload.value.direction,
                        page: 0,
                    },
                };
            } else {
                return {
                    ...state,
                    allDraftsFilter: {
                        ...state.allDraftsFilter,
                        [action.payload.key]: Array.isArray(state.allDraftsFilter[action.payload.key])
                            ? [...action.payload.value]
                            : action.payload.value,
                    },
                };
            }
        }

        case CallOffMyPageActionTypes.DelegationsRequest: {
            return {
                ...state,
                isDelegationsSectionLoading: true,
            };
        }
        case CallOffMyPageActionTypes.DelegationsRequestSuccess: {
            let items = action.payload.items.map((item) => ({
                ...item,
                selected: state.selectedItems.map((s) => s.id).indexOf(item.id) > -1,
            }));
            let pagination = Object.assign({}, action.payload, { items });
            return {
                ...state,
                isDelegationsSectionLoading: false,
                delegationsPagination: pagination,
            };
        }
        case CallOffMyPageActionTypes.DelegationsRequestError: {
            return {
                ...state,
                isDelegationsSectionLoading: false,
            };
        }
        case CallOffMyPageActionTypes.DelegationsUpdateFilterProperty: {
            let selectedItems = state.selectedItems;
            if (action.payload.key === 'type') {
                selectedItems = [];
            }
            if (action.payload.key === 'sortBy') {
                return {
                    ...state,
                    delegationsFilter: {
                        ...state.delegationsFilter,
                        sortBy: action.payload.value.active,
                        direction: action.payload.value.direction,
                        page: 0,
                    },
                    selectedItems,
                };
            } else {
                return {
                    ...state,
                    delegationsFilter: {
                        ...state.delegationsFilter,
                        [action.payload.key]: Array.isArray(state.delegationsFilter[action.payload.key])
                            ? [...action.payload.value]
                            : action.payload.value,
                    },
                    selectedItems,
                };
            }
        }
        case CallOffMyPageActionTypes.DelegationsToggleSelected: {
            let selectedItems = Object.assign(new Array<DelegationsRow>(), state.selectedItems);
            if (action.payload.selected) {
                selectedItems.push(action.payload.item);
            } else {
                let index = selectedItems.map((s) => s.id).indexOf(action.payload.item.id);
                if (index > -1) {
                    selectedItems.splice(index, 1);
                }
            }
            return {
                ...state,
                selectedItems: selectedItems,
            };
        }
        case CallOffMyPageActionTypes.DelegationsSelectedAll: {
            let selectedItems = Object.assign(new Array<DelegationsRow>(), state.selectedItems);
            if (action.payload.selected) {
                selectedItems = selectedItems
                    .concat(state.delegationsPagination.items)
                    .filter((value, index, self) => self.map((s) => s.id).indexOf(value.id) === index);
            } else {
                selectedItems = selectedItems.filter(
                    (s) => state.delegationsPagination.items.findIndex((f) => f.id === s.id) === -1
                );
            }
            return {
                ...state,
                selectedItems: selectedItems,
            };
        }
        case CallOffMyPageActionTypes.DelegationsAssignmentRequest: {
            return {
                ...state,
                isDelegationsSectionLoading: true,
            };
        }
        case CallOffMyPageActionTypes.DelegationsAssignmentRequestSuccess: {
            return {
                ...state,
                isDelegationsSectionLoading: false,
                selectedItems: [],
            };
        }
        case CallOffMyPageActionTypes.DelegationsAssignmentRequestError: {
            return {
                ...state,
                isDelegationsSectionLoading: false,
            };
        }

        default: {
            return state;
        }
    }
}
