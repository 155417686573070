<span class="close" (click)="cancel()">&times;</span>
<div class="title-container">
    <p mat-dialog-title class="title">
        <span class="font-gotham-narrow-bold">select AWE</span>
    </p>
    <div *ngIf="selectedAWE" class="selected-awe">selected AWE: {{ selectedAWE.number }}</div>
</div>
<div class="buttons-container action">
    <button type="button" (click)="cancel()" class="cof-button cof-button-white margin-right20">
        cancel
    </button>
    <button type="button" (click)="confirm()" [disabled]="!selectedAWE" class="cof-button cof-button-light">
        confirm
    </button>
</div>

<div class="filters-container" [formGroup]="filterForm">
    <app-multiple-items-selector
        class="container65pct margin-right20"
        [setInput]="setContractorInput"
        formControlName="contractors"
        [searchFunc]="getContractors"
        [isAsync]="true"
        [isTableAutoComplete]="true"
        [displayedColumns]="autocompleteDisplayedColumns"
        (autocompleteClosed)="onContractorsClosed()"
        (itemRemoved)="onContractorsClosed()"
        [filterForm]="filterForm"
        [formSetter]="'contractors'"
        [propertyToShow]="'contractNo'"
        [placeholder]="'Contractor'"
        [contractorClass]="'nobreak'"
    >
    </app-multiple-items-selector>
    <mat-form-field class="container35pct">
        <mat-label>AWE Title</mat-label>
        <input matInput formControlName="aweTitle" />
        <mat-error
            *ngIf="
                (filterForm.controls['aweTitle'].dirty || filterForm.controls['aweTitle'].touched) &&
                filterForm.controls['aweTitle'].invalid &&
                filterForm.controls['aweTitle'].errors.maxlength
            "
        >
            AWE Title can be no more than 200 characters length.
        </mat-error>
    </mat-form-field>
</div>
<div class="buttons-container">
    <button type="button" (click)="resetFilters()" class="cof-button cof-button-light margin-right20">
        reset filters
    </button>
    <button type="button" (click)="search()" class="cof-button cof-button-light">search</button>
</div>
<mat-dialog-content>
    <div class="results-container">
        <div class="scroll">
            <table mat-table [dataSource]="data" class="awe-table">
                <ng-container matColumnDef="number" sticky>
                    <th app-col-resize mat-header-cell disableClear *matHeaderCellDef class="sort-header">
                        <span>AWE Number</span>
                    </th>
                    <td mat-cell *matCellDef="let element" class="awe-number">{{ element.number }}</td>
                </ng-container>
                <ng-container matColumnDef="subject">
                    <th app-col-resize mat-header-cell disableClear *matHeaderCellDef class="sort-header">
                        <span>AWE Title</span>
                    </th>
                    <td mat-cell *matCellDef="let element" class="awe-title">{{ element.subject }}</td>
                </ng-container>
                <ng-container matColumnDef="nteRemaining">
                    <th app-col-resize mat-header-cell disableClear *matHeaderCellDef class="center">
                        Remaining (USDE)
                    </th>
                    <td mat-cell *matCellDef="let element" class="awe-nte">
                        {{ element.nteRemaining | currency: 'USD':'symbol':'1.2-2' }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="attachments">
                    <th app-col-resize mat-header-cell disableClear *matHeaderCellDef class="center">Attachments</th>
                    <td mat-cell *matCellDef="let element" class="attachments">
                        <div class="uploaded-attachment-buttons" *ngFor="let att of element.attachments">
                            <div
                                class="lds-hourglass-small"
                                *ngIf="uploadedAttachmentsActionInProgress.getValue(att.link) || false"
                            ></div>
                            <button
                                *ngIf="!uploadedAttachmentsActionInProgress.getValue(att.link)"
                                [title]="att.name"
                                type="button"
                                class="download-uploaded-attachment"
                                (click)="download(att)"
                            ></button>
                        </div>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr
                    [ngClass]="{ selected: row.selected }"
                    (click)="selectAWE(row)"
                    mat-row
                    *matRowDef="let row; columns: displayedColumns"
                ></tr>
            </table>
        </div>
        <div class="paginator page-counter">
            <mat-paginator
                [length]="resultsLength"
                [pageSize]="pageSize"
                [pageSizeOptions]="[10, 15, 20]"
                (page)="onPaginatorChange($event)"
                showFirstLastButtons
            >
            </mat-paginator>
            <input type="number" [value]="this.paginator.pageIndex + 1" (change)="onPageChange($event.target.value)" />
        </div>
    </div>
</mat-dialog-content>
<app-loading-indicator *ngIf="isLoading"></app-loading-indicator>
