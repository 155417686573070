import {
    Component,
    OnInit,
    ChangeDetectorRef,
    HostListener,
    OnDestroy,
    ViewChild,
    Renderer2,
    ElementRef,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CallOff, CallOffComment } from '../../store/call-off/model';
import { FormService } from 'src/app/services/shared/form.service';
import { BaseComponent } from 'src/app/components/base.component';
import { CallOffFormValidators } from '../../validators/call-off-form-validators';
import { ActivatedRoute, ParamMap } from '@angular/router';
import {
    takeWhile,
    debounceTime,
    startWith,
    mergeMap,
    map,
    filter,
    distinctUntilChanged,
    take,
    withLatestFrom,
} from 'rxjs/operators';
import { Store, ActionsSubject } from '@ngrx/store';
import {
    GetCallOffRequest,
    CallOffUpdateProperty,
    CallOffClear,
    LockCallOffForm,
    UnLockCallOffForm,
    CallOffActionTypes,
    CallOffUpdateInitialFormWithAttachments,
    CallOffAutosaveRequest,
} from '../../store/call-off/actions';
import { PopupService } from 'src/app/services/shared/popup.service';
import { PopupSettings } from 'src/app/models/popup-settings';
import { Subscription, interval, Subject, combineLatest, defer, from } from 'rxjs';
import { Constants } from 'src/app/constants';
import { JoditDescriptionType } from '../../store/jodit-description/model';
import { JoditDescriptionSetType, JoditDescriptionPreserveBackButton } from '../../store/jodit-description/actions';
import { UserDetail } from 'src/app/store/common.model';
import { CallOffStatusType } from '../../models/enums';
import { InformationDialogComponent } from '../information-dialog/information-dialog.component';
import { ApplicationState } from 'src/app/store/model';
import { MsalService } from '@azure/msal-angular';
import { Dictionary } from 'typescript-collections';
import { CofContractorsEstimateComponent } from './cof-contractors-estimate/cof-contractors-estimate.component';
import { LimitExceeded } from 'src/app/validators/call-off-form-nte-validator';
import { RoleService } from 'src/app/services/shared/role.service';
import { appConfig } from 'src/app/app.config';
import { CallOffAttachmentType } from 'src/app/store/call-off-attachments/model';
import { AccountInfo } from '@azure/msal-common';

declare const Jodit: any;

@Component({
    selector: 'app-call-of-form',
    templateUrl: './call-of-form.component.html',
    styleUrls: ['./call-of-form.component.scss'],
})
export class CallOfFormComponent extends BaseComponent implements OnInit, OnDestroy {
    @ViewChild(CofContractorsEstimateComponent) cofEstimate: CofContractorsEstimateComponent;
    @ViewChild('scrollTargetScopeOfWork') cofScopeOfWork: ElementRef;
    @ViewChild('scrollTargetEstimate') cofEstimateRef: ElementRef;

    jodit: any;
    tokenRefreshSubs: Subscription;
    tokenRefreshInterval = Constants.TokenRefreshInterval;
    tokenRefreshInterval$ = interval(this.tokenRefreshInterval);
    autosaveInterval = Constants.AutosaveInterval;
    autosaveInterval$ = interval(this.autosaveInterval);
    joditDescriptionType: JoditDescriptionType = null;
    editorValueChanged = new Subject<{ key: string; value: string }>();
    isSticky = false;
    user: AccountInfo = null;
    isLocked = false;
    isLatestRevision = true;
    callOffForm: FormGroup;
    initialForm = new CallOff();
    callOffId: number;
    isLoading: boolean = true;
    status: CallOffStatusType;
    originator: UserDetail = null;
    comments: CallOffComment[];
    controlDataReady = new Dictionary<string, boolean>();
    printMode = false;
    attachmentTypes = CallOffAttachmentType;
    statuses = CallOffStatusType;
    callOff$ = this.store.select((state) => state.callOffState.form);
    updatedProperties$ = this.store.select((state) => state.callOffState.updatedProperties);
    joditDescriptionType$ = this.store.select((state) => state.joditDescriptionState.type);
    estimateDetails$ = this.store.select((state) => state.callOffState.form.estimateDetails);
    callOffNumber$ = this.store.select((state) => state.callOffState.form.callOffNumber);
    status$ = this.store.select((state) => state.callOffState.form.status);
    originator$ = this.store.select((state) => state.callOffState.form.originator);
    id$ = this.store.select((state) => state.callOffState.form.id);
    isLocked$ = this.store.select((state) => state.callOffState.isLocked);
    subsystems$ = this.store.select((state) => state.callOffState.form.subsystems);
    tags$ = this.store.select((state) => state.callOffState.form.tags);
    isAutosaveInProgress$ = this.store.select((state) => state.callOffState.isAutosaveInProgress);
    isReadOnly = false;

    callOffLoader$ = this.store.select(
        (state) =>
            state.callOffState.isLoading ||
            state.actionUsersState.isCompanyRepresentativesLoading ||
            state.actionUsersState.isContractorRepresentativesLoading
    );
    comments$ = this.store.select((state) => state.callOffState.form.comments);

    constructor(
        private store: Store<ApplicationState>,
        private formService: FormService,
        private validators: CallOffFormValidators,
        private route: ActivatedRoute,
        private changeDetectorRef: ChangeDetectorRef,
        private popupService: PopupService,
        private authService: MsalService,
        private actionsSubject$: ActionsSubject,
        private renderer: Renderer2,
        private roleService: RoleService
    ) {
        super();
        this.callOffForm = this.formService.createForm(new CallOff(), this.validators);
        this.controlDataReady.setValue('scopeOfWork', false);
        this.controlDataReady.setValue('estimateDetails', false);
        this.renderer.addClass(document.body, 'print-callof');
    }

    ngOnInit() {
        this.user = this.roleService.getAccount();
        this.isReadOnly = this.roleService.isReadOnly();

        this.callOffForm.valueChanges.pipe(takeWhile(() => this.isAlive)).subscribe((data) => {
            if (this.isLocked) {
                this.callOffForm.disable({ emitEvent: false });
            }
        });

        combineLatest([this.status$, this.originator$])
            .pipe(
                takeWhile(() => this.isAlive),
                filter(([status, originator]) => !!status && !!originator && !!originator.email),
                distinctUntilChanged(
                    ([prevStatus, prevOriginator], [nextStatus, nextOriginator]) =>
                        prevStatus === nextStatus && prevOriginator.email === nextOriginator.email
                )
            )
            .subscribe(([status, originator]) => {
                this.status = status;
                if (
                    status === CallOffStatusType.Draft &&
                    originator.email.toLowerCase() === this.user.username.toLowerCase()
                ) {
                    this.enableForm();
                } else {
                    this.disableForm();
                }
            });

        combineLatest([this.subsystems$, this.tags$, this.isLocked$])
            .pipe(
                takeWhile(() => this.isAlive),
                filter(([subsystems, tags]) => !!subsystems && !!tags)
            )
            .subscribe(([subsystems, tags, isLocked]) => {
                if (!isLocked) {
                    if (subsystems.length > 0 && tags.length === 0) {
                        this.callOffForm.controls['subsystems'].enable({
                            emitEvent: false,
                            onlySelf: true,
                        });
                        this.callOffForm.controls['tags'].disable({
                            emitEvent: false,
                            onlySelf: true,
                        });
                    } else if (tags.length > 0) {
                        this.callOffForm.controls['subsystems'].disable({
                            emitEvent: false,
                            onlySelf: true,
                        });
                        this.callOffForm.controls['tags'].enable({
                            emitEvent: false,
                            onlySelf: true,
                        });
                    } else if (tags.length === 0 && subsystems.length === 0) {
                        this.callOffForm.controls['subsystems'].enable({
                            emitEvent: false,
                            onlySelf: true,
                        });
                        this.callOffForm.controls['tags'].enable({
                            emitEvent: false,
                            onlySelf: true,
                        });
                    }
                }
            });

        this.store
            .select((store) => store.callOffState.isLoading)
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((isLoading) => {
                this.isLoading = isLoading;
            });

        this.callOff$
            .pipe(
                takeWhile(() => this.isAlive),
                filter((callOff) => !!callOff.id),
                distinctUntilChanged((prev, curr) => prev.id === curr.id)
            )
            .subscribe((callOff) => {
                this.isLatestRevision = callOff.isLatestRevision;
                this.callOffForm.patchValue(callOff, { emitEvent: false });
                this.callOffId = callOff.id;
                this.setInitialForm();
                this.callOffForm.controls[Constants.Currency.nteUsd].setValidators([
                    LimitExceeded(callOff.contractorLimit),
                ]);
            });

        this.autosaveInterval$
            .pipe(
                takeWhile(() => this.isAlive),
                withLatestFrom(this.status$, this.originator$, this.isAutosaveInProgress$, this.updatedProperties$),
                filter(
                    ([, status, originator, isAutosaveInProgress, updatedProperties]) =>
                        this.areUsersEqual(this.roleService.getAccount(), originator) &&
                        status === CallOffStatusType.Draft &&
                        !isAutosaveInProgress &&
                        updatedProperties.length > 0 &&
                        this.callOffForm.valid
                )
            )
            .subscribe(() => {
                if (!this.isLoading) {
                    this.store.dispatch(new CallOffAutosaveRequest(this.callOffForm.value));
                }
            });

        this.isLocked$.pipe(takeWhile(() => this.isAlive)).subscribe((isLocked) => {
            this.isLocked = isLocked;

            if (isLocked) {
                this.callOffForm.disable({ emitEvent: false });
            } else {
                this.callOffForm.enable({ emitEvent: false });
                setTimeout(() => {
                    if (this.cofEstimate !== undefined) {
                        this.cofEstimate.setContractorsEstimateSectionState(
                            this.callOffForm.value.reasonForInstructionId
                        );
                    }

                    if (this.callOffForm.controls.contractorApproveDate.value) {
                        this.callOffForm.controls['contractorRepresentative'].disable({
                            emitEvent: false,
                            onlySelf: true,
                        });
                    }

                    if (this.callOffForm.controls.companyApproveDate.value) {
                        this.callOffForm.controls['companyRepresentative'].disable({
                            emitEvent: false,
                            onlySelf: true,
                        });
                    }

                    if (this.callOffForm.controls.contractsTeamReviewDate.value) {
                        this.callOffForm.controls['contractsTeamReviewer'].disable({
                            emitEvent: false,
                            onlySelf: true,
                        });
                    }

                    if (
                        this.callOffForm.controls.subsystems.value.length > 0 &&
                        this.callOffForm.controls.tags.value.length === 0
                    ) {
                        this.callOffForm.controls['tags'].disable({
                            emitEvent: false,
                            onlySelf: true,
                        });
                    } else if (this.callOffForm.controls.tags.value.length > 0) {
                        this.callOffForm.controls['subsystems'].disable({
                            emitEvent: false,
                            onlySelf: true,
                        });
                    }
                });
            }
        });

        this.store
            .select((state) => state.callOffState.isCreated)
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((isCreated) => {
                if (isCreated) {
                    this.callOffForm.controls['contractorRepresentative'].enable({
                        emitEvent: false,
                        onlySelf: true,
                    });

                    this.callOffForm.controls['companyRepresentative'].enable({
                        emitEvent: false,
                        onlySelf: true,
                    });

                    this.callOffForm.controls['contractsTeamReviewer'].enable({
                        emitEvent: false,
                        onlySelf: true,
                    });
                }
            });

        this.editorValueChanged
            .pipe(
                takeWhile(() => this.isAlive),
                debounceTime(800)
            )
            .subscribe(({ key, value }) => {
                this.callOffForm.controls[key].patchValue(value);
            });

        this.watchFormChanges();
        this.joditDescriptionType$.pipe(takeWhile(() => this.isAlive)).subscribe((type) => {
            this.joditDescriptionType = type;
        });

        this.setupJodit();
        this.comments$.pipe(takeWhile(() => this.isAlive)).subscribe((comments) => (this.comments = comments));

        this.route.paramMap.pipe(takeWhile(() => this.isAlive)).subscribe((params: ParamMap) => {
            const id = Number.parseInt(params.get('id'));
            this.store.dispatch(new GetCallOffRequest(id));
            this.changeDetectorRef.markForCheck();
        });

        this.callOffLoader$.pipe(takeWhile(() => this.isAlive)).subscribe((isLoading) => (this.isLoading = isLoading));
        this.callOffNumber$
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((number) => this.callOffForm.controls.callOffNumber.setValue(number, { emitEvent: false }));

        this.actionsSubject$
            .pipe(
                takeWhile(() => this.isAlive),
                filter(
                    (action) =>
                        action.type === CallOffActionTypes.CallOffUpdateRequestSuccess ||
                        action.type === CallOffActionTypes.CallOffAutosaveSuccess
                )
            )
            .subscribe(() => this.setInitialForm());

        this.actionsSubject$
            .pipe(
                takeWhile(() => this.isAlive),
                filter((action) => action.type === CallOffActionTypes.CallOffUpdateInitialFormWithAttachments)
            )
            .subscribe((action: CallOffUpdateInitialFormWithAttachments) => {
                this.initialForm = {
                    ...this.initialForm,
                    [`${this.attachmentTypes[action.payload.type]}Attachments`]: action.payload.attachments.attachments,
                };
                this.callOffForm.controls[`${this.attachmentTypes[action.payload.type]}Attachments`].setValue(
                    action.payload.attachments.attachments,
                    { emitEvent: false }
                );
            });

        this.callOffForm.markAllAsTouched();
    }

    getUpdatedProperties(): string[] {
        const result = [];
        if (!this.isNotNullAndNotUndefined(this.initialForm.id)) {
            return result;
        }
        for (const key of Object.keys(this.callOffForm.controls).filter(
            (e) =>
                e !== 'NoMarkupDocuments' &&
                e !== 'SupportingInformationDocuments' &&
                e !== 'AFCDocuments' &&
                e !== 'Comments' &&
                e !== 'nteUsd'
        )) {
            if (
                (!this.isNotNullAndNotUndefined(this.initialForm[key]) || this.initialForm[key] === '') &&
                (!this.isNotNullAndNotUndefined(this.callOffForm.controls[key].value) ||
                    this.callOffForm.controls[key].value === '')
            ) {
                continue;
            }
            if (
                (key === 'SupportingDocumentAttachments' || key === 'EstimateAttachments') &&
                !this.compareArraysOfObjectsByProperty(
                    this.initialForm[key],
                    this.callOffForm.controls[key].value,
                    'name'
                )
            ) {
                result.push(key);
            } else if (JSON.stringify(this.initialForm[key]) !== JSON.stringify(this.callOffForm.controls[key].value)) {
                result.push(key);
            }
        }

        return result;
    }
    watchFormChanges() {
        for (const key of Object.keys(this.callOffForm.controls)) {
            this.callOffForm.controls[key].valueChanges.pipe(takeWhile(() => this.isAlive)).subscribe((value) => {
                const updatedProperties = this.getUpdatedProperties();
                this.store.dispatch(
                    new CallOffUpdateProperty({
                        key,
                        value,
                        updatedProperties,
                    })
                );
            });
        }
    }
    ngOnDestroy() {
        super.ngOnDestroy();
        this.store.dispatch(new CallOffClear());
        this.renderer.removeClass(document.body, 'print-callof');
    }

    onEditorValueSet(event: { controlName: string; value: string }) {
        this.callOffForm.controls[event.controlName].patchValue(event.value, { emitEvent: false });
        this.controlDataReady.setValue(event.controlName, true);
        this.setInitialForm();
    }

    private setInitialForm() {
        if (this.isNotNullAndNotUndefined(this.callOffForm.value.id)) {
            for (const key of Object.keys(this.callOffForm.controls)) {
                const dataReady = this.controlDataReady.getValue(key) || true;
                if (dataReady) {
                    this.initialForm[key] = JSON.parse(JSON.stringify(this.callOffForm.controls[key].value));
                }
            }
        }
    }

    enableForm() {
        this.store.dispatch(new UnLockCallOffForm());
    }

    disableForm() {
        this.store.dispatch(new LockCallOffForm());
    }

    private setupJodit() {
        this.jodit = new Jodit('#editor', {
            language: 'en',
            uploader: {
                url: `${appConfig.apiEndpoint}/image`,
                headers: {
                    Authorization: '',
                },
                prepareData: (formData: FormData) => {
                    formData.append('callOffId', this.callOffId.toString());
                },
                error: () => {
                    this.popupService.openPopup(
                        new PopupSettings(
                            InformationDialogComponent,
                            null,
                            null,
                            {
                                title: 'Not allowed image format',
                                text: 'Images are allowed only in jpg, jpeg, bmp and png format.',
                            },
                            1,
                            1
                        )
                    );
                },
            },
            buttons: `|,bold,strikethrough,underline,italic,|,
                superscript,subscript,|,ul,ol,|,outdent,indent,|,
                font,fontsize,brush,paragraph,|,image,table,link,|,
                align,undo,redo,\n,cut,hr,eraser,copyformat,|,symbol`,
            extraButtons: [
                {
                    name: 'FullWindow',
                    iconURL: 'assets/images/icons/close.svg',
                    exec: (editor) => {
                        editor.toggleFullSize();
                        document.getElementsByClassName('editor-jodit-container')[0].className += ' hidden-jodit';
                        this.scrollToElement();
                        this.store.dispatch(new JoditDescriptionSetType(null));
                        this.tokenRefreshSubs.unsubscribe();
                    },
                },
            ],
            events: {
                change: (newValue) => {
                    if (this.joditDescriptionType !== null) {
                        if (newValue.indexOf(';base64,') > -1 || newValue.indexOf('src="file:') > -1) {
                            if (this.joditDescriptionType === JoditDescriptionType.ContractorsEstimate) {
                                this.jodit.value = this.callOffForm.controls.EstimateDetails.value
                                    ? this.callOffForm.controls.EstimateDetails.value || ''
                                    : '';
                            } else if (this.joditDescriptionType === JoditDescriptionType.ScopeOfWork) {
                                this.jodit.value = this.callOffForm.controls.NoticeInstruction.value
                                    ? this.callOffForm.controls.NoticeInstruction.value || ''
                                    : '';
                            }
                            this.popupService.openPopup(
                                new PopupSettings(
                                    InformationDialogComponent,
                                    null,
                                    null,
                                    {
                                        title: 'Paste not allowed for the images',
                                        text: 'Please use Insert Image option from toolbar menu to insert an image.',
                                    },
                                    1,
                                    1
                                )
                            );
                        } else {
                            this.editorValueChanged.next({
                                key: this.joditDescriptionType.toString(),
                                value: newValue,
                            });
                        }
                    }
                },
            },
        });
    }

    openJoditPopup(event: { description: string; type: JoditDescriptionType }) {
        document.getElementsByClassName('editor-jodit-container')[0].className = document
            .getElementsByClassName('editor-jodit-container')[0]
            .className.replace(/hidden-jodit/g, '');
        this.store.dispatch(new JoditDescriptionSetType(event.type));
        this.jodit.value = event.description || '';
        this.jodit.toggleFullSize(true);
        this.tokenRefreshSubs = this.tokenRefreshInterval$
            .pipe(
                takeWhile(() => this.isAlive),
                startWith(0),
                mergeMap(() =>
                    this.authService.acquireTokenSilent({ scopes: ['user.read'] }).pipe(
                        map((result) => {
                            this.jodit.uploader.options.headers = { Authorization: `Bearer ${result.accessToken}` };
                        })
                    )
                )
            )
            .subscribe();
    }

    @HostListener('window:popstate')
    onPopState() {
        if (this.joditDescriptionType !== null) {
            this.jodit.toggleFullSize();
            document.getElementsByClassName('editor-jodit-container')[0].className += ' hidden-jodit';
            this.tokenRefreshSubs.unsubscribe();
            this.scrollToElement();
            this.store.dispatch(new JoditDescriptionSetType(null));
            this.store.dispatch(new JoditDescriptionPreserveBackButton(true));
        }
    }

    @HostListener('window:scroll', ['$event'])
    checkScroll() {
        this.isSticky = window.pageYOffset >= 250;
    }

    printCallOff() {
        this.printMode = true;

        if (this.printMode) {
            setTimeout(() => {
                window.print();
                setTimeout(() => (this.printMode = false));
            });
        }
    }

    private scrollToElement() {
        this.joditDescriptionType === JoditDescriptionType.ScopeOfWork
            ? this.cofScopeOfWork.nativeElement.scrollIntoView({ behavior: 'auto', block: 'start' })
            : this.cofEstimateRef.nativeElement.scrollIntoView({ behavior: 'auto', block: 'start' });
    }
}
