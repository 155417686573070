export enum CallOffStatusType {
    Draft = 1,
    Submitted = 2,
    Reviewed = 3,
    Approved = 4,
    Completed = 5,
    Cancelled = 6,
    Void = 7,
    CompanyCompleted = 8,
}

export enum CallOffStatusChangeAction {
    Draft = 1,
    Submit = 2,
    ContractsTeamReview = 3,
    ContractorApprove = 4,
    CompanyApprove = 5,
    Issue = 6,
    Cancel = 7,
    Void = 8,
    Complete = 9,
    CompanyComplete = 10,
}

export enum CallOffPricingMechanism {
    Undefined,
    LumpSum,
    Mixed,
    Reimbursable,
    UnitRate,
}

export enum ChangeDocParentType {
    Nil,
    DCN,
    RFI,
    NCR,
    PCN,
    OSD,
    MDR,
}
