import { UserDetail } from 'src/app/store/common.model';
import { Area, ReasonForInstruction } from '../common/model';
import { AWE } from '../awe-form/model';
import { CallOffStatusType, CallOffPricingMechanism, ChangeDocParentType } from '../../models/enums';
import { Attachment } from '../call-off-attachments/model';

export class CallOffInitialFields {
    subject: string = null;
    areaId: number = null;
    aweNumber: string = null;
    discipline = '';
    reasonForInstructionId: number = null;
}

export class CallOff {
    id: number = null;
    aweId: number = null;
    awe: AWE = new AWE();
    callOffNumber = '';
    revision = '';
    cwps: CWP[] = [];
    subsystems: Subsystem[] = [];
    tags: Tag[] = [];
    subject = '';
    raisedDate: Date = null;
    area: Area = new Area();
    areaId: number = null;
    discipline = '';
    originator: UserDetail = new UserDetail();
    reasonForInstructionId: number = null;
    reasonForInstruction: ReasonForInstruction = new ReasonForInstruction();
    status: CallOffStatusType = CallOffStatusType.Draft;
    scopeOfWork: string = null;
    estimateDetails: string = null;
    contractsTeamReviewer: UserDetail = new UserDetail();
    wasContractsTeamReviewerAdmin = false;
    contractorRepresentative: UserDetail = new UserDetail();
    wasContractorRepresentativeAdmin: boolean = false;
    companyRepresentative: UserDetail = new UserDetail();
    wasCompanyRepresentativeAdmin: boolean = false;
    pricingMechanism: CallOffPricingMechanism = CallOffPricingMechanism.LumpSum;
    usd: number = null;
    kzt: number = null;
    eur: number = null;
    rub: number = null;
    nteUsd: number = null;
    contractorLimit: number = null;
    priority: number = null;
    changeDocParentType: ChangeDocParentType = ChangeDocParentType.Nil;
    changeDocRef = '';
    canSendAutomatedEmails = false;
    routeDate: Date = null;
    contractorApproveDate: Date = null;
    companyApproveDate: Date = null;
    contractsTeamReviewDate: Date = null;
    history: CallOffHistory[] = [];
    comments: CallOffComment[] = [];
    isLatestRevision = false;
    contractorCurrencies = '';
    SupportingDocumentAttachments: Attachment[] = [];
    EstimateAttachments: Attachment[] = [];
    COFCompletedAttachments: Attachment[] = [];
    completedUser = new UserDetail();
    completedDate: Date = null;
    companyCompletedUser = new UserDetail();
    companyCompletedDate: Date = null;
    COFCompanyCompletedAttachments: Attachment[] = [];
    OldSupportingDocumentAttachments: Attachment[] = [];
    OldEstimateAttachments: Attachment[] = [];
    OldCOFCompletedAttachments: Attachment[] = [];
    OldCOFCompanyCompletedAttachments: Attachment[] = [];
}

export interface CallOffState {
    readonly form: CallOff;
    readonly updatedProperties: string[];
    readonly isLoading: boolean;
    readonly isHistoryLoading: boolean;
    readonly isCreated: boolean;
    readonly isEstimateDetailsLoading: boolean;
    readonly isScopeOfWorkLoading: boolean;
    readonly isLocked: boolean;
    readonly isAutosaveInProgress: boolean;
}
export interface CallOffHistory {
    callOffId: number;
    status: CallOffStatusType;
    user: UserDetail;
    date: Date;
    action: string;
    prevModelJson: string;
    newModelJson: string;
}
export class CWP {
    id = '';
}

export class Subsystem {
    id = '';
}

export class Tag {
    tagNo = '';
    tagType = '';
}

export class CallOffComment {
    id: number = 0;
    description: string = '';
    userId: number = 0;
    user: UserDetail = null;
    date: Date = null;
    callOffId: number = 0;
    isLoading: boolean = true;
}

export class ExchangeRate {
    currency = '';
    value: number;
    prevValue: number;
    isInEditMode: boolean;
    default: number;
}

export class ExchangeRateEdit extends ExchangeRate {
    prevValue: number;
    isInEditMode: boolean;
}

export class ShareEmail {
    recipient: string;
    id: number;
    number: string;
    subject: string;
}
