import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ToastService } from 'src/app/services/shared/toast.service';
import { BaseComponent } from 'src/app/components/base.component';
import { Contractor } from 'src/app/store/common.model';
import { takeWhile, take } from 'rxjs/operators';
import { NTENotificationDTO } from '../../../../../models/nte-notification-dto';
import { NTENotificationService } from 'src/app/services/nte-notification.service';
import { LookupService } from 'src/app/services/lookup.service';
import { commaSepEmail } from 'src/app/validators/comma-seperated-emails-validator';
 
@Component({
    selector: 'app-nte-notification-popup',
    templateUrl: './add-nte-notification-popup.component.html',
    styleUrls: ['./add-nte-notification-popup.component.scss'],
})
export class AddNTENotificationPopupComponent extends BaseComponent implements OnInit {
    loading: boolean;
    nteNotificationForm: FormGroup;
    contractors: Contractor[] = [];
    
    constructor(
        public dialogRef: MatDialogRef<AddNTENotificationPopupComponent>,
        private toastService: ToastService,
        private nteNotificationService: NTENotificationService,
        private lookupService: LookupService
    ) {
        super();
      
        this.nteNotificationForm = new FormGroup({
            contract: new FormControl('', Validators.required),
            emails: new FormControl('', [Validators.required, commaSepEmail]),
            percentageLimit: new FormControl(50, Validators.required),
        });
    }

    ngOnInit(): void {
        this.loading = true;
        this.lookupService.searchContractors().pipe(take(1)).subscribe(
            (data: Contractor[]) => {
                this.loading = false;
                this.contractors = data;
            });
    }

    public onCancel(): void {
        this.dialogRef.close();
    }

    public onConfirm(): void {
        this.loading = true;
        const form = new NTENotificationDTO();

        form.contract = this.nteNotificationForm.controls.contract.value;
        form.percentageLimit = this.nteNotificationForm.controls.percentageLimit.value;
        form.emails = this.nteNotificationForm.controls.emails.value;

        this.nteNotificationService
            .add(form)
            .pipe(takeWhile(() => this.isAlive === true))
            .subscribe(
                (data) => {
                    this.loading = false;
                    this.toastService.Success(`${form.contract} has been added to the automated AWE NTE notifications.`);
                    this.dialogRef.close({ success: true, data: data });
                },
                (error) => {
                    this.loading = false;
                    if (error.status === 409) {
                        this.toastService.Error(
                            `${form.contract} already exists in automated AWE NTE notifications.`
                        );
                    } else {
                        this.toastService.Error(
                            'An error occurred while adding notification to the automated AWE NTE notifications. Please contact a Program Administrator.'
                        );
                    }
                }
            );
    }
}
