import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import {
    CallOffRegisterActionTypes,
    CallOffRegisterFilterSuccess,
    CallOffRegisterFilterError,
    CallOffRegisterExportToExcelSuccess,
    CallOffRegisterExportToExcelError,
} from './actions';
import { withLatestFrom, mergeMap, map, catchError } from 'rxjs/operators';
import { CallOffService } from '../../services/call-off.service';
import { ToastService } from '../../services/shared/toast.service';
import { of } from 'rxjs';
import moment from 'moment';
import { ApplicationState } from '../model';
import { saveAs } from 'file-saver';

@Injectable()
export class CallOffRegisterEffects {
    constructor(
        private store: Store<ApplicationState>,
        private actions$: Actions,
        private callOffService: CallOffService,
        private toastService: ToastService
    ) {}

    
    filterRequest$ = createEffect(() => this.actions$.pipe(
        ofType(CallOffRegisterActionTypes.CallOffRegisterFilterRequest),
        withLatestFrom(this.store.select((store) => store.callOffRegisterState.filter)),
        mergeMap(([, filter]) => {
            return this.callOffService.getCallOffRegisterData(filter).pipe(
                map((dataPagination) => {
                    return new CallOffRegisterFilterSuccess(dataPagination);
                }),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while filtering Call off register data. Please contact Program Administrator.'
                    );
                    return of(new CallOffRegisterFilterError(error));
                })
            );
        })
    ));

    
    exportToExcel$ = createEffect(() => this.actions$.pipe(
        ofType(CallOffRegisterActionTypes.CallOffRegisterExportToExcelRequest),
        withLatestFrom(this.store.select((store) => store.callOffRegisterState.filter)),
        mergeMap(([, CallOffRegisterFilter]) => {
            let filter = Object.assign({}, CallOffRegisterFilter);
            filter.timezoneOffset = new Date().getTimezoneOffset();
            return this.callOffService.generateExcel(CallOffRegisterFilter).pipe(
                map((excelData) => new CallOffRegisterExportToExcelSuccess(excelData)),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while exporting data to Excel. Please contact Program Administrator.'
                    );
                    return of(new CallOffRegisterExportToExcelError(error));
                })
            );
        })
    ));

    
    saveExcelData = createEffect(() => this.actions$.pipe(
        ofType(CallOffRegisterActionTypes.CallOffRegisterExportToExcelSuccess),
        map((action: CallOffRegisterExportToExcelSuccess) => {
            this.toastService.Success('Data successfully exported to Excel.');

            const blob = new Blob([action.payload], {
                type: 'application/octet-stream',
            });

            saveAs(blob, `CallOff_${moment().format('YYYYMMDD_HHmmss')}` + '.xlsx');
        })
    ), { dispatch: false });
}
