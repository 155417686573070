import { Injectable } from '@angular/core';

@Injectable()
export class Constants {
    static readonly applicableGroups = {
        ReadOnly: 'ReadOnly',
        CallOffUser: 'CallOffUser',
        Contracts: 'Contracts',
        TCOApprover: 'TCOApprover',
        ContractorApprover: 'ContractorApprover',
        Admin: 'Admin',
    };

    static readonly popupWidth: number = 500;
    static readonly popupHeight: number = 300;
    static readonly TokenRefreshInterval = 1800000;
    static readonly AutosaveInterval = 1800000;

    static get CallOffActions() {
        return {
            draft: 'draft',
            edit: 'edit',
            revertToDraft: 'revertToDraft',
            contractorRepresentativeApproved: 'contractorRepresentativeApproved',
            companyRepresentativeApproved: 'companyRepresentativeApproved',
            processed: 'processed',
            cancel: 'cancel',
        };
    }

    static get CallOffDefaults() {
        return {
            defaultSortField: 'raisedDate',
        };
    }

    static get Currency() {
        return {
            usd: 'usd',
            kzt: 'kzt',
            rub: 'rub',
            eur: 'eur',
            nteUsd: 'nteUsd',
        };
    }
}
