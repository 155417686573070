import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AWE } from '../store/awe-form/model';
import { AweRegisterFilter, AweRegisterDataPagination } from '../store/awe-register/model';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { appConfig } from '../app.config';

@Injectable({
    providedIn: 'root',
})
export class AWEService {
    private readonly rootUrl: string = appConfig.apiEndpoint;
    private readonly callOffAWEEndpoint: string = '/calloff/awe';
    constructor(private http: HttpClient) {}

    addAWE(awe: AWE): Observable<any> {
        return this.http.post(`${this.rootUrl}${this.callOffAWEEndpoint}`, awe);
    }

    updateAWE(awe: AWE): Observable<any> {
        return this.http.put(`${this.rootUrl}${this.callOffAWEEndpoint}`, awe);
    }

    getAweById(id: number) {
        return this.http.get<AWE>(`${this.rootUrl}${this.callOffAWEEndpoint}/${id}`);
    }

    getAweRegisterData(filter: AweRegisterFilter) {
        return this.http.post<AweRegisterDataPagination>(`${this.rootUrl}${this.callOffAWEEndpoint}/register`, filter);
    }

    deleteAwe(awe: AWE) {
        return this.http.delete(`${this.rootUrl}${this.callOffAWEEndpoint}/${awe.id}`, {});
    }

    isAWENumberTaken(aweNumber: string) {
        return this.http.get<boolean>(`${this.rootUrl}${this.callOffAWEEndpoint}/${aweNumber}/isTaken`);
    }

    uploadAWEAttachments(formData: FormData) {
        return this.http.post(`${this.rootUrl}${this.callOffAWEEndpoint}/attachments`, formData);
    }

    deleteAWEAttachment(links: string[], aweId: number = null) {
        let params = new HttpParams();
        links.forEach((link) => {
            params = params.append('links', link);
        });
        params = params.append('aweId', aweId ? aweId.toString() : '');

        return this.http.delete(`${this.rootUrl}${this.callOffAWEEndpoint}/attachments`, { params });
    }

    downloadAWEAttachment(link: string) {
        return this.http.get<Blob>(`${this.rootUrl}${this.callOffAWEEndpoint}/attachments?link=${link}`, {
            responseType: 'arraybuffer' as 'json',
        });
    }

    generateExcel(filter: AweRegisterFilter) {
        return this.http.post(`${this.rootUrl}${this.callOffAWEEndpoint}/generateexcel`, filter, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Access-Control-Allow-Headers': 'Content-Disposition',
            }),
            responseType: 'blob' as 'json',
        });
    }
}
