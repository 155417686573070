import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../base.component';

@Component({
    selector: 'app-configuration',
    templateUrl: './configuration.component.html',
    styleUrls: ['./configuration.component.scss'],
})
export class ConfigurationComponent extends BaseComponent implements OnInit {
    navLinks: { label: string; link: string; index: number }[] = [];

    constructor() {
        super();
        this.navLinks = [
            {
                label: 'settings',
                link: './settings',
                index: 0,
            },
            {
                label: 'exchange rate',
                link: './exchange-rate',
                index: 1,
            },
            {
                label: 'automated awe nte notification',
                link: './nte-notification',
                index: 2,
            },
            {
                label: 'limits',
                link: './limits',
                index: 3,
            },
            {
                label: 'currencies',
                link: './currencies',
                index: 4,
            },
        ];
    }

    ngOnInit(): void {}
}
