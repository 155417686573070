import { UserDetail, AppRole } from '../store/common.model';
import { ReasonForInstruction } from '../store/common/model';

export class ApprovalMatrixDTO {
    id: number;
    approver: UserDetail;
    approverType: AppRole;
    reasonForInstructions: ReasonForInstruction[];
    contractNo: string;
    isInEditMode: boolean;
    prevContractNo: string;
    prevApproverType: AppRole;
    prevReasonForInstructions: ReasonForInstruction[];
    prevApprover: UserDetail;
}
