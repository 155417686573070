import { Injectable } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { IValidators } from 'src/app/validators/validators';

@Injectable({
    providedIn: 'root',
})
export class FormService {
    constructor() {}

    createForm(model: any, validators: IValidators): FormGroup {
        const formDetail = new FormGroup({});
        // tslint:disable-next-line: forin
        for (const fieldName in model) {
            const asyncValidators =
                typeof validators.getAsyncValidators === 'function' ? validators.getAsyncValidators(fieldName) : [];
            formDetail.addControl(
                fieldName,
                new FormControl(model[fieldName], {
                    validators:
                        typeof validators.getValidators === 'function' ? validators.getValidators(fieldName) : [],
                    asyncValidators,
                    updateOn: asyncValidators.length ? 'blur' : undefined,
                })
            );
        }
        return formDetail;
    }

    createSimpleForm(model: any): FormGroup {
        const formDetail = new FormGroup({});
        // tslint:disable-next-line: forin
        for (const fieldName in model) {
            formDetail.addControl(fieldName, new FormControl(model[fieldName]));
        }
        return formDetail;
    }
}
