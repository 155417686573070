import { Injectable } from '@angular/core';
import { Constants } from 'src/app/constants';
import { MsalService } from '@azure/msal-angular';

@Injectable({
    providedIn: 'root',
})
export class RoleService {
    get userRoleNames(): string[] {
        var account = this.authService.instance.getActiveAccount();
        return !!account
            ? (account.idTokenClaims.roles as any) || []
            : [];
    }

    constructor(private authService: MsalService) {}

    isInRole(groupName: string): boolean {
        return this.userRoleNames && this.userRoleNames.filter((i) => i === groupName).length > 0;
    }

    isInAnyRole(allowedGroupNames?: string[]): boolean {
        if (allowedGroupNames === undefined) {
            return this.userRoleNames && this.userRoleNames.length > 0;
        } else {
            return allowedGroupNames.filter((groupName) => this.userRoleNames && this.userRoleNames.indexOf(groupName) !== -1).length > 0;
        }
    }

    isReadOnly() {
        return (
            this.userRoleNames &&
            this.userRoleNames.filter((i) => i === Constants.applicableGroups.ReadOnly).length > 0 &&
            this.userRoleNames.length === 1
        );
    }

    getAccount() {
        return this.authService.instance.getActiveAccount();
    }
}
