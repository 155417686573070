<div class="popup">
    <p mat-dialog-title class="title"><span class="font-gotham-narrow-bold">Delegate</span></p>
    <div>
        <div class="delegate-list">
            <label>Delegate Call Offs:</label>
            <ul>
                <li *ngFor="let item of selectedItems">
                    <span>{{ item.callOffNumber + ' rev ' + item.revision }}</span>
                </li>
            </ul>
        </div>
        <form [formGroup]="delegateForm">
            <div class="user-selector">
                <app-multiple-items-selector
                    formControlName="user"
                    [filterForm]="delegateForm"
                    [formSetter]="'user'"
                    [isAsync]="true"
                    [searchFunc]="getUsers"
                    propertyToShow="name"
                    placeholder="User"
                    [ngStyle]="{ display: isIE === true ? 'inline' : null }"
                    [oneItemMode]="true"
                    [isTableAutoComplete]="true"
                    [displayedColumns]="autocompleteDisplayedColumns"
                >
                </app-multiple-items-selector>
            </div>
        </form>
        <div class="row">
            <button mat-button type="button" class="cof-button cof-button-white" (click)="onCancel()">
                cancel
            </button>
            <button
                mat-button
                type="button"
                [disabled]="!delegateForm.valid"
                class="cof-button cof-button-light"
                (click)="onConfirm()"
            >
                delegate
            </button>
        </div>
    </div>
</div>
