<div class="title-container">
    <div class="dmtitle-container">
        <h4>amend users contracts</h4>
    </div>
</div>
<div class="results-container">
    <table
        mat-table
        matSort
        [dataSource]="dataSource"
        [matSortActive]="sortBy"
        matSortDisableClear
        [matSortDirection]="direction"
    >
        <ng-container matColumnDef="userRole">
            <th mat-header-cell disableClear *matHeaderCellDef>
                <div class="sort-container">
                    <span
                        [ngClass]="{
                            'header-underline': showUnderlineForCheckListColumn(columnUserRoles)
                        }"
                        class="hover-pointer"
                        (click)="
                            openHeaderCheckListFilter(
                                'userRole',
                                getValues,
                                'columnUserRoles',
                                'User Role',
                                columnUserRoles,
                                false,
                                false
                            )
                        "
                        >User Role</span
                    >
                    <div class="sort-icon" *ngIf="sortBy === 'userRole'">
                        <mat-icon *ngIf="direction === 'asc'">keyboard_arrow_up</mat-icon>
                        <mat-icon *ngIf="direction === 'desc'">keyboard_arrow_down</mat-icon>
                    </div>
                </div>
            </th>
            <td mat-cell *matCellDef="let element">
                <div>{{ findRole(types, element.userRole)}}</div>
            </td>
        </ng-container>
        <ng-container matColumnDef="user">
            <th mat-header-cell disableClear *matHeaderCellDef>
                <div class="sort-container center">
                    <span
                        [ngClass]="{
                            'header-underline': showUnderlineForCheckListColumn(columnUsers)
                        }"
                        class="hover-pointer"
                        (click)="
                            openHeaderCheckListFilter('user', getValues, 'columnUsers', 'User', columnUsers)
                        "
                        >User</span
                    >
                    <div class="sort-icon" *ngIf="sortBy === 'user'">
                        <mat-icon *ngIf="direction === 'asc'">keyboard_arrow_up</mat-icon>
                        <mat-icon *ngIf="direction === 'desc'">keyboard_arrow_down</mat-icon>
                    </div>
                </div>
            </th>
            <td mat-cell *matCellDef="let element">
                <div>{{ element.user.name + ' (' + element.user.email + ')'}}</div>
            </td>
        </ng-container>
        <ng-container matColumnDef="contracts">
            <th mat-header-cell disableClear *matHeaderCellDef>
                <div class="sort-container">
                    <span
                        [ngClass]="{
                            'header-underline': showUnderlineForCheckListColumn(columnContracts)
                        }"
                        class="hover-pointer"
                        (click)="
                            openHeaderCheckListFilter(
                                'contracts',
                                getValues,
                                'columnContracts',
                                'Contracts',
                                columnContracts
                            )
                        "
                        >Contracts</span
                    >
                    <div class="sort-icon" *ngIf="sortBy === 'contracts'">
                        <mat-icon *ngIf="direction === 'asc'">keyboard_arrow_up</mat-icon>
                        <mat-icon *ngIf="direction === 'desc'">keyboard_arrow_down</mat-icon>
                    </div>
                </div>
            </th>
            <td mat-cell *matCellDef="let element">
                <div *ngIf="!element.isInEditMode">{{ element.contracts.join(', ') }}</div>
                <div *ngIf="element.isInEditMode" class="editmode">
                    <mat-select matNativeControl [(value)]="element.contracts" multiple>
                        <mat-option *ngFor="let c of contractors" [value]="c.contractNo">{{ c.contractNo }}</mat-option>
                    </mat-select>
                </div>
            </td>
        </ng-container>
        <ng-container matColumnDef="edit">
            <th mat-header-cell disableClear *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
                <button *ngIf="!element.isInEditMode" mat-icon-button (click)="enterEditMode(element)" title="edit">
                    <mat-icon svgIcon="edit"></mat-icon>
                </button>
                <button *ngIf="element.isInEditMode" mat-icon-button (click)="save(element)" title="accept">
                    <mat-icon>check_circle_outline</mat-icon>
                </button>
                <button *ngIf="element.isInEditMode" mat-icon-button (click)="cancelEdit(element)" title="cancel">
                    <mat-icon>cancel</mat-icon>
                </button>
            </td>
        </ng-container>
        <ng-container matColumnDef="remove">
            <th mat-header-cell disableClear *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
                <button mat-icon-button (click)="remove(element)" title="remove">
                    <mat-icon svgIcon="delete"></mat-icon>
                </button>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    <mat-toolbar>
        <span>Add new User To Contracts record</span>
        <button mat-icon-button (click)="add()" title="add">
            <mat-icon svgIcon="add"></mat-icon>
        </button>
    </mat-toolbar>
    <div class="paginator page-counter">
        <mat-paginator
            [length]="resultsLength"
            [pageSize]="pageSize"
            [pageSizeOptions]="[5, 10, 15, 20]"
            showFirstLastButtons
        >
        </mat-paginator>
        <input type="number" [value]="this.paginator.pageIndex + 1" (change)="onPageChange($event.target.value)" />
    </div>
</div>
<app-loading-indicator *ngIf="isLoading"> </app-loading-indicator>
