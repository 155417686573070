<div class="attachments-container">
    <div class="position">
        <div class="title">Attachments</div>

        <div [formGroup]="callOffForm">
            <app-cof-attachment-sia
                formControlName="SupportingDocumentAttachments"
                [headerText]="supportingDocsHeader"
                [callOffForm]="callOffForm"
                [printMode]="printMode"
            >
            </app-cof-attachment-sia>
        </div>
    </div>
</div>
