import { Injectable } from '@angular/core';
import { CallOff, CallOffInitialFields, CallOffComment, CallOffHistory, ShareEmail } from '../store/call-off/model';
import { CallOffRegisterFilter, CallOffRegisterDataPagination } from '../store/call-off-register/model';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { CallOffStatusChangeAction } from '../models/enums';
import { Attachment, CallOffAttachmentType } from '../store/call-off-attachments/model';
import { appConfig } from '../app.config';

@Injectable({
    providedIn: 'root',
})
export class CallOffService {
    private readonly rootUrl: string = appConfig.apiEndpoint;
    private readonly callOffEndpoint: string = '/calloff';
    private readonly attachmentTypes = CallOffAttachmentType;

    constructor(private http: HttpClient) {}

    createCallOff(newCallOff: CallOffInitialFields) {
        return this.http.post<number>(`${this.rootUrl}${this.callOffEndpoint}`, newCallOff);
    }

    getCallOffById(id: number) {
        return this.http.get<CallOff>(`${this.rootUrl}${this.callOffEndpoint}/${id}`);
    }

    getScopeOfWorkDescription(callOffId: number) {
        return this.http.get(`${this.rootUrl}${this.callOffEndpoint}/${callOffId}/scopeofwork`, {
            responseType: 'text',
        });
    }
    getEstimateDetailsDescription(callOffId: number) {
        return this.http.get(`${this.rootUrl}${this.callOffEndpoint}/${callOffId}/estimatedetails`, {
            responseType: 'text',
        });
    }

    updateCallOffStatus(id: number, statusChangeAction: CallOffStatusChangeAction) {
        return this.http.patch<CallOff>(`${this.rootUrl}${this.callOffEndpoint}/${id}`, {
            statusChangeAction,
        });
    }
    updateCallOff(form: CallOff, updatedProperties: string[]) {
        return this.http.put<CallOff>(`${this.rootUrl}${this.callOffEndpoint}`, {
            calloff: form,
            updatedProperties,
        });
    }
    getCallOffRegisterData(filter: CallOffRegisterFilter) {
        return this.http.post<CallOffRegisterDataPagination>(`${this.rootUrl}${this.callOffEndpoint}/register`, filter);
    }

    shareEmail(shareDto: ShareEmail) {
        return this.http.post(`${this.rootUrl}${this.callOffEndpoint}/share`, shareDto);
    }

    generateExcel(filter: CallOffRegisterFilter) {
        return this.http.post(`${this.rootUrl}${this.callOffEndpoint}/generateexcel`, filter, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Access-Control-Allow-Headers': 'Content-Disposition',
            }),
            responseType: 'blob' as 'json',
        });
    }

    uploadCallOffAttachmentsRequest(callOffId: number, attachments: Attachment[]) {
        const formData = new FormData();
        formData.append('CallOffId', callOffId.toString());
        attachments.forEach((attachment) => {
            formData.append(
                this.attachmentTypes[attachment.callOffAttachmentType],
                attachment.file,
                attachment.file.name
            );
        });
        return this.http.post(`${this.rootUrl}${this.callOffEndpoint}/attachments`, formData);
    }

    getCallOffAttachmentsRequest(payload: { id: number; type: CallOffAttachmentType }) {
        return this.http.get(`${this.rootUrl}${this.callOffEndpoint}/${payload.id}/attachments/${payload.type}`);
    }

    getCallOffPreviousRevisionsRequest(id: number) {
        return this.http.get(`${this.rootUrl}${this.callOffEndpoint}/${id}/revisions/`);
    }

    downloadCallOffAttachmentRequest(attachment: Attachment) {
        return this.http.get<Blob>(
            `${this.rootUrl}${this.callOffEndpoint}/attachments?callOffId=${attachment.callOffId}&attachmentName=${
                attachment.name
            }&attachmentType=${this.attachmentTypes[attachment.callOffAttachmentType]}`,
            { responseType: 'arraybuffer' as 'json' }
        );
    }

    deleteCallOffAttachmentRequest(attachment: Attachment) {
        return this.http.delete(
            `${this.rootUrl}${this.callOffEndpoint}/attachments?callOffId=${attachment.callOffId}&attachmentName=${attachment.name}`
        );
    }

    removeComment(comment: CallOffComment) {
        return this.http.request('delete', `${this.rootUrl}${this.callOffEndpoint}/comment`, { body: comment });
    }

    addComment(comment: CallOffComment) {
        return this.http.post<CallOffComment>(`${this.rootUrl}${this.callOffEndpoint}/comment`, comment);
    }

    loadHistory(callOffId: number) {
        return this.http.get<CallOffHistory[]>(`${this.rootUrl}${this.callOffEndpoint}/${callOffId}/history`);
    }

    newCallOffRevision(callOffId: number) {
        return this.http.get(`${this.rootUrl}${this.callOffEndpoint}/newrevision/${callOffId}`);
    }
}
